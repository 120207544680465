import React, { useEffect, useState } from "react";
import styles from "./DDUserAssets.module.css";
import CustomSelectChip from "../CustomSelectChip/CustomSelectChip";
import droneImg from "../../Assets/drone18x53.png";
import assetVehicle from "../../Assets/assetVehicle.svg";
import assetBattery from "../../Assets/assetBattery.svg";
import assetCharger from "../../Assets/assetCharger.svg";
import { token_api } from "../../Utils/network";
import { useParams } from "react-router-dom";
import chargingHubAsset from "../../Assets/chargingHubAsset.png";
import laptopAsset from "../../Assets/lapTopAsset.png";
import CustomPagination from "../CustomPagination/CustomPagination";

const DDUserAssets = (props) => {
  const { user } = props;
  const { id } = useParams();
  const [filters, setFilters] = useState({
    category: [],
  });

  const [allAssetsData, setallAssetsData] = useState({});
  const [droneAssetsData, setDroneAssetsData] = useState([]);
  const [chargerAssetsData, setChargerAssetsData] = useState([]);
  const [batteryAssetsData, setBatterAssetsData] = useState([]);
  const [vehicleAssetsData, setVehicleAssetsData] = useState([]);
  const [chargingHubAssetsData, setChargingHubAssetsData] = useState([]);
  const [laptopAssetsData, setLaptopAssetsData] = useState([]);
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;

  const getCategoryItemRow = (item) => {
    if (item?.category === "Drone") {
      return (
        <div className={styles.assetContainer}>
          <div>
            <img src={droneImg} />
          </div>
          <div className={styles.assetCon}>
            <h3>Drone Name</h3>
            <p style={{ color: "rgba(255, 153, 8, 1)" }}>{item?.drone_name}</p>
          </div>
          <div className={styles.assetCon}>
            <h3>Drone ID</h3>
            <p>{item?.drone_id}</p>
          </div>
          <div className={styles.assetCon}>
            <h3>Assigned From</h3>
            <p>{item?.assigned_from}</p>
          </div>{" "}
          <div className={styles.assetCon}>
            <h3>Assigned Till</h3>
            <p>{item?.assigned_to}</p>
          </div>{" "}
          <div className={styles.assetCon}>
            <h3>Assigned By</h3>
            <p>{item?.assigned_by}</p>
          </div>
          <div className={styles.assetCon}>
            <h3>Pilot</h3>
            <p>{item?.pilot}</p>
          </div>
        </div>
      );
    } else if (item?.category === "Vehicle") {
      return (
        <div className={styles.assetContainer}>
          <div>
            <img src={droneImg} />
          </div>
          <div className={styles.assetCon}>
            <h3>Vehicle Name</h3>
            <p style={{ color: "rgba(255, 153, 8, 1)" }}>{item?.drone_name}</p>
          </div>
          <div className={styles.assetCon}>
            <h3>Vehicle ID</h3>
            <p>{item?.drone_id}</p>
          </div>
          <div className={styles.assetCon}>
            <h3>Assigned From</h3>
            <p>{item?.assigned_from}</p>
          </div>{" "}
          <div className={styles.assetCon}>
            <h3>Assigned Till</h3>
            <p>{item?.assigned_to}</p>
          </div>{" "}
          <div className={styles.assetCon}>
            <h3>Assigned By</h3>
            <p>{item?.assign_by}</p>
          </div>
          <div className={styles.assetCon}>
            <h3>Pilot</h3>
            <p>{item?.pilot}</p>
          </div>
        </div>
      );
    } else if (item?.category === "Battery") {
      return (
        <div className={styles.assetContainer}>
          <div>
            <img src={droneImg} />
          </div>
          <div className={styles.assetCon}>
            <h3>Battery Name</h3>
            <p style={{ color: "rgba(255, 153, 8, 1)" }}>{item?.drone_name}</p>
          </div>
          <div className={styles.assetCon}>
            <h3>Battery ID</h3>
            <p>{item?.drone_id}</p>
          </div>
          <div className={styles.assetCon}>
            <h3>Assigned From</h3>
            <p>{item?.assigned_from}</p>
          </div>{" "}
          <div className={styles.assetCon}>
            <h3>Assigned Till</h3>
            <p>{item?.assigned_to}</p>
          </div>{" "}
          <div className={styles.assetCon}>
            <h3>Assigned By</h3>
            <p>{item?.assign_by}</p>
          </div>
          <div className={styles.assetCon}>
            <h3>Pilot</h3>
            <p>{item?.pilot}</p>
          </div>
        </div>
      );
    } else if (item?.category === "Charger") {
      return (
        <div className={styles.assetContainer}>
          <div>
            <img src={droneImg} />
          </div>
          <div className={styles.assetCon}>
            <h3>Charger Name</h3>
            <p style={{ color: "rgba(255, 153, 8, 1)" }}>{item?.drone_name}</p>
          </div>
          <div className={styles.assetCon}>
            <h3>Charger ID</h3>
            <p>{item?.drone_id}</p>
          </div>
          <div className={styles.assetCon}>
            <h3>Assigned From</h3>
            <p>{item?.assigned_from}</p>
          </div>{" "}
          <div className={styles.assetCon}>
            <h3>Assigned Till</h3>
            <p>{item?.assigned_to}</p>
          </div>{" "}
          <div className={styles.assetCon}>
            <h3>Assigned By</h3>
            <p>{item?.assign_by}</p>
          </div>
          <div className={styles.assetCon}>
            <h3>Pilot</h3>
            <p>{item?.pilot}</p>
          </div>
        </div>
      );
    }
  };

  const getChargerAssets = (page) => {
    token_api
      .get(`drone/pilot/charger_assign?pilot=${user?.id}&page=${page}`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          setPaginationData(response?.data);
          setChargerAssetsData(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getVehicleAssets = (page) => {
    token_api
      .get(`drone/pilot/vehicle_assign?pilot=${user?.id}&page=${page}`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          setPaginationData(response?.data);

          setVehicleAssetsData(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getBatteryAssets = (page) => {
    token_api
      .get(`drone/pilot/battery_assign?pilot=${user?.id}&page=${page}`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          setPaginationData(response?.data);
          setBatterAssetsData(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error, "har");
      });
  };

  const getChargingHubAssets = (page) => {
    token_api
      .get(`drone/pilot/charging_hub_assign?pilot=${user?.id}&page=${page}`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          setPaginationData(response?.data);
          setChargingHubAssetsData(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getLaptopAssets = (page) => {
    token_api
      .get(`drone/pilot/laptop_assign?pilot=${user?.id}&page=${page}`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          setPaginationData(response?.data);
          setLaptopAssetsData(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAssetsRelatedToPilot = () => {
    token_api
      .get(`drone/pilot/all_assets?pilot_id=${user?.id}`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          setallAssetsData(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // useEffect(() => {
  //   getAssetsRelatedToPilot();
  // }, []);

  const getDroneAssets = (page) => {
    token_api
      .get(`drone/pilot/drone_assign?pilot=${user?.id}&page=${page}`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          setPaginationData(response?.data);
          setDroneAssetsData(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (filters?.category[0]?.title === "Drone") {
      getDroneAssets(pageState);
    } else if (filters?.category[0]?.title === "Charger") {
      getChargerAssets(pageState);
    } else if (filters?.category[0]?.title === "Vehicle") {
      getVehicleAssets(pageState);
    } else if (filters?.category[0]?.title === "Battery") {
      getBatteryAssets(pageState);
    } else if (filters?.category[0]?.title === "ChargingHub") {
      getChargingHubAssets(pageState);
    } else if (filters?.category[0]?.title === "Laptop") {
      getLaptopAssets(pageState);
    } else {
      getAssetsRelatedToPilot();
    }
  }, [filters?.category, pageState]);

  const handleChangeacc = (e, type, selectName, multiple) => {
    setPageState(1);
    if (type === "select" && selectName && multiple) {
      setFilters({
        ...filters,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setFilters({
        ...filters,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  const handleDeleteChipacc = (id, selectName) => {
    setPageState(1);
    setFilters({
      ...filters,
      [selectName]: filters?.[selectName]?.filter((chip) => chip?.id !== id),
    });
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.topContainer}>
        <CustomSelectChip
          multiple={false}
          label="Filter By Category"
          name="category"
          listArray={categoryList}
          onChange={handleChangeacc}
          onDelete={handleDeleteChipacc}
          value={filters?.category}
        />
      </div>
      <div className={styles.tableContainer}>
        {/* {tableData.map((item, index) => {
          return <div key={index}>{getCategoryItemRow(item)}</div>;
        })} */}
        {Object.keys(allAssetsData).length !== 0 &&
          filters.category?.length === 0 && (
            <div>
              {allAssetsData?.charger_assignment.length > 0 && (
                <div>
                  {allAssetsData?.charger_assignment.map((item, index) => {
                    return (
                      <div className={styles.assetContainer} key={index}>
                        <div>
                          <img
                            src={
                              item?.charger_inventory?.icon
                                ? item?.charger_inventory?.icon
                                : assetCharger
                            }
                            style={{ width: "80px", height: "80px" }}
                          />
                        </div>
                        <div className={styles.assetCon}>
                          <h3>Charger Name</h3>
                          <p style={{ color: "rgba(255, 153, 8, 1)" }}>
                            {item?.charger_inventory?.charger?.name || "N.A"}
                          </p>
                        </div>
                        <div className={styles.assetCon}>
                          <h3>Charger ID</h3>
                          <p>{item?.charger_inventory?.id || "N.A"}</p>
                        </div>
                        <div className={styles.assetCon}>
                          <h3>Assigned From</h3>
                          <p>{item?.assigned_from || "N.A"}</p>
                        </div>{" "}
                        <div className={styles.assetCon}>
                          <h3>Assigned Till</h3>
                          <p>{item?.assigned_to || "N.A"}</p>
                        </div>{" "}
                        <div className={styles.assetCon}>
                          <h3>Assigned By</h3>
                          <p>{item?.assign_by || "N.A"}</p>
                        </div>
                        <div className={styles.assetCon}>
                          <h3>Pilot</h3>
                          <p>
                            {`${item?.pilot?.first_name} ${item?.pilot?.last_name}`}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {allAssetsData?.battery_assignment.length > 0 && (
                <div>
                  {allAssetsData?.battery_assignment.map((item, index) => {
                    return (
                      <div className={styles.assetContainer} key={index}>
                        <div>
                          <img
                            src={
                              item?.battery_inventory?.icon
                                ? item?.battery_inventory?.icon
                                : assetBattery
                            }
                            style={{ width: "80px", height: "80px" }}
                          />
                        </div>
                        <div className={styles.assetCon}>
                          <h3>Battery Name</h3>
                          <p style={{ color: "rgba(255, 153, 8, 1)" }}>
                            {item?.battery_inventory?.battery?.name || "N.A"}
                          </p>
                        </div>
                        <div className={styles.assetCon}>
                          <h3>Battery ID</h3>
                          <p>{item?.battery_inventory?.id || "N.A"}</p>
                        </div>
                        <div className={styles.assetCon}>
                          <h3>Assigned From</h3>
                          <p>{item?.assigned_from || "N.A"}</p>
                        </div>{" "}
                        <div className={styles.assetCon}>
                          <h3>Assigned Till</h3>
                          <p>{item?.assigned_to || "N.A"}</p>
                        </div>{" "}
                        <div className={styles.assetCon}>
                          <h3>Assigned By</h3>
                          <p>{item?.assign_by || "N.A"}</p>
                        </div>
                        <div className={styles.assetCon}>
                          <h3>Pilot</h3>
                          <p>
                            {`${item?.pilot?.first_name} ${item?.pilot?.last_name}`}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {allAssetsData?.drone_assignment.length > 0 && (
                <div>
                  {allAssetsData?.drone_assignment.map((item, index) => {
                    return (
                      <div className={styles.assetContainer} key={index}>
                        <div>
                          <img
                            src={
                              item?.drone_inventory?.icon
                                ? item?.drone_inventory?.icon
                                : droneImg
                            }
                            style={{ width: "80px", height: "80px" }}
                          />
                        </div>
                        <div className={styles.assetCon}>
                          <h3>Drone Name</h3>
                          <p style={{ color: "rgba(255, 153, 8, 1)" }}>
                            {item?.drone_inventory?.drone?.title || "N.A"}
                          </p>
                        </div>
                        <div className={styles.assetCon}>
                          <h3>Drone ID</h3>
                          <p>{item?.drone_inventory?.id || "N.A"}</p>
                        </div>
                        <div className={styles.assetCon}>
                          <h3>Assigned From</h3>
                          <p>{item?.assigned_from || "N.A"}</p>
                        </div>{" "}
                        <div className={styles.assetCon}>
                          <h3>Assigned Till</h3>
                          <p>{item?.assigned_to || "N.A"}</p>
                        </div>{" "}
                        <div className={styles.assetCon}>
                          <h3>Assigned By</h3>
                          <p>{item?.assign_by || "N.A"}</p>
                        </div>
                        <div className={styles.assetCon}>
                          <h3>Pilot</h3>
                          <p>
                            {`${item?.pilot?.first_name} ${item?.pilot?.last_name}`}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {allAssetsData?.vehicle_assignment.length > 0 && (
                <div>
                  {allAssetsData?.vehicle_assignment.map((item, index) => {
                    return (
                      <div className={styles.assetContainer} key={index}>
                        <div>
                          <img
                            src={
                              item?.vehicle_inventory?.icon
                                ? item?.vehicle_inventory?.icon
                                : assetVehicle
                            }
                            style={{ width: "80px", height: "80px" }}
                          />
                        </div>
                        <div className={styles.assetCon}>
                          <h3>Vehicle Name</h3>
                          <p style={{ color: "rgba(255, 153, 8, 1)" }}>
                            {item?.vehicle_inventory?.vehicle?.name || "N.A"}
                          </p>
                        </div>
                        <div className={styles.assetCon}>
                          <h3>Vehicle ID</h3>
                          <p>{item?.vehicle_inventory?.id || "N.A"}</p>
                        </div>
                        <div className={styles.assetCon}>
                          <h3>Assigned From</h3>
                          <p>{item?.assigned_from || "N.A"}</p>
                        </div>{" "}
                        <div className={styles.assetCon}>
                          <h3>Assigned Till</h3>
                          <p>{item?.assigned_to || "N.A"}</p>
                        </div>{" "}
                        <div className={styles.assetCon}>
                          <h3>Assigned By</h3>
                          <p>{item?.assign_by || "N.A"}</p>
                        </div>
                        <div className={styles.assetCon}>
                          <h3>Pilot</h3>
                          <p>
                            {`${item?.pilot?.first_name} ${item?.pilot?.last_name}`}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              {/* Frome here.... */}
              {allAssetsData?.charging_hub_assignment.length > 0 && (
                <div>
                  {allAssetsData?.charging_hub_assignment.map((item, index) => {
                    return (
                      <div className={styles.assetContainer} key={index}>
                        <div>
                          <img
                            src={
                              item?.charging_hub_inventory?.charging_hub?.image
                                ? item?.charging_hub_inventory?.charging_hub
                                    ?.image?.icon
                                : chargingHubAsset
                            }
                            style={{ width: "80px", height: "80px" }}
                          />
                        </div>
                        <div className={styles.assetCon}>
                          <h3>Charging Hub Serial No.</h3>
                          <p style={{ color: "rgba(255, 153, 8, 1)" }}>
                            {item?.charging_hub_inventory?.serial_no || "N.A"}
                          </p>
                        </div>
                        <div className={styles.assetCon}>
                          <h3>Charging Hub Manufacturer</h3>
                          <p>
                            {item?.charging_hub_inventory
                              ?.manufacturer_serial_no || "N.A"}
                          </p>
                        </div>
                        <div className={styles.assetCon}>
                          <h3>Assigned From</h3>
                          <p>{item?.assigned_from || "N.A"}</p>
                        </div>{" "}
                        <div className={styles.assetCon}>
                          <h3>Assigned Till</h3>
                          <p>{item?.assigned_to || "N.A"}</p>
                        </div>{" "}
                        <div className={styles.assetCon}>
                          <h3>Assigned By</h3>
                          <p>{item?.assign_by || "N.A"}</p>
                        </div>
                        <div className={styles.assetCon}>
                          <h3>Pilot</h3>
                          <p>
                            {`${item?.pilot?.first_name} ${item?.pilot?.last_name}`}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              {allAssetsData?.laptop_assignment.length > 0 && (
                <div>
                  {allAssetsData?.laptop_assignment.map((item, index) => {
                    return (
                      <div className={styles.assetContainer} key={index}>
                        <div>
                          <img
                            src={
                              item?.laptop_inventory?.laptop?.image
                                ? item?.laptop_inventory?.laptop?.image?.icon
                                : laptopAsset
                            }
                            style={{ width: "80px", height: "80px" }}
                          />
                        </div>
                        <div className={styles.assetCon}>
                          <h3>Laptop Serial No.</h3>
                          <p style={{ color: "rgba(255, 153, 8, 1)" }}>
                            {item?.laptop_inventory?.serial_no || "N.A"}
                          </p>
                        </div>
                        <div className={styles.assetCon}>
                          <h3>Laptop Manufacturer</h3>
                          <p>
                            {item?.laptop_inventory?.manufacturer_serial_no ||
                              "N.A"}
                          </p>
                        </div>
                        <div className={styles.assetCon}>
                          <h3>Assigned From</h3>
                          <p>{item?.assigned_from || "N.A"}</p>
                        </div>{" "}
                        <div className={styles.assetCon}>
                          <h3>Assigned Till</h3>
                          <p>{item?.assigned_to || "N.A"}</p>
                        </div>{" "}
                        <div className={styles.assetCon}>
                          <h3>Assigned By</h3>
                          <p>{item?.assign_by || "N.A"}</p>
                        </div>
                        <div className={styles.assetCon}>
                          <h3>Pilot</h3>
                          <p>
                            {`${item?.pilot?.first_name} ${item?.pilot?.last_name}`}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        {filters?.category && filters?.category[0]?.title === "Charger" && (
          <div>
            {chargerAssetsData.map((item, index) => {
              return (
                <div className={styles.assetContainer} key={index}>
                  <div>
                    <img
                      src={
                        item?.charger_inventory?.icon
                          ? item?.charger_inventory?.icon
                          : assetCharger
                      }
                      style={{ width: "80px", height: "80px" }}
                    />
                  </div>
                  <div className={styles.assetCon}>
                    <h3>Charger Name</h3>
                    <p style={{ color: "rgba(255, 153, 8, 1)" }}>
                      {item?.charger_inventory?.charger?.name || "N.A"}
                    </p>
                  </div>
                  <div className={styles.assetCon}>
                    <h3>Charger ID</h3>
                    <p>{item?.charger_inventory?.id || "N.A"}</p>
                  </div>
                  <div className={styles.assetCon}>
                    <h3>Assigned From</h3>
                    <p>{item?.assigned_from || "N.A"}</p>
                  </div>{" "}
                  <div className={styles.assetCon}>
                    <h3>Assigned Till</h3>
                    <p>{item?.assigned_to || "N.A"}</p>
                  </div>{" "}
                  <div className={styles.assetCon}>
                    <h3>Assigned By</h3>
                    <p>{item?.assign_by || "N.A"}</p>
                  </div>
                  <div className={styles.assetCon}>
                    <h3>Pilot</h3>
                    <p>
                      {`${item?.pilot?.first_name} ${item?.pilot?.last_name}`}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {filters?.category && filters?.category[0]?.title === "Battery" && (
          <div>
            {batteryAssetsData.map((item, index) => {
              return (
                <div className={styles.assetContainer} key={index}>
                  <div>
                    <img
                      src={
                        item?.battery_inventory?.icon
                          ? item?.battery_inventory?.icon
                          : assetBattery
                      }
                      style={{ width: "80px", height: "80px" }}
                    />
                  </div>
                  <div className={styles.assetCon}>
                    <h3>Battery Name</h3>
                    <p style={{ color: "rgba(255, 153, 8, 1)" }}>
                      {item?.battery_inventory?.battery?.name || "N.A"}
                    </p>
                  </div>
                  <div className={styles.assetCon}>
                    <h3>Battery ID</h3>
                    <p>{item?.battery_inventory?.id || "N.A"}</p>
                  </div>
                  <div className={styles.assetCon}>
                    <h3>Assigned From</h3>
                    <p>{item?.assigned_from || "N.A"}</p>
                  </div>{" "}
                  <div className={styles.assetCon}>
                    <h3>Assigned Till</h3>
                    <p>{item?.assigned_to || "N.A"}</p>
                  </div>{" "}
                  <div className={styles.assetCon}>
                    <h3>Assigned By</h3>
                    <p>{item?.assign_by || "N.A"}</p>
                  </div>
                  <div className={styles.assetCon}>
                    <h3>Pilot</h3>
                    <p>
                      {`${item?.pilot?.first_name} ${item?.pilot?.last_name}`}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {filters?.category && filters?.category[0]?.title === "Vehicle" && (
          <div>
            {vehicleAssetsData.map((item, index) => {
              return (
                <div className={styles.assetContainer} key={index}>
                  <div>
                    <img
                      src={
                        item?.vehicle_inventory?.icon
                          ? item?.vehicle_inventory?.icon
                          : assetVehicle
                      }
                      style={{ width: "80px", height: "80px" }}
                    />
                  </div>
                  <div className={styles.assetCon}>
                    <h3>Vehicle Name</h3>
                    <p style={{ color: "rgba(255, 153, 8, 1)" }}>
                      {item?.vehicle_inventory?.vehicle?.name || "N.A"}
                    </p>
                  </div>
                  <div className={styles.assetCon}>
                    <h3>Vehicle ID</h3>
                    <p>{item?.vehicle_inventory?.id || "N.A"}</p>
                  </div>
                  <div className={styles.assetCon}>
                    <h3>Assigned From</h3>
                    <p>{item?.assigned_from || "N.A"}</p>
                  </div>{" "}
                  <div className={styles.assetCon}>
                    <h3>Assigned Till</h3>
                    <p>{item?.assigned_to || "N.A"}</p>
                  </div>{" "}
                  <div className={styles.assetCon}>
                    <h3>Assigned By</h3>
                    <p>{item?.assign_by || "N.A"}</p>
                  </div>
                  <div className={styles.assetCon}>
                    <h3>Pilot</h3>
                    <p>
                      {`${item?.pilot?.first_name} ${item?.pilot?.last_name}`}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        )}{" "}
        {filters?.category && filters?.category[0]?.title === "Drone" && (
          <div>
            {droneAssetsData.map((item, index) => {
              return (
                <div className={styles.assetContainer} key={index}>
                  <div>
                    <img
                      src={
                        item?.drone_inventory?.icon
                          ? item?.drone_inventory?.icon
                          : droneImg
                      }
                      style={{ width: "80px", height: "80px" }}
                    />
                  </div>
                  <div className={styles.assetCon}>
                    <h3>Drone Name</h3>
                    <p style={{ color: "rgba(255, 153, 8, 1)" }}>
                      {item?.drone_inventory?.drone?.title || "N.A"}
                    </p>
                  </div>
                  <div className={styles.assetCon}>
                    <h3>Drone ID</h3>
                    <p>{item?.drone_inventory?.id || "N.A"}</p>
                  </div>
                  <div className={styles.assetCon}>
                    <h3>Assigned From</h3>
                    <p>{item?.assigned_from || "N.A"}</p>
                  </div>{" "}
                  <div className={styles.assetCon}>
                    <h3>Assigned Till</h3>
                    <p>{item?.assigned_to || "N.A"}</p>
                  </div>{" "}
                  <div className={styles.assetCon}>
                    <h3>Assigned By</h3>
                    <p>{item?.assign_by || "N.A"}</p>
                  </div>
                  <div className={styles.assetCon}>
                    <h3>Pilot</h3>
                    <p>
                      {`${item?.pilot?.first_name} ${item?.pilot?.last_name}`}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {filters?.category && filters?.category[0]?.title === "ChargingHub" && (
          <div>
            {chargingHubAssetsData.map((item, index) => {
              return (
                <div className={styles.assetContainer} key={index}>
                  <div>
                    <img
                      src={
                        item?.charging_hub_inventory?.charging_hub?.image
                          ? item?.charging_hub_inventory?.charging_hub?.image
                              ?.icon
                          : chargingHubAsset
                      }
                      style={{ width: "80px", height: "80px" }}
                    />
                  </div>
                  <div className={styles.assetCon}>
                    <h3>Charging Hub Serial No.</h3>
                    <p style={{ color: "rgba(255, 153, 8, 1)" }}>
                      {item?.charging_hub_inventory?.serial_no || "N.A"}
                    </p>
                  </div>
                  <div className={styles.assetCon}>
                    <h3>Charging Hub Manufacturer</h3>
                    <p>
                      {item?.charging_hub_inventory?.manufacturer_serial_no ||
                        "N.A"}
                    </p>
                  </div>
                  <div className={styles.assetCon}>
                    <h3>Assigned From</h3>
                    <p>{item?.assigned_from || "N.A"}</p>
                  </div>{" "}
                  <div className={styles.assetCon}>
                    <h3>Assigned Till</h3>
                    <p>{item?.assigned_to || "N.A"}</p>
                  </div>{" "}
                  <div className={styles.assetCon}>
                    <h3>Assigned By</h3>
                    <p>{item?.assign_by || "N.A"}</p>
                  </div>
                  <div className={styles.assetCon}>
                    <h3>Pilot</h3>
                    <p>
                      {`${item?.pilot?.first_name} ${item?.pilot?.last_name}`}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {filters?.category && filters?.category[0]?.title === "Laptop" && (
          <div>
            {laptopAssetsData.map((item, index) => {
              return (
                <div className={styles.assetContainer} key={index}>
                  <div>
                    <img
                      src={
                        item?.laptop_inventory?.laptop?.image
                          ? item?.laptop_inventory?.laptop?.image?.icon
                          : laptopAsset
                      }
                      style={{ width: "80px", height: "80px" }}
                    />
                  </div>
                  <div className={styles.assetCon}>
                    <h3>Laptop Serial No.</h3>
                    <p style={{ color: "rgba(255, 153, 8, 1)" }}>
                      {item?.laptop_inventory?.serial_no || "N.A"}
                    </p>
                  </div>
                  <div className={styles.assetCon}>
                    <h3>Laptop Manufacturer</h3>
                    <p>
                      {item?.laptop_inventory?.manufacturer_serial_no || "N.A"}
                    </p>
                  </div>
                  <div className={styles.assetCon}>
                    <h3>Assigned From</h3>
                    <p>{item?.assigned_from || "N.A"}</p>
                  </div>{" "}
                  <div className={styles.assetCon}>
                    <h3>Assigned Till</h3>
                    <p>{item?.assigned_to || "N.A"}</p>
                  </div>{" "}
                  <div className={styles.assetCon}>
                    <h3>Assigned By</h3>
                    <p>{item?.assign_by || "N.A"}</p>
                  </div>
                  <div className={styles.assetCon}>
                    <h3>Pilot</h3>
                    <p>
                      {`${item?.pilot?.first_name} ${item?.pilot?.last_name}`}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {filters?.category[0]?.title && (
        <div className={styles.paginationContainer}>
          <CustomPagination max_pages={max_pages} setPageState={setPageState} />
        </div>
      )}
    </div>
  );
};

export default DDUserAssets;

const categoryList = [
  {
    id: 1,
    title: "Drone",
  },
  {
    id: 2,
    title: "Vehicle",
  },
  {
    id: 3,
    title: "Battery",
  },
  {
    id: 4,
    title: "Charger",
  },
  {
    id: 5,
    title: "ChargingHub",
  },
  {
    id: 6,
    title: "Laptop",
  },
];

const tableData = [
  {
    id: 1,
    category: "Drone",
    drone_name: "Agristar",
    drone_id: "123123",
    assigned_from: "22-04-2024",
    assigned_to: "30-05-2024",
    assigned_by: "Keval Patel",
    pilot: "Rahul Mishra",
  },
  {
    id: 2,
    category: "Vehicle",
    drone_name: "Vehicle 1",
    drone_id: "123123",
    assigned_from: "22-04-2024",
    assigned_to: "30-05-2024",
    assigned_by: "Keval Patel",
    pilot: "Rahul Mishra",
  },
  {
    id: 3,
    category: "Battery",
    drone_name: "Battery 1",
    drone_id: "123123",
    assigned_from: "22-04-2024",
    assigned_to: "30-05-2024",
    assigned_by: "Keval Patel",
    pilot: "Rahul Mishra",
  },
  {
    id: 4,
    category: "Charger",
    drone_name: "Chager 1",
    drone_id: "123123",
    assigned_from: "22-04-2024",
    assigned_to: "30-05-2024",
    assigned_by: "Keval Patel",
    pilot: "Rahul Mishra",
  },
];
