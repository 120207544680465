export const Validate = (value) => {
  return new Promise((resolve, reject) => {
    const {
      license_no,
      first_name,
      last_name,
      email,
      mobile,
      location,
      dob,
      expiry_date,
      joining_date,
      active,
      verified,
      gender,
      is_available,
      line_1,
      line_2,
      area,
      city,
      state,
      tehsil,
      district,
      country,
      pincode,
    } = value;

    const errors = { error: false };
    const mobileRegex = /^\d{10}$/;

    if (!district) {
      errors.district = "Please enter district";
      errors.error = true;
    }

    if (!country) {
      errors.country = "Please enter country";
      errors.error = true;
    }

    if (!tehsil) {
      errors.tehsil = "Please enter tehsil";
      errors.error = true;
    }
    if (!state) {
      errors.state = "Please enter state";
      errors.error = true;
    }

    if (!city) {
      errors.city = "Please enter city/town name";
      errors.error = true;
    }
    if (!license_no || !/^[a-zA-Z0-9\s]*$/.test(license_no)) {
      errors.license_no =
        "Please enter a valid License without special characters or symbols";
      errors.error = true;
    }

    if (!first_name) {
      errors.first_name = "Please enter first name";
      errors.error = true;
    }
    if (!last_name) {
      errors.last_name = "Please enter last name";
      errors.error = true;
    }
    if (!email) {
      errors.email = "Please enter email";
      errors.error = true;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      errors.email = "Please enter a valid email address";
      errors.error = true;
    }

    if (!mobile || !mobileRegex.test(mobile)) {
      errors.mobile = "Please enter a valid 10-digit mobile number";
      errors.error = true;
    }

    if (!dob) {
      errors.dob = "Please enter date of birth";
      errors.error = true;
    }

    if (!expiry_date) {
      errors.expiry_date = "Please enter expiry date";
      errors.error = true;
    }

    if (!joining_date) {
      errors.joining_date = "Please enter joining date";
      errors.error = true;
    }

    if (!active[0]) {
      errors.active = "Please enter if the pilot is active";
      errors.error = true;
    }
    if (!verified[0]) {
      errors.verified = "Please enter if the pilot is verified";
      errors.error = true;
    }
    if (!gender[0]) {
      errors.gender = "Please enter gender details";
      errors.error = true;
    }

    if (!is_available[0]) {
      errors.is_available = "Please enter if the pilot is available";
      errors.error = true;
    }

    if (!pincode) {
      errors.pincode = "Please enter pincode";
      errors.error = true;
    }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};
