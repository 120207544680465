import React, { useEffect, useRef, useState } from "react";
import styles from "./banner.module.css";
import DrawerContainer from "../../Component/DrawerContainer/DrawerContainer";
import DrawerTitle from "../../Component/DrawerTitle/DrawerTitle";
import dragDrop from "../../Assets/drag_drop.svg";
import cancellogo from "../../Assets/cancel.svg";
import CustomSelect from "../../Component/CustomSelect/CustomSelect";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import InputFields from "../../Component/InputFields/InputFields";
import { Button } from "@mui/material";
import axios from "axios";
import { useSelector } from "react-redux";
import { base_url, token_api } from "../../Utils/network";
import CustomBrdChip from "../../Component/CustomBrdChip/CustomBrdChip";
import { validateBanner } from "./formValidate";

const AddBannerForm = ({ handleCancelDrw, bannerDetails, getBannerData }) => {
  const fileInput = useRef(null);
  const { access_token } = useSelector((state) => state.auth);
  const bannerInput = useRef(null);
  const tabInput = useRef(null);
  const mobileInput = useRef(null);
  const [editBanner, setEditBanner] = useState(false);
  const [redDetails, setRedDetails] = useState([]);
  const [redirectID, setRedirectID] = useState();
  const [typedValue, setTypedValue] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [bDetails, setBDetails] = useState({
    status: null,
    redirection: null,
    detail: null,
    web: "",
    tab: "",
    mobile: "",
  });
  const [selectedFile, setSelectedFile] = useState({
    web: "",
    tab: "",
    mobile: "",
  });

  const [selectedFileSize, setSelectedFileSize] = useState({
    web: null,
    tab: null,
    mobile: null,
  });

  // console.log(bannerDetails, "ndi988765872");

  function handleDragOver(e) {
    e.preventDefault();
  }

  function handleDrop(e, type) {
    e.preventDefault();
    // console.log("FGF", e, type);
    const file = e.dataTransfer.files[0];

    console.log(file, "hariFile");
    if (e?.target?.file) {
      // simple drop

      if (file) {
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setBDetails((prevState) => ({
          ...prevState,
          [type]: imageUrl,
        }));
        setSelectedFile((prevState) => ({
          ...prevState,
          [type]: file,
        }));
      } else {
        // setSelectedFile(e?.target?.file[0])
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setBDetails((prevState) => ({
          ...prevState,
          [type]: imageUrl,
        }));
        setSelectedFile((prevState) => ({
          ...prevState,
          [type]: file,
        }));
      }
    } else if (e?.dataTransfer?.file) {
      // drag
    }
  }

  const handleClick = (type) => {
    // console.log("refd", type);
    if (type === "web") {
      // fileInput.current.click();
      bannerInput.current.click();
    } else if (type === "tab") {
      tabInput.current.click();
    } else if (type === "mobile") {
      mobileInput.current.click();
    }
  };
  const handleInputChange = (event, newValue) => {
    setTypedValue(newValue);
    // console.log(newValue,"handleInputChange");
    setBDetails((prev) => ({
      ...prev,
      detail: newValue,
    }));
  };

  const handleFileChange = (e, type) => {
    const file = e.target.files[0];
    // const fileSize = file.size / 1000;

    console.log(file, type, "hariNewFile");

    // console.log(e.target.files[0], type, "chossejdfi767");
    if (file) {
      const fileSize = file.size / 1000;

      setBDetails((prevState) => ({
        ...prevState,
        [type]: URL.createObjectURL(file),
      }));
      setSelectedFile((prevState) => ({
        ...prevState,
        [type]: file,
      }));

      setSelectedFileSize((prevState) => ({
        ...prevState,
        [type]: fileSize,
      }));
    }
  };

  const deleteFile = (type) => {
    // setSelectedFile(null)
    setBDetails((prevState) => ({
      ...prevState,
      [type]: null,
    }));
    setSelectedFileSize((prevState) => ({
      ...prevState,
      [type]: null,
    }));
  };

  const handleChange = (e, type, selectName, multiple) => {
    // debugger;
    // console.log(e?.id, type, selectName, multiple, "selectchip124");

    if (type === "select" && selectName && multiple) {
      setBDetails({
        ...bDetails,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setBDetails({
        ...bDetails,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setBDetails({
        ...bDetails,
        [name]: value,
      });
    }
    let redirectionID = e?.id;

    if (redirectionID == 1) {
      setRedDetails([]);
      courseListing(typedValue);
      setRedirectID(1);
    } else if (redirectionID == 2) {
      setRedDetails([]);
      servicelisting(typedValue);
      setRedirectID(2);
    } else if (redirectionID == 3) {
      setRedDetails([]);
      droneListing(typedValue);
      setRedirectID(3);
    } else {
      setRedDetails([]);
      setRedirectID(null);
    }

    // console.log(redDetails, redirectionID, "redDetails");
    // }
  };
  useEffect(() => {
    if (redirectID == 1) {
      setRedDetails([]);
      courseListing(typedValue);
    } else if (redirectID == 2) {
      setRedDetails([]);
      servicelisting(typedValue);
    } else if (redirectID == 3) {
      setRedDetails([]);
      droneListing(typedValue);
    }

    // else {
    //     setRedDetails([])
    // }
  }, [typedValue]);

  const courseListing = (value) => {
    let apiEndpoint = "course";
    if (value) {
      apiEndpoint += `?q=${value}`;
    }
    return new Promise((resolve, reject) => {
      token_api
        .get(apiEndpoint)
        .then((res) => {
          if (res?.status == 200) {
            const modifiedList = res?.data?.data?.map((item) => {
              return {
                id: item?.id,
                city: `${item?.slug}`,
                title: `${item?.name}`,
              };
            });

            let data = modifiedList;
            setRedDetails(data);

            resolve(data); // Resolve with the fetched data
            //   return 1
          }
        })
        .catch((err) => {
          console.log(err);
          // reject([]);
        });
    });
  };

  const servicelisting = (value) => {
    let apiEndpoint = "service";
    if (value) {
      apiEndpoint += `?q=${value}`;
    }
    return new Promise((resolve, reject) => {
      token_api
        .get(apiEndpoint)
        .then((res) => {
          if (res?.status == 200) {
            const modifiedList = res?.data?.data?.map((item) => {
              return {
                id: item?.id,
                city: `${item?.slug}`,
                title: `${item?.title}`,
              };
            });

            let data = modifiedList;
            setRedDetails(data);

            resolve(data); // Resolve with the fetched data
          }
        })
        .catch((err) => {
          console.log(err);
          // reject([]);
        });
    });
  };

  const droneListing = (value) => {
    let apiEndpoint = "drone";
    if (value) {
      apiEndpoint += `?q=${value}`;
    }
    return new Promise((resolve, reject) => {
      token_api
        .get(apiEndpoint)
        .then((res) => {
          if (res?.status == 200) {
            const modifiedList = res?.data?.data?.map((item) => {
              return {
                id: item?.id,
                city: `${item?.slug}`,
                title: `${item?.title}`,
              };
            });

            let data = modifiedList;
            setRedDetails(data);

            resolve(data); // Resolve with the fetched data
          }
        })
        .catch((err) => {
          console.log(err);
          // reject([]);
        });
    });
  };
  const handleDeleteChip = (id, selectName) => {
    setBDetails({
      ...bDetails,
      [selectName]: bDetails?.[selectName]?.filter((chip) => chip?.id !== id),
    });
    // (chips) => chips.filter((chip) => chip?.id !== id)

    if (selectName === "redirection") {
      setBDetails({
        ...bDetails,
        detail: null,
        redirection: null,
      });
    }
  };

  // post patch start

  const confirmValidate = async () => {
    if (editBanner) {
      const patchdata = {
        ...bDetails,
        redirection: bDetails?.redirection[0]?.id,
        status: bDetails?.status[0]?.id,
        detail: JSON.stringify({
          id: bDetails?.detail?.id,
          slug: bDetails?.detail?.city,
          title: bDetails?.detail?.title,
        }),
      };

      let uplodedata = new FormData();

      if (selectedFile?.web) {
        uplodedata.append("web", selectedFile.web);
      }

      if (selectedFile?.tab) {
        uplodedata.append("tab", selectedFile.tab);
      }

      if (selectedFile?.mobile) {
        uplodedata.append("mobile", selectedFile.mobile);
      }

      if (patchdata?.alt_text) {
        uplodedata.append("alt_text", patchdata.alt_text);
      }

      if (patchdata?.redirection) {
        uplodedata.append("redirection", patchdata.redirection);
      }

      if (patchdata?.detail) {
        uplodedata.append("detail", patchdata?.detail);
      }

      if (patchdata?.status) {
        uplodedata.append("status", patchdata.status);
      }

      try {
        const postbanners = await axios({
          method: "patch",
          url: `${base_url}base/banners/${bannerDetails?.id}/`,
          data: uplodedata,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${access_token}`,
          },
        });
        if (
          postbanners?.status === 200 ||
          postbanners?.status === 201 ||
          postbanners?.status === 204
        ) {
          alert("Banner Details Updated Successfully!");
          handleCancelDrw();
          getBannerData();
        }
      } catch (err) {
        console.log(err);
      }

      // console.log(editBanner, patchdata, "editBanner");
    } else {
      const postdata = {
        ...bDetails,
        redirection: bDetails?.redirection[0]?.id,
        status: bDetails?.status[0]?.id,
        detail: JSON.stringify({
          id: bDetails?.detail?.id,
          slug: bDetails?.detail?.city,
          title: bDetails?.detail?.title,
        }),
      };

      let uplodedata = new FormData();
      uplodedata.append("web", selectedFile?.web);
      uplodedata.append("tab", selectedFile?.tab);
      uplodedata.append("mobile", selectedFile?.mobile);
      uplodedata.append("alt_text", postdata?.alt_text);
      uplodedata.append("detail", postdata?.detail);
      uplodedata.append("redirection", postdata?.redirection);
      uplodedata.append("status", postdata?.status);
      uplodedata.append("index", 0);

      try {
        const postbanners = await axios({
          method: "post",
          url: `${base_url}base/banners/`,
          data: uplodedata,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${access_token}`,
          },
        });
        if (
          postbanners?.status === 200 ||
          postbanners?.status === 201 ||
          postbanners?.status === 204
        ) {
          alert("Banner Details Created Successfully!");

          handleCancelDrw();
          getBannerData();
        }
      } catch (err) {
        console.log(err);
      }
      // console.log(editBanner, bDetails?.detail?.city, "editBanner");
    }
  };

  const handleAddBanner = () => {
    // console.log(bDetails, "handleAddBanner");
    validateBanner(bDetails, selectedFileSize)
      .then((res) => {
        if (res === "success") {
          confirmValidate();
        }
      })
      .catch((err) => {
        console.log(err, "iamurerror");
        setFormErrors(err);
      });
  };

  useEffect(() => {
    // debugger;
    if (bannerDetails?.id) {
      const {
        alt_text,
        created,
        created_modified_info,
        id,
        index,
        detail,
        mobile,
        redirection,
        redirection_str,
        status,
        status_str,
        tab,
        web,
      } = bannerDetails;
      let modifiedStatus = "";
      let modifiedReditrection = "";
      let modifiedDetails = "";
      if (status && status[0]) {
        modifiedStatus = [status[0]];
      } else {
        modifiedStatus = statusList.filter((item) => item?.id === status);
      }

      if (redirection && redirection[0]) {
        modifiedReditrection = [redirection[0]];
      } else {
        modifiedReditrection = redirectionArray.filter(
          (item) => item?.id === redirection
        );
      }

      if (detail) {
        modifiedDetails = [detail];
      }

      if (modifiedReditrection[0]?.id == 1) {
        courseListing(typedValue)
          .then((data) => {
            if (data) {
              modifiedDetails = [data.find((item) => item?.id == detail?.id)];
              setBDetails({
                ...bDetails,
                alt_text,
                status: modifiedStatus,
                redirection: modifiedReditrection,
                detail: modifiedDetails[0],
                web,
                mobile,
                tab,
              });
              setEditBanner(true);
            }
          })
          .catch((error) => {
            console.error("Error fetching course listing:", error);
          });
      } else if (modifiedReditrection[0]?.id == 2) {
        servicelisting(typedValue)
          .then((data) => {
            if (data) {
              modifiedDetails = [data.find((item) => item?.id == detail?.id)];
              setBDetails({
                ...bDetails,
                alt_text,
                status: modifiedStatus,
                redirection: modifiedReditrection,
                detail: modifiedDetails[0],
                web,
                mobile,
                tab,
              });
              setEditBanner(true);
            }
          })
          .catch((error) => {
            console.error("Error fetching course listing:", error);
          });
      } else if (modifiedReditrection[0]?.id == 3) {
        droneListing(typedValue)
          .then((data) => {
            if (data) {
              modifiedDetails = [data.find((item) => item?.id == detail?.id)];
              setBDetails({
                ...bDetails,
                alt_text,
                status: modifiedStatus,
                redirection: modifiedReditrection,
                detail: modifiedDetails[0],
                web,
                mobile,
                tab,
              });
              setEditBanner(true);
            }
          })
          .catch((error) => {
            console.error("Error fetching course listing:", error);
          });
      } else {
        setBDetails({
          ...bDetails,
          alt_text,
          status: modifiedStatus,
          redirection: modifiedReditrection,
          detail: modifiedDetails,
          web,
          mobile,
          tab,
        });

        setEditBanner(true);
      }
    }
  }, [bannerDetails?.id]);

  console.log(formErrors, "formErrors");
  return (
    <DrawerContainer style={{ marginTop: "0px", marginBottom: "10px" }}>
      <DrawerTitle
        text={bannerDetails?.id ? `Edit Banner` : `Add Banner`}
        handleCancelDrw={handleCancelDrw}
      />
      <div style={{ marginTop: "20px" }}>
        <h2 className={styles.imguplodeText}>Upload Banner for Website</h2>
        <p className={styles.warningText}>
          (Only PNG, WEBP files with max 500 KB.)
        </p>

        <div
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop(e, "web")}
          className={styles.dropBoxStyles}
        >
          <div
            onClick={() => handleClick("web")}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p>Drag and Drop an Image File here or click</p>
            <p>
              <img src={dragDrop} alt="dradanddrop" />
            </p>

            <input
              ref={bannerInput}
              type="file"
              accept="image/webp , image/png" //only allow webP and png format images
              onChange={(e) => handleFileChange(e, "web")}
              style={{ display: "none" }}
            ></input>
          </div>
        </div>
        {bDetails?.web ? (
          <div>
            <div className={styles.chooseimgConatiner}>
              <img
                onClick={() => deleteFile("web")}
                className={styles.cancelbtn}
                src={cancellogo}
              />
              <img
                width={200}
                height={100}
                className={styles.displayimg}
                src={bDetails?.web}
                alt="selectedFile"
              />
              {bDetails?.name && <p>{bDetails?.name}</p>}
            </div>

            {selectedFileSize?.web > 500 && (
              <p className={styles.errorfieldtext}>
                Uploaded image size must be less than 500 KB.
              </p>
            )}
          </div>
        ) : (
          <p className={styles.errorfieldtext}>{formErrors?.web}</p>
        )}
      </div>

      <div style={{ marginTop: "20px" }}>
        <h2 className={styles.imguplodeText}>Upload Banner for Tab</h2>
        <p className={styles.warningText}>
          (Only PNG, WEBP files with max 500 KB.)
        </p>
        <div
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop(e, "tab")}
          className={styles.dropBoxStyles}
        >
          <div
            onClick={() => handleClick("tab")}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p>Drag and Drop an Image File here or click</p>
            <p>
              <img src={dragDrop} alt="dradanddrop" />
            </p>

            <input
              ref={tabInput}
              type="file"
              accept="image/webp , image/png" //only allow webP and png format images
              onChange={(e) => handleFileChange(e, "tab")}
              style={{ display: "none" }}
            ></input>
          </div>
        </div>
        {bDetails?.tab ? (
          <div>
            <div className={styles.chooseimgConatiner}>
              <img
                onClick={() => deleteFile("tab")}
                className={styles.cancelbtn}
                src={cancellogo}
              />
              <img
                width={150}
                height={100}
                className={styles.displayimg}
                src={bDetails?.tab}
                alt="selectedFile"
              />
              {bDetails?.name && <p>{bDetails?.name}</p>}
            </div>

            {selectedFileSize?.tab > 500 && (
              <p className={styles.errorfieldtext}>
                Uploaded image size must be less than 500 KB.
              </p>
            )}
          </div>
        ) : (
          <p className={styles.errorfieldtext}>{formErrors?.tab}</p>
        )}
      </div>

      <div style={{ marginTop: "20px" }}>
        <h2 className={styles.imguplodeText}>Upload Banner for Mobile</h2>
        <p className={styles.warningText}>
          (Only PNG, WEBP files with max 500 KB.)
        </p>

        <div
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop(e, "mobile")}
          className={styles.dropBoxStyles}
        >
          <div
            onClick={() => handleClick("mobile")}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p>Drag and Drop an Image File here or click</p>
            <p>
              <img src={dragDrop} alt="dradanddrop" />
            </p>

            <input
              ref={mobileInput}
              type="file"
              accept="image/webp , image/png" //only allow webP and png format images
              onChange={(e) => handleFileChange(e, "mobile")}
              style={{ display: "none" }}
            ></input>
          </div>
        </div>
        {bDetails?.mobile ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              alignItems: "center",
            }}
          >
            <div className={styles.chooseimgConatiner}>
              <img
                onClick={() => deleteFile("mobile")}
                className={styles.cancelbtn}
                src={cancellogo}
              />
              <img
                width={100}
                height={100}
                className={styles.displayimg}
                src={bDetails?.mobile}
                alt="selectedFile"
              />
              {bDetails?.name && <p>{bDetails?.name}</p>}
            </div>

            {selectedFileSize?.mobile > 500 && (
              <p className={styles.errorfieldtext}>
                Uploaded image size must be less than 500 KB.
              </p>
            )}
          </div>
        ) : (
          <p className={styles.errorfieldtext}>{formErrors?.mobile}</p>
        )}

        <div className={styles.inputContainer}>
          <CustomSelectChip
            multiple={false}
            label="Status"
            name="status"
            onChange={handleChange}
            onDelete={handleDeleteChip}
            value={bDetails?.status}
            listArray={statusList}
            error={formErrors?.status}
          />
        </div>
        <div className={styles.inputContainer}>
          <CustomSelectChip
            multiple={false}
            label="Redirection"
            name="redirection"
            onChange={handleChange}
            onDelete={handleDeleteChip}
            value={bDetails?.redirection}
            listArray={redirectionArray}
            error={formErrors?.redirection}
          />
        </div>

        <div className={styles.inputContainer}>
          <CustomBrdChip
            value={bDetails?.detail}
            onChange={handleInputChange}
            listing={redDetails}
            error={formErrors?.detail}
            typedValue={typedValue}
            setTypedValue={setTypedValue}
          />
        </div>

        <div className={styles.inputContainer}>
          <InputFields
            label="Alt text"
            required
            name="alt_text"
            value={bDetails?.alt_text}
            onChange={handleChange}
            error={formErrors?.alt_text}
            helperText={formErrors?.alt_text}
          />
        </div>

        <div className={styles.inputContainer}>
          <Button
            variant="contained"
            className={`${styles.gradientColor}`}
            onClick={handleAddBanner}
          >
            Save
          </Button>
        </div>
      </div>
    </DrawerContainer>
  );
};

export default AddBannerForm;

const statusList = [
  {
    id: 1,
    title: "Active",
  },
  {
    id: 2,
    title: "InActive",
  },
];

const redirectionArray = [
  {
    id: 1,
    title: "Courses",
  },
  {
    id: 2,
    title: "Services",
  },
  {
    id: 3,
    title: "Drones",
  },
  {
    id: 4,
    title: "Maintenance",
  },
  {
    id: 5,
    title: "Drone Hub",
  },
];
