import React, { useEffect } from "react";
import styles from "../../Pages/Courses/CourseList.module.css";
import { useTheme } from "@emotion/react";
import langIcon from "../../Assets/englishIcon.png";
import editIcon from "../../Assets/editIcon.png";
import deleteIcon from "../../Assets/delete.png";
import { token_api } from "../../Utils/network";

const CourseBatchCard = ({ batchData, handleEditBatch, handleDeleteBatch }) => {
  const {
    id,
    name,
    descriptions,
    start_time,
    start_date,
    end_date,
    end_time,
    days_of_weeks,
    days,
    mrp,
    language,
    selling_price,
    mode,
    location,
    slots,
    instructors,
  } = batchData;


  const batchDays = days_of_weeks?.map((item) => {
    return { title: item?.weekday || item?.title };
  });

  const processLocation = (loc) => {
    if (loc && loc?.length > 0) {
      // its array
      const location = loc[0]?.title;
      return location;
    } else {
      const location = `${loc?.line_1 || ""}, ${loc?.line_2 || ""},${
        loc?.area || ""
      }, ${loc?.city || ""},${loc?.state || ""}, ${loc?.country || ""},${
        loc?.pincode || ""
      } `;
      return location;
    }
  };
  const processMode = (mode) => {
    if (mode && mode[0] && mode?.length > 0) {
      // its array
      const returnMode = mode[0]?.title;
      return returnMode;
    } else {
      const returnMode = `${modeArray[mode - 1]}`;
      return returnMode;
    }
  };

  const finalLocation = processLocation(location);
  const finalMode = processMode(mode);


  const theme = useTheme();
  const { palette } = theme;

  // useEffect(() => {
  //   if (batchData) {
  //     const { name, descriptions } = batchData;
  //   }
  // }, [batchData]);

  const handleDelete = () => {
    handleDeleteBatch();
  };

  return (
    <div className={`${styles.batchCard}`}>
      <div className={`${styles.batchIdCard}`}>{`${id || "N.A"}`}</div>
      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: "5px",
          position: "absolute",
          right: "8px",
          top: "8px",
        }}
      >
        <div style={{ cursor: "pointer" }} onClick={handleEditBatch}>
          <img src={editIcon} alt="edit" />
        </div>
        <div style={{ cursor: "pointer" }} onClick={handleDelete}>
          <img src={deleteIcon} alt="edit" />
        </div>
      </div> */}
      <div className={`${styles.batchContent}`}>
        <div style={{ display: "flex" }}>
          <div
            style={{ fontWeight: "500", color: palette?.primary[500] }}
            className={`${styles.titleStyles}`}
          >
            {`${name || "N.A"}`}
          </div>
          <div
            className={`${styles.flexBetween}`}
            style={{
              justifyContent: "start",
              columnGap: "5px",
              marginLeft: "5px",
            }}
          >
            <img src={language?.icon || language[0]?.icon} alt="icon" />
          </div>
        </div>
        <div className={`${styles.flexBetween}`}>
          <div
            style={{ columnGap: "15px" }}
            className={`${styles.flexBetween}`}
          >
            <div>
              <span>{`Time |  `}</span>
              <span className={`${styles.textGrayBatch}`}>
                {`${start_time || "N.A"} to ${end_time || "N.A"}`}
              </span>
              {/* <span>{`Date | `}</span>
            <span>{`Duration | `}</span> */}
            </div>
            <div>
              <span>{`Date |  `}</span>
              <span className={`${styles.textGrayBatch}`}>
                {`${start_date || "N.A"} to ${end_date || "N.A"}`}
              </span>
              {/* <span>{`Date | `}</span>
            <span>{`Duration | `}</span> */}
            </div>
            <div>
              <span>{`Duration |  `}</span>
              <span className={`${styles.textGrayBatch}`}>
                {`${start_time || "N.A"} to ${end_time || "N.A"}`}
              </span>
              {/* <span>{`Date | `}</span>
            <span>{`Duration | `}</span> */}
            </div>
          </div>

          <div
            style={{
              fontSize: "24px",
              fontWeight: "700",
              color: palette?.primary[500],
            }}
          >
            {`Rs ${mrp}`}
          </div>
        </div>
        <div
          style={{ justifyContent: "start", columnGap: "10px" }}
          className={`${styles.flexBetween}`}
        >
          <div>
            <span>Mode:</span>
            <span>{finalMode || "N.A"}</span>
          </div>
          <div>
            <span>Instructor:</span>{" "}
            {instructors?.length > 0
              ? instructors.map((item, index) => {
                  return (
                    <span
                      style={{ marginRight: "10px", boxSizing: "border-box" }}
                      key={index}
                    >
                      {item?.name}
                    </span>
                  );
                })
              : "N.A"}
          </div>
          <div>
            <span>Location:</span>
            <span>{`${finalLocation || "N.A"}`}</span>
          </div>
        </div>
        <div style={{ display: "flex", columnGap: "5px", marginTop: "10px" }}>
          {batchDays?.length > 0 &&
            batchDays.map((item) => (
              <div className={`${styles.dayCardStyle}`}>{item?.title}</div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default CourseBatchCard;

const modeArray = ["Online", "Offline", "Hybrid"];
