import React, { useState, useRef, useEffect } from "react";
import styles from "./CreateService.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "../../Component/Container/Container";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import { Button, useTheme } from "@mui/material";
import InputFields from "../../Component/InputFields/InputFields";
import DurationPicker from "../../Component/DurationPicker/DurationPicker";
import TextEditor from "../../Component/TextEditor/TextEditor";
import dragDrop from "../../Assets/drag_drop.svg";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import CustomDate from "../../Component/CustomDate/CustomDate";
import { base_url, token_api } from "../../Utils/network";
import axios from "axios";
import { useSelector } from "react-redux";
import DrawerComp from "../../Component/DrawerComp/DrawerComp";
import DrawerContainer from "../../Component/DrawerContainer/DrawerContainer";
import DrawerTitle from "../../Component/DrawerTitle/DrawerTitle";
import { validateService } from "./validate";
import AddQuestionForm from "./AddQuestionForm";
import editIcon from "../../Assets/editIcon.png";
import delete_icon from "../../Assets/delete_icon.svg";
import { duration } from "moment";
import Switch from "@mui/material/Switch";
import uploadIcon from "../../Assets/uploadVector.svg";

const CreateService = () => {
  const { access_token } = useSelector((state) => state.auth);

  const { slug } = useParams();
  const [serviceId, setServiceId] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);

  let navigate = useNavigate();
  const theme = useTheme();
  const { palette } = theme;
  const [serviceData, setServiceData] = useState({
    title: "",
    index: "",
    category: [],
    slug: "",
    descriptions: "",
    about: "",
    price: "",
    other_details: [],
    area_covered: "",
    duration: "",
  });

  const [questionsData, setQuestionsData] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [crsMediaPreview, setCrsMediaPreview] = useState([]);
  const [courseMedia, setCourseMedia] = useState([]);
  const [loading, setLoading] = useState(false);
  const fileInput = useRef(null);
  const [apiloading, setapiLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [usedDronesList, setUsedDronesList] = useState([]);

  const bannerInput = useRef(null);
  const [banners, setBanners] = useState("");
  const [bannersPreview, setBannersPreview] = useState("");

  const descriptionRef = useRef(null);
  const aboutRef = useRef(null);
  const preRequisiteRef = useRef(null);
  let errors = { error: false };

  const [choicesList, setChoicesList] = useState([]);
  const [textChoicesList, setTextChoicesList] = useState([]);

  const [questionForm, setQuestionForm] = useState({
    questionId: null,
    title: "", //Common
    more_info: "", //Common
    short_info: "", //Common
    view_type: [], //Common
    index: "", //Common
    is_multi: false, //Common....
    is_required: false, //Common...
    radio_data: [], //For Radio Button Data.... Choices....
    drop_down_data: [], //For drop down data... Choices....
    vertical_stack: [], //For Vertical Stack Data....Text Choices...
    horizontal_stack: [], //For Horizontal Stack Data....Text Choices...
    mcq_data: [], //For MCQ Data....Choices...
    multi_select: [], //For MULTI_SELECT Data....Choices...
  });

  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <Link
      to="/droneservices/serviceslist"
      className="orange-1 bread breadElement"
    >
      Services
    </Link>,
    <div className="orange-1 bread">
      {slug ? "Edit Service" : "Add Service"}
    </div>,
  ];

  const handleNavigateBack = () => {
    navigate(`/droneservices/serviceslist`);
  };

  const handleChange = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && !multiple) {
      setServiceData({
        ...serviceData,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      //   if (name === "pincode" && (!/^\d{6}$/.test(value))) {
      //     return;
      // }

      setServiceData({ ...serviceData, [name]: value });
    }
  };

  const handleDeleteChip = (id, selectName) => {
    setServiceData({
      ...serviceData,
      [selectName]: serviceData?.[selectName]?.filter(
        (chip) => chip?.id !== id
      ),
    });
  };
  function handleDragOver(e) {
    e.preventDefault();
  }

  const handleDrop = (e) => {
    e.preventDefault();
    // const files = Array.from(e.dataTransfer.files);
    if (e?.target?.files) {
      setBanners(e?.target?.files[0]);
      const imageUrl = URL.createObjectURL(e.target.files[0]);
      setBannersPreview(imageUrl);
    }
  };

  const handleClick = (type) => {
    if (type === "c_media") {
      fileInput.current.click();
    } else if (type === "banner") {
      bannerInput.current.click();
    }
  };

  const getCategoryList = () => {
    token_api
      .get(`/service/category`)
      .then((response) => {
        setCategoryList(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDroneList = () => {
    token_api
      .get(`/drone/inventory`)
      .then((response) => {
        if (response) {
          let modifiedArray = response?.data?.data.map(
            (item, index) => item?.drone
          );
          setUsedDronesList(modifiedArray);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //choices list for 1.Radio ,2.DropDown, 7.MCQ, 8.Multi-Select...

  const getChoicesList = () => {
    token_api
      .get(`service/other_detail_choices?page_size=1000`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          setChoicesList(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //text Choices list for 3.Vertical Text Stack , 4.Horizontal Text stack...

  const getTextChoicesList = () => {
    token_api
      .get(`service/other_detail/text_choices?page_size=200`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          setTextChoicesList(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCategoryList();
    getDroneList();
    getChoicesList();
    getTextChoicesList();
  }, []);

  const getServicesDetails = (slug) => {
    token_api
      .get(`service/${slug}`)
      .then((response) => {
        if (response?.data?.data) {
          const {
            id,
            title,
            slug,
            price,
            index,
            descriptions,
            about,
            banner,
            other_details,
            category,
            area_covered,
            duration,
          } = response?.data?.data;
          setServiceData({
            ...serviceData,
            title,
            slug,
            price,
            index,
            descriptions,
            about,
            other_details,
            area_covered,
            duration,

            category: [category],
          });
          setServiceId(id);
          setBannersPreview(banner);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (slug) {
      getServicesDetails(slug);
    }
  }, [slug]);

  const handleCreateServiceData = async () => {
    let isDescriptionsEmpty;
    let isAboutEmpty;

    if (descriptionRef.current) {
      const content = descriptionRef.current.getContent();
      serviceData.descriptions = content;

      // Set a boolean value based on whether the content is empty or not
      isDescriptionsEmpty = !content.trim();
    }

    if (aboutRef.current) {
      const aboutContent = aboutRef.current.getContent();
      serviceData.about = aboutContent;

      // Set a boolean value based on whether the content is empty or not
      isAboutEmpty = !aboutContent.trim();
    }

    let data = {
      creator: 1,
      category_id: serviceData?.category[0]?.id,
      title: serviceData?.title,
      slug: serviceData?.slug,
      descriptions: serviceData?.descriptions
        ? serviceData?.descriptions
        : null,
      about: serviceData?.about ? serviceData?.about : null,
      index: serviceData?.index,
      price: serviceData?.price ? serviceData?.price : null,
      area_covered: serviceData?.area_covered,
      duration: serviceData?.duration,
    };

    try {
      const response = await validateService(serviceData);

      if (response === "success") {
        if (!serviceId) {
          const newServiceResponse = await token_api.post(`service/`, data);
          if (
            newServiceResponse?.status === 400 ||
            newServiceResponse?.status === 404
          ) {
            console.log(newServiceResponse?.data, "hariHhahaha");
            setFormErrors({
              [newServiceResponse?.data?.error?.fields[0]?.field]:
                newServiceResponse?.data?.error?.fields[0]?.message[0],
              error: true,
            });
          }

          if (banners) {
            let serviceMedia = new FormData();
            serviceMedia.append("banner", banners);

            await axios({
              method: "patch",
              url: `${base_url}service/${newServiceResponse?.data?.data?.id}/`,
              data: serviceMedia,
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${access_token}`,
              },
            });
          }

          if (
            newServiceResponse?.status === 200 ||
            newServiceResponse?.status === 201
          ) {
            alert("New Service Created Successfully!");
            setFormErrors({});
            navigate(`/droneservices/serviceslist`);
          }
        } else {
          const newServiceResponse = await token_api.patch(
            `service/${serviceId}/`,
            data
          );

          if (
            newServiceResponse?.status === 400 ||
            newServiceResponse?.status === 404
          ) {
            console.log(newServiceResponse?.data, "hariHhahaha");
            setFormErrors({
              [newServiceResponse?.data?.error?.fields[0]?.field]:
                newServiceResponse?.data?.error?.fields[0]?.message[0],
              error: true,
            });
          }
          if (banners) {
            let serviceMedia = new FormData();
            serviceMedia.append("banner", banners);

            await axios({
              method: "patch",
              url: `${base_url}service/${newServiceResponse?.data?.data?.id}/`,
              data: serviceMedia,
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${access_token}`,
              },
            });
          }

          if (
            newServiceResponse?.status === 200 ||
            newServiceResponse?.status === 201
          ) {
            alert("Service Details Updated Successfully!");
            setFormErrors({});

            navigate(`/droneservices/serviceslist`);
          }
        }
      }
    } catch (error) {
      console.log(error, "ahrIerrrrara");
      setFormErrors(error);
    }
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(!openDrawer);
    setQuestionForm({
      ...questionForm,
      questionId: null,
      title: "", //Common
      more_info: "", //Common
      short_info: "", //Common
      view_type: [], //Common
      index: "", //Common
      is_multi: false, //Common....
      is_required: false, //Common...
      radio_data: [], //For Radio Button Data.... Choices....
      drop_down_data: [], //For drop down data... Choices....
      vertical_stack: [], //For Vertical Stack Data....Text Choices...
      horizontal_stack: [], //For Horizontal Stack Data....Text Choices...
      mcq_data: [], //For MCQ Data....Choices...
      multi_select: [], //For MULTI_SELECT Data....Choices...
    });
  };

  const handleEditDetail = (detail, index) => {
    setOpenDrawer(true);

    //Implemented...Radio Button
    if (detail?.view_type == 1) {
      const {
        id,
        is_multi,
        more_info,
        short_info,
        is_required,
        index,
        title,
        view_type,
        choices,
      } = detail;
      let questionType = typeArray?.filter((item) => item?.id === view_type);
      let choiceData = choices.map((item, index) => ({
        id: item?.choice,
        title: item?.title,
        index: item?.index,
      }));

      setQuestionForm({
        ...questionForm,
        questionId: id,
        title: title,
        is_multi: is_multi,
        is_required: is_required,
        index: index,
        more_info: more_info,
        short_info: short_info,
        view_type: [...questionType],
        radio_data: choiceData,
      });
    }
    //Drop Down Data....
    else if (detail?.view_type == 2) {
      const {
        id,
        is_multi,
        is_required,
        more_info,
        short_info,
        index,
        title,
        view_type,
        choices,
      } = detail;
      let questionType = typeArray?.filter((item) => item?.id === view_type);
      let choicesList = choices.map((item, index) => ({
        title: item?.title,
        index: item?.index,
      }));

      setQuestionForm({
        ...questionForm,
        questionId: id,
        view_type: [...questionType],
        title: title,
        index: index,
        more_info: more_info,
        short_info: short_info,
        is_multi: is_multi,
        is_required: is_required,
        drop_down_data: choicesList,
      });
    }

    //Vertical Text Stack....
    else if (detail?.view_type == 3) {
      const {
        id,
        is_multi,
        is_required,
        index,
        title,
        short_info,
        more_info,
        view_type,
        text_choices,
      } = detail;
      let questionType = typeArray?.filter((item) => item?.id === view_type);
      let textChoiceData = text_choices.map((item, index) => ({
        id: item?.text_choice,
        hint: item?.hint,
        more_info: item?.more_info,
        index: item?.index,
      }));

      setQuestionForm({
        ...questionForm,
        questionId: id,
        view_type: [...questionType],
        title: title,
        index: index,
        short_info: short_info,
        more_info: more_info,
        is_multi: is_multi,
        is_required: is_required,
        vertical_stack: textChoiceData,
      });
    }

    //Horizontal Text Stack....
    else if (detail?.view_type == 4) {
      const {
        id,
        is_multi,
        short_info,
        more_info,
        is_required,
        index,
        title,
        view_type,
        text_choices,
      } = detail;
      let questionType = typeArray?.filter((item) => item?.id === view_type);
      let textChoiceData = text_choices.map((item, index) => ({
        id: item?.text_choice,
        hint: item?.hint,
        more_info: item?.more_info,
        index: item?.index,
      }));

      setQuestionForm({
        ...questionForm,
        questionId: id,
        view_type: [...questionType],
        index: index,
        short_info: short_info,
        more_info: more_info,
        is_multi: is_multi,
        title: title,
        is_required: is_required,
        horizontal_stack: textChoiceData,
      });
    }
    //Icon Text Pending...
    else if (detail?.view_type == 5) {
      const { id, is_multi, is_required, index, title, view_type, choices } =
        detail;
      let questionType = typeArray?.filter((item) => item?.id === view_type);

      setQuestionForm({
        ...questionForm,
        questionId: id,
        view_type: [...questionType],
        text: questionType[0]?.title,
        index: index,
        question: title,
        is_required: is_required,
        is_multi: is_multi,
        drop_down: [],
        multi_select: [],
      });
    }
    //Date is Pending...
    else if (detail?.view_type == 6) {
      const { id, is_multi, is_required, index, title, view_type, choices } =
        detail;
      let questionType = typeArray?.filter((item) => item?.id === view_type);

      setQuestionForm({
        ...questionForm,
        questionId: id,
        view_type: [...questionType],
        // text: questionType[0]?.title,
        index: index,
        is_multi: is_multi,
        question: title,
        option_type: [],
        is_required: is_required,
        drop_down: [],
        multi_select: choices,
      });
    }

    //Implemented...Mcq selection
    else if (detail?.view_type == 7) {
      const {
        id,
        is_multi,
        more_info,
        short_info,
        is_required,
        index,
        title,
        view_type,
        choices,
      } = detail;
      let questionType = typeArray?.filter((item) => item?.id === view_type);
      let choiceData = choices.map((item, index) => ({
        id: item?.choice,
        title: item?.title,
        index: item?.index,
      }));

      setQuestionForm({
        ...questionForm,
        questionId: id,
        title: title,
        is_multi: is_multi,
        is_required: is_required,
        index: index,
        more_info: more_info,
        short_info: short_info,
        view_type: [...questionType],
        mcq_data: choiceData,
      });
    }

    //Implemented...Multi-Select
    else if (detail?.view_type == 8) {
      const {
        id,
        is_multi,
        more_info,
        short_info,
        is_required,
        index,
        title,
        view_type,
        choices,
      } = detail;
      let questionType = typeArray?.filter((item) => item?.id === view_type);
      let choiceData = choices.map((item, index) => ({
        id: item?.choice,
        title: item?.title,
        index: item?.index,
      }));

      setQuestionForm({
        ...questionForm,
        questionId: id,
        title: title,
        is_multi: is_multi,
        is_required: is_required,
        index: index,
        more_info: more_info,
        short_info: short_info,
        view_type: [...questionType],
        multi_select: choiceData,
      });
    }
  };

  const handleDeleteDetail = (detail, index) => {
    token_api
      .delete(`service/other_detail/service_question/${detail?.id}/`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("Question Deleted Successfully!");
          getServicesDetails(slug);
        }
      });
  };

  //Question adding changes......

  const handleChangeQuestion = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setQuestionForm({
        ...questionForm,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setQuestionForm({
        ...questionForm,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setQuestionForm({
        ...questionForm,
        [name]: value,
      });
    }
  };
  const handleDeleteQuestion = (id, selectName) => {
    setQuestionForm({
      ...questionForm,
      [selectName]: questionForm?.[selectName]?.filter(
        (chip) => chip?.id !== id
      ),
    });
  };

  const handleChangeMandatory = () => {
    setQuestionForm((prevState) => ({
      ...prevState,
      is_required: !prevState.is_required,
    }));
  };

  const handleAddQuestion = () => {
    let data = {
      service_id: serviceId,
      is_multi: questionForm?.is_multi,
      view_type: questionForm?.view_type[0]?.id,
      index: questionForm?.index,
      is_required: questionForm?.is_required,
      other_detail_question_detail: {
        title: questionForm?.title,
        more_info: questionForm?.more_info,
        short_info: questionForm?.short_info,
      },
    };

    //1.Radio Button...Completed...
    if (questionForm?.view_type[0]?.id == 1) {
      data.is_multi = false;

      data.other_detail_choice_detail = questionForm.radio_data.map(
        (item, index) => ({
          id: item?.id,
          title: item?.title,
          index: item?.index,
        })
      );
    }

    //2. Drop Down Data....Completed...
    if (questionForm?.view_type[0]?.id == 2) {
      data.is_multi = false;

      data.other_detail_choice_detail = questionForm.drop_down_data.map(
        (item, index) => ({
          id: item?.id,
          title: item?.title,
          index: item?.index,
        })
      );
    }

    //3. Vertical Text Stack...Completed...

    if (questionForm?.view_type[0]?.id == 3) {
      data.is_multi = true;
      data.other_detail_text_choice_detail = questionForm?.vertical_stack.map(
        (item, index) => ({
          more_info: item?.more_info,
          index: item?.index,
          hint: item?.hint,
        })
      );
    }

    //4. Horizontal Text Stack....Completed...

    if (questionForm?.view_type[0]?.id == 4) {
      data.is_multi = true;
      data.other_detail_text_choice_detail = questionForm?.horizontal_stack.map(
        (item, index) => ({
          more_info: item?.more_info,
          index: item?.index,
          hint: item?.hint,
        })
      );
    }

    //.5 Icon Text ...Pending...
    if (questionForm?.view_type[0]?.id == 5) {
      data.is_multi = false;
      data.other_detail_choice_detail.push({
        title: questionForm?.text,
        index: 1,
      });
    }
    //6. Date...Pending...
    if (questionForm?.view_type[0]?.id == 6) {
      data.is_multi = true;
      questionForm?.multi_select.forEach((item, idx) => {
        data.other_detail_choice_detail.push({
          title: item.title,
          index: idx + 1,
        });
      });
    }

    //7. MCQ...Implemented...

    if (questionForm?.view_type[0]?.id == 7) {
      data.is_multi = false;
      data.other_detail_choice_detail = questionForm.mcq_data.map(
        (item, index) => ({
          id: item?.id,
          title: item?.title,
          index: item?.index,
        })
      );
    }

    //8. Multi-Select...Implemented...

    if (questionForm?.view_type[0]?.id == 8) {
      data.is_multi = false;
      data.other_detail_choice_detail = questionForm.multi_select.map(
        (item, index) => ({
          id: item?.id,
          title: item?.title,
          index: item?.index,
        })
      );
    }

    if (!questionForm?.questionId) {
      token_api
        .post(`service/other_detail/service_question/`, data)
        .then((response) => {
          if (
            response?.status === 200 ||
            response?.status === 201 ||
            response?.status === 204
          ) {
            alert("Question is created successfully!");
            setQuestionForm({
              ...questionForm,
              view_type: [],
              question: "",
              option_type: "",
              mandatory: false,
            });

            setOpenDrawer(false);
            getServicesDetails(slug);
            getChoicesList();
            getTextChoicesList();
          }
        });
    } else {
      token_api
        .patch(
          `service/other_detail/service_question/${questionForm?.questionId}/`,
          data
        )
        .then((response) => {
          if (
            response?.status === 200 ||
            response?.status === 201 ||
            response?.status === 204
          ) {
            alert("Question is updated successfully!");
            setQuestionForm({
              ...questionForm,
              questionId: null,
              title: "", //Common
              more_info: "", //Common
              short_info: "", //Common
              view_type: [], //Common
              index: "", //Common
              is_multi: false, //Common....
              is_required: false, //Common...
              radio_data: [], //For Radio Button Data.... Choices....
              drop_down_data: [], //For drop down data... Choices....
              vertical_stack: [], //For Vertical Stack Data....Text Choices...
              horizontal_stack: [], //For Horizontal Stack Data....Text Choices...
              mcq_data: [], //For MCQ Data....Choices...
              multi_select: [], //For MULTI_SELECT Data....Choices...
            });
            setOpenDrawer(false);
            getServicesDetails(slug);
            getChoicesList();
            getTextChoicesList();
          }
        });
    }
  };

  //1,2,7,8 Data Changing...

  //Radio Button Data Changing...

  const handleSelectRadioData = (radioItem) => {
    setQuestionForm((prevForm) => {
      // Check if the mcqItem is already in the vertical_stack array by comparing ids
      const isAlreadySelected = prevForm.radio_data.some(
        (item) => item?.id === radioItem?.id
      );

      // If it's already selected, remove it; otherwise, add it
      const updatedRadioData = isAlreadySelected
        ? prevForm.radio_data.filter((item) => item?.id !== radioItem?.id)
        : [...prevForm.radio_data, radioItem];

      return {
        ...prevForm,
        radio_data: updatedRadioData,
      };
    });
  };

  //MCQ Data Changing...

  const handleSelectMcqItem = (mcqItem) => {
    setQuestionForm((prevForm) => {
      // Check if the mcqItem is already in the vertical_stack array by comparing ids
      const isAlreadySelected = prevForm.mcq_data.some(
        (item) => item?.id === mcqItem?.id
      );

      // If it's already selected, remove it; otherwise, add it
      const updatedMcqData = isAlreadySelected
        ? prevForm.mcq_data.filter((item) => item?.id !== mcqItem?.id)
        : [...prevForm.mcq_data, mcqItem];

      return {
        ...prevForm,
        mcq_data: updatedMcqData,
      };
    });
  };

  //Multi-Select Data Changing...

  const handleSelectMultiItem = (multiItem) => {
    setQuestionForm((prevForm) => {
      // Check if the mcqItem is already in the vertical_stack array by comparing ids
      const isAlreadySelected = prevForm.multi_select.some(
        (item) => item?.id === multiItem?.id
      );

      // If it's already selected, remove it; otherwise, add it
      const updatedMultiData = isAlreadySelected
        ? prevForm.multi_select.filter((item) => item?.id !== multiItem?.id)
        : [...prevForm.multi_select, multiItem];

      return {
        ...prevForm,
        multi_select: updatedMultiData,
      };
    });
  };

  //3 and 4 data changing...
  //Vertical Stack changes....

  const handleChangeVertical = (mcqItem) => {
    setQuestionForm((prevForm) => {
      // Check if the mcqItem is already in the vertical_stack array by comparing ids
      const isAlreadySelected = prevForm.vertical_stack.some(
        (item) => item?.id === mcqItem?.id
      );

      // If it's already selected, remove it; otherwise, add it
      const updatedVerticalStack = isAlreadySelected
        ? prevForm.vertical_stack.filter((item) => item?.id !== mcqItem?.id)
        : [...prevForm.vertical_stack, mcqItem];

      return {
        ...prevForm,
        vertical_stack: updatedVerticalStack,
      };
    });
  };

  //Horizontal Stack Changes....

  const handleChangeHorizontal = (mcqItem) => {
    setQuestionForm((prevForm) => {
      // Check if the mcqItem is already in the vertical_stack array by comparing ids
      const isAlreadySelected = prevForm.horizontal_stack.some(
        (item) => item?.id === mcqItem?.id
      );

      // If it's already selected, remove it; otherwise, add it
      const updatedVerticalStack = isAlreadySelected
        ? prevForm.horizontal_stack.filter((item) => item?.id !== mcqItem?.id)
        : [...prevForm.horizontal_stack, mcqItem];

      return {
        ...prevForm,
        horizontal_stack: updatedVerticalStack,
      };
    });
  };

  const getTypeOfQuestion = (typeVal) => {
    switch (typeVal) {
      case 1:
        return {
          title: "Radio Button",
          titleSymbol: "O",
          titleStyles: {
            color: "rgba(166, 102, 9, 1)",
            backgroundColor: "rgba(255, 224, 176, 1)",
          },
        };

      case 2:
        return {
          title: "Drop Down",
          titleSymbol: "O",
          titleStyles: {
            color: "rgba(188, 70, 7, 1)",
            backgroundColor: "rgba(250, 217, 212, 1)",
          },
        };
      case 3:
        return {
          title: "Vetical Text Stack",
          titleSymbol: "M",
          titleStyles: {
            color: "rgba(5, 97, 182, 1)",
            backgroundColor: "rgba(216, 245, 249, 1)",
          },
        };
      case 4:
        return {
          title: "Horizontal Text Stack",
          titleSymbol: "M",
          titleStyles: {
            color: "rgba(45, 112, 5, 1)",
            backgroundColor: "rgba(217, 249, 216, 1)",
          },
        };

      //5, 6 PENDING....
      case 7:
        return {
          title: "MCQ",
          titleSymbol: "0",
          titleStyles: {
            color: "rgba(164, 6, 148, 1)",
            backgroundColor: "rgba(249, 216, 247, 1)",
          },
        };

      case 8:
        return {
          title: "Multi-Select",
          titleSymbol: "0",
          titleStyles: {
            color: "rgba(149, 91, 4, 1)",
            backgroundColor: "rgba(248, 255, 169, 1)",
          },
        };

      default:
        return {
          title: "Multi-Select",
          titleSymbol: "O",
          titleStyles: {
            color: "rgba(149, 91, 4, 1)",
            backgroundColor: "rgba(248, 255, 169, 1)",
          },
        };
    }
  };
  return (
    <div>
      <Container>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
            <img src={breadCrumbBack} />
          </div>
          <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
        </div>

        <div>
          <div
            style={{
              padding: "10px 100px",
              boxSizing: "border-box",
              borderRadius: "0px",
            }}
          >
            <div
              className={`${styles.titleStyles} ${styles.formContainer}`}
              style={{ color: palette?.primary[500] }}
            >
              {slug ? "Edit Service" : "Add Service"}
            </div>
            <div className={`${styles.formContainer} mt-20`}>
              <div className={styles.commonFieldsBox}>
                <InputFields
                  required
                  style={{ marginBottom: "20px" }}
                  label="Service Name"
                  name="title"
                  value={serviceData?.title}
                  error={formErrors?.title}
                  helperText={formErrors?.title}
                  onChange={handleChange}
                />
                <InputFields
                  required
                  style={{ marginBottom: "20px" }}
                  label="Service Slug"
                  name="slug"
                  value={serviceData?.slug}
                  error={formErrors?.slug}
                  helperText={formErrors?.slug}
                  onChange={handleChange}
                />

                <InputFields
                  required
                  style={{ marginBottom: "20px" }}
                  label="Service Index"
                  name="index"
                  value={serviceData?.index}
                  error={formErrors?.index}
                  helperText={formErrors?.index}
                  onChange={handleChange}
                  type="number"
                />
                <InputFields
                  style={{ marginBottom: "20px" }}
                  label="Area Covered"
                  name="area_covered"
                  value={serviceData?.area_covered}
                  onChange={handleChange}
                />
                <DurationPicker
                  style={{ width: "100%" }}
                  label="Duration"
                  size="small"
                  name="duration"
                  onChange={handleChange}
                  value={serviceData?.duration}
                />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "10px",
                    marginBottom: "20px",
                  }}
                >
                  <CustomSelectChip
                    multiple={false}
                    label="Category *"
                    name="category"
                    onChange={handleChange}
                    onDelete={handleDeleteChip}
                    value={serviceData?.category}
                    listArray={categoryList}
                    error={formErrors?.category}
                    helperText={formErrors?.category}
                  />
                </div>
                <InputFields
                  label="Average Price / Acre"
                  name="price"
                  value={serviceData?.price}
                  onChange={handleChange}
                  type="number"
                  error={formErrors?.price}
                  helperText={formErrors?.price}
                />
              </div>
            </div>
            <div className={`${styles.formContainer} mt-20`}>
              <p
                className={`${styles.titleStyles}`}
                style={{ color: palette?.primary[500], marginBottom: "20px" }}
              >
                Description (What You'll Get){" "}
              </p>
              <TextEditor
                editorRef={descriptionRef}
                initialValue={serviceData?.descriptions}
              />
              <small style={{ marginTop: "5px", color: "#d32f2f" }}>
                {formErrors?.descriptions &&
                  "Please enter description of the service"}
              </small>
            </div>
            <div className={`${styles.formContainer} mt-20`}>
              <p
                className={`${styles.titleStyles}`}
                style={{ color: palette?.primary[500], marginBottom: "20px" }}
              >
                About Service
              </p>
              <TextEditor
                editorRef={aboutRef}
                initialValue={serviceData?.about}
              />
              <small style={{ marginTop: "5px", color: "#d32f2f" }}>
                {formErrors?.about && "Please enter about the Service"}
              </small>
            </div>
            <div className={`${styles.formContainer} mt-20`}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p
                  className={`${styles.titleStyles}`}
                  style={{ color: palette?.primary[500], marginBottom: "20px" }}
                >
                  Other Service Details
                </p>
                {/* {serviceId && (
                  <p
                    className={`${styles.titleStyles}`}
                    style={{
                      color: palette?.primary[500],
                      marginBottom: "20px",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={handleOpenDrawer}
                  >
                    + ADD QUESTIONS
                  </p>
                )} */}
              </div>
            </div>

            <div className={`${styles.formContainer} mt-20`}>
              {serviceData?.other_details.length > 0 ? (
                <div style={{ widows: "100%", marginTop: "20px" }}>
                  {serviceData?.other_details.map((detail, index) => {
                    return (
                      <div key={index} className={styles.detailCard}>
                        <div className={styles.firstCon}>
                          <p className={styles.indexText}>Q.{detail?.id}</p>
                          <h1 className={styles.titleText}>{detail?.title}</h1>
                        </div>

                        <div className={styles.secondCon}>
                          <div className={styles.viewCon}>
                            <h3
                              className={styles.viewTypeText}
                              style={
                                getTypeOfQuestion(detail?.view_type)
                                  ?.titleStyles
                              }
                            >
                              {getTypeOfQuestion(detail?.view_type)?.title}
                            </h3>
                            <h3 className={styles.viewStr}>
                              {
                                getTypeOfQuestion(detail?.view_type)
                                  ?.titleSymbol
                              }
                            </h3>
                          </div>
                          {/* <div>
                            <img
                              src={editIcon}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handleEditDetail(detail, index);
                              }}
                            />
                            <img
                              src={delete_icon}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handleDeleteDetail(detail, index);
                              }}
                            />
                          </div> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p>No questions added.</p>
              )}
            </div>

            <div className={`${styles.formContainer} mt-20`}>
              <div
                style={{ color: palette?.primary[500] }}
                className={`${styles.titleStyles}`}
              >
                Add Top Banner
              </div>

              <div style={{ marginTop: "20px" }}>
                <div
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e)}
                  className={styles.dropBoxStyles}
                >
                  <div
                    onClick={() => {
                      handleClick("banner");
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p>
                      <img src={dragDrop} alt="dradanddrop" />
                    </p>
                    <p>Drag & Drop the File or click here</p>
                    <input
                      ref={bannerInput}
                      type="file"
                      // multiple
                      onChange={(e) => {
                        handleDrop(e);
                      }}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "35%",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                {bannersPreview && (
                  <div
                    className={`${styles.flexBetween} ${styles.imgCardBox} `}
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    <div>
                      <img width={200} height={140} src={bannersPreview} />
                    </div>
                    <div>
                      <Button
                        variant="contained"
                        onClick={() => handleClick("banner")}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className={`${styles.formContainer} mt-20`}>
              <Button
                disabled={apiloading}
                // style={{ backgroundColor: apiloading && "gray" }}
                onClick={handleCreateServiceData}
                className={`${styles.gradientColor}`}
                variant="contained"
              >
                Save & Publish
              </Button>
            </div>
          </div>
        </div>
      </Container>
      <DrawerComp
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor="right"
      >
        <DrawerContainer style={{ marginTop: "0px", marginBottom: "10px" }}>
          <DrawerTitle
            text={`Add Question`}
            handleCancelDrw={() => setOpenDrawer((prev) => !prev)}
          />

          <div
            style={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "column",
              width: "80%",
            }}
          >
            <div>
              <h3>Type</h3>
              <div style={{ marginTop: "20px" }}>
                <CustomSelectChip
                  multiple={false}
                  label="Question Type"
                  name="view_type"
                  onChange={handleChangeQuestion}
                  onDelete={handleDeleteQuestion}
                  value={questionForm?.view_type || [typeArray[0]]}
                  listArray={typeArray}
                />
              </div>
            </div>
            <div>
              <h3>Question</h3>
              <div style={{ marginTop: "20px" }}>
                <textarea
                  placeholder="write here."
                  name="title"
                  value={questionForm?.title}
                  onChange={handleChangeQuestion}
                  style={{
                    padding: "20px",
                    boxSizing: "border-box",
                    width: "100%",
                  }}
                  rows={10}
                />{" "}
              </div>
            </div>
            <div>
              <h3>More Info</h3>
              <div style={{ marginTop: "20px" }}>
                <textarea
                  placeholder="Type more info here..."
                  name="more_info"
                  value={questionForm?.more_info}
                  onChange={handleChangeQuestion}
                  style={{
                    padding: "20px",
                    boxSizing: "border-box",
                    width: "100%",
                  }}
                  rows={10}
                />{" "}
              </div>
            </div>
            <div>
              <h3>Short Info</h3>
              <div style={{ marginTop: "20px" }}>
                <textarea
                  placeholder="Type short info here..."
                  name="short_info"
                  value={questionForm?.short_info}
                  onChange={handleChangeQuestion}
                  style={{
                    padding: "20px",
                    boxSizing: "border-box",
                    width: "100%",
                  }}
                  rows={10}
                />{" "}
              </div>
            </div>
            <div>
              <h3>Mandatory</h3>
              <div style={{ marginTop: "20px" }}>
                <Switch
                  checked={questionForm?.is_required}
                  onChange={handleChangeMandatory}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
            </div>
            <div style={{ margin: "10px 0px" }}>
              <h3>Index</h3>

              <InputFields
                // style={{ marginBottom: "20px" }}
                label="Index"
                name="index"
                value={questionForm?.index}
                // error={formErrors?.title}
                // helperText={formErrors?.title}
                onChange={handleChangeQuestion}
              />
            </div>
            {/* Radio-Button Implemented..... */}
            {questionForm?.view_type[0]?.id == 1 && (
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h3>0ptions</h3>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      // flexWrap: "wrap",
                      gap: "20px",
                    }}
                  >
                    {choicesList.map((item, index) => {
                      return (
                        <div
                          style={{
                            width: "60%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            padding: "20px 10px",
                            boxSizing: "border-box",
                            color: "white",
                            backgroundColor: questionForm?.radio_data.some(
                              (radioItem) => radioItem?.id === item?.id
                            )
                              ? "#E95E33"
                              : "lightgrey",
                            border: "1px solid black",
                            cursor: "pointer",
                          }}
                          key={item?.id}
                          onClick={() => {
                            handleSelectRadioData(item);
                          }}
                        >
                          {item?.title}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
            {/* DropDown Implemented...... */}
            {questionForm?.view_type[0]?.id == 2 && (
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h3>Answer</h3>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <div style={{ marginTop: "20px" }}>
                    <CustomSelectChip
                      multiple={true}
                      label="Drop-Down"
                      name="drop_down_data"
                      onChange={handleChangeQuestion}
                      onDelete={handleDeleteQuestion}
                      value={questionForm?.drop_down_data}
                      listArray={choicesList}
                    />
                  </div>
                </div>
              </div>
            )}
            {/* Vertical Text Stack.... Implemented*/}
            {questionForm?.view_type[0]?.id == 3 && (
              <div>
                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    // flexWrap: "wrap",
                    gap: "20px",
                  }}
                >
                  {textChoicesList.map((textField, index) => {
                    return (
                      <div
                        style={{
                          width: "60%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          padding: "20px 10px",
                          boxSizing: "border-box",
                          color: "white",
                          backgroundColor: questionForm?.vertical_stack.some(
                            (item) => item?.id === textField?.id
                          )
                            ? "#E95E33"
                            : "lightgrey",
                          border: "1px solid black",
                          cursor: "pointer",
                        }}
                        key={textField?.id}
                        onClick={() => {
                          handleChangeVertical(textField);
                        }}
                      >
                        {textField?.more_info || textField?.hint}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {/* Horizontal Text Stack...Implemented */}
            {questionForm?.view_type[0]?.id == 4 && (
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h3>Options</h3>
                </div>
                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    // flexWrap: "wrap",
                    gap: "20px",
                  }}
                >
                  {textChoicesList.map((textField, index) => {
                    return (
                      <div
                        style={{
                          width: "60%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          padding: "20px 10px",
                          boxSizing: "border-box",
                          color: "white",
                          backgroundColor: questionForm?.horizontal_stack.some(
                            (item) => item?.id === textField?.id
                          )
                            ? "#E95E33"
                            : "lightgrey",
                          border: "1px solid black",
                          cursor: "pointer",
                        }}
                        key={textField?.id}
                        onClick={() => {
                          handleChangeHorizontal(textField);
                        }}
                      >
                        {textField?.more_info || textField?.hint}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {/* Text----....pending */}
            {questionForm?.view_type[0]?.id == 5 && (
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h3>Answer</h3>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <textarea
                    placeholder="write here."
                    name="text"
                    value={questionForm?.text}
                    onChange={handleChangeQuestion}
                    style={{
                      padding: "20px",
                      boxSizing: "border-box",
                      width: "100%",
                    }}
                    rows={5}
                  />{" "}
                </div>
              </div>
            )}
            {/* Multi-Select..... pending */}
            {questionForm?.view_type[0]?.id == 6 && (
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h3>Options</h3>
                  {/* <h3
                    style={{
                      color: palette?.primary[500],
                      marginBottom: "20px",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    +Add
                  </h3> */}
                </div>
                <div style={{ marginTop: "20px" }}>
                  {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      // justifyContent: "center",
                      alignItems: "center",
                      gap: "20px",
                      background: "lightgrey",
                      borderRadius: "20px",
                      width: "40%",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        border: "1px solid grey",
                        borderRadius: "100%",
                        padding: "10px",
                        height: "40px",
                        width: "40px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={uploadIcon} />
                    </div>
                    <p>Name 1</p>
                  </div> */}

                  <CustomSelectChip
                    multiple={true}
                    label="Multi-Select"
                    name="multi_select"
                    onChange={handleChangeQuestion}
                    onDelete={handleDeleteQuestion}
                    value={questionForm?.multi_select}
                    listArray={optionsList}
                    // error={formErrors?.option_type}
                    // helperText={formErrors?.option_type}
                  />
                </div>
              </div>
            )}
            {/* Mcq Data is implemented... */}
            {questionForm?.view_type[0]?.id == 7 && (
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h3>0ptions</h3>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      // flexWrap: "wrap",
                      gap: "20px",
                    }}
                  >
                    {choicesList.map((item, index) => {
                      return (
                        <div
                          style={{
                            width: "60%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            padding: "20px 10px",
                            boxSizing: "border-box",
                            color: "white",
                            backgroundColor: questionForm?.mcq_data.some(
                              (mcqItem) => mcqItem?.id === item?.id
                            )
                              ? "#E95E33"
                              : "lightgrey",
                            border: "1px solid black",
                            cursor: "pointer",
                          }}
                          key={item?.id}
                          onClick={() => {
                            handleSelectMcqItem(item);
                          }}
                        >
                          {item?.title}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}

            {/* Multi Select Data is implemented... */}
            {questionForm?.view_type[0]?.id == 8 && (
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h3>0ptions</h3>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      // flexWrap: "wrap",
                      gap: "20px",
                    }}
                  >
                    {choicesList.map((item, index) => {
                      return (
                        <div
                          style={{
                            width: "60%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            padding: "20px 10px",
                            boxSizing: "border-box",
                            color: "white",
                            backgroundColor: questionForm?.multi_select.some(
                              (multiItem) => multiItem?.id === item?.id
                            )
                              ? "#E95E33"
                              : "lightgrey",
                            border: "1px solid black",
                            cursor: "pointer",
                          }}
                          key={item?.id}
                          onClick={() => {
                            handleSelectMultiItem(item);
                          }}
                        >
                          {item?.title}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div style={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              className={`${styles.gradientColor}`}
              onClick={handleAddQuestion}
            >
              Save
            </Button>
          </div>
        </DrawerContainer>
      </DrawerComp>
    </div>
  );
};

export default CreateService;

const typeArray = [
  {
    id: 1, //RADIO
    title: "Radio-Button",
  },
  {
    id: 2, //DROP-DOWN
    title: "Drop-Down",
  },
  {
    id: 3, //VERTICAL TEXT STACK
    title: "Vertical Text Stack",
  },
  {
    id: 4, //HORIZONTAL TEXT STACK
    title: "Horizontal Text Stack",
  },
  {
    id: 5, //ICON TEXT
    title: "Icon Text",
  },

  {
    id: 6, //DATE
    title: "Date",
  },

  {
    id: 7, //MCQ
    title: "Mcq",
  },
  {
    id: 8, //Multi-Select
    title: "Multi-Select",
  },
];

const optionsList = [
  {
    id: 1,
    title: "Title 1",
  },
  {
    id: 2,
    title: "Title 2",
  },
  {
    id: 3,
    title: "Title 3",
  },
  {
    id: 4,
    title: "Title 4",
  },
  {
    id: 5,
    title: "Title 5",
  },
  {
    id: 6,
    title: "Title 6",
  },
];
