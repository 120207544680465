import React, { useState, useEffect } from "react";
import styles from "./Review.module.css";
import Container from "../../Component/Container/Container";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DroneProductReviews from "../../Component/DroneProductReviews/DroneProductReviews";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import ServiceReviews from "../../Component/ServiceReviews/ServiceReviews";
import CourseReviews from "../../Component/CourseReviews/CourseReviews";
import { useNavigate, Link } from "react-router-dom";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import { token_api } from "../../Utils/network";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Review = () => {
  let navigate = useNavigate();
  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <div className="orange-1 bread">Service & Course Reviews</div>,
  ];
  const [value, setValue] = React.useState(0);
  const [serviceList, setServiceList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [droneProductList, setDroneProductList] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getServiceList = () => {
    token_api
      .get(`service?page_size=100`)
      .then((response) => {
        if (response?.data?.data) {
          setServiceList(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getUserList = () => {
    token_api
      .get(`users/dd_profiles?page_size=500`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response.status === 204
        ) {
          let newRes = response?.data?.data.map((info) => {
            return {
              id: info?.creator?.id,
              title: `${info?.name} - (${info?.creator?.id})`,
            };
          });
          setUserList(newRes);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCourseList = () => {
    token_api
      .get(`course?page_size=100`)
      .then((response) => {
        if (response?.data?.data) {
          let newRes = response?.data?.data.map((info) => {
            return {
              id: info?.id,
              title: info?.name,
            };
          });
          setCourseList(newRes);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDroneProductList = () => {
    token_api
      .get(`drone/product?page_size=100`)
      .then((response) => {
        if (response?.data?.data) {
          let newRes = response?.data?.data.map((info) => {
            return {
              id: info?.id,
              title: info?.name,
            };
          });
          setDroneProductList(newRes);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getServiceList();
    getUserList();
    getCourseList();
    getDroneProductList();
  }, []);

  const handleNavigateBack = () => {
    navigate(`/dashboard`);
  };
  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
          <img src={breadCrumbBack} />
        </div>{" "}
        <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
      </div>
      <div className={styles.tabContainer}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Service Reviews" {...a11yProps(0)} />
              <Tab label="Course Reviews" {...a11yProps(1)} />
              <Tab label="Drone Product Reviews" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <ServiceReviews userList={userList} serviceList={serviceList} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <CourseReviews userList={userList} courseList={courseList} />
          </CustomTabPanel>

          <CustomTabPanel value={value} index={2}>
            <DroneProductReviews
              userList={userList}
              productsList={droneProductList}
            />
          </CustomTabPanel>
        </Box>
      </div>
    </Container>
  );
};

export default Review;
