import React, { useState, useEffect } from "react";
import Container from "../../Component/Container/Container";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import styles from "./AddCourseBooking.module.css";
import happy from "../../Assets/happy.svg";
// import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import CustomSelectChip from "../../Component/CustomSelectChipforCoursebooking/CustomSelectChip";
import CustomDate from "../../Component/CustomDate/CustomDate";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { token_api, open_api } from "../../Utils/network";
import { navigate, useLocation } from "react-router-dom";
import AutocompleteSearch from "../../Component/AutocompleteSearch/AutocompleteSearch";
import DynamicForm from "../../Component/DynamicForm/DyamicForm";
import CustomModal from "../../Component/CustomModal/CustomModal";
import AddAddress from "../../Component/AddAddress/AddAddress";
import RoomCustomChip from "../../Component/RoomCustomChip/RoomCustomChip";
import StartAndEndDateDropdown from "../../Component/StartAndEndDateDropdown/StartAndEndDateDropdown";
import { Link, useNavigate } from "react-router-dom";
import { Validate } from "./Validate";
import CustomBatchListingChip from "../../Component/CustomBatchListingChip/CustomBatchListingChip";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import CustomDatePicker from "../../Component/MaterialDate/MaterialDate";
import dayjs from "dayjs";
import { formatDate } from "../../Utils/helper";
import { useParams } from "react-router-dom";
import { batch } from "react-redux";

const AddCourseBooking = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [formDetails, setFormDetails] = useState({});

  console.log(formDetails, "hariFormDetaisl");
  const [batchForm, setbatchForm] = useState({
    dduser: null,
    course: [],
    batch: [],
    guestroom: [],
    slot: null, //this one is for id of selected flying slot
    classslot: [], // this one is for flying class slot
    hotel: [],
    mode: null,
    start_date: null,
    end_date: null,
    couponCode: [],
    paymentMode: [],
    paymentStatus: [],
    purchaseStatus: [],
  });

  const [formErrors, setFormErrors] = useState({});

  const [courselisting, setCourselisting] = useState([]);
  const [dduserlisting, setDDuserListing] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [batchlisting, setBatchlisting] = useState([]);
  const [flyinglisting, setFlyinglisting] = useState([]);
  const [flyinglocation, setFlyingLocation] = useState([]);
  const [roomacclisting, setroomAcc] = useState([]);
  const [couponCode, setCouponCode] = useState([]);
  const [hotellisting, setHotellisting] = useState([]);
  const [loading, setLoading] = useState(false);
  const [typedValue, setTypedValue] = useState("");

  const [open, setOpen] = useState(false);
  const [buttonShow, setButtonShow] = useState(false);

  const [price, setPrice] = useState({});

  useEffect(() => {
    if (formDetails?.id) {
      const abc = {
        creator: {
          mobile: formDetails?.user?.mobile,
          email: formDetails?.user?.email,
          id: formDetails?.user?.id,
        },
        ...formDetails?.user?.profile,
        // addresses: dduserlisting?.filter(
        //   (item) => item?.id === formDetails?.user?.id
        // ),
      };

      setbatchForm((prev) => ({
        ...prev,
        dduser: abc,
      }));
    }
  }, [formDetails?.id]);

  const getUserAddressList = async (userId) => {
    try {
      const response = await token_api.get(`users/address?user=${userId}`);
      if (response?.status === 200 || response?.status === 201) {
        setbatchForm((prev) => ({
          ...prev,
          dduser: {
            ...prev?.dduser,
            addresses: response?.data?.data,
          },
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (batchForm?.dduser?.creator?.id) {
      getUserAddressList(batchForm?.dduser?.creator?.id);
    }
  }, [batchForm?.dduser?.creator?.id]);

  useEffect(() => {
    if (formDetails?.id) {
      setbatchForm((prev) => {
        const updatedForm = { ...prev };

        if (formDetails.mode) {
          updatedForm.mode = batchMode?.filter(
            (info) => info?.title == formDetails?.mode
          );
        }

        // if (formDetails.course) {
        //   updatedForm.course = courselisting.filter(
        //     (item) => item?.id == formDetails?.course
        //   );
        // }
        if (formDetails?.batch) {
          updatedForm.batch = [formDetails?.batch];
        }
        if (formDetails?.flying_slot) {
          updatedForm.classslot = [formDetails?.flying_slot];
        }

        if (formDetails?.user_accommodation?.accommodation?.hotel) {
          updatedForm.hotel = [
            formDetails?.user_accommodation?.accommodation?.hotel,
          ];
        }
        if (formDetails?.user_accommodation?.check_in_date) {
          updatedForm.start_date = dayjs(
            formDetails?.user_accommodation?.check_in_date
          );
        }
        if (formDetails?.user_accommodation?.check_out_date) {
          updatedForm.end_date = dayjs(
            formDetails?.user_accommodation?.check_out_date
          );
        }
        if (formDetails?.user_accommodation?.accommodation) {
          updatedForm.guestroom = [
            formDetails?.user_accommodation?.accommodation,
          ];
        }
        if (formDetails?.payment_status) {
          updatedForm.paymentStatus = paymentStatuses.filter(
            (info) => info?.id == formDetails?.payment_status
          );
        }

        if (formDetails?.payment?.[0]?.payment_method) {
          updatedForm.paymentMode = paymentMethods?.filter(
            (info) => info?.id == formDetails?.payment[0]?.payment_method
          );
        }

        if (formDetails?.purchase_status) {
          updatedForm.purchaseStatus = purchaseStatDetails?.filter(
            (info) => info?.id == formDetails?.purchase_status
          );
        }

        if (formDetails?.coupon?.id) {
          updatedForm.couponCode = [formDetails?.coupon];
        }

        return updatedForm;
      });
    }
  }, [formDetails?.id]);

  function courseData() {
    const selectedID = batchForm?.mode[0]?.id;

    open_api
      .get(`/course?page_size=1000&mode=${selectedID}`)
      .then((response) => {
        setCourselisting(response?.data?.data);
      })

      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    if (batchForm?.mode) {
      courseData();
    }
  }, [batchForm?.mode, formDetails?.id]);

  useEffect(() => {
    if (formDetails?.course) {
      setbatchForm((prev) => ({
        ...prev,
        course: courselisting?.filter(
          (item) => item?.id == formDetails?.course
        ),
      }));
    }
  }, [courselisting, formDetails?.course]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <div className="orange-1 bread">
      {id ? "Edit" : "Create"} Course Booking
    </div>,
  ];

  function getBatchDetails(slug) {
    token_api
      .get(`/course/batches?page_size=1000&course=${slug}`)
      .then((response) => {
        setBatchlisting(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (batchForm?.course?.length > 0) {
      getBatchDetails(batchForm?.course[0]?.slug);
    }
  }, [batchForm?.course]);

  useEffect(() => {
    if (batchForm?.batch?.length > 0) {
      flyingSlot(
        batchForm?.batch[0]?.start_date,
        batchForm?.batch[0]?.end_date
      );
    }
  }, [batchForm?.batch]);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#E95E33",
      },
      secondary: {
        main: "#E95E33",
      },
    },
  });

  const handleChange = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setbatchForm({
        ...batchForm,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setbatchForm({
        ...batchForm,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setbatchForm({
        ...batchForm,
        [name]: value,
      });
    }
  };

  console.log(batchForm, "gatattat");

  const resetBatchForm = () => {
    setbatchForm({
      dduser: batchForm?.dduser,
      course: [],
      batch: [],
      guestroom: [],
      slot: null,
      classslot: [],
      hotel: [],
      mode: null,
      start_date: null,
      end_date: null,
      couponCode: [],
      paymentMode: [],
      paymentStatus: [],
      purchaseStatus: [],
    });
  };

  const handleDeleteChip = (id, selectName) => {
    setbatchForm({
      ...batchForm,
      [selectName]: batchForm?.[selectName]?.filter((chip) => chip?.id !== id),
    });

    let namefiled = batchForm[selectName];
    if (
      namefiled[0]?.title == "Online" ||
      namefiled[0]?.title == "Offline" ||
      namefiled[0]?.title == "Hybrid"
    ) {
      resetBatchForm();
    }
  };

  const handleInputChange = (event, newValue) => {
    setTypedValue(newValue);
    setbatchForm((prev) => ({
      ...prev,
      dduser: newValue,
    }));
  };

  const getCourseBookingDetails = async () => {
    try {
      token_api
        .get(`course/course_book/${id}`)
        .then((response) => {
          if (response?.status === 200 || response?.status === 201) {
            setFormDetails(response?.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (id) {
      getCourseBookingDetails();
    }
  }, [id]);

  function ddUser(value) {
    let apiEndpoint = "/users/dd_profiles";

    if (value) {
      apiEndpoint += `?q=${value}`;
    }
    open_api
      .get(apiEndpoint)
      .then((response) => {
        setDDuserListing(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function gethotelListing(city_new) {
    if (city_new) {
      open_api
        .get(`/course/hotels?city=${city_new}`)
        .then((response) => {
          setHotellisting(response?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function flyingSlot(startdate, enddate) {
    let sd = formatDate(startdate);
    let ed = formatDate(enddate);
    open_api
      // .get(`/course/flying_class_slots?start_date=${sd}&end_date=${ed}`)
      .get(`/course/flying_class_slots?start_date=${sd}`)

      .then((response) => {
        setFlyinglisting(response?.data?.data);
        setbatchForm({
          ...batchForm,
          classslot: response?.data?.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function roomAccommodations(hotelId) {
    let sd = formatDate(batchForm?.start_date);
    let ed = formatDate(batchForm?.end_date);

    open_api
      .get(
        `/course/accommodations/available?hotel=${hotelId}&check_in_date=${sd}&check_out_date=${ed}`
      )
      .then((response) => {
        setroomAcc(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function couponCodes(batchId) {
    open_api
      .get(`/course/coupons?batch=${batchId}`)
      .then((response) => {
        setCouponCode(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    ddUser(typedValue);
  }, [typedValue]);

  useEffect(() => {
    if (
      batchForm?.hotel.length > 0 &&
      batchForm?.start_date &&
      batchForm?.end_date
    ) {
      let hotelID = batchForm?.hotel[0]?.id;
      roomAccommodations(hotelID);
    }
  }, [batchForm?.hotel, batchForm?.start_date, batchForm?.end_date]);

  useEffect(() => {
    if (batchForm?.batch?.length > 0) {
      const firstBatchId = batchForm.batch[0]?.id;
      getPriceBreakdown({ batch_id: firstBatchId });
      couponCodes(firstBatchId);
    }
  }, [batchForm?.batch]);

  useEffect(() => {
    if (batchForm?.dduser?.addresses?.length == 0) {
      setOpen(true);
      setButtonShow(true);
    } else {
      setButtonShow(false);
    }
  }, [batchForm?.dduser?.addresses]);

  function getPriceBreakdown(paramsdata) {
    token_api
      .post(`/course/course_book/price_breakdown/`, paramsdata)
      .then((response) => {
        setPrice(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  console.log(price, batchForm, "hariBatchForm");
  useEffect(() => {
    if (
      batchForm?.guestroom &&
      batchForm?.guestroom?.length > 0 &&
      batchForm?.couponCode?.length == 0 &&
      batchForm?.batch?.length > 0
    ) {
      const firstGuestroomId = batchForm?.guestroom[0]?.id;
      const batchId = batchForm?.batch[0].id;

      getPriceBreakdown({
        accomo_id: firstGuestroomId,
        start_date: formatDate(batchForm?.start_date),
        end_date: formatDate(batchForm?.end_date),
        batch_id: batchId,
      });
    } else if (
      batchForm?.guestroom &&
      batchForm?.guestroom?.length > 0 &&
      batchForm?.couponCode?.length > 0 &&
      batchForm?.batch?.length > 0
    ) {
      const firstGuestroomId = batchForm?.guestroom[0]?.id;
      const batchId = batchForm?.batch[0]?.id;
      const couponId = batchForm?.couponCode[0]?.id;

      getPriceBreakdown({
        accomo_id: firstGuestroomId,
        start_date: formatDate(batchForm?.start_date),
        end_date: formatDate(batchForm?.end_date),
        batch_id: batchId,
        coupon_id: couponId,
      });
    }
  }, [batchForm?.guestroom, batchForm?.batch, batchForm?.couponCode]);

  useEffect(() => {
    if (
      batchForm?.couponCode &&
      batchForm?.couponCode?.length > 0 &&
      batchForm?.guestroom?.length === 0
    ) {
      const couponId = batchForm?.couponCode[0]?.id;
      const batchId = batchForm?.batch[0]?.id;

      getPriceBreakdown({
        batch_id: batchId,
        coupon_id: couponId,
      });
    } else if (batchForm?.guestroom?.length === 0 && batchForm?.batch[0]?.id) {
      const batchId = batchForm?.batch[0]?.id;

      getPriceBreakdown({
        batch_id: batchId,
      });
    }

    if (batchForm?.course?.length == 0) {
      setPrice({});
    }
  }, [
    batchForm?.couponCode,
    batchForm?.batch,
    batchForm?.hotel,
    batchForm?.guestroom,
    batchForm?.course,
  ]);

  useEffect(() => {
    if (batchForm?.classslot?.length > 0) {
      const cityid = batchForm?.classslot[0]?.location?.city_new_id;

      gethotelListing(cityid);
    } else if (batchForm?.batch?.length > 0 && batchForm?.mode[0]?.id == 2) {
      gethotelListing(batchForm?.batch[0]?.slots[0]?.location?.city_new_id);
    }
  }, [batchForm?.classslot, batchForm?.mode, batchForm?.batch]);

  const handleUploadDetails = () => {
    const { pincode, city, line_1, line_2, state } = batchForm?.dduser
      ?.addresses?.[0] || {
      pincode: undefined,
      city: "",
      line_1: "",
      line_2: "",
      state: "",
    };

    const typeOffline = {
      user_id: batchForm?.dduser?.creator?.id ?? "",
      course_id: batchForm?.course[0]?.id ?? "",
      batch_id: batchForm?.batch[0]?.id ?? "",
      flying_slot_id: batchForm?.classslot[0]?.id ?? "",
      ...(batchForm?.guestroom[0]?.id && {
        accommodation_id: batchForm?.guestroom[0]?.id,
        check_in_date: formatDate(batchForm?.start_date),
        check_out_date: formatDate(batchForm?.end_date),
      }),
      payment_mode: batchForm?.paymentStatus[0]?.id ?? "",
      payment_status: batchForm?.paymentMode[0]?.id ?? "",
      purchase_status: batchForm?.purchaseStatus[0]?.id ?? "",
      address_line_1: line_1 ?? "",
      address_line_2: line_2 ?? "",
      city: city,
      pincode: pincode,
      state: state,
      ...(batchForm?.couponCode[0]?.id && {
        coupon_id: batchForm.couponCode[0].id,
      }),
    };

    const typeOnline = {
      user_id: batchForm?.dduser?.creator?.id ?? "",
      course_id: batchForm?.course[0]?.id ?? "",
      batch_id: batchForm?.batch[0]?.id ?? "",
      payment_status: batchForm?.paymentStatus[0]?.id ?? "",
      payment_mode: batchForm?.paymentMode[0]?.id ?? "",
      purchase_status: batchForm?.purchaseStatus[0]?.id ?? "",
      address_line_1: line_1 ?? "",
      address_line_2: line_2 ?? "",
      city: city,
      pincode: pincode,
      state: state,
      ...(batchForm?.couponCode[0]?.id && {
        coupon_id: batchForm?.couponCode[0]?.id,
      }),
    };

    const typeHybrid = {
      user_id: batchForm?.dduser?.creator?.id ?? "",
      course_id: batchForm?.course[0]?.id ?? "",
      batch_id: batchForm?.batch[0]?.id ?? "",
      flying_slot_id: batchForm?.classslot[0]?.id ?? "",
      flying_slot_id: batchForm?.classslot[0]?.id ?? "",
      purchase_status: batchForm?.purchaseStatus[0]?.id ?? "",
      ...(batchForm?.guestroom[0]?.id && {
        accommodation_id: batchForm?.guestroom[0]?.id,
        check_in_date: formatDate(batchForm?.start_date),
        check_out_date: formatDate(batchForm?.end_date),
      }),
      payment_status: batchForm?.paymentStatus[0]?.id ?? "",
      payment_mode: batchForm?.paymentMode[0]?.id ?? "",
      address_line_1: line_1 ?? "",
      address_line_2: line_2 ?? "",
      city: city,
      pincode: pincode,
      state: state,
      ...(batchForm?.couponCode[0]?.id && {
        coupon_id: batchForm?.couponCode[0]?.id,
      }),
    };

    const defaultBatchForm = {
      dduser: null,
      course: [],
      batch: [],
      guestroom: [],
      slot: null,
      classslot: [],
      hotel: [],
      mode: null,
      start_date: null,
      end_date: null,
      couponCode: [],
      paymentMode: [],
      paymentStatus: [],
      purchaseStatus: [],
    };

    Validate(batchForm, formDetails?.id)
      .then((response) => {
        if (response === "success") {
          setLoading(true);

          const type = getTypeByMode(batchForm?.mode[0]?.id);

          if (formDetails?.id) {
            if (type?.pincode) {
              const { payment_mode, ...rest } = type;
              token_api
                .patch(`/course/admin_course_book/${formDetails?.id}/`, rest)
                .then((res) => {
                  if (res?.status == 201 || res?.status == 200) {
                    setFormErrors({});
                    setLoading(false);

                    setbatchForm(defaultBatchForm);
                    alert("Course Booking Details Updated!");
                    navigate(
                      `/bookings/course-booking-details/${formDetails?.id}`
                    );
                  }
                })
                .catch((err) => {
                  console.log(err);
                  setLoading(false);
                });
            } else {
              setOpen(true);
            }
          } else {
            if (type?.pincode) {
              token_api
                .post(`/course/admin_course_book/`, type)
                .then((res) => {
                  if (res.status == 201 || res.status == 200) {
                    setLoading(false);

                    setFormErrors({});
                    setbatchForm(defaultBatchForm);
                    alert("Course Booking is created successfully!");
                    navigate(`/bookings/course-bookings`);
                  }
                })
                .catch((error) => {
                  setLoading(false);
                  if (
                    error?.response &&
                    error?.response?.data &&
                    error?.response?.data?.error &&
                    error?.response.data?.error?.message
                  ) {
                    alert(error?.response?.data?.error?.message);
                  }
                });
            } else {
              setOpen(true);
            }
          }
        }
      })
      .catch((err) => {
        setFormErrors(err);
        setLoading(false);
      });

    function getTypeByMode(mode) {
      const typeMap = {
        1: typeOnline,
        2: typeOffline,
        3: typeHybrid,
      };

      return typeMap[mode];
    }
  };

  const handleUserSet = (incomingdata) => {
    setbatchForm((prev) => ({
      ...prev,
      dduser: {
        ...prev?.dduser,
        addresses: [incomingdata],
      },
    }));

    setOpen(false);
    setButtonShow(false);
  };

  const handleNavigateBack = () => {
    navigate(`/bookings/course-bookings`);
  };

  console.log(batchForm, formDetails, buttonShow, "hariCourseBookingDetails");
  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
          <img src={breadCrumbBack} />
        </div>
        <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
      </div>
      <div className={styles.flexWrapper}>
        <div className={styles.leftWrapper}>
          <div>
            <p>User & Course Details</p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "20px",
                // justifyContent: "space-between",
              }}
            >
              <div
                className={styles.widthContainerforDrop}
                style={{ width: buttonShow ? "75%" : "90%" }}
              >
                <DynamicForm
                  value={batchForm?.dduser}
                  onChange={handleInputChange}
                  listing={dduserlisting}
                  error={formErrors?.dduser}
                  typedValue={typedValue}
                  setTypedValue={setTypedValue}
                />
              </div>

              <div className={styles.btnContainer}>
                <div className={styles.addressBtn}>
                  {buttonShow && (
                    <button
                      className={styles.adduserbtn}
                      onClick={() => setOpen(true)}
                    >
                      {" "}
                      Add Address{" "}
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className={styles.widthContainerforDrop}>
              <CustomSelectChip
                multiple={false}
                label="Select Course Mode*"
                name="mode"
                onChange={handleChange}
                onDelete={handleDeleteChip}
                value={batchForm?.mode}
                listArray={batchMode}
                error={formErrors?.mode}
              />
            </div>

            <div className={styles.widthContainerforDrop}>
              <CustomSelectChip
                multiple={false}
                label="Select A Course*"
                name="course"
                onChange={handleChange}
                onDelete={handleDeleteChip}
                value={batchForm?.course}
                listArray={courselisting}
                error={formErrors?.course}
              />
            </div>
            <div className={styles.widthContainerforDrop}>
              <CustomBatchListingChip
                multiple={false}
                label={" Select A Batch*"}
                name="batch"
                value={batchForm?.batch}
                listArray={batchlisting}
                // minWidth={"175px"}
                onChange={handleChange}
                onDelete={handleDeleteChip}
                error={formErrors?.batch}
              />
            </div>

            {batchForm?.batch?.length > 0 && (
              <small style={{ color: "red" }}>
                This Batch will be conducted in{" "}
                {batchForm?.batch[0]?.location?.city}
              </small>
            )}

            {batchForm?.mode?.[0]?.id != null &&
              batchForm.mode[0].id !== 1 &&
              batchForm.mode[0].id !== 2 && (
                <div className={styles.widthContainerforDrop}>
                  <StartAndEndDateDropdown
                    multiple={false}
                    label="Select Flying Class Slot"
                    name="classslot"
                    onChange={handleChange}
                    onDelete={handleDeleteChip}
                    value={batchForm?.classslot}
                    listArray={flyinglisting}
                    error={formErrors?.classslot}
                  />
                </div>
              )}
          </div>

          {batchForm?.mode?.[0]?.id != null && batchForm.mode[0].id !== 1 && (
            <div>
              <p>Accommodation Details</p>

              <div className={styles.widthContainerforDrop}>
                <CustomSelectChip
                  multiple={false}
                  label="Select Accommodation Guest House"
                  name="hotel"
                  onChange={handleChange}
                  onDelete={handleDeleteChip}
                  value={batchForm?.hotel}
                  listArray={hotellisting}
                  error={formErrors?.hotel}
                />
              </div>

              <div className={styles.dateContainer} style={{ gap: "10px" }}>
                {/* <div style={{ width: "48%" }}>
                  <CustomDate
                    label="Start Date"
                    handleChange={handleChange}
                    name="start_date"
                    value={batchForm?.start_date}
                  />
                </div>

                <div style={{ width: "48%" }}>
                  <CustomDate
                    label="End Date"
                    handleChange={handleChange}
                    name="end_date"
                    value={batchForm?.end_date}
                  />
                </div> */}

                <CustomDatePicker
                  label="Start date"
                  value={batchForm?.start_date}
                  onChange={(newValue) =>
                    setbatchForm({
                      ...batchForm,
                      start_date: newValue,
                    })
                  }
                  // error={batchForm?.start_date}
                />

                <CustomDatePicker
                  label="End date"
                  value={batchForm?.end_date}
                  onChange={(newValue) =>
                    setbatchForm({
                      ...batchForm,
                      end_date: newValue,
                    })
                  }
                  // error={batchForm?.end_date}
                />
              </div>

              <div className={styles.widthContainerforDrop}>
                <RoomCustomChip
                  multiple={false}
                  label="Select Accommodation Room"
                  name="guestroom"
                  onChange={handleChange}
                  onDelete={handleDeleteChip}
                  value={batchForm?.guestroom}
                  listArray={roomacclisting}
                  error={formErrors?.guestrooom}
                />
              </div>
            </div>
          )}

          <div>
            <p>Payment Details</p>

            <div className={styles.widthContainerforDrop}>
              <CustomSelectChip
                multiple={false}
                label="Coupon Code"
                name="couponCode"
                onChange={handleChange}
                onDelete={handleDeleteChip}
                value={batchForm?.couponCode}
                listArray={couponCode}
                error={formErrors?.couponCode}
              />
            </div>

            <div className={styles.widthContainerforDrop}>
              <CustomSelectChip
                multiple={false}
                label="Payment Mode*"
                name="paymentMode"
                onChange={handleChange}
                onDelete={handleDeleteChip}
                value={batchForm?.paymentMode}
                listArray={paymentMethods}
                error={formErrors?.paymentMode}
              />
            </div>

            <div className={styles.widthContainerforDrop}>
              <CustomSelectChip
                multiple={false}
                label="Payment Status*"
                name="paymentStatus"
                onChange={handleChange}
                onDelete={handleDeleteChip}
                value={batchForm?.paymentStatus}
                listArray={paymentStatuses}
                error={formErrors?.paymentStatus}
              />
            </div>

            <div className={styles.widthContainerforDrop}>
              <CustomSelectChip
                multiple={false}
                label="Purchase Status*"
                name="purchaseStatus"
                onChange={handleChange}
                onDelete={handleDeleteChip}
                value={batchForm?.purchaseStatus}
                listArray={purchaseStatDetails}
                error={formErrors?.purchaseStatus}
              />
            </div>
          </div>
        </div>

        <div className={styles.rightWrapper}>
          <div>
            {price?.course_amt &&
              batchForm?.batch.length > 0 &&
              batchForm?.dduser?.creator?.id && (
                <div className={styles.priceContainer}>
                  <p className={`${styles.fonttwentytwo}  `}>Price Breakup</p>

                  {price.course_amt && (
                    <div
                      className={`${styles.flexforText} ${styles.margintopbottom}`}
                    >
                      <p
                        className={`${styles.colorlightGray} ${styles.marginZero}`}
                      >
                        Course Amount
                      </p>
                      <p className={`${styles.marginZero}`}>
                        ₹ {price.course_amt}
                      </p>
                    </div>
                  )}

                  {price.accomodation_amt && (
                    <div
                      className={`${styles.flexforText} ${styles.margintopbottom}`}
                    >
                      <p
                        className={`${styles.colorlightGray} ${styles.marginZero}`}
                      >
                        Accommodation Amount
                      </p>
                      <p className={`${styles.marginZero}`}>
                        ₹ {price.accomodation_amt}
                      </p>
                    </div>
                  )}

                  {price.course_discount > 0 && (
                    <div
                      className={`${styles.flexforText} ${styles.margintopbottom}`}
                    >
                      <p
                        className={`${styles.colorlightGray} ${styles.marginZero}`}
                      >
                        Discount
                      </p>
                      <p className={`${styles.marginZero}`}>
                        ₹ {price.course_discount}
                      </p>
                    </div>
                  )}

                  {price?.coupon_discount > 0 && (
                    <div
                      className={`${styles.flexforText} ${styles.margintopbottom}`}
                    >
                      <p
                        className={`${styles.colorlightGray} ${styles.marginZero}`}
                      >
                        Coupon Discount
                      </p>
                      <p className={`${styles.marginZero}`}>
                        ₹ {price.coupon_discount}
                      </p>
                    </div>
                  )}

                  {price.gst_amt && (
                    <div
                      className={`${styles.flexforText} ${styles.margintopbottom}`}
                    >
                      <p
                        className={`${styles.colorlightGray} ${styles.marginZero}`}
                      >
                        GST Amount
                      </p>
                      <p className={`${styles.marginZero}`}>
                        ₹ {price?.gst_amt}
                      </p>
                    </div>
                  )}

                  <div className={styles.congratsCon}>
                    <div className={styles.congratsleft}>
                      <img src={happy} alt="happy" style={{ width: "50px" }} />
                    </div>

                    <div className={styles.congratsright}>
                      <p
                        className={`${styles.fontBold} ${styles.greenColor} ${styles.marginZero}`}
                      >
                        Congratulations! You have saved ₹
                        {price.total_discount_amt} on this order.
                      </p>
                    </div>
                  </div>

                  <hr style={{ width: "85%", marginTop: "20px" }} />

                  <div
                    className={`${styles.flexforText} ${styles.margintopbottom}`}
                  >
                    <p className={`${styles.colorPrimary} ${styles.fontBold}`}>
                      Final Amount
                    </p>
                    <p className={`${styles.colorPrimary} ${styles.fontBold}`}>
                      ₹ {price.total_payable}
                    </p>
                  </div>
                </div>
              )}
          </div>

          {price?.course_amt &&
            batchForm?.batch.length > 0 &&
            batchForm?.dduser?.creator?.id && (
              <div
                style={{ cursor: "pointer" }}
                className={styles.bookNowContainer}
              >
                <button
                  disabled={loading}
                  className={` ${
                    loading ? styles.loadingStyle : styles.bookcourseBtn
                  }`}
                  onClick={handleUploadDetails}
                >
                  BOOK COURSE
                </button>
              </div>
            )}
        </div>
      </div>

      <CustomModal open={open} onClose={handleClose}>
        <p>
          {" "}
          Since the user currently lacks address details, could you please
          provide the PIN code details? This information is necessary for us to
          generate GST details.
        </p>
        <AddAddress
          handleDrawerClose={handleClose}
          userListGet={ddUser}
          selectedUser={batchForm?.dduser}
          handleUserSet={handleUserSet}
        />
      </CustomModal>
    </Container>
  );
};

export default AddCourseBooking;

const slotTypesObj = [
  { id: 1, title: "Online Class" },
  { id: 2, title: "Offline Class" },
  { id: 3, title: "Flying Class" },
];

const batchMode = [
  {
    title: "Online",
    id: 1,
  },
  {
    title: "Offline",
    id: 2,
  },
  {
    title: "Hybrid",
    id: 3,
  },
];

const paymentStatuses = [
  { id: 1, title: "Pending" },
  { id: 2, title: "Partially Paid" },
  { id: 3, title: "Paid" },
  { id: 4, title: "Failed" },
];

const paymentMethods = [
  { id: 1, title: "Card" },
  { id: 2, title: "UPI" },
  { id: 3, title: "Netbanking" },
  { id: 4, title: "Wallet" },
  { id: 5, title: "Cardless EMI" },
];

const purchaseStatDetails = [
  { id: 1, title: "Pending" },
  { id: 2, title: "Confirmed" },
  { id: 3, title: "On Hold" },
  { id: 4, title: "Cancelled" },
];
