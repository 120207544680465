import React, { useEffect, useRef, useState } from "react";
import styles from "./CreateCoupon.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import Container from "../../Component/Container/Container";
import DurationPicker from "../../Component/DurationPicker/DurationPicker";
import InputFields from "../../Component/InputFields/InputFields";
import { Link } from "react-router-dom";
import TextEditor from "../../Component/TextEditor/TextEditor";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import Switch from "@mui/material/Switch";
import { Button, useTheme, Checkbox, colors } from "@mui/material";
import { useSelector } from "react-redux";

import CustomDatePicker from "../../Component/MaterialDate/MaterialDate";
import { token_api, base_url } from "../../Utils/network";
import { formatDate } from "../../Utils/helper";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import { Validate } from "./Utility";
import dayjs from "dayjs";
import axios from "axios";
import DroneComboxCom from "../../Component/DroneComboxCom/DroneComboxCom";
import CourseComboBoxCom from "../../Component/CourseComboBoxCom/CourseComboBoxCom";
import ServiceComboBox from "../../Component/ServiceComboBox/ServiceComboBox";
import DDuserComboBox from "../../Component/DDuserComboBox/DDuserComboBox";
import BatchComboBox from "../../Component/BatchCombobox/BatchComboBox";
import DroneProductComboBox from "../../Component/DroneProductComboBoxCom/DroneProductComboBox";
import DroneProductCategoryComboBox from "../../Component/DroneProductCategoryCombo/DroneProductCategoryCombo";
import DroneProductBrandComboBox from "../../Component/DroneProductBrandCombo/DroneProductBrandCombo";

const CreateCoupon = () => {
  const location = useLocation();
  const coupon = location.state?.item;
  const { access_token } = useSelector((state) => state.auth);

  console.log(coupon, "hariDaata");
  const navigate = useNavigate();
  const { id } = useParams();
  const descriptionRef = useRef(null);
  const [couponData, setCouponData] = useState({
    title: "",
    description: "",
    from_time: "",
    to_time: "",
    code: "",
    status: false,
    all_batches: false,
    all_courses: false,
    all_services: false,
    all_drone: false,
    all_users: false,
    first_time: false,
    multiple_time: false,
    discount_type: [],
    discount: "",
    upto: "",
    usage_count: "",
    from_date: null,
    to_date: null,
    batch: [],
    course: [],
    service: [],
    drone: [],
    user: [],
    drone_product: [],
    drone_product_category: [],
    drone_product_brand: [],
  });
  const [droneProductList, setDroneProductList] = useState([]);
  const [droneProductCategories, setDroneProductCategories] = useState([]);
  const [droneProductBrands, setDroneProductBrands] = useState([]);
  const [droneList, setDroneList] = useState([]);
  const [batchList, setBatchList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [icon, setIcon] = useState("");
  const [iconPreview, setIconPreview] = useState("");
  const iconInput = useRef(null);

  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <Link to="/coupons" className="orange-1 bread breadElement">
      Coupons
    </Link>,

    <div className="orange-1 bread">{id ? "Edit Coupon" : "Add Coupon"}</div>,
  ];

  function handleDrop(e, type) {
    e.preventDefault();
    if (e?.target?.files) {
      setIcon(e?.target?.files[0]);
      const imageUrl = URL.createObjectURL(e.target.files[0]);
      setIconPreview(imageUrl);
    } else if (e?.dataTransfer?.files) {
      setIcon(e?.dataTransfer?.files[0]);
      const imageUrl = URL.createObjectURL(e.dataTransfer.files[0]);
      setIconPreview(imageUrl);
    }
  }

  const handleClick = (type) => {
    iconInput.current.click();
  };
  function handleDragOver(e) {
    e.preventDefault();
  }

  const handleNavigateBack = () => {
    navigate(`/coupons`);
  };

  const handleChange = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setCouponData({
        ...couponData,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setCouponData({
        ...couponData,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setCouponData({
        ...couponData,
        [name]: value,
      });
    }
  };
  const handleDeleteChip = (id, selectName) => {
    setCouponData({
      ...couponData,
      [selectName]: couponData?.[selectName]?.filter((chip) => chip?.id !== id),
    });
  };

  const handleChangeSwitch = (e) => {
    const { name, checked } = e.target;
    setCouponData({
      ...couponData,
      [name]: checked,
    });
  };

  const handleChangeCheckBox = (e) => {
    const { name, checked } = e.target;
    if (name === "all_batches") {
      const newCouponData = { ...couponData, [name]: checked };
      if (checked) {
        newCouponData.batch = batchList;
      } else {
        newCouponData.batch = [];
      }
      setCouponData(newCouponData);
    }
    if (name === "all_courses") {
      const newCouponData = { ...couponData, [name]: checked };
      if (checked) {
        newCouponData.course = courseList;
      } else {
        newCouponData.course = [];
      }
      setCouponData(newCouponData);
    }
    if (name === "all_services") {
      const newCouponData = { ...couponData, [name]: checked };
      if (checked) {
        newCouponData.service = serviceList;
      } else {
        newCouponData.service = [];
      }
      setCouponData(newCouponData);
    }
    if (name === "all_drone") {
      const newCouponData = { ...couponData, [name]: checked };
      if (checked) {
        newCouponData.drone = droneList;
      } else {
        newCouponData.drone = [];
      }
      setCouponData(newCouponData);
    }
    // if (name === "all_drone_products") {
    //   const newCouponData = { ...couponData, [name]: checked };
    //   if (checked) {
    //     newCouponData.drone_product = droneProductList;
    //   } else {
    //     newCouponData.drone_product = [];
    //   }
    //   setCouponData(newCouponData);
    // }

    if (name === "all_users") {
      const newCouponData = { ...couponData, [name]: checked };
      if (checked) {
        newCouponData.user = userList;
      } else {
        newCouponData.user = [];
      }
      setCouponData(newCouponData);
    }
  };
  const getBatchList = async () => {
    try {
      const batchListData = await token_api.get(`course/batches?page_size=100`);
      if (batchListData.status !== 200) {
        throw new Error("Error in fetching");
      }
      let newRes = batchListData?.data?.data.map((info) => {
        return {
          id: info?.id,
          title: info?.name,
        };
      });
      setBatchList(newRes);
    } catch (error) {
      console.log(error);
    }
  };
  const getCourseList = () => {
    token_api
      .get(`course?page_size=100`)
      .then((response) => {
        if (response?.data?.data) {
          let newRes = response?.data?.data.map((info) => {
            return {
              id: info?.id,
              title: info?.name,
            };
          });
          setCourseList(newRes);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getServiceList = () => {
    token_api
      .get(`service?page_size=100`)
      .then((response) => {
        if (response?.data?.data) {
          setServiceList(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDroneList = () => {
    token_api
      .get(`drone?page_size=100`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response.status === 204
        ) {
          setDroneList(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getDroneProductsList = () => {
    token_api
      .get(`drone/product?page_size=1000`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response.status === 204
        ) {
          const data = response?.data?.data?.map((info) => ({
            id: info?.id,
            title: info?.name,
          }));
          setDroneProductList(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUserList = () => {
    token_api
      .get(`users/dd_profiles?page_size=500`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response.status === 204
        ) {
          let newRes = response?.data?.data.map((info) => {
            return {
              id: info?.creator?.id,
              title: `${info?.name} - (${info?.creator?.id})`,
            };
          });
          setUserList(newRes);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDroneProductCategories = () => {
    token_api
      .get(`drone/product/category?page_size=1000`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response.status === 204
        ) {
          const data = response?.data?.data?.map((info) => ({
            id: info?.id,
            title: info?.name,
          }));
          setDroneProductCategories(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDroneProductBrands = () => {
    token_api
      .get(`drone/product/brand?page_size=1000`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response.status === 204
        ) {
          const data = response?.data?.data?.map((info) => ({
            id: info?.id,
            title: info?.name,
          }));
          setDroneProductBrands(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log(droneProductCategories, droneProductBrands, "hariDroneData");

  useEffect(() => {
    getBatchList();
    getCourseList();
    getServiceList();
    getDroneList();
    getUserList();
    getDroneProductsList();
    getDroneProductCategories();
    getDroneProductBrands();
  }, []);

  useEffect(() => {
    if (coupon?.id) {
      const {
        title,
        discount,
        description,
        code,
        upto,
        usage_count,
        status,
        all_batches,
        all_courses,
        all_drone,
        all_services,
        all_users,
        discount_type,
        from_date,
        to_date,
        first_time,
        multiple_time,
        user,
        course,
        batch,
        service,
        drone,
        icon,
        drone_product,
        drone_product_category,
        drone_product_brand,
      } = coupon;

      let batchValue =
        all_batches && batchList.length > 0
          ? batchList
          : batchList.filter((item) => item?.id === batch);

      console.log(all_batches, batchValue, batchList, batch, "batchData");

      let droneValue =
        all_drone && droneList.length > 0
          ? droneList
          : droneList.filter((item) => item?.id === drone);
      let serviceValue =
        all_services && serviceList.length > 0
          ? serviceList
          : serviceList.filter((item) => item?.id === service);
      let courseValue =
        all_courses && courseList.length > 0
          ? courseList
          : courseList.filter((item) => item?.id === course);
      let userValue =
        all_users && userList.length > 0
          ? userList
          : userList.filter((item) => item?.id === user);

      console.log(batchValue, droneValue, "hariDroneValue");
      setCouponData({
        ...couponData,
        title,
        discount,
        description,
        upto,
        usage_count,
        code,
        status,
        all_batches,
        all_courses,
        all_drone,
        all_services,
        all_users,
        discount_type: discountTypeList.filter(
          (item) => item?.id === discount_type
        ),
        from_date: dayjs(from_date),
        to_date: dayjs(to_date),
        first_time,
        multiple_time,
        user: userValue,
        batch: batchValue,
        course: courseValue,
        service: serviceValue,
        drone: droneValue,
        drone_product: droneProductList.filter(
          (item) => item?.id === drone_product
        ),
        drone_product_category: droneProductCategories.filter(
          (item) => item?.id === drone_product_category
        ),
        drone_product_brand: droneProductBrands.filter(
          (item) => item?.id === drone_product_brand
        ),
      });
      setIconPreview(icon);
    }
  }, [
    coupon?.id,
    batchList,
    userList,
    droneList,
    serviceList,
    courseList,
    droneProductList,
    droneProductCategories,
    droneProductBrands,
  ]);

  console.log(batchList, coupon?.id, "hartsysy");

  console.log(couponData, "hariCouponData");
  const handleCreateCoupon = () => {
    let isDescriptionsEmpty;

    if (descriptionRef.current) {
      const content = descriptionRef.current.getContent();
      couponData.description = content;

      // Set a boolean value based on whether the content is empty or not
      isDescriptionsEmpty = !content.trim();
    }

    let data = {
      title: couponData?.title,
      description: couponData?.description,
      // from_time: "",
      // to_time: "",
      code: couponData?.code,
      status: couponData?.status,
      all_batches: couponData?.all_batches,
      all_courses: couponData?.all_courses,
      all_services: couponData?.all_services,
      all_drone: couponData?.all_drone,
      all_users: couponData?.all_users,
      first_time: couponData?.first_time,
      multiple_time: couponData?.multiple_time,
      discount_type: couponData?.discount_type[0]?.id,
      discount: couponData?.discount,
      upto: couponData?.upto,
      usage_count: couponData?.usage_count,
      from_date: formatDate(couponData?.from_date),
      to_date: formatDate(couponData?.to_date),
      drone_product: couponData?.drone_product[0]?.id,
      drone_product_category: couponData?.drone_product_category[0]?.id,
      drone_product_brand: couponData?.drone_product_brand[0]?.id,

      // batch: couponData?.batch[0]?.id,
      // course: couponData?.course[0]?.id,
      // service: couponData?.service[0]?.id,
      // drone: couponData?.all_drone[0]?.id,
      // user: couponData?.all_users[0]?.id,
    };
    if (!couponData?.all_batches) {
      data.batch = couponData?.batch[0]?.id;
    }
    if (!couponData?.all_courses) {
      data.course = couponData?.course[0]?.id;
    }
    if (!couponData?.all_services) {
      data.service = couponData?.service[0]?.id;
    }
    if (!couponData?.all_drone) {
      data.drone = couponData?.drone[0]?.id;
    }
    if (!couponData?.all_users) {
      data.user = couponData?.user[0]?.id;
      // data.user = couponData?.user.map((item) => item?.id);
    }

    Validate(couponData)
      .then(async (response) => {
        if (response == "success") {
          if (id) {
            const couponData = await token_api.patch(
              `course/coupon/${id}/`,
              data
            );

            if (couponData?.status == 400) {
              alert(couponData?.data?.error?.fields[0]?.message[0]);
              setCouponData({
                ...couponData,
                title: "",
                description: "",
                from_time: "",
                to_time: "",
                code: "",
                status: false,
                all_batches: false,
                all_courses: false,
                all_services: false,
                all_drone: false,
                all_users: false,
                // all_users: true,
                first_time: false,
                multiple_time: false,
                discount_type: [],
                discount: "",
                upto: "",
                usage_count: "",
                from_date: null,
                to_date: null,
                batch: [],
                course: [],
                service: [],
                drone: [],
                user: [],
                drone_product: [],
                drone_product_category: [],
                drone_product_brand: [],
              });
              setFormErrors({});
              setIcon({});
              setIconPreview({});

              navigate(`/coupons`);
            }

            if (couponData.status !== 200) {
              throw new Error("Error in fetching");
            }

            if (couponData?.status === 200 || couponData?.status === 201) {
              alert("Coupon Details Updated Successfully!");
            }

            if (icon) {
              let couponIcon = new FormData();
              couponIcon.append("icon", icon);

              await axios({
                method: "patch",
                url: `${base_url}course/coupon/${id}/`,
                data: couponIcon,
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${access_token}`,
                },
              });
            }

            setCouponData({
              ...couponData,
              title: "",
              description: "",
              from_time: "",
              to_time: "",
              code: "",
              status: false,
              all_batches: false,
              all_courses: false,
              all_services: false,
              all_drone: false,
              all_users: false,
              // all_users: true,

              first_time: false,
              multiple_time: false,
              discount_type: [],
              discount: "",
              upto: "",
              usage_count: "",
              from_date: null,
              to_date: null,
              batch: [],
              course: [],
              service: [],
              drone: [],
              user: [],
              drone_product: [],
              drone_product_category: [],
              drone_product_brand: [],
            });
            setFormErrors({});
            setIcon({});
            setIconPreview({});

            navigate(`/coupons`);
          } else {
            const couponData = await token_api.post(`course/coupons/`, data);

            if (couponData?.status == 400) {
              alert(couponData?.data?.error?.fields[0]?.message[0]);
              setCouponData({
                ...couponData,
                title: "",
                description: "",
                from_time: "",
                to_time: "",
                code: "",
                status: false,
                all_batches: false,
                all_courses: false,
                all_services: false,
                all_drone: false,
                all_users: false,
                // all_users: true,

                first_time: false,
                multiple_time: false,
                discount_type: [],
                discount: "",
                upto: "",
                usage_count: "",
                from_date: null,
                to_date: null,
                batch: [],
                course: [],
                service: [],
                drone: [],
                user: [],
              });
              setFormErrors({});
              setIcon({});
              setIconPreview({});

              navigate(`/coupons`);
            }
            if (couponData?.status !== 201) {
              throw new Error("Error in fetching");
            }

            if (couponData?.status === 201 || couponData?.status === 201) {
              alert("Coupon Created Successfully!");
            }

            if (icon) {
              let couponIcon = new FormData();
              couponIcon.append("icon", icon);

              await axios({
                method: "patch",
                url: `${base_url}course/coupon/${couponData?.data?.data?.id}/`,
                data: couponIcon,
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${access_token}`,
                },
              });
            }
            setCouponData({
              ...couponData,
              title: "",
              description: "",
              from_time: "",
              to_time: "",
              code: "",
              status: false,
              all_batches: false,
              all_courses: false,
              all_services: false,
              all_drone: false,
              all_users: false,
              // all_users: true,

              first_time: false,
              multiple_time: false,
              discount_type: [],
              discount: "",
              upto: "",
              usage_count: "",
              from_date: null,
              to_date: null,
              batch: [],
              course: [],
              service: [],
              drone: [],
              user: [],
              drone_product: [],
              drone_product_category: [],
              drone_product_brand: [],
            });
            setFormErrors({});
          }
          setIcon({});
          setIconPreview({});

          navigate(`/coupons`);
        }
      })
      .catch((error) => {
        setFormErrors(error);
      });
  };

  const handleAutocompleteChangeforDrone = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Drone already included");
    } else {
      setCouponData({
        ...couponData,
        ["drone"]: selectedValues,
      });
    }
  };

  const handleAutocompleteChangeforDroneProduct = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Drone product already included");
    } else {
      setCouponData({
        ...couponData,
        ["drone_product"]: selectedValues,
      });
    }
  };

  const handleAutocompleteChangeforDroneProductCategory = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Drone product category already included");
    } else {
      setCouponData({
        ...couponData,
        ["drone_product_category"]: selectedValues,
      });
    }
  };
  const handleAutocompleteChangeforDroneProductBrand = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Drone product brand already included");
    } else {
      setCouponData({
        ...couponData,
        ["drone_product_brand"]: selectedValues,
      });
    }
  };
  const handleAutocompleteChangeforCourse = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Course already included");
    } else {
      setCouponData({
        ...couponData,
        ["course"]: selectedValues,
      });
    }
  };

  const handleAutocompleteChangeforService = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Service already included");
    } else {
      setCouponData({
        ...couponData,
        ["service"]: selectedValues,
      });
    }
  };

  const handleAutocompleteChangeforUser = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("User already included");
    } else {
      setCouponData({
        ...couponData,
        ["user"]: selectedValues,
      });
    }
  };

  const handleAutocompleteChangeforBatch = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("User already included");
    } else {
      setCouponData({
        ...couponData,
        ["batch"]: selectedValues,
      });
    }
  };

  console.log(couponData, "hariCouponData");
  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
          <img src={breadCrumbBack} />
        </div>
        <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
      </div>

      <div>
        <div
          style={{
            boxSizing: "border-box",
            borderRadius: "0px",
          }}
        >
          <div className={`${styles.formContainer} mt-20`}>
            <div className={styles.commonFieldsBox}>
              <InputFields
                label="Title"
                required
                name="title"
                value={couponData?.title}
                onChange={handleChange}
                error={formErrors?.title}
                helperText={formErrors?.title}
              />
            </div>
          </div>

          <div className={`${styles.formContainer} mt-20`}>
            <p>Description</p>
            <TextEditor
              editorRef={descriptionRef}
              initialValue={couponData?.description}
            />
            {formErrors?.description && (
              <p style={{ color: "#d32f2f" }}>Description is required</p>
            )}
          </div>

          <div className={`${styles.formContainer} mt-20`}>
            <div className={styles.commonFieldsBox}>
              <InputFields
                label="Code"
                required
                name="code"
                value={couponData?.code}
                onChange={handleChange}
                error={formErrors?.code}
                helperText={formErrors?.code}
              />
              <div className={styles.switchCon}>
                <p>Status</p>
                <Switch
                  checked={couponData?.status}
                  name="status"
                  inputProps={{ "aria-label": "controlled" }}
                  onChange={handleChangeSwitch}
                />
              </div>
            </div>
          </div>

          {/* Batch and Course Drop downs.... */}

          <div className={`${styles.formContainer} mt-20`}>
            <div className={styles.commonFieldsBox}>
              <div>
                {/* <CustomSelectChip
                  multiple={true}
                  label="Batch"
                  name="batch"
                  onChange={handleChange}
                  onDelete={handleDeleteChip}
                  value={couponData?.batch}
                  listArray={batchList}
                  error={formErrors?.batch}
                  helperText={formErrors?.batch}
                /> */}

                <div>
                  <BatchComboBox
                    value={couponData?.batch}
                    onChange={handleAutocompleteChangeforBatch}
                  />
                  {/* {formErrors?.batch && (
                    <p style={{ color: "red" }}>{formErrors?.batch}</p>
                  )} */}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Checkbox
                    checked={couponData?.all_batches}
                    name="all_batches"
                    onChange={handleChangeCheckBox}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <p>All Batches</p>
                </div>
              </div>

              <div>
                {/* <CustomSelectChip
                  multiple={true}
                  label="Course"
                  name="course"
                  onChange={handleChange}
                  onDelete={handleDeleteChip}
                  value={couponData?.course}
                  listArray={courseList}
                  error={formErrors?.course}
                  helperText={formErrors?.course}
                /> */}

                <div>
                  <CourseComboBoxCom
                    value={couponData?.course}
                    onChange={handleAutocompleteChangeforCourse}
                  />
                  {/* {formErrors?.course && (
                    <p style={{ color: "red" }}>{formErrors?.course}</p>
                  )} */}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Checkbox
                    checked={couponData?.all_courses}
                    name="all_courses"
                    onChange={handleChangeCheckBox}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <p>All Courses</p>
                </div>
              </div>
            </div>
          </div>

          {/* Service and Drone Drop downs.... */}
          <div className={`${styles.formContainer} mt-20`}>
            <div className={styles.commonFieldsBox}>
              <div>
                {/* <CustomSelectChip
                  multiple={true}
                  label="Service"
                  name="service"
                  onChange={handleChange}
                  onDelete={handleDeleteChip}
                  value={couponData?.service}
                  listArray={serviceList}
                  error={formErrors?.service}
                  helperText={formErrors?.service}
                /> */}

                <div>
                  <ServiceComboBox
                    value={couponData?.service}
                    onChange={handleAutocompleteChangeforService}
                  />
                  {/* {formErrors?.service && (
                    <p style={{ color: "red" }}>{formErrors?.service}</p>
                  )} */}
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Checkbox
                    checked={couponData?.all_services}
                    name="all_services"
                    onChange={handleChangeCheckBox}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <p>All Services</p>
                </div>
              </div>

              <div>
                <div>
                  <div>
                    <DroneComboxCom
                      value={couponData?.drone}
                      onChange={handleAutocompleteChangeforDrone}
                    />
                    {/* {formErrors?.drone && (
                    <p style={{ color: "red" }}>{formErrors?.drone}</p>
                  )} */}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Checkbox
                      checked={couponData?.all_drone}
                      name="all_drone"
                      onChange={handleChangeCheckBox}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <p>All Drones</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.formContainer} mt-20`}>
            <div className={styles.commonFieldsBox}>
              <div>
                {" "}
                <div>
                  <DroneProductComboBox
                    value={couponData?.drone_product}
                    onChange={handleAutocompleteChangeforDroneProduct}
                  />
                </div>
              </div>

              <div>
                {" "}
                <div>
                  <DroneProductCategoryComboBox
                    value={couponData?.drone_product_category}
                    onChange={handleAutocompleteChangeforDroneProductCategory}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.formContainer} mt-20`}>
            <div className={styles.commonFieldsBox}>
              <div>
                {" "}
                <div>
                  <DroneProductBrandComboBox
                    value={couponData?.drone_product_brand}
                    onChange={handleAutocompleteChangeforDroneProductBrand}
                  />
                </div>
              </div>

              <div>
                <div>
                  <DDuserComboBox
                    value={couponData?.user}
                    // value={userList}
                    onChange={handleAutocompleteChangeforUser}
                  />
                  {/* {formErrors?.user && (
                    <p style={{ color: "red" }}>{formErrors?.user}</p>
                  )} */}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Checkbox
                    checked={couponData?.all_users}
                    name="all_users"
                    onChange={handleChangeCheckBox}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <p>All Users</p>
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.formContainer} mt-20`}>
            <div className={styles.commonFieldsBox}>
              <div className={styles.switchCon}>
                <p>First Time</p>
                <Switch
                  checked={couponData?.first_time}
                  name="first_time"
                  inputProps={{ "aria-label": "controlled" }}
                  onChange={handleChangeSwitch}
                />
              </div>
              <div className={styles.switchCon}>
                <p>Multiple Time</p>
                <Switch
                  checked={couponData?.multiple_time}
                  name="multiple_time"
                  inputProps={{ "aria-label": "controlled" }}
                  onChange={handleChangeSwitch}
                />
              </div>
            </div>
          </div>

          <div className={`${styles.formContainer} mt-20`}>
            <div className={styles.commonFieldsBox}>
              <CustomSelectChip
                multiple={false}
                label="Discount Type *"
                required
                name="discount_type"
                onChange={handleChange}
                onDelete={handleDeleteChip}
                value={couponData?.discount_type}
                listArray={discountTypeList}
                error={formErrors?.discount_type}
                helperText={formErrors?.discount_type}
              />
              <InputFields
                label="Discount"
                required
                name="discount"
                type="number"
                value={couponData?.discount}
                onChange={handleChange}
                error={formErrors?.discount}
                helperText={formErrors?.discount}
              />
            </div>
          </div>

          <div className={`${styles.formContainer} mt-20`}>
            <div className={styles.commonFieldsBox}>
              <InputFields
                label="Upto"
                required
                name="upto"
                type="number"
                value={couponData?.upto}
                onChange={handleChange}
                error={formErrors?.upto}
                helperText={formErrors?.upto}
              />
              <InputFields
                label="Usage Count"
                required
                name="usage_count"
                type="number"
                value={couponData?.usage_count}
                onChange={handleChange}
                error={formErrors?.usage_count}
                helperText={formErrors?.usage_count}
              />
            </div>
          </div>

          <div className={`${styles.formContainer} mt-20`}>
            <div className={styles.commonFieldsBox}>
              <div>
                <h4>From Date*</h4>
                <div
                  style={{
                    padding: "0px 20px",
                    boxSizing: "border-box",
                    marginTop: "20px",
                  }}
                >
                  <div style={{ marginBottom: "20px" }}>
                    <CustomDatePicker
                      label="From Date"
                      value={couponData?.from_date}
                      onChange={(newValue) =>
                        setCouponData({
                          ...couponData,
                          from_date: newValue,
                        })
                      }
                      error={formErrors?.from_date}
                      helperText={formErrors?.from_date}
                    />
                  </div>

                  {/* <DurationPicker
                    style={{ width: "100%" }}
                    label="Time"
                    size="small"
                    name="from_time"
                    onChange={handleChange}
                    value={couponData?.from_time}
                  /> */}
                </div>
              </div>
              <div>
                <h4>To Date*</h4>
                <div
                  style={{
                    padding: "0px 20px",
                    boxSizing: "border-box",
                    marginTop: "20px",
                  }}
                >
                  <div style={{ marginBottom: "20px" }}>
                    <CustomDatePicker
                      label="To Date"
                      value={couponData?.to_date}
                      onChange={(newValue) =>
                        setCouponData({
                          ...couponData,
                          to_date: newValue,
                        })
                      }
                      error={formErrors?.to_date}
                      helperText={formErrors?.to_date}
                    />
                  </div>

                  {/* <DurationPicker
                    style={{ width: "100%" }}
                    label="Time"
                    size="small"
                    name="to_time"
                    onChange={handleChange}
                    value={couponData?.to_time}
                  /> */}
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.formContainer} mt-20`}>
            <div className={`${styles.titleStyles}`}>Icon </div>

            <div style={{ marginTop: "20px" }}>
              <div
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e)}
                className={styles.dropBoxStyles}
              >
                <div
                  onClick={() => handleClick()}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <p>{/* <img src={dragDrop} alt="dradanddrop" /> */}</p>
                  <p>Drag & Drop the File or click here</p>
                  <input
                    ref={iconInput}
                    type="file"
                    // id="myfile"
                    // name="myfile"
                    // multiple
                    onChange={(e) => handleDrop(e)}
                    style={{ display: "none" }}
                  ></input>
                </div>
              </div>
            </div>
          </div>

          {iconPreview && (
            <div className={`${styles.formContainer} mt-20`}>
              <div className={styles.commonFieldsBox}>
                <div>
                  <p
                    // style={{ color: palette?.primary[500] }}
                    className={`${styles.titleStyles}`}
                  >
                    Icon Preview
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <div
                      className={`${styles.flexBetween} ${styles.imgCardBox} `}
                    >
                      {" "}
                      <img
                        src={iconPreview}
                        alt="icon"
                        width={200}
                        height={140}
                      />
                      <div onClick={() => handleClick()}>
                        <Button
                          variant="contained"
                          // onClick={() => {
                          //   handleMediaDelete("image");
                          // }}
                        >
                          Update
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className={`${styles.formContainer} mt-20`}>
            <Button
              className={`${styles.gradientColor}`}
              variant="contained"
              onClick={handleCreateCoupon}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CreateCoupon;

const discountTypeList = [
  {
    id: 1,
    title: "Percentage",
  },
  {
    id: 2,
    title: "Amount",
  },
];
