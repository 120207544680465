import React, { useState, useEffect, useContext } from "react";
import styles from "./Hotel.module.css";
import Container from "../../Component/Container/Container";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import CustomButton from "../../Component/CustomButton/CustomButton";
import CustomSearchFilter from "../../Component/CustomSearch/CustomSearchFilter";
import CustomSelect from "../../Component/CustomSelect/CustomSelect";
import FilterContainer from "../../Component/FitlerContainer/FilterContainer";
import CustomTable from "../../Component/CustomTable/CustomTable";
import CustomPagination from "../../Component/CustomPagination/CustomPagination";
import { Checkbox } from "@mui/material";
import filterIcon from "../../Assets/filterIcon.png";
import hotelIcon from "../../Assets/hotelsIcon.svg";
import DashboardCard from "../../Component/DashboardCard/DashboardCard";
import { Link, useNavigate } from "react-router-dom";
import { token_api } from "../../Utils/network";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import SearchAndDropdown from "../../Component/SearchAndDropdown/SearchAndDropdown";
import delete_icon from "../../Assets/delete_icon.svg";
import edit_icon from "../../Assets/edit_icon.svg";
import { Button } from "@mui/material";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import CustomModal from "../../Component/CustomModal/CustomModal";

const Hotel = () => {
  const navigate = useNavigate();
  const { user_permissions } = useContext(UserCredsContext);

  const [filters, setfilters] = useState({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [hotelsCount, setHotelsCount] = useState({ hotelcdata: "" });
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const [hotelList, setHotelList] = useState([]);
  const [typedValue, setTypedValue] = useState("");
  const [accForm, setaccForm] = useState({});
  const [locationList, setLocationList] = useState([]);
  const [openDailog, setOpenDailog] = useState(false);
  const [deletedHotel, setDeletedHotel] = useState(null);
  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <div className="orange-1 bread">Hotels</div>,
  ];

  const handleRedirect = (id) => {
    navigate(`/lms/edit/createhotel/${id}`);
  };

  const getHotelsList = async (filters) => {
    try {
      const fetchedHotels = await token_api.get(`course/hotels`, {
        params: { ...filters },
      });

      if (fetchedHotels?.status !== 200) {
        throw new Error("Error while fetching Hotels!");
      }
      setPaginationData(fetchedHotels?.data);

      setHotelsCount((prev) => ({
        ...prev,
        hotelcdata: fetchedHotels?.data?.total_count,
      }));

      let modifiedArray = fetchedHotels?.data?.data?.map((item) => {
        return {
          hotelId: (
            <div
              style={{ cursor: "pointer", fontSize: "12px" }}
              onClick={() => handleRedirect(item?.id)}
            >
              {item?.id}
            </div>
          ),

          hotelName: (
            <div
              style={{ cursor: "pointer", fontSize: "12px" }}
              onClick={() => handleRedirect(item?.id)}
            >
              <p>{item?.hotel_name}</p>
            </div>
          ),
          location: (
            <p style={{ fontSize: "12px" }}>
              {item?.location?.line_1 && `${item.location.line_1}, `}
              {item?.location?.line_2 && `${item.location.line_2}, `}
              {item?.location?.area && `${item.location.area}, `}
              {item?.location?.city && `${item.location.city}, `}
              {item?.location?.state && `${item.location.state}, `}
              {item?.location?.country && `${item.location.country}, `}
              {item?.location?.pincode && item.location.pincode}
            </p>
          ),
          singleOccupancy: (
            <div
              style={{ cursor: "pointer", fontSize: "12px" }}
              onClick={() => handleRedirect(item?.id)}
            >
              {item?.single_occupancy}
            </div>
          ),
          doubleOccupancy: (
            <div
              style={{ cursor: "pointer", fontSize: "12px" }}
              onClick={() => handleRedirect(item?.id)}
            >
              {item?.double_occupancy}
            </div>
          ),
          checkIn: (
            <div
              style={{ cursor: "pointer", fontSize: "12px" }}
              onClick={() => handleRedirect(item?.id)}
            >
              {item?.check_in_time}
            </div>
          ),
          checkOut: (
            <div
              style={{ cursor: "pointer", fontSize: "12px" }}
              onClick={() => handleRedirect(item?.id)}
            >
              {item?.check_out_time}
            </div>
          ),
          action: (
            <div style={{ display: "flex", alignItems: "center" }}>
              {user_permissions?.update && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleRedirect(item?.id)}
                >
                  <img src={edit_icon} />
                </div>
              )}
              {user_permissions?.delete && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDeleteorgPopup(item?.id)}
                >
                  <img src={delete_icon} />
                </div>
              )}
            </div>
          ),
        };
      });
      setHotelList([...modifiedArray]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      let filterObject = {};
      if (pageState) {
        filterObject.page = pageState;
      }

      if (filters?.q) {
        filterObject.q = filters?.q;
      }

      if (filters?.occupancy_type) {
        filterObject.occupancy_type = filters.occupancy_type[0]?.id;
      }
      if (accForm?.location) {
        filterObject.location = accForm?.location?.id;
      }

      getHotelsList(filterObject);
    }, 500);
    return () => clearTimeout(timeout);
  }, [pageState, filters?.occupancy_type, accForm?.location, filters?.q]);

  const handleFilter = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setfilters({
        ...filters,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setfilters({
        ...filters,
        [selectName]: [e],
      });
    }
  };

  const handleDeleteChip = (id, selectName) => {
    setfilters({
      ...filters,
      [selectName]: filters?.[selectName]?.filter((chip) => chip?.id !== id),
    });
  };

  const handleChangeforSearch = (event, newValue) => {
    setTypedValue(newValue);
    setaccForm((prev) => ({
      ...prev,
      location: newValue,
    }));
  };

  const getLocationList = (value) => {
    let apiEndpoint = "/course/location";

    if (value) {
      apiEndpoint += `?q=${value}`;
    }
    token_api
      .get(apiEndpoint)
      .then((res) => {
        if (res.data.data) {
          let userloc = res.data.data?.map((info) => ({
            id: info?.id,
            title: `${info?.line_1}, ${info?.line_2}, ${info?.area}, ${info?.city_new}, ${info?.state}, ${info?.country}`,
          }));
          setLocationList(userloc);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    let timer;

    if (typedValue) {
      timer = setTimeout(() => {
        getLocationList(typedValue);
      }, 100);
    } else {
      getLocationList();
    }
    return () => {
      clearTimeout(timer);
    };
  }, [typedValue]);

  const handleDeleteorg = () => {
    token_api
      .delete(`/course/hotels/${deletedHotel}/`)
      .then((response) => {
        if (response?.status == 204 || response?.status == 201) {
          alert("Hotel deleted successfully!");
          setOpenDailog(false);
          setDeletedHotel(null);
          getHotelsList();
        }
      })
      .catch((error) => {});
  };

  const handleCloseModal = () => {
    setOpenDailog(false);
    setDeletedHotel(null);
  };

  const handleDeleteorgPopup = (id) => {
    setOpenDailog(true);
    setDeletedHotel(id);
  };

  const handleNavigateBack = () => {
    navigate(`/dashboard`);
  };

  return (
    <Container style={{ overflowX: "auto" }}>
      <div className="flexBetween">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
            <img src={breadCrumbBack} />
          </div>
          <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
        </div>

        {user_permissions?.write && (
          <Link to="/lms/createhotel">
            <Button variant="contained" className="btnLinearGradient">
              + New Hotel{" "}
            </Button>
          </Link>
        )}
      </div>{" "}
      <div
        style={{
          display: "flex",
          columnGap: "20px",
          width: "300px",
          margin: "20px 0px",
        }}
      >
        <DashboardCard
          cardTitle="Hotels"
          cardIcon={hotelIcon}
          primaryGardient="linear-gradient(rgba(124, 147, 195, 1),
              rgba(160, 181, 224, 0.77))"
          secondaryGradient="linear-gradient(rgba(88, 116, 174, 1),
          rgba(124, 147, 195, 0.42))"
          value="4"
          countvalue={hotelsCount}
          hint="hotelcdata"
          present={true}
        />
      </div>
      <FilterContainer
        className={styles.filterContainer}
        // className={`${styles.marginTopBox}`}
      >
        <CustomSearchFilter
          placeholder="Search by hotel name..."
          onChange={(e) => {
            setfilters({ ...filters, q: e });
          }}
        />

        <SearchAndDropdown
          label="Filter By Location"
          value={accForm?.location}
          onChange={handleChangeforSearch}
          listing={locationList}
          typedValue={typedValue}
          setTypedValue={setTypedValue}
        />

        <CustomSelectChip
          multiple={false}
          label="Filter By Occupancy Type"
          name="occupancy_type"
          onChange={handleFilter}
          onDelete={handleDeleteChip}
          value={filters?.occupancy_type}
          listArray={occupancyListing}
        />
      </FilterContainer>
      <div className={styles.tableContainer}>
        <CustomTable
          columns={tableColumns}
          data={hotelList}
          handleClick={handleRedirect}
          tableFor="hotels"
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className={styles.paginationContainer}>
          <CustomPagination max_pages={max_pages} setPageState={setPageState} />
        </div>
      </div>
      <CustomModal open={openDailog} onClose={handleCloseModal} height="300px">
        <div className="alertBox">
          <h3>Are you sure you want to delete the hotel?</h3>
          <div className="alertBtnCon">
            <Button onClick={handleDeleteorg} variant="contained">
              Yes
            </Button>
            <Button onClick={handleCloseModal} variant="contained">
              No
            </Button>
          </div>
        </div>
      </CustomModal>
    </Container>
  );
};

export default Hotel;

const tableColumns = [
  {
    id: "hotelId",
    label: "Hotel ID",
  },
  {
    id: "hotelName",
    label: "Name Of Hotel",
  },

  {
    id: "location",
    label: "Location",
  },

  {
    id: "singleOccupancy",
    label: "Single Occupancy",
  },
  {
    id: "doubleOccupancy",
    label: "Double Occupancy",
  },
  {
    id: "checkIn",
    label: "Check-In Time",
  },
  {
    id: "checkOut",
    label: "Checkout-Out Time",
  },

  {
    id: "action",
    label: "Action",
  },
];

const tableBodyData = [
  {
    id: 1,
    hotelId: "HI10029",
    hotelName: "The Galaxy Home Apartments",
    location: "1st floor 108, Veera Desai Road,andheri west, Mumbai",
    singleOccupancy: "15",
    doubleOccupancy: "24",
    checkIn: "07:00 AM",
    checkOut: "04:00 PM",
  },
  {
    id: 2,
    hotelId: "HI10029",
    hotelName: "The Galaxy Home Apartments",
    location: "1st floor 108, Veera Desai Road,andheri west, Mumbai",
    singleOccupancy: "15",
    doubleOccupancy: "24",
    checkIn: "07:00 AM",
    checkOut: "04:00 PM",
  },
  {
    id: 3,
    hotelId: "HI10029",
    hotelName: "The Galaxy Home Apartments",
    location: "1st floor 108, Veera Desai Road,andheri west, Mumbai",
    singleOccupancy: "15",
    doubleOccupancy: "24",
    checkIn: "07:00 AM",
    checkOut: "04:00 PM",
  },
];

const occupancyListing = [
  {
    id: "1",
    title: "Single Occupancy",
  },
  {
    id: "2",
    title: "Double Occupancy",
  },
];

const statusListing = [
  {
    id: true,
    title: "Active",
  },
  {
    id: false,
    title: "Inactive",
  },
];
