import React, { useCallback, useEffect, useRef, useState } from "react";
import Container from "../../Component/Container/Container";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import styles from "./CourseList.module.css";
import InputFields from "../../Component/InputFields/InputFields";
import CustomSelect from "../../Component/CustomSelect/CustomSelect";
import TextEditor from "../../Component/TextEditor/TextEditor";
import { Button, useTheme } from "@mui/material";
import DrawerComp from "../../Component/DrawerComp/DrawerComp";
import CourseBatchCard from "../../Component/CourseBatchCard/CourseBatchCard";
import ImageIcon from "../../Assets/image_icon.svg";
import VideoIcon from "../../Assets/video_icon.svg";
import editIcon from "../../Assets/editIcon.png";
import dragDrop from "../../Assets/drag_drop.svg";
import AddBatchForm from "./AddBatchForm";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import { Link, useNavigate, useParams } from "react-router-dom";
import { base_url, token_api } from "../../Utils/network";
import axios from "axios";
import { useSelector } from "react-redux";
import { BannerAndMediaService, mediaDataForApi } from "./courseUtility";
import { validateMedia, validateCourse } from "./validate";
import Backdrop from "@mui/material/Backdrop";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";

const CreateCourse = () => {
  const { access_token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const fileInput = useRef(null);
  const bannerInput = useRef(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [banners, setBanners] = useState("");
  const [bannersPreview, setBannersPreview] = useState("");
  const [courseMedia, setCourseMedia] = useState([]);
  const [crsMediaPreview, setCrsMediaPreview] = useState([]);
  const descriptionRef = useRef(null);
  const preRequisiteRef = useRef(null);
  const { id } = useParams();
  console.log(id, "divyaId");
  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <Link to="/lms/courses" className="orange-1 bread breadElement">
      Course
    </Link>,
    <div className="orange-1 bread">
      {id ? "Edit Course" : "Create Course"}
    </div>,
  ];
  console.log("MEDIAPREVIEWAFTER", bannersPreview);
  const theme = useTheme();
  const { palette } = theme;
  const [openBatchDrawer, setBatchDrawer] = useState(false);
  const [batchObjEdit, setBatchObjEdit] = useState({});
  const [instructorList, setInstructorList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [courseForm, setCourseForm] = useState({
    descriptions: "",
    // mode: "",
    name: "",
    descriptions: "",
    eligibility: "",
    duration: "",
    batches: [],
    includes: [],
    excludes: [],
    type_id: null,
    languages: [],
    modes: [],
    cities: [],
  });
  const [citiesList, setCitiesList] = useState([]);
  const [courseTypeList, setCourseTypeList] = useState([]);
  const [includesArray, setIncludesArray] = useState([]);
  const [exludesArray, setExcludesArray] = useState([]);
  const [includesApiList, setIncludesApiList] = useState([]);
  const [excludesApiList, setExcludesApiList] = useState([]);
  const [editState, setEditState] = useState(false);
  const [addServices, setAddService] = useState({});
  const [mediaUploading, setMediaUploading] = useState(false);
  const [crsMediaPatch, setCrsMediaPatch] = useState({});
  const [patchIdchk, setPatchIdchk] = useState(null);
  console.log("SETBANNERSSS", courseForm);

  const [formErrors, setFormErrors] = useState([]);

  const handleCreateCourse = async () => {
    if (descriptionRef.current) {
      console.log(descriptionRef.current.getContent(), "dess");
      courseForm.descriptions = descriptionRef.current.getContent();
    }
    if (preRequisiteRef.current) {
      console.log(preRequisiteRef.current.getContent(), "preeee");
      courseForm.eligibility = preRequisiteRef.current.getContent();
    }
    const batches_details = courseForm?.batches?.map((item) => {
      const {
        name,
        days,
        instructors,
        descriptions,
        end_date,
        start_date,
        start_time,
        end_time,
        mrp,
        selling_price,
        discount,
        mode,
        language,
        location,
        days_of_weeks,
        ...rest
      } = item;
      const language_id = language?.id;
      const location_id = location?.id;
      const days_of_weeks_process = days_of_weeks?.map((item) => item?.id);
      const instructor = instructors?.map((item) => item?.id);

      return {
        ...rest,
        name,
        days_of_week: days_of_weeks_process,
        instructor: instructor,
        descriptions,
        end_date,
        start_date,
        start_time,
        end_time,
        mrp,
        selling_price,
        discount,
        mode: mode?.[0]?.id,
        language_id: language_id,
        location_id: location_id,
      };
    });

    console.log("FinalCourseForm", courseForm, batches_details, editState);

    console.log(batchObjEdit, "batchObjEdit");

    try {
      const response = await validateCourse(courseForm);
      if (response === "success") {
        if (!editState) {
          // create course
          const courseResponse = await token_api.post(`course/`, {
            ...courseForm,
            course_language_id: courseForm?.languages?.map((item) => item?.id),
            course_location_id: courseForm?.cities?.map((item) => item?.id),
            course_mode_id: courseForm?.modes?.map((item) => item?.id),
            batches_details: batches_details,
            include: courseForm?.includes?.map((item) => item.id),
            exclude: courseForm?.excludes?.map((item) => item.id),
            type_id: courseForm?.type_id[0]?.id,
          });

          if (courseResponse?.status !== 201) {
            alert(courseResponse?.data?.error?.fields[0]?.message[0]);
          }

          if (
            courseResponse?.status === 200 ||
            courseResponse?.status === 201
          ) {
            alert("Course Created Successfully!");
            navigate("/lms/courses");
          }
          if (banners) {
            let bannerForm = new FormData();
            bannerForm.append("banner", banners);
            const addbanners = await axios({
              method: "patch",
              url: `${base_url}course/${courseResponse?.data?.data?.slug}/`,
              data: bannerForm,
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${access_token}`,
              },
            });
          }
        } else {
          // edit course
          const courseResponse = await token_api.patch(`course/${id}/`, {
            ...courseForm,
            batches_details: batches_details,
            include: courseForm?.includes?.map((item) => item.id),
            exclude: courseForm?.excludes?.map((item) => item.id),
            type_id: courseForm?.type_id[0]?.id,
            course_language_id: courseForm?.languages?.map((item) => item?.id),
            course_mode_id: courseForm?.modes?.map((item) => item?.id),
            course_location_id: courseForm?.cities?.map((item) => item?.id),
          });

          if (
            courseResponse?.status === 200 ||
            courseResponse?.status === 201
          ) {
            alert("Course details updated successfully!");
            navigate("/lms/courses");
          }

          if (banners) {
            let bannerForm = new FormData();
            bannerForm.append("banner", banners);
            const addbanners = await axios({
              method: "patch",
              url: `${base_url}course/${courseResponse?.data?.data?.slug}/`,
              data: bannerForm,
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${access_token}`,
              },
            });
            navigate("/lms/courses");
            return;
          }
        }
      }
    } catch (error) {
      console.log("Error:", error);
      setFormErrors(error);
    }
  };

  const handleMediaUpload = async () => {
    console.log("COURSEMEDIA", courseMedia);
    setMediaUploading(true);

    if (Array.isArray(courseMedia) && courseMedia?.length > 0) {
      let validate = validateMedia(courseMedia);
      if (validate?.errorCheck) {
        setCourseMedia(validate?.validData);
        setMediaUploading(false);
        return;
      }
      const { patchData, postData } = mediaDataForApi(courseMedia);
      console.log("CRSMEDIAVALIDATE", postData, patchData);
      if (postData && postData?.length > 0) {
        for (let i = 0; i < postData?.length; i++) {
          let formData = new FormData();
          formData.append("course", courseForm?.id);
          formData.append("media", postData[i]?.media);
          formData.append("index", postData[i]?.index);
          formData.append("headline", postData[i]?.headline);
          formData.append("tagline", postData[i]?.tagline);

          const addMedia = await axios({
            method: "post",
            url: `${base_url}course/media/`,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${access_token}`,
            },
          });
        }
      }

      // if (patchData && patchData?.length > 0) {
      //   for (let i = 0; i < patchData?.length; i++) {
      //     let formData = new FormData();
      //     formData.append("course", courseForm?.id);
      //     formData.append("media", patchData[i]?.media);
      //     formData.append("index", patchData[i]?.index);
      //     formData.append("headline", patchData[i]?.headline);
      //     formData.append("tagline", patchData[i]?.tagline);

      //     const addMedia = await axios({
      //       method: "patch",
      //       url: `${base_url}course/media/${patchData[i]?.id}/`,
      //       data: formData,
      //       headers: {
      //         "Content-Type": "multipart/form-data",
      //         Authorization: `Bearer ${access_token}`,
      //       },
      //     });
      //   }
      // }

      setMediaUploading(false);
    }
  };

  const handleClick = (type) => {
    console.log("refd", type);
    if (type === "c_media") {
      fileInput.current.click();
    } else {
      bannerInput.current.click();
    }
  };
  function handleDragOver(e) {
    e.preventDefault();
  }

  const handleMediaUpdate = async (id, index) => {
    let formData = new FormData();

    console.log("FORMDATACRSSS", crsMediaPatch, id, index);
    formData.append("course", courseForm?.id);
    Object.keys(crsMediaPatch).forEach((key) => {
      formData.append(key, crsMediaPatch[key]);
    });

    try {
      const addMedia = await axios({
        method: "patch",
        url: `${base_url}course/media/${id}/`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (addMedia?.status !== 200) {
        throw new Error("PATCH ERROR");
      }
      getCourseDetails();
    } catch (error) {
      console.log(error);
    }
  };
  const handleBannerDelete = (type, itemIndex) => {
    if (type === "image") {
      const bannersFilter = banners.filter(
        (item, index) => index !== itemIndex
      );

      const bannersPreviewFilter = bannersPreview.filter(
        (item, index) => index !== itemIndex
      );
      console.log("DELETEBANNERS", bannersFilter, type, itemIndex);
      setBannersPreview(bannersPreviewFilter);
      setBanners(bannersFilter);
    } else if (type === "video") {
    }
  };
  const handleMediaDelete = async (type, itemIndex, id) => {
    if (type === "image") {
      if (id) {
        setPageLoading(true);
        const deleteMedia = await token_api.delete(`course/media/${id}/`);
        console.log("DELETERESPONSE", deleteMedia);
        getCourseDetails();
      } else {
        const mediaFilter = courseMedia.filter(
          (item, index) => index !== itemIndex
        );
        setCourseMedia(mediaFilter);
      }
    } else if (type === "video") {
    }
  };

  function handleDrop(e, type) {
    console.log("FGF", e, type);
    e.preventDefault();
    if (e?.target?.files) {
      // simple drop
      // type=c_media or banner
      if (type === "c_media") {
        // mediaService
        const mediaService = new BannerAndMediaService(
          e.target.files,
          ""
        ).addCourseMedia();
        console.log("LOGMEDIASERVICE", mediaService);
        setCrsMediaPreview((prev) => {
          let prevCopy = [...prev];
          prevCopy.push(...mediaService.imgsPReview);
          return prevCopy;
        });
        setCourseMedia((prev) => {
          let prevCopy = [...prev];
          prevCopy.push(...mediaService.imgFiles);
          return prevCopy;
        });
      } else {
        // banner
        setBanners(e?.target?.files[0]);
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setBannersPreview(imageUrl);
      }
    } else if (e?.dataTransfer?.files) {
      // dragged
      // type=c_media or banner
      if (type === "c_media") {
        const mediaService = new BannerAndMediaService(
          e?.dataTransfer?.files,
          ""
        ).addCourseMedia();
        console.log("MEDIASERVICE", mediaService);
        setCrsMediaPreview((prev) => {
          let prevCopy = [...prev];
          prevCopy.push(...mediaService.imgsPReview);
          return prevCopy;
        });
        setCourseMedia((prev) => {
          let prevCopy = [...prev];
          prevCopy.push(...mediaService.imgFiles);
          return prevCopy;
        });
      } else {
        setBanners(e?.dataTransfer?.files[0]);
        const imageUrl = URL.createObjectURL(e.dataTransfer.files[0]);
        setBannersPreview(imageUrl);
      }
    }
  }

  console.log("BANBANBA", bannersPreview, banners);

  const handleInputAdd = (type) => {
    if (type === "includes") {
      setIncludesArray([...includesArray, ""]);
    } else if (type === "excludes") {
      setExcludesArray([...exludesArray, ""]);
    }
  };

  const handleAddBatch = () => {
    setBatchDrawer(true);
  };
  const [editBatchIndex, seteditBatchIndex] = useState(null);
  const handleCloseDrawer = () => {
    seteditBatchIndex(null);
    setBatchObjEdit({});
    setBatchDrawer(false);
  };

  console.log("DRBOOL", openBatchDrawer);

  const handleEditBatch = (batch, index) => {
    console.log(batch, "userbatch");

    seteditBatchIndex(index);
    setBatchObjEdit(batch); // ye hai edit ka
    setBatchDrawer(true);
  };

  const handleDeleteBatch = (batch, index) => {
    token_api
      .delete(`/course/batches/${batch?.id}/`)
      .then((response) => {
        getCourseDetails();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log("MEDIATEXT", crsMediaPatch);

  const handleChangeMedia = (e, index) => {
    const { name, value } = e.target;
    setPatchIdchk(index);
    setCrsMediaPatch({
      ...crsMediaPatch,
      [name]: value,
    });

    setCourseMedia((prev) => {
      let prevCopy = [...prev];
      console.log("MEDIAPREVCOPY", prevCopy);
      prevCopy[index][name] = value;
      return prevCopy;
    });
  };

  const handleChange = (e, type, selectName) => {
    console.log("CHANGE", e, type, selectName);
    if (type === "select" && selectName) {
      setCourseForm({
        ...courseForm,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
      return;
    }
    setCourseForm({
      ...courseForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeSingle = (e, type, selectName, multiple) => {
    console.log("TYTYY");
    if (type === "select" && selectName && multiple) {
      setCourseForm({
        ...courseForm,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setCourseForm({
        ...courseForm,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setCourseForm({
        ...courseForm,
        [name]: value,
      });
    }
    // const { name, target } = e.target;
  };

  const handleChangeIncExc = (e, index, type) => {
    console.log("INNDDDD", e, index, type);
    if (type === "includes") {
      // includesArray[index] = e;
      // setIncludesArray([...includesArray]);
      setIncludesArray((prev) => {
        const updateArray = [...prev];
        updateArray[index] = e;
        return updateArray;
      });
    } else if (type === "excludes") {
      setExcludesArray((prev) => {
        const updateArray = [...prev];
        updateArray[index] = e;
        return updateArray;
      });
    }
  };
  console.log("SASASASA", banners);
  const handleDeleteChip = (id, selectName) => {
    console.log("DELETE", id, selectName);
    // setFormData({
    //   ...formData,
    //   [selectName]: formData?.[selectName]?.filter((chip) => chip?.id !== id),
    // });
    // (chips) => chips.filter((chip) => chip?.id !== id)

    setCourseForm({
      ...courseForm,
      [selectName]: courseForm?.[selectName]?.filter((chip) => chip?.id !== id),
    });
  };

  const getCourseDetails = async () => {
    if (id) {
      try {
        const fetchCourse = await token_api.get(`course/admin_detail/${id}`);

        // await token_api.get(`course/${id}`);

        if (fetchCourse?.status !== 200) {
          throw new Error("Error while fetching");
        }

        console.log(
          fetchCourse,
          citiesList,
          languageList,
          modesList,
          "hariFetchCourse"
        );

        if (fetchCourse?.data?.data) {
          setEditState(true);
        }
        const {
          name,
          descriptions,
          eligibility,
          includes,
          duration,
          batches,
          slug,
          course_media,
          banner,
          type,
          excludes,
          course_location,
          course_language,
          course_mode,
        } = fetchCourse?.data?.data;

        if (course_media && course_media?.length > 0) {
          let mediaLinks = course_media?.map((item) => item?.media);
          let mediaObject = course_media?.map((item) => {
            return {
              id: item?.id,
              headline: item?.headline,
              tagline: item?.tagline,
              index: item?.index,
              media_preview: item?.media,
            };
          });

          setCourseMedia(mediaObject);
        } else {
          setCourseMedia([]);
        }

        // setBanners(banner);
        setBannersPreview(banner);

        setCourseForm({
          ...courseForm,
          id: fetchCourse?.data?.data?.id,
          name,
          descriptions,
          eligibility,
          duration,
          batches,
          slug,
          includes: includes?.map((item) => {
            return { id: item?.id, title: item?.text };
          }),
          excludes: excludes?.map((item) => ({
            id: item?.id,
            title: item?.text,
          })),
          type_id: [type],
          cities: course_location.map((item) => {
            return { id: item?.id, title: `${item?.city} ${item?.state}` };
          }),
          languages: course_language.map((item) => {
            return { id: item?.id, title: item?.title };
          }),
          modes: course_mode.map((item) => {
            return { id: item?.id, title: item?.title };
          }),
        });
        setPageLoading(false);
        console.log("FEtchIndividualcourse", fetchCourse);
      } catch (error) {
        console.log("FetchCOurseError", error);
      }
    }
  };

  console.log(courseForm, "divyaBanneraaa");
  const getLanguage = () => {
    token_api
      .get(`course/language`)
      .then((response) => {
        if (response.status === 200) {
          const modifiedList = response?.data?.data?.map((item) => {
            return {
              id: item?.id,
              title: item?.title,
              icon: item?.icon,
            };
          });
          setLanguageList(modifiedList);
        }
      })
      .catch((err) => {});
  };
  const getInstructor = () => {
    token_api
      .get(`course/instructor`)
      .then((response) => {
        if (response.status === 200) {
          const modifiedList = response?.data?.data?.map((item) => {
            return {
              id: item?.id,
              title: item?.name,
            };
          });
          setInstructorList(modifiedList);
        }
      })
      .catch((err) => {});
  };

  // const getLocation = () => {
  //   token_api
  //     .get(`course/location`)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         const modifiedList = response?.data?.data?.map((item) => {
  //           return {
  //             id: item?.id,
  //             title: `${item?.city || ""} ${item?.state}`,
  //           };
  //         });
  //         setLocationList(modifiedList);
  //       }
  //     })
  //     .catch((err) => {});
  // };

  const getIncludesList = () => {
    token_api
      .get(`course/includes`)
      .then((response) => {
        if (response.status === 200) {
          const modifiedList = response?.data?.data?.map((item) => {
            return {
              id: item?.id,
              title: `${item?.text}`,
            };
          });

          setIncludesApiList(modifiedList);
        }
      })
      .catch((err) => {});
  };
  const getExcludesList = () => {
    token_api
      .get(`course/excludes`)
      .then((response) => {
        if (response.status === 200) {
          const modifiedList = response?.data?.data?.map((item) => {
            return {
              id: item?.id,
              title: `${item?.text}`,
            };
          });

          setExcludesApiList(modifiedList);
        }
      })
      .catch((err) => {});
  };

  console.log("Listings", includesApiList);

  const getCourseType = () => {
    token_api
      .get(`/course/types`)
      .then((response) => {
        setCourseTypeList(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCitiesList = () => {
    token_api
      .get(`/course/location?page_size=100`)
      .then((response) => {
        if (response.status === 200) {
          const modifiedList = response?.data?.data?.map((item) => {
            return {
              id: item?.id,
              title: `${item?.city || ""} ${item?.state}`,
            };
          });
          setCitiesList(modifiedList);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(courseForm, formErrors, "hariCourseForm");
  useEffect(() => {
    getIncludesList();
    getExcludesList();
    getLanguage();
    getInstructor();
    getCitiesList();
    getCourseDetails();
    getCourseType();
  }, []);

  console.log("COurseForm", courseForm);

  const handleDeleteBanner = () => {
    // setBannersPreview("")
  };

  const handleNavigateBack = () => {
    navigate(`/lms/courses`);
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
          <img src={breadCrumbBack} />
        </div>
        <CustomBreadCrumbs breadcrumbs={breadcrumbs} />
      </div>

      <div>
        <div
          style={{
            padding: "10px",
            borderRadius: "0px",
          }}
        >
          <div
            className={`${styles.titleStyles}`}
            style={{ color: palette?.primary[500] }}
          >
            Course Details
          </div>
          <div className={`${styles.formContainer} mt-20`}>
            <div className={styles.commonFieldsBox}>
              <InputFields
                label="Course Name"
                required
                name="name"
                value={courseForm?.name}
                onChange={handleChange}
                error={formErrors?.name}
                helperText={formErrors?.name}
              />
              <InputFields
                label="Course Slug"
                required
                name="slug"
                value={courseForm?.slug}
                onChange={handleChange}
                error={formErrors?.slug}
                helperText={formErrors?.slug}
              />

              <InputFields
                label="Duration in days"
                required
                name="duration"
                value={courseForm?.duration}
                onChange={handleChange}
                error={formErrors?.duration}
                helperText={formErrors?.duration}
              />

              <CustomSelectChip
                multiple={false}
                label="Course Type"
                name="type_id"
                onChange={handleChangeSingle}
                onDelete={handleDeleteChip}
                value={courseForm?.type_id}
                listArray={courseTypeList}
              />
              <CustomSelectChip
                multiple={true}
                label="Course Languages"
                name="languages"
                onChange={handleChangeSingle}
                onDelete={handleDeleteChip}
                value={courseForm?.languages}
                listArray={languageList}
              />
              <CustomSelectChip
                multiple={true}
                label="Course Modes"
                name="modes"
                onChange={handleChangeSingle}
                onDelete={handleDeleteChip}
                value={courseForm?.modes}
                listArray={modesList}
              />
              <CustomSelectChip
                multiple={true}
                label="Course Locations"
                name="cities"
                onChange={handleChangeSingle}
                onDelete={handleDeleteChip}
                value={courseForm?.cities}
                listArray={citiesList}
              />
            </div>
          </div>
          <div className={`${styles.formContainer} mt-20`}>
            <p>Course description*</p>
            <TextEditor
              editorRef={descriptionRef}
              initialValue={courseForm?.descriptions}
            />
            {formErrors?.descriptions && (
              <p style={{ color: "#d32f2f" }}>{formErrors?.descriptions}</p>
            )}
          </div>
          <div className={`${styles.formContainer} mt-20`}>
            <p>Eligibility criteria</p>
            <TextEditor
              editorRef={preRequisiteRef}
              initialValue={courseForm?.eligibility}
            />
          </div>

          <div className={`${styles.formContainer} mt-20`}>
            <div className={styles.commonFieldsBox}>
              <div>
                Course Includes
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "10px",
                  }}
                >
                  <CustomSelectChip
                    multiple="true"
                    name="includes"
                    onChange={handleChange}
                    onDelete={handleDeleteChip}
                    value={courseForm?.includes}
                    listArray={includesApiList}
                  />
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  {/* <Button
                    variant="contained"
                    onClick={() => handleInputAdd("includes")}
                  >
                    Add
                  </Button> */}
                </div>
              </div>
              <div>
                Course Excludes
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "10px",
                  }}
                >
                  <CustomSelectChip
                    multiple="true"
                    name="excludes"
                    onChange={handleChange}
                    onDelete={handleDeleteChip}
                    value={courseForm?.excludes}
                    listArray={excludesApiList}
                  />
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  {/* <Button
                    variant="contained"
                    onClick={() => handleInputAdd("excludes")}
                  >
                    Add
                  </Button> */}
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.formContainer} mt-20`}>
            <div
              className={`${styles.flexBetween}`}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{ color: palette?.primary[500] }}
                className={`${styles?.titleStyles}`}
              >
                Batch Details
              </div>

              {/* {id && (
                <Button
                  onClick={handleAddBatch}
                  variant="contained"
                  className={`${styles.gradientColor}`}
                >
                  + Add Batch
                </Button>
              )} */}
            </div>

            {/* <div>Batch Details</div> */}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "10px",
            }}
            className={`${styles.formContainer} mt-20`}
          >
            {courseForm?.batches?.length > 0 ? (
              <>
                {courseForm?.batches.map((item, index) => (
                  <CourseBatchCard
                    batchData={item}
                    handleEditBatch={() => handleEditBatch(item, index)}
                    handleDeleteBatch={() => handleDeleteBatch(item, index)}
                  />
                ))}
              </>
            ) : (
              "No batches added."
            )}
          </div>
          <div className={`${styles.formContainer} mt-20`}>
            <div
              style={{ color: palette?.primary[500] }}
              className={`${styles.titleStyles}`}
            >
              Add Course Banner
            </div>
            {/* <div
        style={{
          marginTop: "12px",
          justifyContent: "start",
          columnGap: "20px",
        }}
        className={`${styles.flexBetween}`}
      >
        <IconComponent
          icon={ImageIcon}
          title={"Image"}
          subTitle={"Png,JPG,JPEG"}
        />
        <IconComponent
          icon={VideoIcon}
          title={"Video"}
          subTitle={"MP4,MOV,AVI"}
        />
      </div> */}

            <div style={{ marginTop: "20px" }}>
              <div
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, "banner")}
                className={styles.dropBoxStyles}
              >
                <div
                  onClick={() => handleClick("banner")}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <p>
                    <img src={dragDrop} alt="dradanddrop" />
                  </p>
                  <p>Drag & Drop the File or click here</p>
                  <input
                    ref={bannerInput}
                    type="file"
                    // id="myfile"
                    // name="myfile"
                    // multiple
                    onChange={(e) => handleDrop(e, "banner")}
                    style={{ display: "none" }}
                  ></input>
                </div>
              </div>
            </div>
          </div>

          {bannersPreview && (
            <div className={`${styles.formContainer} mt-20`}>
              <div className={styles.commonFieldsBox}>
                <div>
                  <p
                    style={{ color: palette?.primary[500] }}
                    className={`${styles.titleStyles}`}
                  >
                    Course Banner Preview
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <div
                      className={`${styles.flexBetween} ${styles.imgCardBox} `}
                    >
                      {" "}
                      <img
                        src={bannersPreview}
                        alt="banner"
                        width={200}
                        height={140}
                      />
                      <div onClick={() => handleClick("banner")}>
                        <Button
                          variant="contained"
                          // onClick={() => {
                          //   handleMediaDelete("image");
                          // }}
                        >
                          Update
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* course media section */}

          {editState && (
            <div className={`${styles.formContainer} mt-20`}>
              <div
                style={{ color: palette?.primary[500] }}
                className={`${styles.titleStyles}`}
              >
                Add Course Media
              </div>
              <div style={{ marginTop: "20px" }} className={styles.mediaBox}>
                <div
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, "c_media")}
                  className={styles.dropBoxStyles}
                >
                  <div
                    onClick={() => handleClick("c_media")}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p>
                      <img src={dragDrop} alt="dradanddrop" />
                    </p>
                    <p>Drag & Drop the File or click here</p>
                    <input
                      ref={fileInput}
                      type="file"
                      // id="myfile"
                      // name="myfile"
                      multiple
                      onChange={(e) => handleDrop(e, "c_media")}
                      style={{ display: "none" }}
                    ></input>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "20px" }}>
                <Button
                  disabled={mediaUploading}
                  variant="contained"
                  onClick={handleMediaUpload}
                >
                  {mediaUploading ? "Uploading..." : "Upload"}
                </Button>
              </div>
            </div>
          )}

          {editState && courseMedia?.length > 0 && (
            <div className={`${styles.formContainer} mt-20`}>
              <div className={styles.commonFieldsBox}>
                <div>
                  <p
                    style={{ color: palette?.primary[500] }}
                    className={`${styles.titleStyles}`}
                  >
                    Course Media Preview
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    {courseMedia?.length > 0 &&
                      courseMedia.map((item, index) => {
                        console.log("RENDERCRSMEDIA", courseMedia);
                        return (
                          <div
                            className={`${styles.flexBetween} ${styles.imgCardBox} `}
                          >
                            <div>
                              <img
                                width={200}
                                height={140}
                                src={item?.media_preview}
                                alt="img"
                              />
                            </div>
                            <div>
                              <InputFields
                                label="Tagline"
                                name="tagline"
                                value={item?.tagline}
                                onChange={(e) => handleChangeMedia(e, index)}
                                error={item?.tagline_error}
                                helperText={item?.tagline_error}
                              />
                            </div>
                            <div>
                              <InputFields
                                label="Headline"
                                name="headline"
                                value={item?.headline}
                                onChange={(e) => handleChangeMedia(e, index)}
                                error={item?.headline_error}
                                helperText={item?.headline_error}
                              />
                            </div>
                            <div>
                              <InputFields
                                label="Index"
                                name="index"
                                value={item?.index}
                                onChange={(e) => handleChangeMedia(e, index)}
                                error={item?.index_error}
                                helperText={item?.index_error}
                              />
                            </div>
                            {item?.id && (
                              <div>
                                <Button
                                  disabled={index !== patchIdchk}
                                  variant="contained"
                                  onClick={() => {
                                    handleMediaUpdate(item?.id, index);
                                  }}
                                >
                                  Update
                                </Button>
                              </div>
                            )}

                            <div>
                              <Button
                                variant="contained"
                                onClick={() => {
                                  handleMediaDelete("image", index, item?.id);
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className={`${styles.formContainer} mt-20`}>
            <Button
              onClick={handleCreateCourse}
              className={`${styles.gradientColor}`}
              variant="contained"
            >
              Save & Publish
            </Button>
          </div>
        </div>
      </div>
      <DrawerComp
        width="45%"
        open={openBatchDrawer}
        onClose={handleCloseDrawer}
        anchor={"right"}
      >
        <AddBatchForm
          handleCancelDrw={handleCloseDrawer}
          setCourseForm={setCourseForm}
          courseForm={courseForm}
          batchObject={batchObjEdit}
          instructorList={instructorList}
          languageList={languageList}
          locationList={locationList}
          editBatchIndex={editBatchIndex}
        />
      </DrawerComp>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 9000 }}
        open={pageLoading}
        // onClick={handleClose}
      ></Backdrop>
    </Container>
  );
};

export default CreateCourse;

const IconComponent = ({ icon, title, subTitle }) => {
  return (
    <div style={{ columnGap: "10px" }} className={`${styles.flexBetween}`}>
      <div style={{ display: "flex" }}>
        <img src={icon} alt="image_icon" />
      </div>

      <div>
        <div style={{ fontSize: "14px", fontWeight: "500" }}>{title}</div>
        <div>
          <small>{subTitle}</small>
        </div>
      </div>
    </div>
  );
};

const slotTypesObj = [
  { id: 1, title: "Online Class" },
  { id: 2, title: "Offline Class" },
  { id: 3, title: "Flying Class" },
];

const modesList = [
  {
    title: "Online",
    id: 1,
  },
  {
    title: "Offline",
    id: 2,
  },
  {
    title: "Hybrid",
    id: 3,
  },
];
