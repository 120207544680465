import React from "react";
import styles from "./CreateDroneBooking.module.css";
import Container from "../../Component/Container/Container";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import { useNavigate, Link } from "react-router-dom";

const CreateDroneBooking = () => {
  const navigate = useNavigate();
  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <Link
      to="/bookings/drone-hub-bookings"
      className="orange-1 bread breadElement"
    >
      Drone Hub Bookings
    </Link>,
    <div className="orange-1 bread">Create Drone HUb Booking</div>,
  ];

  const handleNavigateBack = () => {
    navigate(`/bookings/drone-hub-bookings`);
  };
  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
            <img src={breadCrumbBack} />
          </div>
          <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
        </div>
      </div>
    </Container>
  );
};

export default CreateDroneBooking;
