import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Container from "../../Component/Container/Container";
import { useNavigate, Link, useParams } from "react-router-dom";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import { Button } from "@mui/material";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import { token_api } from "../../Utils/network";
import { helperdayMonthYearFull } from "../../Utils/helper";
import MyMap from "../../Component/MyMap/MyMap";
import paymentIcon from "../../Assets/paymentIcon.svg";
import noTransaction from "../../Assets/noTransactions.svg";
import { extractDateTime } from "../../Utils/helper";

const DronehubBookingDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [droneHubDetails, setDroneHubDetails] = useState({});
  const [loc, updLoc] = useState({ lat: 20.5937, lng: 78.9629 });
  const [transactionData, setTransactiondData] = useState([]);
  const [paymentData, setPaymentData] = useState({});

  const [bookingStatus, setBookingStatus] = useState({
    status: [],
  });
  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <Link
      to="/bookings/drone-hub-bookings"
      className="orange-1 bread breadElement"
    >
      Drone Hub Bookings
    </Link>,
    <div className="orange-1 bread">Drone Hub Booking Details</div>,
  ];

  const getDroneHubDetails = () => {
    token_api
      .get(`drone/shop_order/${id}`)
      .then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          setDroneHubDetails(response?.data?.data);
          let latVal = response?.data?.data?.shipping_address?.lat;
          let longVal = response?.data?.data?.shipping_address?.long;
          updLoc({
            lat: latVal ? latVal : 20.5937,
            lng: longVal ? longVal : 78.9629,
          });
          let isverified = bookingStatusList?.filter(
            (info) => info?.id == response?.data?.data?.purchase_status
          );

          setBookingStatus({
            status: isverified,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPaymentDetails = () => {
    token_api
      .get(`payment/pay/?shop_order=${id}`)
      .then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          setTransactiondData(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTransactionDetails = () => {
    token_api
      .get(`drone/shop_order/transaction_history?shop_order=${id}`)
      .then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          setPaymentData(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (id) {
      getDroneHubDetails();
      getPaymentDetails();
      getTransactionDetails();
    }
  }, [id]);

  console.log(paymentData, transactionData, "hariTransactionData");

  const handleNavigateBack = () => {
    navigate(`/bookings/drone-hub-bookings`);
  };

  const handleChangeBookingStats = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setBookingStatus({
        ...bookingStatus,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setBookingStatus({
        ...bookingStatus,
        [selectName]: [e],
      });
    }
  };

  const handleDeleteBookingStatus = (id, selectName) => {
    setBookingStatus({
      ...bookingStatus,
      [selectName]: bookingStatus?.[selectName]?.filter(
        (chip) => chip?.id !== id
      ),
    });
  };

  const handleChangeBookingStatus = () => {
    let data = {
      purchase_status: bookingStatus?.status[0]?.id,
    };

    token_api
      .patch(`drone/shop_order/${id}/`, data)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("DroneHub Booking Status Updated Successfully!");
          getDroneHubDetails();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleInvoiceData = (data) => {
    if (droneHubDetails?.invoice_available) {
      token_api
        .get(`course/invoice/?shop_order=${droneHubDetails?.id}`)
        .then((response) => {
          console.log(response, "response");
          if (response?.status === 200 || response?.status === 201) {
            alert("Invoice downloaded successfully!");
            const link = document.createElement("a");
            link.href = response?.data?.data?.[0]?.invoice_file;
            link.target = "_blank";
            link.download = "quote.pdf";

            document.body.appendChild(link);

            link.click();
            document.body.removeChild(link);
          }
        })
        .catch((error) => {
          console.log(error, "hariError");
          alert("Invoice is not generated.");
        });
    } else {
      token_api
        .post(`drone/generate_invoice/shop_order/`, {
          shop_order_id: droneHubDetails?.id,
        })
        .then((response) => {
          console.log(response, "response");
          if (response?.status === 200 || response?.status === 201) {
            alert("Invoice Generated Successfully!");
            getDroneHubDetails();
          }
          if (response?.status === 400 || response?.status === 404) {
            alert(response?.data?.data?.message);
          }
        })
        .catch((error) => {
          alert(error?.response?.data?.data?.message);
        });

      // 1. calling same api in the backend...
      //2. If there is no invoicepdf, then call another api...  Implement this one...
      // }
    }
  };

  return (
    <Container>
      <div className={styles.breadCrumbsCon}>
        <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
          <img src={breadCrumbBack} />
        </div>
        <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
      </div>

      <div className={styles.topContainer}>
        <div className={styles.topContainerLeft}>
          <div className={styles.leftFirstCon}>
            <h3>
              {" "}
              DroneHub Booking ID :{" "}
              <span>{droneHubDetails?.id ? droneHubDetails?.id : "N.A"}</span>
            </h3>
            <p>
              Created On:{" "}
              {droneHubDetails?.created
                ? helperdayMonthYearFull(droneHubDetails?.created)
                : "N.A"}
            </p>
            <p>
              Delivery Date:{" "}
              {droneHubDetails?.delivery_date
                ? helperdayMonthYearFull(droneHubDetails?.delivery_date)
                : "N.A"}
            </p>
          </div>
          <h3>
            {droneHubDetails?.order_product
              ? droneHubDetails?.order_product[0]?.product?.name
              : "N.A"}
          </h3>
        </div>
        <div className={styles.topContainerRight}>
          <CustomSelectChip
            multiple={false}
            label="DroneHub Booking Status"
            name="status"
            listArray={bookingStatusList}
            onChange={handleChangeBookingStats}
            onDelete={handleDeleteBookingStatus}
            value={bookingStatus?.status}
          />
          <Button
            variant="contained"
            onClick={handleChangeBookingStatus}
            disabled={!bookingStatus?.status[0]?.id}
            style={{
              backgroundColor: bookingStatus?.status[0]?.id
                ? "linear-gradient( 90deg, rgb(255, 153, 8) 0%, rgb(237, 103, 31) 100.02% )"
                : "light-grey",
              color: "white",
            }}
          >
            Submit
          </Button>
        </div>
      </div>

      <div className={styles.topContainer}>
        <div className={styles.dataLeft}>
          <h3>Customer Details & Billing Address</h3>
          <div className={styles.detailsCon}>
            <div>
              <h3>Full Name</h3>
              <p>{`${droneHubDetails?.user?.first_name} ${droneHubDetails?.user?.last_name}`}</p>
            </div>
            <div>
              <h3>Mobile</h3>
              <p>{`${droneHubDetails?.user?.mobile}`}</p>
            </div>
            <div>
              <h3>Email</h3>
              <p>{`${droneHubDetails?.user?.email}`}</p>
            </div>
            <div>
              <h3>GST Number</h3>
              <p>{droneHubDetails?.customer_gst_number || "N.A"}</p>
            </div>
            <div>
              <h3>Billing Address</h3>
              <p>
                {droneHubDetails?.billing_address_line_1
                  ? `${droneHubDetails.billing_address_line_1}, `
                  : ""}
                {droneHubDetails?.billing_address_line_2
                  ? `${droneHubDetails.billing_address_line_2}, `
                  : ""}
                {droneHubDetails?.billing_city
                  ? `${droneHubDetails.billing_city}, `
                  : ""}
                {droneHubDetails?.billing_district
                  ? `${droneHubDetails.billing_district}, `
                  : ""}
                {droneHubDetails?.billing_state
                  ? `${droneHubDetails.billing_state}, `
                  : ""}
                {droneHubDetails?.billing_country
                  ? `${droneHubDetails.billing_country}, `
                  : ""}
                {droneHubDetails?.billing_pincode || ""}
              </p>{" "}
            </div>{" "}
            <div>
              <h3>Shipping Address</h3>
              <p>
                {droneHubDetails?.shipping_address_line_1
                  ? `${droneHubDetails.shipping_address_line_1}, `
                  : ""}
                {droneHubDetails?.shipping_address_line_2
                  ? `${droneHubDetails.shipping_address_line_2}, `
                  : ""}
                {droneHubDetails?.shipping_city
                  ? `${droneHubDetails.shipping_city}, `
                  : ""}
                {droneHubDetails?.shipping_district
                  ? `${droneHubDetails.shipping_district}, `
                  : ""}
                {droneHubDetails?.shipping_state
                  ? `${droneHubDetails.shipping_state}, `
                  : ""}
                {droneHubDetails?.shipping_country
                  ? `${droneHubDetails.shipping_country}, `
                  : ""}
                {droneHubDetails?.shipping_pincode || ""}
              </p>{" "}
            </div>{" "}
          </div>
        </div>
        <div className={styles.topContainerRight}>
          <MyMap
            width="100%"
            height="40vh"
            search={
              droneHubDetails?.shipping_aaddress?.pincode ||
              droneHubDetails?.billing_address?.pincode
            }
            loc={loc}
            updLoc={updLoc}
          />
        </div>
      </div>

      <div className={styles.topContainer}>
        <div style={{ width: "70%" }}>
          <div
            className={styles.dataLeft}
            style={{ width: "100%", marginBottom: "20px" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3>Payment & Amount Details</h3>
              <Button
                variant="contained"
                style={{ cursor: "pointer" }}
                onClick={handleInvoiceData}
              >
                {`${
                  droneHubDetails?.invoice_available ? "Download" : "Generate"
                } Invoice`}
              </Button>
            </div>
            <div className={styles.bulletPointsCon}>
              <div>
                <h3>Total Amount </h3> <span>:</span>
                <p>
                  {droneHubDetails?.total_amount
                    ? `₹ ${droneHubDetails?.total_amount}`
                    : "N.A"}
                </p>
              </div>
              <div>
                <h3>Sub Total Amount </h3> <span>:</span>
                <p>
                  {droneHubDetails?.sub_total_amount
                    ? `₹ ${droneHubDetails?.sub_total_amount}`
                    : "N.A"}
                </p>
              </div>
              <div>
                <h3>GST Amount </h3> <span>:</span>
                <p>
                  {droneHubDetails?.gst_amount
                    ? `₹ ${droneHubDetails?.gst_amount}`
                    : "N.A"}
                </p>
              </div>
              <div>
                <h3>Discount Amount </h3> <span>:</span>
                <p>{`₹ ${droneHubDetails?.discount_amount}`}</p>{" "}
              </div>{" "}
              <div>
                <h3>Payment Status </h3> <span>:</span>
                <p>{droneHubDetails?.payment_status_str || "N.A"}</p>
              </div>{" "}
            </div>
          </div>
          {droneHubDetails?.order_product &&
            droneHubDetails?.order_product.length > 0 && (
              <div className={styles.dataLeft} style={{ width: "100%" }}>
                <h3>Ordered Product Details</h3>
                <div className={styles.orderProductsCon}>
                  {droneHubDetails?.order_product.map((item, index) => {
                    return (
                      <div>
                        <h3>{`${index + 1}. ${item?.product?.name}`}</h3>
                        <p>
                          Product Amount:{" "}
                          {item?.product_amount
                            ? `₹ ${item.product_amount}`
                            : "N.A"}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
        </div>

        <div className={styles.paymentDetailsMainCon}>
          <h3 className={styles.paymentTitle}>Payment Details</h3>
          <div className={styles.paymentDetailsCon}>
            <div className={styles.transactionListCon}>
              <div
                style={{
                  background: "rgba(255, 183, 77, 1)",
                }}
                className={styles.transactionCon}
              >
                <h4>Total Booking Amount</h4>
                <p>Incl. GST</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <img src={paymentIcon} alt="payment_icon" />
                  <h3>{`₹  ${paymentData?.total_shop_order_amt}`}</h3>
                </div>
              </div>
              <div
                style={{
                  background: "rgba(139, 195, 74, 1)",
                }}
                className={styles.transactionCon}
              >
                <h4>Total Paid Amount</h4>
                <p>Incl. GST</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <img src={paymentIcon} alt="payment_icon" />
                  <h3>{`₹  ${paymentData?.total_paid_amt}`}</h3>
                </div>{" "}
              </div>{" "}
              <div
                style={{
                  background: "rgba(255, 115, 102, 1)",
                }}
                className={styles.transactionCon}
              >
                <h4>Total Pending Amount</h4>
                <p>Incl. GST</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <img src={paymentIcon} alt="payment_icon" />
                  <h3>{`₹  ${paymentData?.total_pending_amt}`}</h3>
                </div>{" "}
              </div>
            </div>
            {transactionData && transactionData.length > 0 ? (
              <div>
                <h3>Transaction History</h3>

                <div className={styles.withdrawListCon}>
                  {transactionData.map((withdraw, indx) => {
                    return (
                      <div key={indx} className={styles.withdrawCon}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          <div>
                            <h3 className={styles.withdrawBlack}>
                              {withdraw?.order_id}
                            </h3>
                            <h3 className={styles.withdrawGrey}>
                              {" "}
                              {helperdayMonthYearFull(withdraw?.created)},{" "}
                              {extractDateTime(withdraw?.created)}
                            </h3>
                          </div>
                          <div>
                            <h3 className={styles.withdrawBlack}>
                              ₹ {withdraw?.amount}
                            </h3>
                            <button
                              className={styles.downloadBtn}
                              style={{
                                background: `${
                                  withdraw?.payment_status === 4
                                    ? "linear-gradient(to right, rgba(9, 154, 6, 1), rgba(20, 219, 16, 1))"
                                    : "linear-gradient(to right, rgba(200, 8, 8, 1), rgba(255, 124, 116, 1))"
                                }`,
                              }}
                            >
                              {withdraw?.payment_status_str}
                            </button>
                          </div>
                        </div>

                        {withdraw?.transaction_id && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "10px",
                              width: "100%",
                            }}
                          >
                            <h3 className={styles.withdrawRed}>
                              {withdraw?.payment_method_str}
                            </h3>
                            <h3 className={styles.withdrawRed}>
                              {withdraw?.transaction_id || "N.A"}
                            </h3>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div style={{ marginTop: "20px" }}>
                <h3>Transaction History</h3>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "20px",
                    boxSizing: "border-box",
                  }}
                >
                  <img src={noTransaction} />
                  <h1
                    style={{
                      color: "grey",
                      fontSize: "24px",
                      fontWeight: "800",
                      marginBottom: "10px",
                    }}
                  >
                    NO TRANSACTIONS{" "}
                  </h1>
                  <p>You haven't made any transactions yet.</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default DronehubBookingDetails;

const bookingStatusList = [
  {
    id: 1,
    title: "Pending",
  },
  {
    id: 2,
    title: "Confirmed",
  },
  {
    id: 3,
    title: "On Hold",
  },
  {
    id: 4,
    title: "Cancelled",
  },
  {
    id: 5,
    title: "Completed",
  },
  {
    id: 6,
    title: "Out For Delivery",
  },
  {
    id: 7,
    title: "Delivered",
  },
];
