import React, { useContext, useEffect, useState } from "react";
import Container from "../../Component/Container/Container";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import DashboardCard from "../../Component/DashboardCard/DashboardCard";
import CustomTabs3 from "../../Component/CustomTabsNew/CustomTabs3";
import FilterContainer from "../../Component/FitlerContainer/FilterContainer";
import CustomSearchFilter from "../../Component/CustomSearch/CustomSearchFilter";
import CustomSelect from "../../Component/CustomSelect/CustomSelect";
import CustomButton from "../../Component/CustomButton/CustomButton";
import CustomTable from "../../Component/CustomTable/CustomTable";
import CustomPagination from "../../Component/CustomPagination/CustomPagination";
import CustomDate from "../../Component/CustomDate/CustomDate";
import filterIcon from "../../Assets/filterIcon.png";
import { token_api, base_url } from "../../Utils/network";
import styles from "./courseBook.module.css";
import { modifyCourseBookData } from "./utility";
import useFetchData from "../../Component/CustomHook/useFetchData";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import CustomBatchListingChip from "../../Component/CustomBatchListingChip/CustomBatchListingChip";
import CustomBatchChip from "../../Component/CutomBatchChip/CustomBatchChip";
import { useSearchParams } from "react-router-dom";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import SearchAndDropdown from "../../Component/SearchAndDropdown/SearchAndDropdown";
import CustomDatePicker from "../../Component/MaterialDate/MaterialDate";
import { formatDate } from "../../Utils/helper";
import axios from "axios";
import { helperdayMonthYearFull } from "./utility";
import editIcon from "../../Assets/edit_icon.svg";
import deleteIcon from "../../Assets/delete_icon.svg";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";

const CourseBookings = () => {
  const { user_permissions } = useContext(UserCredsContext);
  const { data: locationFetch } = useFetchData(`course/location`);
  const [searchParams, setSearchParams] = useSearchParams();
  const myParam = searchParams.get("batchid");
  const [sortBy, setsortBy] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [filters, setfilters] = useState({ q: "", batch: null });
  const [openDrawer, setOpenDrawer] = useState(false);
  const [advFilter, setAdvFilter] = useState(false);
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const [bookingList, setBookingList] = useState([]);
  const [courseListing, setCourseListing] = useState([]);
  const [batchListing, setBatchListing] = useState([]);
  const [batchPage, setBatchPage] = useState({});
  const [batchForm, setbatchForm] = useState({
    start_date: null,
    end_date: null,
  });
  const [typedValue, setTypedValue] = useState("");
  const [locationTypedValue, setLocationTypedValue] = useState("");
  const [hasEffectRun, setHasEffectRun] = useState(false);

  const [loading, setLoading] = useState(false);

  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <div className="orange-1 bread">Course Bookings</div>,
  ];
  const courseBookingCard = [{}, {}];

  const getCourselisting = () => {
    token_api
      .get(`/course?page_size=100`)
      .then((response) => {
        if (response?.status == 200 || 201) {
          const newdata = response?.data?.data;
          let filterres = newdata?.map((info) => ({
            title: info?.name,
            id: info?.id,
            slug: info?.slug,
          }));
          setCourseListing(filterres);
        }
      })
      .catch((error) => console.log(error));
  };

  const getBatchListing = (value) => {
    let apiEndpoint = "/course/batches";

    if (value) {
      apiEndpoint += `?q=${value}`;
    }

    token_api
      .get(apiEndpoint)
      .then((response) => {
        setBatchListing(response?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  const getLocationList = (value) => {
    let apiEndpoint = "/course/location";

    if (value) {
      apiEndpoint += `?q=${value}`;
    }
    token_api
      .get(apiEndpoint)
      .then((res) => {
        if (res.data.data) {
          let userloc = res.data.data?.map((info) => ({
            id: info?.id,
            // city_new_id: info?.city_new_id,
            title: `${info?.line_1}, ${info?.line_2}, ${info?.area}, ${info?.city_new}, ${info?.state}, ${info?.country}`,
          }));
          setLocationList(userloc);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleRedirect = (id, type = "none") => {
    if (type == "edit") {
      navigate(`/bookings/edit-course-bookings/${id}`);
      return;
    }
    navigate(`/bookings/course-booking-details/${id}`);
  };

  const fetchInvoiceData = (receiveid) => {
    token_api
      .get(`/course/invoice/?course_purchase=${receiveid}`)
      .then((response) => {
        const data = response?.data?.data[0];
        if (data && data.hasOwnProperty("invoice_file")) {
          const pdfUrl = data.invoice_file;
          window.open(pdfUrl, "_blank");
        } else {
          alert("Please Generate Invoice");
        }
      })
      .catch((error) => {
        console.error("Error fetching invoice data:", error);
      });
  };

  const handleDownloadInvoice = (item, type) => {
    if (type === "download") {
      fetchInvoiceData(item?.id);
    } else if (type === "generate") {
      token_api
        .post(`/course/create_invoice_course_book/`, {
          course_purchase_id: item?.id,
        })
        .then((response) => {
          if (response?.status == 200 || response?.status == 201) {
            alert(`Invoice generated successfully!`);
            getAllCourseBookingDetails();
          } else {
            alert(response?.data?.data?.message);
            // throw new Error("Something went wrong");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleDeleteChip = (id, selectName) => {
    setfilters({
      ...filters,
      [selectName]: filters?.[selectName]?.filter((chip) => chip?.id !== id),
    });
  };

  const handleFilter = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setfilters({
        ...filters,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setfilters({
        ...filters,
        [selectName]: [e],
      });
    }
  };

  const handleInputChange = (event, newValue) => {
    setTypedValue(newValue);
    setfilters((prev) => ({
      ...prev,
      batch: newValue,
    }));
  };

  const getAllCourseBookingDetails = async (filterObject) => {
    try {
      const fetchCourseBookings = await token_api.get(`course/course_book`, {
        params: { ...filterObject },
        paramsSerializer: {
          indexes: null,
        },
      });
      if (fetchCourseBookings?.status !== 200) {
        throw new Error("NETWORk Error");
      }

      const modifiedArray = fetchCourseBookings?.data?.data?.map((item) => {
        console.log(item, "hariItem");
        return {
          bookin_status: (
            <div
              onClick={() => handleRedirect(item?.id)}
              style={{ fontSize: "12px", cursor: "pointer" }}
            >
              {item?.purchase_status_str || "N.A"}
            </div>
          ),
          book_id: (
            <div
              onClick={() => handleRedirect(item?.id)}
              style={{ fontSize: "12px", cursor: "pointer" }}
            >
              {item?.id}
            </div>
          ),
          book_date: (
            <div
              style={{ fontSize: "12px", cursor: "pointer" }}
              onClick={() => handleRedirect(item?.id)}
            >{`${helperdayMonthYearFull(item?.created)} `}</div>
          ),
          user_name: (
            <div onClick={() => handleRedirect(item?.id)}>
              <div style={{ fontSize: "12px", cursor: "pointer" }}>
                {" "}
                {item?.user_name}
              </div>
              <div style={{ fontSize: "12px", cursor: "pointer" }}>
                {item?.mobile}
              </div>
            </div>
          ),
          course_name: (
            <div
              style={{ minWidth: "120px", fontSize: "12px", cursor: "pointer" }}
              onClick={() => handleRedirect(item?.id)}
            >
              {item?.course_name}
            </div>
          ),
          batch_name: (
            <div
              style={{ minWidth: "100px", fontSize: "12px", cursor: "pointer" }}
              onClick={() => handleRedirect(item?.id)}
            >
              {item?.batch_name}
            </div>
          ),
          mode: (
            <div
              onClick={() => handleRedirect(item?.id)}
              style={{ fontSize: "12px", cursor: "pointer" }}
            >
              {item?.mode}
            </div>
          ),
          start_date: item?.batch_start_date ? (
            <div
              onClick={() => handleRedirect(item?.id)}
              style={{ fontSize: "12px", cursor: "pointer" }}
            >
              {helperdayMonthYearFull(item?.batch_start_date)}
            </div>
          ) : (
            "N.A"
          ),

          slot_fly: item?.flying_slot?.start_date ? (
            <div
              style={{ minWidth: "160px", fontSize: "12px", cursor: "pointer" }}
              onClick={() => handleRedirect(item?.id)}
            >{`${helperdayMonthYearFull(
              item?.flying_slot?.start_date
            )} to ${helperdayMonthYearFull(
              item?.flying_slot?.end_date
            )} `}</div>
          ) : (
            "N.A"
          ),

          // slot_flying_center: (
          //   <div onClick={() => handleRedirect(item?.id)}>
          //     {item?.flying_slot?.location?.line_1},
          //     {item?.flying_slot?.location?.line_2},
          //     {item?.flying_slot?.location?.area},
          //     {item?.flying_slot?.location?.city},
          //     {item?.flying_slot?.location?.state},
          //     {item?.flying_slot?.location?.country},
          //     {item?.flying_slot?.location?.pincode},
          //   </div>
          // ),
          location: (
            <div
              onClick={() => handleRedirect(item?.id)}
              style={{ fontSize: "12px", cursor: "pointer" }}
            >
              {item?.batch_location?.line_1 &&
                `${item?.batch_location?.line_1}, `}
              {item?.batch_location?.line_2 &&
                `${item?.batch_location?.line_2}, `}
              {item?.batch_location?.area && `${item?.batch_location?.area}, `}
              {item?.batch_location?.city && `${item?.batch_location?.city}, `}
              {item?.batch_location?.state &&
                `${item?.batch_location?.state}, `}
              {item?.batch_location?.country &&
                `${item?.batch_location?.country}, `}
              {item?.batch_location?.pincode}
            </div>
          ),

          payment_status: (
            <div
              onClick={() => handleRedirect(item?.id)}
              style={{ fontSize: "12px", cursor: "pointer" }}
            >
              {item?.payment_status_str || "N.A"}
            </div>
          ),
          amount: (
            <div
              onClick={() => handleRedirect(item?.id)}
              style={{ fontSize: "12px", cursor: "pointer" }}
            >
              <span>&#x20b9;</span> {item?.total_payable || "N.A"}
            </div>
          ),
          accommodation: (
            <div
              onClick={() => handleRedirect(item?.id)}
              style={{ fontSize: "12px", cursor: "pointer" }}
            >
              {item?.hotel_name || "N.A"}
            </div>
          ),
          accomodation_date: item?.accomodation_date ? (
            <div
              onClick={() => handleRedirect(item?.id)}
              style={{ fontSize: "12px", cursor: "pointer" }}
            >
              {helperdayMonthYearFull(item?.accomodation_date)}
            </div>
          ) : (
            "N.A"
          ),

          invoice: (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                columnGap: "10px",
                justifyContent: "center",
              }}
            >
              {item?.invoice_available ? (
                <p
                  className={styles.downloadBtn}
                  style={{ cursor: "pointer", fontSize: "12px" }}
                  onClick={() => handleDownloadInvoice(item, "download")}
                >
                  Download Invoice
                </p>
              ) : (
                <p
                  className={styles.downloadBtn}
                  style={{ cursor: "pointer", fontSize: "12px" }}
                  onClick={() => handleDownloadInvoice(item, "generate")}
                >
                  Generate Invoice
                </p>
              )}
            </div>
          ),

          actions: (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                columnGap: "10px",
                justifyContent: "center",
              }}
            >
              {user_permissions?.update && (
                <img
                  src={editIcon}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleRedirect(item?.id, "edit")}
                />
              )}

              {/* <span
                style={{ cursor: "pointer" }}
                onClick={() => handleRedirect(item?.id)}
              >
                Delete
              </span> */}
            </div>
          ),
        };
      });
      setLoading(false);
      setBookingList(modifiedArray);
      setPaginationData(fetchCourseBookings?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getBatchListingforquery = (value) => {
    return new Promise((resolve, reject) => {
      let apiEndpoint = "/course/batches";

      if (value) {
        apiEndpoint += `?q=${value}`;
      }

      token_api
        .get(apiEndpoint)
        .then((response) => {
          setBatchListing(response?.data?.data);
          resolve(response?.data?.data); // Resolve with the data
        })
        .catch((err) => {
          console.log(err);
          reject(err); // Reject with the error
        });
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const batchData = await getBatchListingforquery(myParam);

        if (batchData && batchData.length > 0) {
          const res = batchData[0];

          setfilters((prevFilters) => ({
            ...prevFilters,
            batch: res,
          }));
        }

        setHasEffectRun(true);
      } catch (error) {
        console.error("Error fetching batch data:", error);
      }
    };

    if (myParam) {
      fetchData();
    }
  }, [myParam]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setLoading(true);
      let filterObject = {};
      if (pageState) {
        filterObject.page = pageState;
      }

      if (filters?.location) {
        filterObject.location = filters?.location?.id;
      }
      if (filters?.q) {
        filterObject.q = filters?.q;
      }

      if (filters?.course) {
        const ids = filters?.course?.map((item) => item?.slug);
        filterObject.course = ids;
      }

      if (filters?.batch) {
        const ids = filters?.batch?.id;
        filterObject.batch = ids;
      }

      if (filters?.payment_status) {
        const ids = filters?.payment_status?.map((item) => item?.id);
        filterObject.payment_status = ids;
      }

      if (filters?.sort) {
        const ids = filters?.sort?.map((item) => item?.id);
        filterObject.ordering = ids;
      }

      if (filters?.booking_status) {
        const ids = filters?.booking_status?.map((item) => item?.id);
        filterObject.booking_status = ids;
      }

      if (batchForm?.start_date) {
        filterObject.booking_date = formatDate(batchForm?.start_date);
      }

      getAllCourseBookingDetails(filterObject);
    }, 500);
    return () => {
      setLoading(false);
      clearTimeout(timeOut);
    };
  }, [
    pageState,
    filters?.q,
    filters?.city,
    filters?.location,
    filters.course,
    filters?.batch,
    filters?.payment_status,
    filters?.booking_status,
    batchForm?.start_date,
    filters?.sort,
  ]);

  useEffect(() => {
    getCourselisting();
  }, []);

  useEffect(() => {
    let timer;

    if (typedValue) {
      timer = setTimeout(() => {
        getBatchListing(typedValue);
      }, 500);
    } else {
      getBatchListing();
    }
    return () => {
      clearTimeout(timer);
    };
  }, [typedValue]);

  useEffect(() => {
    let timer;

    if (locationTypedValue) {
      timer = setTimeout(() => {
        getLocationList(locationTypedValue);
      }, 500);
    } else {
      getLocationList();
    }
    return () => {
      clearTimeout(timer);
    };
  }, [locationTypedValue]);

  const handleChangeforSearch = (event, newValue) => {
    setLocationTypedValue(newValue);
    setfilters((prev) => ({
      ...prev,
      location: newValue,
    }));
  };

  const handleChange = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setbatchForm({
        ...batchForm,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setbatchForm({
        ...batchForm,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setbatchForm({
        ...batchForm,
        [name]: value,
      });
    }
    // const { name, target } = e.target;
  };

  const handleReset = () => {
    setbatchForm((prev) => ({
      ...prev,
      start_date: null,
    }));

    setfilters((prev) => ({
      ...prev,
      city: [],
      course: [],
      batch: null,
      q: "",
      payment_status: [],
      booking_status: [],
      sort: [],
    }));
  };
  const handleNavigateBack = () => {
    navigate(`/dashboard`);
  };

  //Export Logic....

  const handleExportData = async () => {
    try {
      let filterObj = {};

      if (filters?.location) {
        filterObj.location = filters?.location?.id;
      }
      if (filters?.q) {
        filterObj.q = filters?.q;
      }

      if (filters?.course) {
        const ids = filters?.course?.map((item) => item?.slug);
        filterObj.course = ids;
      }

      if (filters?.batch) {
        const ids = filters?.batch?.id;
        filterObj.batch = ids;
      }

      if (filters?.payment_status) {
        const ids = filters?.payment_status?.map((item) => item?.id);
        filterObj.payment_status = ids;
      }

      if (filters?.sort) {
        const ids = filters?.sort?.map((item) => item?.id);
        filterObj.ordering = ids;
      }

      if (filters?.booking_status) {
        const ids = filters?.booking_status?.map((item) => item?.id);
        filterObj.booking_status = ids;
      }

      if (batchForm?.start_date) {
        filterObj.booking_date = formatDate(batchForm?.start_date);
      }

      const response = await axios.get(`${base_url}course/course_book/export`, {
        params: { ...filterObj },
        paramsSerializer: { indexes: null },
      });
      const excelUrl = response.data.data.data.excel_url;
      const link = document.createElement("a");
      link.href = excelUrl;
      link.setAttribute("download", `${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      if (response?.status === 200 || response?.status === 201) {
        alert("Course bookings excel file downloaded successfully!");
        setbatchForm({
          start_date: null,
          end_date: null,
        });
        setfilters({ q: "", batch: null });
      }
    } catch (error) {
      console.error("Error exporting the file", error);
    }
  };

  return (
    <Container>
      <div className="flexBetween">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
            <img src={breadCrumbBack} />
          </div>
          <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
        </div>
        {user_permissions?.write && (
          <Link to="/bookings/create-course-bookings">
            <Button variant="contained" className="btnLinearGradient">
              + Add Course Booking{" "}
            </Button>
          </Link>
        )}
      </div>

      <div style={{ display: "flex", columnGap: "20px" }}></div>
      <div style={{ marginTop: "12px" }}>
        <CustomTabs3 tabHead={tabsForcourseBookings} currentTab={1} />
      </div>

      <FilterContainer style={{ maxHeight: "40px", marginTop: "20px" }}>
        <CustomSearchFilter
          placeholder="Search by booking id, user name, mobile no..."
          value={filters?.q}
          onChange={(e) => {
            setfilters({ ...filters, q: e });
          }}
        />

        <SearchAndDropdown
          label="Filter By Location"
          value={filters?.location}
          onChange={handleChangeforSearch}
          listing={locationList}
          typedValue={locationTypedValue}
          setTypedValue={setLocationTypedValue}
        />
        <CustomSelectChip
          label={"Filter By Course"}
          listArray={courseListing}
          name="course"
          value={filters?.course}
          multiple={false}
          minWidth={"175px"}
          onChange={handleFilter}
          onDelete={handleDeleteChip}
        />

        <CustomBatchChip
          label="Filter By Batch"
          value={filters?.batch}
          onChange={handleInputChange}
          listing={batchListing}
          typedValue={typedValue}
          setTypedValue={setTypedValue}
        />

        <CustomButton
          text={"ADVANCE FILTERS"}
          btnIcon={<img src={filterIcon} alt="adv-filter" />}
          onClick={() => setAdvFilter((prev) => !prev)}
        />
        <CustomButton
          text={"Export"}
          maxWidth="110px"
          onClick={handleExportData}
        />
      </FilterContainer>
      {advFilter && (
        <FilterContainer style={{ maxHeight: "40px", marginTop: "20px" }}>
          <CustomDatePicker
            label="Created Date"
            value={batchForm?.start_date}
            onChange={(newValue) =>
              setbatchForm({
                ...batchForm,
                start_date: newValue,
              })
            }
          />

          <CustomSelectChip
            label={"Filter By Payment Status"}
            listArray={paymentStatuses}
            name="payment_status"
            value={filters?.payment_status}
            multiple={false}
            minWidth={"175px"}
            onChange={handleFilter}
            onDelete={handleDeleteChip}
          />

          <CustomSelectChip
            label={"Filter By Booking Status"}
            listArray={purchaseStatDetails}
            name="booking_status"
            value={filters?.booking_status}
            multiple={false}
            minWidth={"175px"}
            onChange={handleFilter}
            onDelete={handleDeleteChip}
          />

          <CustomSelectChip
            label={"Sort"}
            listArray={orderingList}
            name="sort"
            value={filters?.sort}
            multiple={false}
            minWidth={"175px"}
            onChange={handleFilter}
            onDelete={handleDeleteChip}
          />

          <CustomButton
            text={"Reset All"}
            onClick={() => handleReset()}
            maxWidth="170px"
          />
        </FilterContainer>
      )}
      <div className="totalCountText">
        Total Count : <span>{total_count}</span>
      </div>

      {loading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <CustomTable
            columns={tableColumns}
            data={bookingList}
            handleClick={handleRedirect}
            tableFor="courseOrders"
          />
        </div>
      )}
      <div style={{ marginTop: "15px" }}>
        <CustomPagination max_pages={max_pages} setPageState={setPageState} />
      </div>
    </Container>
  );
};

export default CourseBookings;

const tabsForcourseBookings = [
  {
    id: 1,
    title: "Course Bookings",
  },
];

const tableColumns = [
  {
    id: "bookin_status",
    label: "Booking Status",
  },
  {
    id: "book_id",
    label: "Booking ID",
  },
  {
    id: "book_date",
    label: "Booking Date",
  },
  {
    id: "user_name",
    label: "User Name",
  },
  {
    id: "course_name",
    label: "Course Name",
  },
  {
    id: "batch_name",
    label: "Batch Name",
  },
  {
    id: "mode",
    label: "Mode",
  },
  {
    id: "start_date",
    label: "Start Date",
  },

  {
    id: "slot_fly",
    label: "Flying Slot",
  },

  {
    id: "location",
    label: "Location(City)",
  },
  {
    id: "payment_status",
    label: "Payment Status",
  },
  {
    id: "amount",
    label: "Amount",
  },
  {
    id: "accommodation",
    label: "Accomodation",
  },
  {
    id: "accomodation_date",
    label: "Accomodation Date",
  },

  {
    id: "invoice",
    label: "Invoice",
  },

  {
    id: "actions",
    label: "Actions",
  },
];

const paymentStatuses = [
  { id: 1, title: "Pending" },
  { id: 2, title: "Partially Paid" },
  { id: 3, title: "Paid" },
  { id: 4, title: "Failed" },
];

const purchaseStatDetails = [
  { id: 1, title: "Pending" },
  { id: 2, title: "Confirmed" },
  { id: 3, title: "On Hold" },
  { id: 4, title: "Cancelled" },
];

const orderingList = [
  { id: "index", title: "Ascending" },
  { id: "-index", title: "Descending" },
];
