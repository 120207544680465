import React, { useEffect, useState } from "react";
import Container from "../../Component/Container/Container";
import CustomTabs3 from "../../Component/CustomTabsNew/CustomTabs3";
import styles from "./PilotProfile.module.css";
import UserProfileCard from "../../Component/UserProfileCard/UserProfileCard";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import CourseTakenComponent from "../../Component/CourseTakenComponent/CourseTakenComponent";
import { useParams } from "react-router";
import useFetchData from "../../Component/CustomHook/useFetchData";
import CustomTable from "../../Component/CustomTable/CustomTable";
import CustomPagination from "../../Component/CustomPagination/CustomPagination";
import { Link, useNavigate } from "react-router-dom";
import { token_api } from "../../Utils/network";
// import { modifyCourseBookData } from "../CourseBookings/utility";
import { helperdayMonthYearFull } from "../../Utils/Utils";
import { extractDateTime, getTooltipComponent } from "../../Utils/helper";
import DrawerComp from "../../Component/DrawerComp/DrawerComp";
import EditProfileDetails from "./EditPilotProfile";
import CustomModal from "../../Component/CustomModal/CustomModal";
import dragDrop from "../../Assets/drag_drop.svg";
import cancellogo from "../../Assets/cancel.svg";
import EditPilotPicModel from "./EditPilotPicModel";
import Registration from "../../Component/RegistationDetails/Registration";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import ddUserDummy from "../../Assets/DDuserIcons/dduserDummyProfile.svg";
import editIcon from "../../Assets/userProfile/editIcon.svg";
import ddPersonal from "../../Assets/DDuserIcons/ddPersonal.svg";
import ddArea from "../../Assets/DDuserIcons/ddArea.svg";
import ddAssets from "../../Assets/pilotAssetNew.svg";
import ddAddresses from "../../Assets/DDuserIcons/ddAddresses.svg";
import ddServiceBooking from "../../Assets/DDuserIcons/ddServiceBookings.svg";
import DDUserAreaAlloted from "../../Component/DDUserAreaAlloted/DDUserAreaAlloted";
import DDUsersAssets from "../../Component/DDUserAssets/DDUserAssets";
import DDUserAddress from "../../Component/DDUserAddress/DDUserAddress";
import PilotServiceBooking from "../../Component/PilotServiceBooking/PilotServiceBooking";
import PilotProfileCard from "../../Component/PilotProfileCard/PilotProfileCard";
import DrawerContainer from "../../Component/DrawerContainer/DrawerContainer";
import DrawerTitle from "../../Component/DrawerTitle/DrawerTitle";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import InputFields from "../../Component/InputFields/InputFields";
import { Button } from "@mui/material";
import MyMap from "../../Component/MyMap/MyMap";
import axios from "axios";
import AddressBlock from "../../Component/AddressBlock/AddressBlock";

const UserProfile = () => {
  const [loading, setLoading] = useState(false);
  const [bookingList, setBookingList] = useState([]);
  const navigate = useNavigate();
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const [openPDrawer, setOpenPDrawer] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [profilePic, setProfilePic] = useState({
    avatar: "",
  });
  const [userDataFetch, setUserDataFetch] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const { id: userId } = useParams();
  const [userData, setUserData] = useState({});
  const [userAddresses, setUserAddresses] = useState([]);
  const [serviceBookingData, setServiceBookingData] = useState([]);
  const [pilotDetails, setPilotDetails] = useState({
    id: null,
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    mobile_verified: "",
    email_verified: "",
    // gst: "",
    active: "",
    gender: "",
    location: "",
  });
  const [drawerType, setDrawerType] = useState("");
  const [selectedBlock, setSelectedBlock] = useState([]);
  const [loc, updLoc] = useState({ lat: 20.5937, lng: 78.9629 });

  const [addressData, setAddressData] = useState({
    id: null,
    area: "",
    area_in_acres: "",
    city: "",
    country: "",
    district: "",
    line_1: "",
    line_2: "",
    plot_no: "",
    line_kms: "",
    pincode: "",
    state: "",
    tehsil: "",
  });
  const getPilotDetails = () => {
    token_api
      .get(`users/profile/pilot/${userId}/`)
      .then((response) => {
        if (response?.status === 200) {
          setUserData(response?.data?.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching pilot details:", error);
      });
  };

  const getPilotAddressesList = () => {
    token_api
      .get(`users/address?creator=${userData?.user?.id}`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          setUserAddresses(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (userData?.user?.id) {
      getPilotAddressesList();
    }
  }, [userData?.user?.id]);
  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <Link to="/pilots/pilotslist" className="orange-1 bread breadElement">
      Pilots
    </Link>,
    <div className="orange-1 bread">Pilot Profile</div>,
  ];
  const [tabValue, setTabValue] = useState(1);

  const getServiceBookings = async (filterObject) => {
    setLoading(true);
    setBookingList([]);
    try {
      const fetchCourseBookings = await token_api.get(
        `service/pilots/booked_services?pilot=${userId}`,
        {
          params: { ...filterObject },
          paramsSerializer: {
            indexes: null,
          },
        }
      );
      console.log("FATCHBOOK", fetchCourseBookings);
      if (fetchCourseBookings?.status !== 200) {
        throw new Error("NETWORk Error");
      }

      let modifiedArray = fetchCourseBookings?.data?.data?.map((item) => {
        return {
          id: (
            <div
              onClick={() => handleRedirectService(item?.id)}
              style={{ cursor: "pointer" }}
            >
              {item?.id}
            </div>
          ),
          name: (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleRedirectService(item?.id)}
            >
              <div>{`${item?.full_name}`}</div>
              <div>{`${item?.contact_no}`}</div>
            </div>
          ),
          address: item?.address_line_1 || "N.A",
          date: item?.date || "N.A",
          dealer_code: item?.dealer_code || "N.A",
          block_name: item?.block_name || "N.A",

          time: item?.time || "N.A",
          location: item?.address?.city || "N.A",
          area_in_acres: (
            <p>
              {item?.area_in_acres || "N.A"} /{" "}
              {item?.total_amt ? <span>Rs. {item?.total_amt}</span> : "N.A"}
            </p>
          ),
          created: (
            <div>
              <div>{helperdayMonthYearFull(item?.created)}</div>
              <div>{extractDateTime(item?.created)}</div>
            </div>
          ),
          serviceDate:
            (item?.serviceDate && helperdayMonthYearFull(item?.serviceDate)) ||
            "N.A",
          category:
            getTooltipComponent(item?.bookingaddonservice, "category") || "N.A",
          service: item?.service_name || "N.A",
          drone: item?.drone || "N.A",
          pilot: item?.pilot || "N.A",

          start_date:
            (item?.start_date && helperdayMonthYearFull(item?.start_date)) ||
            "N.A",
          status: item?.status_str || "N.A",

          end_date:
            (item?.end_date && helperdayMonthYearFull(item?.end_date)) || "N.A",
        };
      });
      setServiceBookingData(modifiedArray);
      setBookingList([...modifiedArray]);
      setLoading(false);
      setPaginationData(fetchCourseBookings?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRedirectService = (id) => {
    navigate(`/bookings/service-booking-details/${id}`);
  };

  useEffect(() => {
    if (tabValue == 1) {
      getPilotDetails();
    }
    if (tabValue == 5) {
      getServiceBookings();
    }
  }, [tabValue]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setLoading(true);
      let filterObject = {};
      // filterObject = filters;
      if (pageState) {
        filterObject.page = pageState;
      }
      if (tabValue == 5) {
        getServiceBookings(filterObject);
      }
    }, 500);
    return () => {
      setLoading(false);
      clearTimeout(timeOut);
    };
  }, [pageState]);

  //  profile edit ////////

  const opneProfileDrawer = (user) => {
    setDrawerType("profile");
    setOpenPDrawer(true);
    const {
      id,
      first_name,
      last_name,
      user: { mobile, mobile_verified, email, email_verified },
      joined_date,
      gender,
      location,
      active,
      // gst,
    } = user;

    const gender1 = parseInt(gender);
    let modifiedStatus = "";
    let modifiedMVStatus = "";
    let modifiedEVStatus = "";
    let modifiedGender = "";

    if (active && active[0]) {
      modifiedStatus = [active[0]];
    } else {
      modifiedStatus = statusList.filter((item) => item?.id === active);
    }
    if (mobile_verified && mobile_verified[0]) {
      modifiedMVStatus = [mobile_verified[0]];
    } else {
      modifiedMVStatus = isVerified.filter(
        (item) => item?.id === mobile_verified
      );
    }

    if (email_verified && email_verified[0]) {
      modifiedEVStatus = [email_verified[0]];
    } else {
      modifiedEVStatus = isVerified.filter(
        (item) => item?.id === email_verified
      );
    }

    if (gender1 && gender1[0]) {
      modifiedGender = [gender1[0]];
    } else {
      modifiedGender = genderArray.filter((item) => item?.id === gender1);
    }

    setPilotDetails({
      ...pilotDetails,
      id: id,
      first_name: first_name,
      last_name: last_name,
      mobile_verified: modifiedMVStatus,
      active: modifiedStatus,
      email: email,
      email_verified: modifiedEVStatus,
      gender: modifiedGender,
      // gst: gst,
      mobile: mobile,
      location: location,
    });
  };

  const handleCloseProfileDrawer = () => {
    setOpenPDrawer(false);
    setDrawerType("");
    setSelectedBlock([]);
  };

  // Function to close the modal
  const opneProfileModel = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleNavigateBack = () => {
    navigate(`/pilots/pilotslist`);
  };

  const handleChangeTab = (tabId) => {
    setTabValue(tabId);
  };

  const handleAddNewAddress = () => {
    setDrawerType("address");
    setOpenPDrawer(true);
    setAddressData({
      ...addressData,
      id: null,
      area: "",
      area_in_acres: "",
      city: "",
      country: "",
      district: "",
      line_1: "",
      line_2: "",
      plot_no: "",
      line_kms: "",
      pincode: "",
      state: "",
      tehsil: "",
    });
    setSelectedBlock([]);
  };
  const handleEditAddress = (info, drawertype) => {
    setDrawerType(drawertype);
    setAddressData({
      ...addressData,
      id: info?.id,
      area: info?.area,
      area_in_acres: info?.area_in_acres,
      city: info?.city,
      country: info?.country,
      district: info?.district,
      line_1: info?.line_1,
      line_2: info?.line_2,
      plot_no: info?.plot_no,
      line_kms: info?.line_kms,
      pincode: info?.pincode,
      state: info?.state,
      tehsil: info?.tehsil,
    });
    updLoc((prev) => ({
      ...prev,
      lat: info?.lat,
      lng: info?.long,
    }));
    setSelectedBlock([info?.block]);
    setOpenPDrawer(true);
  };

  console.log(addressData, "hariAddressData");
  const handleDeleteAddress = (addressId) => {
    token_api
      .delete(`users/address/${addressId}/`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("Pilot address deleted successfully!");
          getPilotAddressesList();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setPilotDetails({
        ...pilotDetails,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setPilotDetails({
        ...pilotDetails,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setPilotDetails({
        ...pilotDetails,
        [name]: value,
      });
    }
  };

  const handleDeleteChip = (id, selectName) => {
    setPilotDetails({
      ...pilotDetails,
      [selectName]: pilotDetails?.[selectName]?.filter(
        (chip) => chip?.id !== id
      ),
    });
  };

  const handleAutoCompleteChangeForBlock = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Block already included");
    } else {
      setSelectedBlock(selectedValues);
    }
  };

  console.log(pilotDetails, "hariPilotDEtails");
  const handleSaveDetails = () => {
    const {
      first_name,
      last_name,
      mobile,
      active,
      gender,
      email,
      email_verified,
      mobile_verified,
      // gst,
      location,
    } = pilotDetails;
    let data = {
      // user: {
      //   mobile: mobile,
      //   email: email,
      //   // mobile_verified: pilotDetails?.mobile_verified[0]?.id,
      //   // email_verified: pilotDetails?.email_verified[0]?.id,
      // },
      first_name: first_name,
      last_name: last_name,
      gender: gender[0]?.id,
      active: active[0]?.id,
      // gst: gst,
      location: location,
      mobile: mobile,
      email: email,
      email_verified: email_verified[0]?.id,
      mobile_verified: mobile_verified[0]?.id,
    };

    if (pilotDetails?.id) {
      token_api
        .patch(`users/profile/pilot/${pilotDetails?.id}/`, data)
        .then((res) => {
          if (
            res?.status === 200 ||
            res?.status === 201 ||
            res?.status === 204
          ) {
            alert("pilot profile details updated successfully!");
            getPilotDetails();
            handleCloseProfileDrawer();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    console.log(pilotDetails?.id, "handleSaveDetails");
  };

  const handleChangeAddress = (e) => {
    const { name, value } = e.target;
    setAddressData({
      ...addressData,
      [name]: value,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (addressData?.pincode && addressData?.pincode?.length === 6) {
        try {
          setLoading(true);

          const response = await axios.get(
            `https://api.postalpincode.in/pincode/${addressData?.pincode}`
          );

          if (
            response.data.length !== 0 &&
            response.data[0].PostOffice.length !== 0
          ) {
            const pinData = response.data[0].PostOffice[0];
            const { District, Division, State, Name, Region, Country } =
              pinData;

            setAddressData({
              ...addressData,
              tehsil: Division,
              state: State,
              district: District,
              city: District,
              country: Country,
            });

            // setFormData({
            //   ...formData,
            //   district: Name,
            //   city: Region,
            //   state: State,
            //   country: Country,
            //   pincode: pin,
            // });
            // setShowOther(true);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [addressData?.pincode]);

  const handlePostPilotAddress = (e) => {
    e.preventDefault();
    const data = {
      user_id: userData?.user?.id,
      area: addressData?.area,
      area_in_acres: addressData?.area_in_acres,
      city: addressData?.city,
      country: addressData?.country,
      district: addressData?.district,
      line_1: addressData?.line_1,
      line_2: addressData?.line_2,
      plot_no: addressData?.plot_no,
      line_kms: addressData?.line_kms,
      pincode: addressData?.pincode,
      state: addressData?.state,
      tehsil: addressData?.tehsil,
      lat: loc?.lat,
      long: loc?.lng,
      ...(selectedBlock.length > 0 && {
        block_id: selectedBlock[0]?.id,
      }),
    };
    if (addressData?.id) {
      //PATCH API....
      token_api
        .patch(`users/address/${addressData?.id}/`, data)
        .then((response) => {
          if (
            response?.status === 200 ||
            response?.status === 201 ||
            response?.status === 204
          ) {
            alert("Pilot Address Updated Successfully!");
            setDrawerType("");
            setOpenPDrawer(false);
            setAddressData({});
            getPilotAddressesList();
            setSelectedBlock([]);
          }
        });
    } else {
      //POST API....
      token_api.post(`users/admin/address/`, data).then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("Pilot New Address Add Successfully!");
          setDrawerType("");
          setOpenPDrawer(false);
          setAddressData({});
          getPilotAddressesList();
          setSelectedBlock([]);
        }
      });
    }
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
          <img src={breadCrumbBack} />
        </div>

        <CustomBreadCrumbs breadcrumbs={breadcrumbs} />
      </div>

      <div className={styles.topcards}>
        <div className={styles.leftCon}>
          <div>
            <div className={styles.userProfileIconCon}>
              <img
                src={
                  userData?.avatar ? userData?.avatar : userData?.default_avatar
                }
                style={{ width: "100%" }}
              />
              <img
                src={editIcon}
                className={styles.editIcon}
                onClick={opneProfileModel}
              />
            </div>
            <h3 style={{ textAlign: "center" }}>{userData?.name}</h3>
          </div>
          <div style={{ marginTop: "30px" }}>
            {tabData.map((tab, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    handleChangeTab(tab?.id);
                  }}
                  className={
                    tabValue === tab?.id ? styles.activeTab : styles.normalTab
                  }
                >
                  <img src={tab?.icon} />
                  <p>{tab?.title}</p>
                </div>
              );
            })}
          </div>
        </div>

        <div className={styles.rightCon}>
          {tabValue === 1 && (
            <PilotProfileCard
              user={userData}
              opneProfileDrawer={opneProfileDrawer}
              opneProfileModel={opneProfileModel}
            />
          )}
          {tabValue === 2 && <DDUserAreaAlloted user={userData} />}
          {tabValue === 3 && <DDUsersAssets user={userData} />}
          {tabValue === 4 && (
            <DDUserAddress
              userAddresses={userAddresses}
              handleDeleteAddress={handleDeleteAddress}
              handleEditAddress={handleEditAddress}
              handleAddNewAddress={handleAddNewAddress}
            />
          )}

          {tabValue === 5 &&
            (serviceBookingData.length > 0 ? (
              // <PilotServiceBooking
              //   data={serviceBookingData}
              //   handleClick={handleRedirectService}
              // />
              <div>
                <div>
                  <CustomTable
                    columns={tableColumns}
                    data={serviceBookingData}
                    tableFor="serviceOrders"
                  />{" "}
                </div>
                <div style={{ marginTop: "15px" }}>
                  <CustomPagination
                    max_pages={max_pages}
                    setPageState={setPageState}
                  />
                </div>
              </div>
            ) : (
              <p>No Service Bookings Available</p>
            ))}
        </div>
      </div>

      <DrawerComp
        width="45%"
        open={openPDrawer}
        onClose={handleCloseProfileDrawer}
        anchor={"right"}
      >
        {drawerType === "profile" && (
          <DrawerContainer style={{ marginTop: "0px", marginBottom: "10px" }}>
            <DrawerTitle
              text={`Edit Profile`}
              handleCancelDrw={handleCloseProfileDrawer}
            />
            <div className={styles.inputContainer}>
              <InputFields
                label="First Name"
                required
                name="first_name"
                value={pilotDetails?.first_name}
                onChange={handleChange}
                // error={formErrors?.alt_text}
                // helperText={formErrors?.alt_text}
              />
            </div>
            <div className={styles.inputContainer}>
              <InputFields
                label="Last Name"
                required
                name="last_name"
                value={pilotDetails?.last_name}
                onChange={handleChange}
                // error={formErrors?.alt_text}
                // helperText={formErrors?.alt_text}
              />
            </div>
            <div className={styles.inputContainer}>
              <InputFields
                label="Mobile Number"
                required
                name="mobile"
                value={pilotDetails?.mobile}
                onChange={handleChange}
                // error={formErrors?.contact_no}
              />
            </div>
            <div className={styles.inputContainer}>
              <CustomSelectChip
                multiple={false}
                label="Mobile Number Verified"
                name="mobile_verified"
                onChange={handleChange}
                onDelete={handleDeleteChip}
                value={pilotDetails?.mobile_verified}
                listArray={isVerified}
                // error={formErrors?.redirection}
              />
            </div>
            <div className={styles.inputContainer}>
              <CustomSelectChip
                multiple={false}
                label="Gender"
                name="gender"
                onChange={handleChange}
                onDelete={handleDeleteChip}
                value={pilotDetails?.gender}
                listArray={genderArray}
                // error={formErrors?.redirection}
              />
            </div>
            <div className={styles.inputContainer}>
              <InputFields
                label="Email"
                required
                name="email"
                value={pilotDetails?.email}
                onChange={handleChange}
                // error={formErrors?.contact_no}
              />
            </div>
            <div className={styles.inputContainer}>
              <CustomSelectChip
                multiple={false}
                label="Email Verified"
                name="email_verified"
                onChange={handleChange}
                onDelete={handleDeleteChip}
                value={pilotDetails?.email_verified}
                listArray={isVerified}
                // error={formErrors?.redirection}
              />
            </div>
            {/* <div className={styles.inputContainer}>
              <InputFields
                label="GST no."
                required
                name="gst"
                value={pilotDetails?.gst}
                onChange={handleChange}
                // error={formErrors?.contact_no}
              />
            </div> */}

            <div className={styles.inputContainer}>
              <InputFields
                label="Location"
                required
                name="location"
                value={pilotDetails?.location}
                onChange={handleChange}
                // error={formErrors?.alt_text}
                // helperText={formErrors?.alt_text}
              />
            </div>

            <div className={styles.inputContainer}>
              <CustomSelectChip
                multiple={false}
                label="Pilot Status"
                name="active"
                onChange={handleChange}
                onDelete={handleDeleteChip}
                value={pilotDetails?.active}
                listArray={statusList}
                // error={formErrors?.status}
              />
            </div>
            <div className={styles.inputContainer}>
              <Button
                variant="contained"
                // onClick={() => handleOpenModal(item)}
                onClick={handleSaveDetails}
                // style={{ maxWidth: "170px" }}
              >
                Update
              </Button>
            </div>
          </DrawerContainer>
        )}

        {drawerType === "address" && (
          <DrawerContainer style={{ marginTop: "0px", marginBottom: "10px" }}>
            <DrawerTitle
              text={addressData?.id ? "Edit Address" : "Add Address"}
              handleCancelDrw={handleCloseProfileDrawer}
            />

            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <MyMap
                  width="30vw"
                  height="40vh"
                  search={addressData?.pincode}
                  loc={loc}
                  updLoc={updLoc}
                />
              </div>
            </div>
            <div className={styles.inputContainer}>
              <InputFields
                label="Pincode"
                name="pincode"
                value={addressData?.pincode}
                onChange={handleChangeAddress}
                // error={formErrors?.alt_text}
                // helperText={formErrors?.alt_text}
              />
            </div>

            <div style={{ marginTop: "10px" }}>
              <AddressBlock
                value={selectedBlock}
                onChange={handleAutoCompleteChangeForBlock}
              />
              {/* {formErrors?.selectedBlock && (
                <p style={{ color: "red" }}>{formErrors?.selectedBlock}</p>
              )} */}
            </div>

            <div className={styles.inputContainer}>
              <InputFields
                label="Line 1"
                name="line_1"
                value={addressData?.line_1}
                onChange={handleChangeAddress}
                // error={formErrors?.contact_no}
              />
            </div>
            <div className={styles.inputContainer}>
              <InputFields
                label="Line 2"
                name="line_2"
                value={addressData?.line_2}
                onChange={handleChangeAddress}
                // error={formErrors?.contact_no}
              />
            </div>
            <div className={styles.inputContainer}>
              <InputFields
                label="Area"
                name="area"
                value={addressData?.area}
                onChange={handleChangeAddress}
                // error={formErrors?.alt_text}
                // helperText={formErrors?.alt_text}
              />
            </div>
            <div className={styles.inputContainer}>
              <InputFields
                label="Area In Acres"
                name="area_in_acres"
                value={addressData?.area_in_acres}
                onChange={handleChangeAddress}
                // error={formErrors?.contact_no}
              />
            </div>
            <div className={styles.inputContainer}>
              <InputFields
                label="City"
                name="city"
                value={addressData?.city}
                onChange={handleChangeAddress}
                // error={formErrors?.contact_no}
              />
            </div>

            <div className={styles.inputContainer}>
              <InputFields
                label="Plot No."
                name="plot_no"
                value={addressData?.plot_no}
                onChange={handleChangeAddress}
                // error={formErrors?.contact_no}
              />
            </div>
            <div className={styles.inputContainer}>
              <InputFields
                label="Line In Kms"
                name="line_kms"
                value={addressData?.line_kms}
                onChange={handleChangeAddress}
                // error={formErrors?.contact_no}
              />
            </div>
            <div className={styles.inputContainer}>
              <InputFields
                label="Tehsil"
                name="tehsil"
                value={addressData?.tehsil}
                onChange={handleChangeAddress}
                // error={formErrors?.contact_no}
              />
            </div>
            <div className={styles.inputContainer}>
              <InputFields
                label="District"
                name="district"
                value={addressData?.district}
                onChange={handleChangeAddress}
                // error={formErrors?.contact_no}
              />
            </div>

            <div className={styles.inputContainer}>
              <InputFields
                label="State"
                name="state"
                value={addressData?.state}
                onChange={handleChangeAddress}
                // error={formErrors?.contact_no}
              />
            </div>
            <div className={styles.inputContainer}>
              <InputFields
                label="Country"
                name="country"
                value={addressData?.country}
                onChange={handleChangeAddress}
                // error={formErrors?.contact_no}
              />
            </div>

            <div className={styles.inputContainer}>
              <Button
                variant="contained"
                // onClick={() => handleOpenModal(item)}
                onClick={handlePostPilotAddress}
                // style={{ maxWidth: "170px" }}
              >
                {addressData?.id ? "Update Address" : "Add Address"}
              </Button>
            </div>
          </DrawerContainer>
        )}
      </DrawerComp>

      <CustomModal open={isModalOpen} onClose={handleCloseModal}>
        <EditPilotPicModel
          user={userData}
          userFetchDatanew={getPilotDetails}
          handleCloseModal={handleCloseModal}
        />
      </CustomModal>
    </Container>
  );
};

export default UserProfile;

const tabData = [
  {
    id: 1,
    title: "Personal Details",
    icon: ddPersonal,
  },
  {
    id: 2,
    title: "Area Alloted",
    icon: ddArea,
  },
  {
    id: 3,
    title: "Asset History",
    icon: ddAssets,
  },
  {
    id: 4,
    title: "Addresses",
    icon: ddAddresses,
  },

  {
    id: 5,
    title: "Service Bookings",
    icon: ddServiceBooking,
  },
];

const statusArray = [
  {
    id: 1,
    title: "True",
    city: true,
  },
  {
    id: 2,
    title: "False",
    city: false,
  },
];

const statusList = [
  {
    id: true,
    title: "Active",
  },
  {
    id: false,
    title: "Inactive",
  },
];

const genderArray = [
  {
    id: 1,
    title: "Male",
  },
  {
    id: 2,
    title: "Female",
  },
];

const isVerified = [
  {
    id: true,
    title: "Verified",
  },
  {
    id: false,
    title: "Not verified",
  },
];

const tableColumns = [
  {
    id: "id",
    label: "Service Booking ID",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "created",
    label: "Service Created On",
  },
  {
    id: "dealer_code",
    label: "Dealer Code",
  },
  {
    id: "address",
    label: "Address",
  },

  {
    id: "block_name",
    label: "Block Name",
  },
  {
    id: "area_in_acres",
    label: "Area in acres  / Price ",
  },

  {
    id: "service",
    label: "Service",
  },

  {
    id: "start_date",
    label: "Start date",
  },
  {
    id: "end_date",
    label: "End date",
  },

  {
    id: "status",
    label: "Status",
  },
];
