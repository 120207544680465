export class ApiCallForEvents {
  constructor(token_api) {
    this.token_api = token_api;
  }

  async getAllCourses() {
    try {
      const response = await this.token_api.get(`course?page_size=100`);
      const modifiedCourse = response.data.data?.map((item) => {
        return {
          id: item?.id,
          title: item?.name,
        };
      });
      return modifiedCourse;
    } catch (error) {
      console.error("Error fetching courses:", error.message);
      throw error;
    }
  }

  async getAllPilots() {
    try {
      const response = await this.token_api.get(
        `course/instructor?page_size=1000`
      ); //users/profile/pilot  changed this pilots api to instructors api
      const modifiedData = response.data.data?.map((item) => {
        return {
          id: item?.id,
          title: `${item?.name}`,
        };
      });
      return modifiedData;
    } catch (error) {
      console.error("Error fetching pilots:", error.message);
      throw error;
    }
  }

  async getAllAccommodations() {
    // try {
    //   const response = await this.token_api.get("");
    //   return response.data;
    // } catch (error) {
    //   console.error("Error fetching accommodations:", error.message);
    //   throw error;
    // }
  }

  async getAllUsers() {
    try {
      const response = await this.token_api.get(`users/?page_size=1000`);
      const modifiedData = response.data.data?.map((item) => {
        return {
          id: item?.id,
          title: `${item?.first_name} ${item?.last_name}`,
        };
      });
      return modifiedData;
    } catch (error) {
      console.error("Error fetching users:", error.message);
      throw error;
    }
  }

  async getAllLocations() {
    try {
      const response = await this.token_api.get(
        `course/location?page_size=1000`
      );
      const modifiedData = response.data.data?.map((item) => {
        return {
          id: item?.id,
          title: item?.city,
        };
      });
      return modifiedData;
    } catch (error) {
      console.error("Error fetching locations:", error.message);
      throw error;
    }
  }
  async getAllEventTypes() {
    try {
      const response = await this.token_api.get(`course/event_type`);
      const modifiedData = response.data.data?.map((item) => {
        return {
          id: item?.id,
          title: item?.title,
        };
      });
      return modifiedData;
    } catch (error) {
      console.error("Error fetching locations:", error.message);
      throw error;
    }
  }
  async getAllEventCategories() {
    try {
      const response = await this.token_api.get(`course/event_category`);
      const modifiedData = response.data.data?.map((item) => {
        return {
          id: item?.id,
          title: item?.title,
        };
      });
      return modifiedData;
    } catch (error) {
      console.error("Error fetching locations:", error.message);
      throw error;
    }
  }
  async getAllChapters() {
    try {
      const response = await this.token_api.get(`course/chapter?page_size=100`);
      const modifiedData = response.data.data?.map((item) => {
        return {
          id: item?.id,
          title: item?.name,
        };
      });
      return modifiedData;
    } catch (error) {
      console.error("Error fetching locations:", error.message);
      throw error;
    }
  }

  async getALLApiData() {
    try {
      const courses = await this.getAllCourses();
      const pilots = await this.getAllPilots();
      const accommodations = await this.getAllAccommodations();
      // const users = await this.getAllUsers();
      const locations = await this.getAllLocations();
      const eventTypes = await this.getAllEventTypes();
      const eventCategories = await this.getAllEventCategories();
      const chapters = await this.getAllChapters();

      return {
        courses,
        pilots,
        accommodations,
        // users,
        locations,
        eventTypes,
        eventCategories,
        chapters,
      };
    } catch (error) {
      console.error("Error fetching all API data:", error.message);
      throw error;
    }
  }
}
