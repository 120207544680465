import React, { useState, useEffect, useContext } from "react";
import styles from "./Organization.module.css";
import Container from "../../Component/Container/Container";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import CustomButton from "../../Component/CustomButton/CustomButton";
import CustomSearchFilter from "../../Component/CustomSearch/CustomSearchFilter";
import CustomSelect from "../../Component/CustomSelect/CustomSelect";
import FilterContainer from "../../Component/FitlerContainer/FilterContainer";
import CustomTable from "../../Component/CustomTable/CustomTable";
import CustomPagination from "../../Component/CustomPagination/CustomPagination";
import filterIcon from "../../Assets/filterIcon.png";
import hotelIcon from "../../Assets/hotelsIcon.svg";
import organizationNo from "../../Assets/organizationNo.svg";
import organizationFigma from "../../Assets/organizationFigma.svg";
import DashboardCard from "../../Component/DashboardCard/DashboardCard";
import { Link, useNavigate } from "react-router-dom";
import { token_api, base_url } from "../../Utils/network";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import defaultImg from "../../Assets/defaultImg.svg";
import delete_icon from "../../Assets/delete_icon.svg";
import edit_icon from "../../Assets/edit_icon.svg";
import InputFields from "../../Component/InputFields/InputFields";
import { Button } from "@mui/material";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import SearchAndDropdown from "../../Component/SearchAndDropdown/SearchAndDropdown";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import CustomModal from "../../Component/CustomModal/CustomModal";
import axios from "axios";

const Organization = () => {
  const navigate = useNavigate();
  const { user_permissions } = useContext(UserCredsContext);

  console.log(user_permissions, "organizationUserr");
  const [orgCount, setorgCount] = useState({ orgcdata: "" });
  const [filters, setfilters] = useState({});
  const [typedValue, setTypedValue] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const [organizationList, setOrganizationList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [openDailog, setOpenDailog] = useState(false);
  const [deleteOrg, setDeleteOrg] = useState(null);

  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <div className="orange-1 bread">Organizations</div>,
  ];

  const handleRedirect = (id) => {
    console.log("IFIDCOME", id);
    navigate(`/users/edit/createorganization/${id}`);
  };

  const getOrganizationsList = async (filters) => {
    try {
      const fetchOrganizationData = await token_api.get(`users/organizations`, {
        params: { ...filters },
      });
      if (fetchOrganizationData?.status !== 200) {
        throw new Error("Error while fething Organizations");
      }
      setPaginationData(fetchOrganizationData?.data);
      setorgCount((prev) => ({
        ...prev,
        orgcdata: fetchOrganizationData?.data?.total_count,
      }));

      let modifiedArray = fetchOrganizationData?.data?.data.map((item) => {
        return {
          organizationId: (
            <div
              style={{ cursor: "pointer", fontSize: "12px" }}
              onClick={() => handleRedirect(item?.id)}
            >
              {item?.id}
            </div>
          ),

          organizationName: (
            <p
              style={{ cursor: "pointer", fontSize: "12px" }}
              onClick={() => handleRedirect(item?.id)}
            >
              {item?.name}
            </p>
          ),
          location: (
            <p
              style={{ cursor: "pointer", fontSize: "12px" }}
              onClick={() => handleRedirect(item?.id)}
            >
              {item?.line_1 && `${item.line_1}, `}
              {item?.line_2 && `${item.line_2}, `}
              {item?.area && `${item.area}, `}
              {item?.city && `${item.city}, `}
              {item?.tehsil && `${item.tehsil}, `}
              {item?.district && `${item.district}, `}
              {item?.state && `${item.state}, `}
              {item?.country && `${item.country}, `}
              {item?.pincode && `${item.pincode}`}
            </p>
          ),

          since: (
            <div
              style={{ cursor: "pointer", fontSize: "12px" }}
              onClick={() => handleRedirect(item?.id)}
            >
              {item?.since_year}
            </div>
          ),
          boarding: (
            <div
              style={{ cursor: "pointer", fontSize: "12px" }}
              onClick={() => handleRedirect(item?.id)}
            >
              {item?.on_boarding_date}
            </div>
          ),
          logo: item?.logo ? (
            <img
              src={item?.logo}
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          ) : (
            <img
              src={defaultImg}
              style={{ width: "60px", height: "60px", objectFit: "cover" }}
            />
          ),
          action: (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {user_permissions?.update && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleRedirect(item?.id)}
                >
                  <img src={edit_icon} />
                </div>
              )}

              {user_permissions?.delete && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDeleteorgPopup(item?.id)}
                >
                  <img src={delete_icon} />
                </div>
              )}
            </div>
          ),
        };
      });
      setOrganizationList([...modifiedArray]);
    } catch (error) {
      console.log(error);
    }
  };

  const getLocationList = (value) => {
    let apiEndpoint = "/course/location";

    if (value) {
      apiEndpoint += `?q=${value}`;
    }
    token_api
      .get(apiEndpoint)
      .then((res) => {
        if (res.data.data) {
          console.log(res.data.data, "newresdata");
          let userloc = res.data.data?.map((info) => ({
            id: info?.id,
            title: `${info?.line_1}, ${info?.line_2}, ${info?.area}, ${info?.city_new}, ${info?.state}, ${info?.country}`,
          }));
          setLocationList(userloc);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      let filterObject = {};
      if (pageState) {
        filterObject.page = pageState;
      }
      if (filters?.name) {
        filterObject.q = filters?.name;
      }
      if (filters?.location) {
        filterObject.location = filters?.location[0]?.id;
      }
      if (filters?.year && filters.year.length === 4) {
        filterObject.year = filters.year;
      }

      getOrganizationsList(filterObject);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [pageState, filters?.name, filters?.location, filters?.year]);

  useEffect(() => {
    getLocationList();
  }, []);
  const handleDeleteorg = () => {
    token_api
      .delete(`/users/organizations/${deleteOrg}/`)
      .then((response) => {
        if (response?.status == 204 || response?.status == 201) {
          alert("Organization deleted successfully!");
          setDeleteOrg(null);
          setOpenDailog(false);
          getOrganizationsList();
        }
      })
      .catch((error) => {});
  };

  const handleDeleteorgPopup = (orgId) => {
    setOpenDailog(true);
    setDeleteOrg(orgId);
  };

  const handleCloseModal = () => {
    setOpenDailog(false);
    setDeleteOrg(null);
  };

  const handleNavigateBack = () => {
    navigate(`/dashboard`);
  };

  useEffect(() => {
    let timer;

    if (typedValue) {
      timer = setTimeout(() => {
        getLocationList(typedValue);
      }, 500);
    } else {
      getLocationList();
    }
    return () => {
      clearTimeout(timer);
    };
  }, [typedValue]);

  const handleExportData = async () => {
    try {
      let filterObj = {};
      if (filters?.name) {
        filterObj.q = filters?.name;
      }
      if (filters?.location) {
        filterObj.location = filters?.location[0]?.id;
      }
      if (filters?.year && filters?.year?.length === 4) {
        filterObj.year = filters?.year;
      }

      const response = await axios.get(
        `${base_url}users/organizations/export`,
        {
          params: { ...filterObj },
          paramsSerializer: { indexes: null },
        }
      );
      const excelUrl = response.data.data.data.excel_url;
      const link = document.createElement("a");
      link.href = excelUrl;
      link.setAttribute("download", `${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      if (
        response?.status === 200 ||
        response?.status === 201 ||
        response?.status === 204
      ) {
        alert("Organizations export file downloaded successfully!");
        setfilters({});
      }
    } catch (error) {
      console.error("Error exporting the file", error);
    }
  };
  return (
    <Container style={{ overflowX: "auto" }}>
      <div className="flexBetween">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
            <img src={breadCrumbBack} />
          </div>{" "}
          <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
        </div>

        {user_permissions?.write && (
          <Link to="/users/createorganization">
            <Button variant="contained" className="btnLinearGradient">
              + New Organization{" "}
            </Button>
          </Link>
        )}
      </div>
      <div
        style={{
          display: "flex",
          columnGap: "20px",
          width: "300px",
          margin: "20px 0px",
        }}
      >
        <DashboardCard
          cardTitle="Organization"
          cardIcon={hotelIcon}
          primaryGardient="linear-gradient(rgba(124, 147, 195, 1),
              rgba(160, 181, 224, 0.77))"
          secondaryGradient="linear-gradient(rgba(88, 116, 174, 1),
          rgba(124, 147, 195, 0.42))"
          value="4"
          countvalue={orgCount}
          hint="orgcdata"
          present={true}
        />
      </div>

      <FilterContainer className={styles.filterContainer}>
        <CustomSearchFilter
          placeholder="Search by name of the organization, since year..."
          value={filters?.name}
          onChange={(e) => {
            setfilters({ ...filters, name: e });
          }}
        />
        <InputFields
          label="Fiter By Boarding Year"
          name="year"
          type="number"
          value={filters?.year}
          onChange={(e) => {
            setfilters({ ...filters, year: e.target.value });
          }}
          maxLength={4}
        />
        <CustomButton
          text={"Export"}
          maxWidth="110px"
          onClick={handleExportData}
        />
      </FilterContainer>
      <div className={styles.tableContainer}>
        <CustomTable
          columns={tableColumns}
          data={organizationList}
          handleClick={handleRedirect}
          tableFor="hotels"
        />
      </div>
      <div className={styles.paginationContainer}>
        <CustomPagination max_pages={max_pages} setPageState={setPageState} />
      </div>
      <CustomModal open={openDailog} onClose={handleCloseModal} height="300px">
        <div className="alertBox">
          <h3>Are you sure you want to delete the organization?</h3>
          <div className="alertBtnCon">
            <Button onClick={handleDeleteorg} variant="contained">
              Yes
            </Button>
            <Button onClick={handleCloseModal} variant="contained">
              No
            </Button>
          </div>
        </div>
      </CustomModal>
    </Container>
  );
};
export default Organization;
const tableColumns = [
  {
    id: "organizationId",
    label: "Organization ID",
  },
  {
    id: "organizationName",
    label: "Name Of Organization",
  },

  {
    id: "location",
    label: "Location",
  },

  {
    id: "since",
    label: "Since Year",
  },
  {
    id: "boarding",
    label: "On Boarding Date",
  },
  {
    id: "logo",
    label: "Logo",
  },
  {
    id: "action",
    label: "Action",
  },
];
