import React, { useEffect, useState, useContext } from "react";
import Container from "../../Component/Container/Container";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import FilterContainer from "../../Component/FitlerContainer/FilterContainer";
import CustomSearchFilter from "../../Component/CustomSearch/CustomSearchFilter";
import CustomSelect from "../../Component/CustomSelect/CustomSelect";
import CustomButton from "../../Component/CustomButton/CustomButton";
import CustomDate from "../../Component/CustomDate/CustomDate";
import filterIcon from "../../Assets/filterIcon.png";
import CustomTable from "../../Component/CustomTable/CustomTable";
import { token_api } from "../../Utils/network";
import CustomPagination from "../../Component/CustomPagination/CustomPagination";
import { Checkbox } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardCard from "../../Component/DashboardCard/DashboardCard";
import { serviceOrderCard } from "../../Utils/constants";
import CustomTabs3 from "../../Component/CustomTabsNew/CustomTabs3";
import SearchAndDropdown from "../../Component/SearchAndDropdown/SearchAndDropdown";

import { Button } from "@mui/material";
import {
  extractDateTime,
  getTooltipComponent,
  helperdayMonthYearFull,
  formatDate,
} from "../../Utils/helper";
// import PushNotification from "../../Component/PushNotification/PushNotification";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import CustomDatePicker from "../../Component/MaterialDate/MaterialDate";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";

const DraftBookings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user_permissions } = useContext(UserCredsContext);
  console.log(user_permissions, "draftUserPermissions");

  const [countData, setCountData] = useState({});
  const [filters, setfilters] = useState({
    start_date: null,
    end_date: null,
  });
  const [openDrawer, setOpenDrawer] = useState(false);
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const [bookingList, setBookingList] = useState([]);
  const [tabValue, setTabValue] = useState(5);
  const [categoryList, setCategoryList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [typedValue, setTypedValue] = useState("");

  console.log(page, "yserhsd");
  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <div className="orange-1 bread">Draft Bookings</div>,
  ];

  const handleRedirect = (id) => {
    console.log("IFIDCOME", id);
    navigate(`/bookings/service-booking-details/${id}`);
  };

  const getAllServiceBookings = (finalFilters) => {
    token_api
      .get(`service/booking`, {
        params: { ...finalFilters },

        paramsSerializer: {
          indexes: null,
        },
      })
      .then((response) => {
        setPaginationData(response?.data);
        let modifiedArray = response?.data?.data?.map((item) => {
          // const { bookingaddonservice } = item;

          return {
            // checkBox: (
            //   <Checkbox
            //     checked={false}
            //     // onChange={handleChange}
            //     inputProps={{ "aria-label": "controlled" }}
            //   />
            // ),
            id: (
              <div
                onClick={() => handleRedirect(item?.id)}
                style={{ cursor: "pointer", fontSize: "12px" }}
              >
                {item?.id}
              </div>
            ),
            name: (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => handleRedirect(item?.id)}
              >
                <div style={{ fontSize: "12px" }}>{`${item?.full_name}`}</div>
                <div style={{ fontSize: "12px" }}>{`${item?.contact_no}`}</div>
              </div>
            ),
            // name: item?.full_name + +item?.contact_no,
            date: <div style={{ fontSize: "12px" }}>{item?.date || "N.A"}</div>,
            time: <div style={{ fontSize: "12px" }}>{item?.time || "N.A"}</div>,
            location: (
              <div style={{ fontSize: "12px" }}>
                {item?.address?.city || "N.A"}
              </div>
            ),
            created: (
              <div>
                <div style={{ fontSize: "12px" }}>
                  {helperdayMonthYearFull(item?.created)}
                </div>
                <div style={{ fontSize: "12px" }}>
                  {extractDateTime(item?.created)}
                </div>
              </div>
            ),
            serviceDate:
              (item?.serviceDate && (
                <div style={{ fontSize: "12px" }}>
                  {helperdayMonthYearFull(item?.serviceDate)}
                </div>
              )) ||
              "N.A",
            category:
              getTooltipComponent(item?.bookingaddonservice, "category") ||
              "N.A",
            service:
              getTooltipComponent(item?.bookingaddonservice, "services") ||
              "N.A",
            // drone: item?.drone || "N.A",
            // pilot: item?.pilot || "N.A",

            start_date:
              (item?.start_date && (
                <div style={{ fontSize: "12px" }}>
                  {helperdayMonthYearFull(item?.start_date)}
                </div>
              )) ||
              "N.A",

            end_date:
              (item?.end_date && (
                <div style={{ fontSize: "12px" }}>
                  {helperdayMonthYearFull(item?.end_date)}
                </div>
              )) ||
              "N.A",
          };
        });
        console.log("PAginationData", modifiedArray);
        setBookingList([...modifiedArray]);
      })
      .catch((err) => {
        console.log("BookingErr", err);
      });
  };

  useEffect(() => {
    const timout = setTimeout(() => {
      const finalFilters = {};
      finalFilters.page = pageState;
      finalFilters.q = filters?.q;

      if (filters?.category) {
        const ids = filters?.category?.map((info) => info?.id);
        finalFilters.category = ids;
      }
      if (filters?.service) {
        const ids = filters?.service?.map((info) => info?.slug);
        finalFilters.service = ids;
      }

      if (tabValue) {
        finalFilters.status = tabValue;
      }
      if (filters?.start_date) {
        const filter_start_date = formatDate(filters?.start_date);

        finalFilters.start_date = filter_start_date;
      }

      if (filters?.end_date) {
        const filter_end_date = formatDate(filters?.end_date);

        finalFilters.end_date = filter_end_date;
      }
      if (filters?.location) {
        // finalFilters.location = filters?.location[0]?.id;
        finalFilters.location = filters?.location?.id;
      }

      getAllServiceBookings(finalFilters);
    }, 600);
    return () => clearTimeout(timout);
  }, [
    pageState,
    filters,
    filters?.category,
    filters?.service,
    tabValue,
    filters?.start_date,
    filters?.end_date,
    filters?.location,
  ]);

  function getCount() {
    token_api
      .get(`/service/booking_service_count`)
      .then((response) => {
        let newresponse = response?.data?.data;
        setCountData(newresponse);

        console.log(response, "newres");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getCategory() {
    token_api
      .get(`/service/category`)
      .then((response) => {
        console.log(response?.data?.data, "newcatresponwse");
        setCategoryList(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getServiceList = () => {
    token_api
      .get(`service?page_size=100`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          setServiceList(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getCount();
    getCategory();
    getServiceList();
  }, []);

  console.log(filters, serviceList, "hariServiceList");

  const handleFilter = (e, type, selectName, multiple) => {
    console.log("HANDLERSS", e, type);
    if (type === "select" && selectName && multiple) {
      setfilters({
        ...filters,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setfilters({
        ...filters,
        [selectName]: [e],
      });
    }
  };

  const handleDeleteChip = (id, selectName) => {
    setfilters({
      ...filters,
      [selectName]: filters?.[selectName]?.filter((chip) => chip?.id !== id),
    });
  };

  const handleTabChange = (newTabValue) => {
    console.log(newTabValue, "newtabvalue");
    setPageState(1);

    setTabValue(newTabValue);
  };

  console.log(filters, "filyers");
  const handleNavigateBack = () => {
    navigate(`/dashboard`);
  };

  const handleChangeforSearch = (event, newValue) => {
    setTypedValue(newValue);
    setfilters((prev) => ({
      ...prev,
      location: newValue,
    }));
  };

  const getLocationList = (value) => {
    let apiEndpoint = "/users/address";

    if (value) {
      apiEndpoint += `?q=${value}`;
    }
    token_api
      .get(apiEndpoint)
      .then((res) => {
        if (res.data.data) {
          console.log(res.data.data, "newresdata");
          let userloc = res.data.data?.map((info) => ({
            id: info?.id,
            title: `${info?.line_1}, ${info?.line_2}, ${info?.area}, ${info?.city_new}, ${info?.state}, ${info?.country}`,
          }));
          setLocationList(userloc);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    let timer;

    if (typedValue) {
      timer = setTimeout(() => {
        getLocationList(typedValue);
      }, 100);
    } else {
      getLocationList();
    }
    return () => {
      clearTimeout(timer);
    };
  }, [typedValue]);

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
          <img src={breadCrumbBack} />
        </div>
        <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      ></div>
      <div style={{ display: "flex", columnGap: "20px" }}></div>
      <div style={{ marginTop: "12px" }}>
        <CustomTabs3
          tabHead={tabsForServiceOrders}
          currentTab={tabValue}
          setTabVal={handleTabChange}
        />
      </div>

      <FilterContainer
        style={{ maxHeight: "40px", marginTop: "20px" }}
        // className={`${styles.marginTopBox}`}
      >
        <CustomSearchFilter
          placeholder="Search by name, mobile no..."
          onChange={(e) => {
            setPageState(1);
            setfilters({ ...filters, q: e });
          }}
        />

        <SearchAndDropdown
          label="Filter By Location"
          value={filters?.location}
          onChange={handleChangeforSearch}
          listing={locationList}
          typedValue={typedValue}
          setTypedValue={setTypedValue}
        />

        <CustomDatePicker
          label="Service Start Date"
          value={filters?.start_date}
          onChange={(newValue) =>
            setfilters({
              ...filters,
              start_date: newValue,
            })
          }
          // error={formErrors?.date}
        />

        <CustomDatePicker
          label="Service End Date"
          value={filters?.end_date}
          onChange={(newValue) =>
            setfilters({
              ...filters,
              end_date: newValue,
            })
          }
          // error={formErrors?.date}
        />

        <CustomSelectChip
          multiple={false}
          label="Filter By Service "
          name="service"
          listArray={serviceList}
          onChange={handleFilter}
          onDelete={handleDeleteChip}
          value={filters?.service}
        />

        <CustomSelectChip
          multiple={false}
          label="Filter By Service Category"
          name="category"
          listArray={categoryList}
          onChange={handleFilter}
          onDelete={handleDeleteChip}
          value={filters?.category}
        />

        <CustomButton
          text={"Export"}
          maxWidth="110px"
          // onClick={() => setOpenDrawer(true)}
        />
      </FilterContainer>
      <div className="totalCountText">
        Total Count : <span>{total_count}</span>
      </div>

      <div>
        <CustomTable
          columns={tableColumns}
          data={bookingList}
          handleClick={handleRedirect}
          tableFor="serviceOrders"
        />
      </div>

      <div style={{ marginTop: "15px" }}>
        <CustomPagination max_pages={max_pages} setPageState={setPageState} />
      </div>
      {/* <PushNotification/> */}
    </Container>
  );
};

export default DraftBookings;

const tableColumns = [
  // {
  //   id: "checkBox",
  //   label: "",
  // },
  {
    id: "id",
    label: "Service ID",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "created",
    label: "Service Created",
  },
  // {
  //   id: "serviceDate",
  //   label: "Service Date",
  // },
  // {
  //   id: "time",
  //   label: "Time",
  // },
  {
    id: "location",
    label: "Location(City)",
  },
  {
    id: "category",
    label: "Category",
  },
  {
    id: "service",
    label: "Service",
  },
  // {
  //   id: "drone",
  //   label: "Drone",
  // },
  // {
  //   id: "pilot",
  //   label: "Pilot",
  // },

  {
    id: "start_date",
    label: "Start date",
  },
  {
    id: "end_date",
    label: "End date",
  },
];

const tabsForServiceOrders = [
  {
    id: 5,
    title: "Draft Bookings",
  },
];
