import React, { useState, useEffect } from "react";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import styles from "./UserOtherServiceDetails.module.css";
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import {
  Chip,
  Box,
  InputLabel,
  OutlinedInput,
  Select,
  MenuItem,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { token_api } from "../../Utils/network";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

const theme = createTheme();
const themeone = createTheme({
  palette: {
    primary: {
      main: "#E95E33",
    },
    secondary: {
      main: "#yourSecondaryColor",
    },
  },
});

const UserOtherServiceDetails = ({ handleEditClick, serviceData }) => {
  const { id } = useParams();

  const getStyles = (name, selected, theme) => ({
    fontWeight:
      selected.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  });

  const [questions, setQuestions] = useState([]);
  const [dummyresponse, setDummyres] = useState([]);
  const [userResponses, setUserResponses] = useState({
    other_details_ids: dummyresponse,
  });
  const [details, setDetails] = useState(null);
  const [goodtoGo, setgoodtoGo] = useState(false);

  useEffect(() => {
    setQuestions(serviceData?.other_details);
    setDetails(serviceData);
  }, [serviceData]);

  useEffect(() => {
    const updatedUserResponses = questions.map((question) => ({
      question_id: question?.question?.id,
      selected_choices_ids: question?.selected_choices,
      text_choices: question?.text_answers,
    }));

    setDummyres(updatedUserResponses);
  }, [questions]);

  useEffect(() => {
    setUserResponses({
      other_details_ids: dummyresponse,
    });
  }, [dummyresponse]);

  useEffect(() => {
    const updatedUserResponses = questions.map((question) => ({
      question_id: question?.question?.id,
      selected_choices_ids: question?.selected_choices?.map((info) => info?.id),
      text_choices: question?.text_answers.map(
        ({ text_choice, text_choice_ans }) => ({
          text_choice_id: text_choice?.id,
          text_choice_ans,
        })
      ),
    }));

    setDummyres(updatedUserResponses);
  }, [questions]);

  const handleSave = () => {
    handleEditClick(false, null);
  };

  //   Function to handle user responses
  const handleResponse = (questionId, response, textChoice = null) => {
    setUserResponses((prevResponses) => {
      const updatedResponses = {
        ...prevResponses,
        other_details_ids: prevResponses.other_details_ids.map((detail) => {
          if (detail.question_id === questionId) {
            let updatedChoices = [];
            if (questions?.find((q) => q.id === questionId)?.is_multi) {
              // If is_multi is true, push multiple selected choices
              updatedChoices = response;
            } else {
              // If is_multi is false, allow only a single selected choice
              updatedChoices = response.slice(0, 1);
            }

            let updatedTextChoices = detail.text_choices;
            if (textChoice) {
              const selectedChoice = questions
                ?.find((q) => q.id === questionId)
                .choices?.find((choice) => choice.id === parseInt(textChoice));
              const textChoiceId = selectedChoice?.id || 1; // Use the selected choice's id or default to 1
              updatedTextChoices = [
                ...detail.text_choices,
                { text_choice_id: textChoiceId, text_choice_ans: textChoice },
              ];
            }

            return {
              ...detail,
              selected_choices_ids: updatedChoices,
              text_choices: updatedTextChoices,
            };
          }
          return detail;
        }),
      };

      return updatedResponses;
    });
  };

  const handleTextChoice = (questionId, textChoiceId, textChoiceAns) => {
    setUserResponses((prevResponses) => {
      const updatedResponses = {
        ...prevResponses,
        other_details_ids: prevResponses.other_details_ids.map((detail) => {
          if (detail.question_id === questionId) {
            const existingTextChoices = detail.text_choices.filter(
              (choice) => choice.text_choice_id !== textChoiceId
            );

            return {
              ...detail,
              text_choices: [
                ...existingTextChoices,
                {
                  text_choice_id: textChoiceId,
                  text_choice_ans: textChoiceAns,
                },
              ],
            };
          }
          return detail;
        }),
      };

      return updatedResponses;
    });
    // dispatch(actions.setUserResponses(userResponses));
  };

  const handleChange = (questionId, choiceId, question) => {
    setUserResponses((prevResponses) => {
      const updatedResponses = {
        ...prevResponses,
        other_details_ids: prevResponses.other_details_ids.map((detail) => {
          if (detail.question_id === questionId) {
            let updatedChoices = [];
            if (question.is_multi) {
              // If is_multi is true, toggle the selection
              if (detail.selected_choices_ids.includes(choiceId)) {
                // Remove the choice if it's already selected
                updatedChoices = detail.selected_choices_ids.filter(
                  (id) => id !== choiceId
                );
              } else {
                // Add the choice if it's not selected
                updatedChoices = [...detail.selected_choices_ids, choiceId];
              }
            } else {
              // If is_multi is false, allow only a single selected choice
              updatedChoices = [choiceId];
            }

            return {
              ...detail,
              selected_choices_ids: updatedChoices,
            };
          }
          return detail;
        }),
      };

      return updatedResponses;
    });
  };

  const handleSecondform = () => {
    let data = userResponses?.other_details_ids;
    const newdata = {
      other_details_ids: data,
    };

    token_api
      .patch(`/service/admin_booking/other_detail/${id}/`, newdata)
      .then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          alert("Other service details updated successfully!");
          handleEditClick(false, null);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      {questions.map((question) => (
        <div
          className={` ${styles.margintopfifty}`}
          key={question?.id}
          id={`question-${question?.id}`}
        >
          <p className={styles.questiontitle}>
            {question?.question?.is_required && (
              <span style={{ color: "red", fontWeight: "bold" }}>* </span>
            )}
            {question?.question?.title}{" "}
            <span className={styles.moreInfotitle}>
              {`(${question?.question?.more_info})`}
            </span>
          </p>

          {question?.question.view_type === 1 /* Radio buttons */ && (
            <FormControl component="fieldset">
              <RadioGroup
                aria-label={`question_${question?.question.id}`}
                name={`question_${question?.question.id}`}
                value={
                  userResponses.other_details_ids?.find(
                    (detail) => detail.question_id === question?.question.id
                  )?.selected_choices_ids[0] || ""
                }
                onChange={(e) =>
                  handleResponse(question?.question.id, [
                    parseInt(e.target.value),
                  ])
                }
              >
                {question?.question.choices.map((choice) => (
                  <FormControlLabel
                    key={choice.id}
                    value={choice.id.toString()}
                    control={<Radio />}
                    label={choice.title}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          )}

          {question?.question?.view_type === 2 /* Dropdown */ && (
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id={`multiple-chip-label-${question?.question?.id}`}>
                {question?.question?.title}
              </InputLabel>
              <Select
                labelId={`multiple-chip-label-${question?.question?.id}`}
                id={`multiple-chip-${question?.question?.id}`}
                multiple={question?.question?.is_multi}
                value={
                  userResponses.other_details_ids?.find(
                    (detail) => detail.question_id === question?.question?.id
                  )?.selected_choices_ids || []
                }
                onChange={(e) =>
                  handleResponse(
                    question?.question?.id,
                    Array.isArray(e.target.value)
                      ? e.target.value
                      : [e.target.value]
                  )
                }
                input={
                  <OutlinedInput
                    id={`select-multiple-chip-${question?.question?.id}`}
                    label={question?.question?.title}
                  />
                }
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={
                          question?.question?.choices?.find(
                            (choice) => choice.id === value
                          )?.title
                        }
                      />
                    ))}
                  </Box>
                )}
              >
                {question?.question?.choices.map((choice) => (
                  <MenuItem
                    key={choice.id}
                    value={choice.id}
                    style={getStyles(
                      choice.title,
                      userResponses.other_details_ids?.find(
                        (detail) =>
                          detail.question_id === question?.question?.id
                      )?.selected_choices_ids || [],
                      theme
                    )}
                  >
                    {choice.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {question?.question?.view_type === 3 /* vertical Text Stack */ && (
            <div className={styles.textStack}>
              {question?.question?.text_choices.map((textChoice) => (
                <TextField
                  id="outlined-basic"
                  label={textChoice?.hint}
                  variant="outlined"
                  key={textChoice.id}
                  value={
                    userResponses.other_details_ids
                      ?.find(
                        (detail) =>
                          detail.question_id === question?.question?.id
                      )
                      ?.text_choices?.find(
                        (choice) => choice.text_choice_id === textChoice.id
                      )?.text_choice_ans || ""
                  }
                  onChange={(e) =>
                    handleTextChoice(
                      question?.question?.id,
                      textChoice.id,
                      e.target.value
                    )
                  }
                />
              ))}
            </div>
          )}

          {question?.question?.view_type === 4 /* horizontal Text Stack */ && (
            <div className={styles.textStackhorizontal}>
              {question?.question?.text_choices.map((textChoice) => (
                <TextField
                  id="outlined-basic"
                  label={textChoice?.hint}
                  variant="outlined"
                  key={textChoice.id}
                  value={
                    userResponses.other_details_ids
                      ?.find(
                        (detail) =>
                          detail.question_id === question?.question?.id
                      )
                      ?.text_choices?.find(
                        (choice) => choice.text_choice_id === textChoice.id
                      )?.text_choice_ans || ""
                  }
                  onChange={(e) =>
                    handleTextChoice(
                      question?.question?.id,
                      textChoice.id,
                      e.target.value
                    )
                  }
                />
              ))}
            </div>
          )}

          {question?.question?.view_type === 5 && (
            <div className={styles.displayFlexfruit}>
              {question?.question?.choices.map((choice) => {
                const isChoiceSelected =
                  userResponses.other_details_ids
                    ?.find(
                      (detail) => detail.question_id === question?.question?.id
                    )
                    ?.selected_choices_ids.includes(choice.id) ?? false;

                return (
                  <div
                    key={choice.id}
                    className={` ${styles.fruitsCon}  ${
                      isChoiceSelected ? styles.selectedFruit : ""
                    }`}
                    onClick={() =>
                      handleChange(
                        question?.question?.id,
                        choice.id,
                        question?.question
                      )
                    }
                  >
                    <div className={`${styles.fruitrealimg}`}>
                      <img
                        src={choice.icon}
                        alt={choice.title}
                        style={{ width: "35px" }}
                      />
                    </div>

                    <p
                      className={`${styles.marginzero} ${
                        isChoiceSelected ? styles.selectedFruittext : ""
                      }`}
                    >
                      {choice.title.length > 10
                        ? `${choice.title.slice(0, 10)}...`
                        : choice.title}
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      ))}

      <button
        className={`${styles.saveandnextbtn} ${styles.colorprimary}`}
        onClick={handleSecondform}
      >
        Save & Next
      </button>
    </div>
  );
};

export default UserOtherServiceDetails;
