export const validateBatch = (
  value,
  isOfflineMode,
  switchValue,
  isDescriptionsEmpty
) => {
  return new Promise((resolve, reject) => {
    const {
      name,
      course,
      start_date,
      end_date,
      start_time,
      end_time,
      mrp,
      selling_price,
      total_seats,
      location,
      days_of_weeks,
      mode,
      language,
      flying_start_date,
      flying_end_date,
      flying_slot_id,
      zoom_link,
      instructors,
    } = value;

    const errors = { error: false };

    if (isOfflineMode) {
      if (switchValue) {
        // If isOfflineMode is true and switchValue is true
        if (!flying_start_date) {
          errors.flying_start_date = "Enter start date";
          errors.error = true;
        }
        if (!flying_end_date) {
          errors.flying_end_date = "Enter end date";
          errors.error = true;
        }
        // Do additional validations for flying_start_date and flying_end_date
      } else {
        // If isOfflineMode is true and switchValue is false
        if (!flying_slot_id[0] || !flying_slot_id) {
          errors.flying_slot_id = "Enter flying details";
          errors.error = true;
        }
      }
    }

    if (isDescriptionsEmpty) {
      errors.descriptions = "Enter description";
      errors.error = true;
    }

    if (!name) {
      errors.name = "Enter batch name";
      errors.error = true;
    }

    if (!start_date) {
      errors.start_date = "Enter start date";
      errors.error = true;
    }
    if (start_date > end_date) {
      errors.start_date =
        "Batch start date should not be greater than end date";
      errors.error = true;
    }

    if (!end_date) {
      errors.end_date = "Enter end date";
      errors.error = true;
    }

    if (end_date < start_date) {
      errors.end_date = "Batch end date should not be less than start date";
      errors.error = true;
    }

    if (!start_time) {
      errors.start_time = "Enter start time";
      errors.error = true;
    }

    if (!end_time) {
      errors.end_time = "Enter end time";
      errors.error = true;
    }

    if (!mrp) {
      errors.mrp = "Enter MRP ";
      errors.error = true;
    }

    if (!selling_price) {
      errors.selling_price = "Enter selling price";
      errors.error = true;
    }

    if (!total_seats) {
      errors.total_seats = "Enter total seats";
      errors.error = true;
    }

    if (mode[0]?.id !== 1) {
      if (!location || !location[0]) {
        errors.location = "Enter location";
        errors.error = true;
      }
    }
    if (!days_of_weeks || !days_of_weeks[0]) {
      errors.days_of_weeks = "Enter days of the week";
      errors.error = true;
    }

    if (!mode || !mode[0]) {
      errors.mode = "Enter mode";
      errors.error = true;
    }
    if (!language || !language[0]) {
      errors.language = "Enter mode of language";
      errors.error = true;
    }
    if (!course || !course[0]) {
      errors.course = "please select course";
      errors.error = true;
    }

    if (mode[0]?.id !== 2) {
      if (!zoom_link) {
        errors.zoom_link = "Please enter zoom link";
        errors.error = true;
      }
    }

    if (!instructors || !instructors[0]) {
      errors.instructors = "Please select instructor";
      errors.error = true;
    }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};

// Example usage:
const valuesToValidate = {
  // ... your values here ...
};

// validateBatch(valuesToValidate)
//   .then((successMessage) => {
//     console.log(successMessage);
//   })
//   .catch((validationErrors) => {
//     console.log("Validation failed. Errors:", validationErrors);
//   });

export const validateMedia = (value) => {
  let errorCheck = false;
  const validData = value?.map((item) => {
    if (!item?.headline) {
      item.headline_error = "Add Headline";
      errorCheck = true;
    }
    if (!item?.tagline) {
      item.tagline_error = "Add Tagline";
      errorCheck = true;
    }
    if (!item?.index || item?.index === 0) {
      item.index_error = "Add Index";
      errorCheck = true;
    }

    return item;
  });
  console.log("VALIDDATA", validData, errorCheck);

  return {
    errorCheck,
    validData,
  };
};

export const validateCourse = (value) => {
  return new Promise((resolve, reject) => {
    const { slug, name, descriptions, duration } = value;

    const errors = { error: false };

    // Check if slug is empty
    if (!slug || slug.trim() === "") {
      errors.slug = "Slug cannot be empty";
      errors.error = true;
    }
    if (!name) {
      errors.name = "Please enter course name";
      errors.error = true;
    }
    if (!descriptions) {
      errors.descriptions = "Please enter description";
      errors.error = true;
    }
    if (!duration) {
      errors.duration = "Please enter duration";
      errors.error = true;
    }

    // Check if slug contains '/'
    if (slug && slug.includes("/")) {
      errors.slug = "Slug cannot contain '/'";
      errors.error = true;
    }

    // if (!mode || !mode[0]) {
    //   errors.mode = "Enter mode";
    //   errors.error = true;
    // }
    // if (!language || !language[0]) {
    //   errors.language = "Enter mode of language";
    //   errors.error = true;
    // }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};
