import React, { useEffect, useState } from "react";
import styles from "./VehicleInventory.module.css";
import FilterContainer from "../FitlerContainer/FilterContainer";
import CustomTable from "../CustomTable/CustomTable";
import CustomPagination from "../CustomPagination/CustomPagination";
import CustomSearchFilter from "../CustomSearch/CustomSearchFilter";
import { token_api, base_url } from "../../Utils/network";
import editIcon from "../../Assets/edit_icon.svg";
import deleteIcon from "../../Assets/delete_icon.svg";
import { Button } from "@mui/material";
import DrawerComp from "../DrawerComp/DrawerComp";
import DrawerContainer from "../DrawerContainer/DrawerContainer";
import DrawerTitle from "../DrawerTitle/DrawerTitle";
import CustomDatePicker from "../MaterialDate/MaterialDate";
import InputFields from "../InputFields/InputFields";
import CustomSelectChip from "../CustomSelectChip/CustomSelectChip";
import dayjs from "dayjs";
import { formatDate } from "../../Utils/helper";
import { Validate } from "./Utility";
import DronePilot from "../../Component/DronePilot/DronePilot";
import CustomCard from "../../Component/CustomCard/CustomCard";
import pilotUser from "../../Assets/pilotUser.svg";
import dronePilot from "../../Assets/dronePilot.svg";
import vehicleInv from "../../Assets/vehicleInv.svg";
import droneInventoryBook from "../../Assets/droneInventoryBook.svg";
import CustomModal from "../CustomModal/CustomModal";
import { getStatusStyles } from "../../Utils/Utils";
import blackArrow from "../../Assets/breadCrumbBack.svg";
import axios from "axios";
import CustomButton from "../CustomButton/CustomButton";

const VehicleInventory = () => {
  const [vehicleInventoryList, setVehicleInventoryList] = useState([]);
  const [filters, setfilters] = useState({});
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const [vehicleList, setVehicleList] = useState([]);
  const [vehicleForm, setVehicleForm] = useState({
    id: null,
    registration_date: null,
    vehicle_no: "",
    vehicle_id: [],
    status: [],
  });
  const [open, setOpen] = useState(false);
  const [assignOpen, setAssignOpen] = useState(false);

  const [assignPilot, setAssignPilot] = useState({
    vehicle_inventory_id: null,
    pilot_id: null,
    assigned_from: null,
    assigned_to: null,
  });
  const [openDailog, setOpenDailog] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deletedVehicle, setDeletedVehicle] = useState(null);
  const [formErrors, setformErrors] = useState({});
  const [pilotSelected, setPilotSelected] = useState([]);
  const [cardData, setCardData] = useState({
    assign: { name: "Assigned", value: 0 },
    not_assign: { name: "Not Assigned", value: 0 },
    working: { name: "Working", value: 0 },
    damaged: { name: "Damaged", value: 0 },
    sold: { name: "Sold", value: 0 },
    repair: { name: "Under Repair", value: 0 },
    at_base: { name: "At Base", value: 0 },
    on_field: { name: "On Field", value: 0 },
    training: { name: "Training", value: 0 },
    survey_service: { name: "Survey Service", value: 0 },
    agri_service: { name: "Agri Service", value: 0 },
    no_category_assign: { name: "No Application Assigned", value: 0 },
  });

  const [assignInventoryObj, setAssignInventoryObj] = useState({});
  const [VehicleAssignPilotHistory, setVehicleAssignPilotHistory] = useState(
    []
  );

  const [vehicleAssignPilot, setvehicleAssignPilot] = useState({
    id: null,
    assigned_from: null,
    assigned_to: null,
    pilot: [],
  });

  const handleAdd = () => {
    setOpen(true);
    setVehicleForm({
      ...vehicleForm,
      id: null,
      registration_date: null,
      vehicle_no: "",
      vehicle_id: [],
      status: [],
    });
    setformErrors({});
    setPilotSelected([]);
  };
  const handleEditVehicle = (item) => {
    const { id, vehicle, vehicle_no, registration_date, inv_status } = item;

    setVehicleForm({
      ...vehicleForm,
      id: id,
      registration_date: dayjs(registration_date),
      vehicle_no,

      vehicle_id: [vehicle]?.map((info) => ({
        title: info?.name,
        id: info?.id,
      })),
      status: statusOptions?.filter((info) => info?.id === inv_status),
    });

    setOpen(true);
    setformErrors({});
  };

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return "Working";
      case 2:
        return "Sold";
      case 3:
        return "Damaged";
      case 4:
        return "Under Repair";
      default:
        return "Unknown";
    }
  };
  const handleDeleteVehicle = () => {
    token_api
      .delete(`drone/vehicle/inventory/${deletedVehicle}/`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("Vehicle Inventory Deleted Successfully!");
          setIsModalOpen(false);
          setDeletedVehicle(null);
          getVehicleInventoryList();
          setPilotSelected([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteVehicleModal = (id) => {
    setDeletedVehicle(id);
    setIsModalOpen(true);
  };

  const handleCloseModal1 = () => {
    setIsModalOpen(false);
    setDeletedVehicle(null);
  };
  const getVehicleInventoryList = async (filterobject) => {
    try {
      const fetchVehicleInventory = await token_api.get(
        `drone/vehicle/inventory`,
        {
          params: { ...filterobject },
        }
      );

      if (fetchVehicleInventory.status !== 200) {
        throw new Error("Error in fetching");
      }
      setPaginationData(fetchVehicleInventory?.data);
      let modifiedArray = fetchVehicleInventory?.data?.data?.map((item) => {
        const droneStatusStyles = getStatusStyles(item?.inv_status);

        return {
          id: (
            <p
              onClick={() => {
                handleToShowAssignList(item);
              }}
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                fontSize: "12px",
              }}
            >
              {item?.id}
            </p>
          ),

          // creator_id: <p>{item?.created_by}</p>,
          vehicle_number: (
            <p style={{ fontSize: "12px" }}>{item?.vehicle_no}</p>
          ),
          vehicle_id: <p style={{ fontSize: "12px" }}>{item?.vehicle?.id}</p>,
          vehicle_name: (
            <p style={{ fontSize: "12px" }}>{item?.vehicle?.name}</p>
          ),

          registered_on: (
            <p style={{ fontSize: "12px" }}>
              {item?.registration_date || "N.A"}
            </p>
          ),
          assign: (
            <Button
              variant="contained"
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleOpenAssignPilot(item?.id);
              }}
            >
              Assign
            </Button>
          ),

          inv_status: (
            <span
              style={{
                padding: "5px",
                borderRadius: "50px",
                ...droneStatusStyles,
                minWidth: "60%",
                font: "bold",
                display: "inline-block",
                width: "100px",
                fontSize: "12px",
              }}
            >
              {getStatusText(item?.inv_status)}
            </span>
          ),
          action: (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <div
                style={{ cursor: "pointer" }}
                onClick={(e) => handleEditVehicle(item)}
              >
                <img src={editIcon} />
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => handleDeleteVehicleModal(item?.id)}
              >
                <img src={deleteIcon} />
              </div>
            </div>
          ),
        };
      });
      setVehicleInventoryList([...modifiedArray]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseModal = () => {
    setOpenDailog(false);
    setAssignPilot({
      vehicle_inventory_id: null,
      pilot_id: null,
      assigned_from: null,
      assigned_to: null,
    });
  };

  const handleOpenAssignPilot = (inventoryId) => {
    // setPageState(1);
    setOpenDailog(true);
    setAssignPilot({
      ...assignPilot,
      vehicle_inventory_id: inventoryId,
      assigned_from: null,
      assigned_to: null,
    });
    setPilotSelected([]);
  };
  useEffect(() => {
    const timeOut = setTimeout(() => {
      let filtersObject = {};
      if (pageState) {
        filtersObject.page = pageState;
      }
      if (filters?.q) {
        filtersObject.q = filters?.q;
      }
      if (filters?.vehicle_no) {
        filtersObject.vehicle_no = filters?.vehicle_no;
      }
      if (filters?.vehicle) {
        const ids = filters?.vehicle?.map((info) => info?.id);
        filtersObject.vehicle = ids[0];
      }
      if (filters?.status) {
        const ids = filters?.status?.map((info) => info?.id);
        filtersObject.status = ids[0];
      }

      if (filters?.deployment && filters?.deployment[0]) {
        if (filters?.deployment[0]?.id === 1) {
          filtersObject.at_base = true;
        } else if (filters?.deployment[0]?.id === 2) {
          filtersObject.on_field = true;
        }
      }

      getVehicleInventoryList(filtersObject);
    }, 500);

    return () => clearTimeout(timeOut);
  }, [
    pageState,
    filters?.q,
    filters?.vehicle_no,
    filters?.vehicle,
    filters?.status,
    filters?.deployment,
  ]);

  const handleText = (e) => {
    const { name, value } = e.target;
    setVehicleForm({
      ...vehicleForm,
      [name]: value,
    });
  };

  const handleChange = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setVehicleForm({
        ...vehicleForm,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setVehicleForm({
        ...vehicleForm,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setVehicleForm({
        ...vehicleForm,
        [name]: value,
      });
    }
  };

  const handleDeleteChip = (id, selectName) => {
    setVehicleForm({
      ...vehicleForm,
      [selectName]: vehicleForm?.[selectName]?.filter(
        (chip) => chip?.id !== id
      ),
    });
  };

  const getVehicleList = () => {
    token_api
      .get(`drone/vehicles`)
      .then((res) => {
        if (res.data.data) {
          let newres = res?.data?.data.map((info) => ({
            title: info?.name,
            id: info?.id,
          }));

          setVehicleList(newres);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleAutocompleteChangeforPilot = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Pilot already included");
    } else {
      setPilotSelected(selectedValues);
    }
  };

  const handleAutocompleteChangeforVehiclePilot = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Pilot already included");
    } else {
      setvehicleAssignPilot({
        ...vehicleAssignPilot,
        ["pilot"]: selectedValues,
      });
    }
  };

  const getDashboardCount = () => {
    token_api
      .get(`drone/vehicle_inventory/dashboard_count`)
      .then((res) => {
        if (res.data.data) {
          const {
            assign,
            not_assign,
            damaged,
            sold,
            working,
            under_repair,
            agri_service,
            no_category_assign,
            survey_service,
            training,
            at_base,
            on_field,
          } = res.data.data;
          const _data = {
            assign: { name: "Assigned", value: assign },
            not_assign: { name: "Not Assigned", value: not_assign },
            damaged: { name: "Damaged", value: damaged },
            working: { name: "Working", value: working },
            sold: { name: "Sold", value: sold },
            repair: { name: "Under Repair", value: under_repair },
            at_base: { name: "At Base", value: at_base },
            on_field: { name: "On Field", value: on_field },
            training: { name: "Training", value: training },
            survey_service: { name: "Survey Service", value: survey_service },
            agri_service: { name: "Agri Service", value: agri_service },
            no_category_assign: {
              name: "No Application Assigned",
              value: no_category_assign,
            },
          };
          setCardData(_data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getVehicleList();
    getDashboardCount();
  }, []);

  const handleAddVehicleInventory = () => {
    let data = {
      registration_date: formatDate(vehicleForm?.registration_date),
      vehicle_no: vehicleForm?.vehicle_no,
      inv_status: vehicleForm?.status[0]?.id,
      vehicle_id: vehicleForm?.vehicle_id[0]?.id,
    };

    Validate(vehicleForm)
      .then((response) => {
        if (response == "success") {
          if (vehicleForm?.id) {
            token_api
              .patch(`/drone/vehicle/inventory/${vehicleForm?.id}/`, data)
              .then((res) => {
                if (
                  res?.status == 200 ||
                  res?.status == 201 ||
                  res?.status == 204
                ) {
                  alert("Vehicle Inventory Updated Successfully!");
                  getVehicleInventoryList();
                  setVehicleForm({
                    id: null,
                    vehicle_no: "",
                    vehicle_id: [],
                    registration_date: null,
                    status: [],
                  });

                  setOpen(false);
                  setPilotSelected([]);
                }
              })
              .catch((err) => console.log(err));
          } else {
            token_api
              .post(`/drone/vehicle/inventory/`, data)
              .then((res) => {
                if (res?.status == 200 || res?.status == 201) {
                  alert("Vehicle Inventory Created Successfully!");
                  getVehicleInventoryList();
                  setVehicleForm({
                    id: null,
                    vehicle_id: [],
                    vehicle_no: "",
                    registration_date: null,
                    status: [],
                  });

                  setOpen(false);
                  setPilotSelected([]);
                }
              })
              .catch((err) => console.log(err));
          }
        }
      })
      .catch((error) => {
        setformErrors(error);
      });
  };

  const handleChangeacc = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setfilters({
        ...filters,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setfilters({
        ...filters,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setfilters({
        ...filters,
        [name]: value,
      });
    }
    // const { name, target } = e.target;
  };

  const handleToShowAssignList = (inventoryobj) => {
    setPageState(1);
    setAssignInventoryObj(inventoryobj);
  };

  const handleDeleteChipacc = (id, selectName) => {
    setfilters({
      ...filters,
      [selectName]: filters?.[selectName]?.filter((chip) => chip?.id !== id),
    });
  };

  const handleAssignPilot = () => {
    let data = {
      vehicle_inventory_id: assignPilot?.vehicle_inventory_id,
      pilot_id: pilotSelected[0]?.id,
      assigned_from: formatDate(assignPilot?.assigned_from),
      assigned_to: formatDate(assignPilot?.assigned_to),
    };

    token_api
      .post(`drone/pilot/vehicle_assign/`, data)
      .then((res) => {
        if (res.status == 400) {
          alert(res?.data?.error?.fields[0]?.message[0]);
          setOpenDailog(false);
          setAssignPilot({
            vehicle_inventory_id: null,
            pilot_id: null,
            assigned_from: null,
            assigned_to: null,
          });
          setPilotSelected([]);
          setPageState(1);
          getInventoryAssignedList();
        }
        if (res.status == 200 || res.status == 201 || res.status == 204) {
          alert("Pilot Assigned Successfully!");
          setOpenDailog(false);
          // getVehicleInventoryList();
          setAssignPilot({
            vehicle_inventory_id: null,
            pilot_id: null,
            assigned_from: null,
            assigned_to: null,
          });
          setPilotSelected([]);
          if (!assignInventoryObj?.id) {
            setPageState(1);
            getVehicleInventoryList();
          } else {
            setPageState(1);

            getInventoryAssignedList();
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleEditVehicleAssign = (item) => {
    setvehicleAssignPilot({
      ...vehicleAssignPilot,
      id: item?.id,
      assigned_from: dayjs(item?.assigned_from),
      assigned_to: dayjs(item?.assigned_to),
      pilot: [item?.pilot],
    });
    setAssignOpen(true);
  };

  const handleDeleteVehicleAssign = (assignId) => {
    token_api
      .delete(`drone/pilot/vehicle_assign/${assignId}/`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("Vehicle Assigned pilot deleted!");

          setAssignOpen(false);
          getInventoryAssignedList();
        }
      });
  };

  const getInventoryAssignedList = async (filterobject) => {
    try {
      const fethLaptopInventoryAssignedList = await token_api.get(
        `drone/pilot/vehicle_assign?vehicle_inventory=${assignInventoryObj?.id}`,
        {
          params: { ...filterobject },
        }
      );

      if (fethLaptopInventoryAssignedList.status !== 200) {
        throw new Error("Error in fetching");
      }
      setPaginationData(fethLaptopInventoryAssignedList?.data);
      let modifiedArray = fethLaptopInventoryAssignedList?.data?.data?.map(
        (item) => {
          return {
            assigned_to: (
              <p
                style={{ fontSize: "12px" }}
              >{`${item?.pilot?.user?.first_name} ${item?.pilot?.user?.last_name}`}</p>
            ),
            assigned_from: (
              <p style={{ fontSize: "12px" }}>{item?.assigned_from || "N.A"}</p>
            ),
            assigned_till: (
              <p style={{ fontSize: "12px" }}>{item?.assigned_to || "N.A"}</p>
            ),
            handover_accepted_on: (
              <p style={{ fontSize: "12px" }}>
                {item?.handover_datetime
                  ? formatDate(item?.handover_datetime)
                  : "N.A"}
              </p>
            ),
            handover_accepted_by: (
              <p style={{ fontSize: "12px" }}>
                {item?.handover_accept_name || "N.A"}
              </p>
            ),
            updated_on: (
              <p style={{ fontSize: "12px" }}>
                {item?.modified ? formatDate(item?.modified) : "N.A"}
              </p>
            ),
            modified_by: (
              <p style={{ fontSize: "12px" }}>{item?.updated_by || "N.A"}</p>
            ),
            assign_by: (
              <p style={{ fontSize: "12px" }}>{item?.assign_by || "N.A"}</p>
            ),
            created_on: (
              <p style={{ fontSize: "12px" }}>
                {item?.created ? formatDate(item?.created) : "N.A"}
              </p>
            ),
            // assign: (
            //   <Button
            //     variant="contained"
            //     style={{ cursor: "pointer" }}
            //     onClick={() => {
            //       handleOpenAssignPilot(assignInventoryObj?.id);
            //     }}
            //   >
            //     Assign
            //   </Button>
            // ),

            action: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleEditVehicleAssign(item)}
                >
                  <img src={editIcon} />
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDeleteVehicleAssign(item?.id)}
                >
                  <img src={deleteIcon} />
                </div>
              </div>
            ),
          };
        }
      );
      setVehicleAssignPilotHistory([...modifiedArray]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (assignInventoryObj?.id) {
      const timeOut = setTimeout(() => {
        let filtersObject = {};

        if (pageState) {
          filtersObject.page = pageState;
        }

        getInventoryAssignedList(filtersObject);
      }, 500);

      return () => clearTimeout(timeOut);
    }
  }, [pageState, assignInventoryObj?.id]);

  const handleHideAssignHistory = () => {
    setAssignInventoryObj({});
    // setPageState(1);
    setfilters({});

    // getVehicleInventoryList();
  };

  const handleUpdateVehicleAssignPilot = () => {
    let data = {
      pilot_id: vehicleAssignPilot?.pilot[0]?.id,
      assigned_from: formatDate(vehicleAssignPilot?.assigned_from),
      assigned_to: formatDate(vehicleAssignPilot?.assigned_to),
    };
    token_api
      .patch(`drone/pilot/vehicle_assign/${vehicleAssignPilot?.id}/`, data)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("Vehicle ASsign Details updated");
          setvehicleAssignPilot({
            id: null,
            assigned_from: null,
            assigned_to: null,
            pilot: [],
          });
          setAssignOpen(false);
          getInventoryAssignedList();
        }
      });
  };

  //Export Logic....

  const handleExportData = async () => {
    try {
      let filterObj = {};

      if (filters?.q) {
        filterObj.q = filters?.q;
      }
      if (filters?.vehicle_no) {
        filterObj.vehicle_no = filters?.vehicle_no;
      }
      if (filters?.vehicle) {
        const ids = filters?.vehicle?.map((info) => info?.id);
        filterObj.vehicle = ids[0];
      }
      if (filters?.status) {
        const ids = filters?.status?.map((info) => info?.id);
        filterObj.status = ids[0];
      }

      if (filters?.deployment && filters?.deployment[0]) {
        if (filters?.deployment[0]?.id === 1) {
          filterObj.at_base = true;
        } else if (filters?.deployment[0]?.id === 2) {
          filterObj.on_field = true;
        }
      }

      const response = await axios.get(
        `${base_url}drone/export/vehicle_inventory`,
        {
          params: { ...filterObj },

          paramsSerializer: {
            indexes: null,
          },
        }
      );
      const excelUrl = response.data.data.data.excel_url;
      const link = document.createElement("a");
      link.href = excelUrl;
      link.setAttribute("download", `${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      if (response?.status === 200 || response?.status === 201) {
        alert("Vehicle inventories excel file downloaded successfully!");
        setfilters({});
      }
    } catch (error) {
      console.error("Error exporting the file", error);
    }
  };
  return (
    <div>
      <div
        className={styles.pilotDash}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          // flexWrap: "wrap",
        }}
      >
        <div
          style={{
            minWidth: "25%",
            minHeight: "150px",
            margin: "0px 10px 0px 0px",
            alignSelf: "stretch",
          }}
        >
          <CustomCard
            cardIcon={pilotUser}
            data={{
              assign: cardData.assign,
              not_assign: cardData.not_assign,
            }}
            background={`linear-gradient(to left, rgb(81, 79, 255), rgb(87, 194, 255))`}
          />
        </div>
        <div
          style={{
            minWidth: "35%",
            minHeight: "150px",
            margin: "0px 10px 0px 10px",
            alignSelf: "stretch",
          }}
        >
          <CustomCard
            cardIcon={vehicleInv}
            data={{
              working: cardData.working,
              damaged: cardData.damaged,
              sold: cardData.sold,
              repair: cardData.repair,
            }}
            background={` linear-gradient(103.23deg, #57B67A -2.03%, #0B7754 96.35%)`}
          />
        </div>
        <div
          style={{
            minWidth: "25%",
            minHeight: "150px",
            margin: "0px 0px 0px 10px",
            alignSelf: "stretch",
          }}
        >
          <CustomCard
            cardIcon={droneInventoryBook}
            data={{
              at_base: cardData.at_base,
              on_field: cardData.on_field,
            }}
            background={`linear-gradient(to left, rgba(132, 98, 251, 1), rgba(235, 137, 235, 1))`}
          />
        </div>
      </div>

      {!assignInventoryObj?.id ? (
        <div style={{ marginTop: "20px" }}>
          <FilterContainer
            style={{ maxHeight: "40px", marginTop: "20px" }}
            // className={`${styles.marginTopBox}`}
          >
            <CustomSearchFilter
              placeholder="Search by vehicle name..."
              value={filters?.q}
              onChange={(e) => {
                setPageState(1);
                setfilters({ ...filters, q: e });
              }}
            />
            <InputFields
              label="Filter By Vehicle Number"
              name="vehicle_no"
              value={filters?.vehicle_no}
              onChange={handleChangeacc}
            />
            <CustomSelectChip
              multiple={false}
              label="Filter By Vehicle"
              name="vehicle"
              listArray={vehicleList}
              onChange={handleChangeacc}
              onDelete={handleDeleteChipacc}
              value={filters?.vehicle}
            />
            <CustomSelectChip
              multiple={false}
              label="Filter By Deployment"
              name="deployment"
              listArray={deploymentList}
              onChange={handleChangeacc}
              onDelete={handleDeleteChipacc}
              value={filters?.deployment}
            />
            {/* <CustomSelectChip
          multiple={false}
          label="Filter By Status"
          name="status"
          onChange={handleChangeacc}
          onDelete={handleDeleteChipacc}
          value={filters?.status}
          listArray={statusOptions}
        /> */}
            <CustomButton
              text={"Export"}
              maxWidth="110px"
              onClick={handleExportData}
            />{" "}
            <Button
              variant="contained"
              onClick={handleAdd}
              sx={{ height: "53px" }}
            >
              Add
            </Button>
          </FilterContainer>

          <div className={styles.totalCount}>
            Total Count : <span>{total_count}</span>
          </div>

          <div className={styles.tableContainer}>
            <CustomTable
              columns={tableColumns}
              data={vehicleInventoryList}
              // handleClick={handleRedirect}
              // tableFor=""
            />
          </div>
          <div className={styles.paginationCon}>
            <CustomPagination
              max_pages={max_pages}
              setPageState={setPageState}
            />
          </div>
        </div>
      ) : (
        <div style={{ marginTop: "20px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <img
                src={blackArrow}
                onClick={handleHideAssignHistory}
                style={{ cursor: "pointer" }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h4>{assignInventoryObj?.id}</h4>
                <p>Vehicle Inventory ID</p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h4>{assignInventoryObj?.vehicle?.name || "N.A"}</h4>
                <p style={{ fontSize: "14px" }}>Vehicle Name</p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h4>{assignInventoryObj?.vehicle?.company || "N.A"}</h4>
                <p style={{ fontSize: "14px" }}>Vehicle Company</p>
              </div>
            </div>
            <div>
              <Button
                variant="contained"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleOpenAssignPilot(assignInventoryObj?.id);
                }}
              >
                Assign
              </Button>
            </div>
          </div>

          <div className={styles.tableContainer}>
            <CustomTable
              columns={assignPilotColumns}
              data={VehicleAssignPilotHistory}
              // handleClick={handleRedirect}
              // tableFor=""
            />
          </div>
          <div className={styles.paginationCon}>
            <CustomPagination
              max_pages={max_pages}
              setPageState={setPageState}
            />
          </div>
        </div>
      )}

      <DrawerComp open={open} onClose={() => setOpen(false)} anchor="right">
        <DrawerContainer>
          <DrawerTitle
            text={
              vehicleForm?.id
                ? "Update Vehicle Inventory"
                : "Add Vehicle Inventory"
            }
            handleCancelDrw={() => setOpen((prev) => !prev)}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "20px",
            }}
          >
            <InputFields
              label="Vehicle Number"
              required
              name="vehicle_no"
              value={vehicleForm?.vehicle_no || ""}
              onChange={handleText}
              error={formErrors?.vehicle_no}
              helperText={formErrors?.vehicle_no}
            />

            <CustomSelectChip
              multiple={false}
              label="Select Vehicle *"
              name="vehicle_id"
              onChange={handleChange}
              onDelete={handleDeleteChip}
              value={vehicleForm?.vehicle_id}
              listArray={vehicleList}
              error={formErrors?.vehicle_id}
            />
            <CustomDatePicker
              label="Registered On *"
              onChange={(newValue) => {
                setVehicleForm({
                  ...vehicleForm,
                  registration_date: newValue,
                });
              }}
              value={vehicleForm?.registration_date}
              error={formErrors?.registration_date}
            />

            <CustomSelectChip
              multiple={false}
              label="Inventory Status *"
              name="status"
              onChange={handleChange}
              onDelete={handleDeleteChip}
              value={vehicleForm?.status}
              listArray={statusOptions}
              error={formErrors?.status}
            />

            <div>
              <Button variant="contained" onClick={handleAddVehicleInventory}>
                {vehicleForm?.id ? "Update" : "Add"}
              </Button>
            </div>
          </div>
        </DrawerContainer>
      </DrawerComp>

      <DrawerComp
        open={assignOpen}
        onClose={() => setAssignOpen(false)}
        anchor="right"
      >
        <DrawerContainer>
          <DrawerTitle
            text={"Update Pilot Assign Details"}
            handleCancelDrw={() => setAssignOpen((prev) => !prev)}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "20px",
            }}
          >
            <DronePilot
              value={vehicleAssignPilot?.pilot}
              onChange={handleAutocompleteChangeforVehiclePilot}
            />

            <CustomDatePicker
              label="Assigned From"
              onChange={(newValue) => {
                setvehicleAssignPilot({
                  ...vehicleAssignPilot,
                  assigned_from: newValue,
                });
              }}
              value={vehicleAssignPilot?.assigned_from}
            />

            <CustomDatePicker
              label="Assigned Till"
              onChange={(newValue) => {
                setvehicleAssignPilot({
                  ...vehicleAssignPilot,
                  assigned_to: newValue,
                });
              }}
              value={vehicleAssignPilot?.assigned_to}
            />

            <div>
              <Button
                variant="contained"
                onClick={handleUpdateVehicleAssignPilot}
              >
                {"Update"}
              </Button>
            </div>
          </div>
        </DrawerContainer>
      </DrawerComp>

      <CustomModal open={openDailog} onClose={handleCloseModal} height="400px">
        <div>
          <h3 style={{ marginBottom: "10px" }}>Assigned Pilot</h3>
          <div style={{ marginTop: "10px" }}>
            <CustomDatePicker
              label="Assigned From"
              value={assignPilot?.assigned_from}
              onChange={(newValue) =>
                setAssignPilot({
                  ...assignPilot,
                  assigned_from: newValue,
                })
              }
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <CustomDatePicker
              label="Assigned Till"
              value={assignPilot?.assigned_to}
              onChange={(newValue) =>
                setAssignPilot({
                  ...assignPilot,
                  assigned_to: newValue,
                })
              }
            />
          </div>{" "}
          <div style={{ margin: "10px 0px" }}>
            <DronePilot
              value={pilotSelected}
              onChange={handleAutocompleteChangeforPilot}
            />
          </div>
          <Button variant="contained" onClick={handleAssignPilot}>
            Submit
          </Button>
        </div>
      </CustomModal>

      <CustomModal
        open={isModalOpen}
        onClose={handleCloseModal1}
        height="300px"
      >
        <div className={styles.deleteConfirmCon}>
          <p className={styles.reallyText}>
            Do you really want to delete this vehicle inventory?
          </p>
          <div className={styles.bottomCon}>
            <h1 className={styles.yesText} onClick={handleDeleteVehicle}>
              Yes
            </h1>
            <h1 className={styles.noText} onClick={handleCloseModal1}>
              No
            </h1>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default VehicleInventory;

const tableColumns = [
  {
    id: "id",
    label: "ID",
  },
  // {
  //   id: "creator_id",
  //   label: "Creator Id",
  // },
  {
    id: "vehicle_number",
    label: "Vehicle Number",
  },
  {
    id: "vehicle_id",
    label: "Vehicle Type ID",
  },
  {
    id: "vehicle_name",
    label: "Vehicle Name",
  },
  {
    id: "registered_on",
    label: "Registered On",
  },
  {
    id: "assign",
    label: "Assign",
  },

  {
    id: "inv_status",
    label: "Status",
  },

  {
    id: "action",
    label: "Action",
  },
];

const tableBodyData = [
  {
    id: 1,
    pilot_id: 101,
    vehicle_number: "AP31V3123",
    vehicle_id: 201,
    registered_on: "24-05-2021",
    status: "Active",
  },
  {
    id: 2,
    pilot_id: 102,
    vehicle_number: "AP31V3123",
    vehicle_id: 202,
    registered_on: "24-05-2021",
    status: "Inactive",
  },
  {
    id: 3,
    pilot_id: 103,
    vehicle_number: "AP31V3123",
    vehicle_id: 203,
    registered_on: "24-05-2021",
    status: "Active",
  },
  {
    id: 4,
    pilot_id: 104,
    vehicle_number: "AP31V3123",
    vehicle_id: 204,
    registered_on: "24-05-2021",
    status: "Active",
  },
  {
    id: 5,
    pilot_id: 105,
    vehicle_number: "AP31V3123",
    vehicle_id: 205,
    registered_on: "24-05-2021",
    status: "Inactive",
  },
];

const statusOptions = [
  { title: "Working", id: 1 },
  { title: "Sold", id: 2 },
  { title: "Damaged", id: 3 },
  { title: "Under Repair", id: 4 },
];

const assignPilotColumns = [
  {
    id: "assigned_to",
    label: "Assigned To",
  },
  {
    id: "assigned_from",
    label: "Assigned From",
  },
  {
    id: "assigned_till",
    label: "Assigned Till",
  },
  {
    id: "handover_accepted_on",
    label: "Handover Accepted On",
  },
  {
    id: "handover_accepted_by",
    label: "Handover Accepted By",
  },
  {
    id: "updated_on",
    label: "Updated On",
  },
  {
    id: "modified_by",
    label: "Updated By",
  },
  {
    id: "assign_by",
    label: "Created By",
  },
  {
    id: "created_on",
    label: "Created On",
  },
  // {
  //   id: "assign",
  //   label: "Assign Pilot",
  // },
  {
    id: "action",
    label: "Action",
  },
];

const deploymentList = [
  {
    id: 1,
    title: "At Base",
    value: true,
  },
  {
    id: 2,
    title: "On Field",
    value: true,
  },
];
