import React from "react";

const OtherServiceDetails = ({
  title = "TTTT",
  singleText = "SingleAnswer",
  multiText,
  type,
  data,
}) => {
  console.log(title, type, data, "userdata");
  return (
    <div style={{ marginTop: "8px" }}>
      <div style={{ color: "#848484" }}>{title}</div>
      {type === "single" && (
        <ul>
          {data?.map((info) => (
            <li
              key={info?.id}
              style={{ marginLeft: "20px", boxSizing: "border-box" }}
            >
              {info?.firstLine}
            </li>
          ))}
        </ul>
      )}

      <div
        style={{
          display: "flex",
          columnGap: "15px",
          rowGap: "10px",
          flexWrap: "wrap",
          marginTop: "5px",
        }}
      >
        {type === "multiple" &&
          data.map((item) => {
            return (
              <MultipleText
                firstLine={item?.firstLine}
                // secondLine={item?.secondLine}
              />
            );
          })}
      </div>
    </div>
  );
};

export default OtherServiceDetails;

const MultipleText = ({ firstLine }) => {
  return (
    <div>
      <div>{firstLine || ""}</div>
      {/* <div>{secondLine || ""}</div> */}
    </div>
  );
};
