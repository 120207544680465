import moment from "moment";
import { token_api } from "../../Utils/network";
import { useNavigate } from "react-router-dom";

export class RoomsAndBookings {
  constructor(id, page, handleNavigate) {
    this.id = id;
    this.page = page;
    this.handleNavigate = handleNavigate;
  }
  async getRoomData() {
    try {
      const response = await token_api.get(
        `course/admin_accommodations?page_size=100&hotel=${this.id}`
      );
      return response?.data?.data;
    } catch (error) {
      console.error("Error fetching room data:", error.message);
      throw error;
    }
  }

  async getBookingData() {
    try {
      const response = await token_api.get(
        `course/admin_user_accommodations?page=${this.page}&hotel=${this.id}`
      );

      const modifiedBookings = response.data?.data?.map((item) => {
        return {
          ...item,
          title: (
            <p
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
              onClick={() => {
                this.handleNavigate(item);
              }}
            >
              <span
                style={{
                  height: "50%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >{`${item?.title}`}</span>
              <span
                style={{
                  height: "50%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {" "}
                {`${item?.course_booking_detail[0]?.course_name}`}
              </span>
            </p>
          ),
          group: item?.accommodation,
          start_time: moment(`${item?.start_date} ${item?.start_time}`),
          end_time: moment(`${item?.end_date} ${item?.end_time}`),
        };
      });

      const paginatedData = response?.data;
      return { modifiedBookings, paginatedData };
    } catch (error) {
      console.error("Error fetching booking data:", error.message);
      throw error;
    }
  }

  async getRoomAndBookingsData() {
    try {
      const roomData = await this.getRoomData();
      const { modifiedBookings, paginatedData } = await this.getBookingData();

      console.log(roomData, modifiedBookings, paginatedData, "jaysyayyay");
      return { roomData, modifiedBookings, paginatedData };
    } catch (error) {
      console.error("Error fetching room and booking data:", error.message);
      throw error;
    }
  }
}

// const getRoomsAndBookings = async (id) => {
//     // above in room id
//     try {
//       const fetchRooms = await token_api.get(
//         `course/admin_accommodations?hotel=${id}`
//       );
//       if (fetchRooms?.status !== 200) {
//         throw new Error("Room Fetch Error");
//       }
//       console.log(fetchRooms, "ROOMSAPI");

//       setRoomsListing(fetchRooms?.data?.data);

//       const fetchBookings = await token_api.get(
//         `course/admin_user_accommodations?hotel=${id}`
//       );
//       if (fetchBookings?.status !== 200) {
//         throw new Error("Room Fetch Error");
//       }
//       const modifiedBookings = fetchBookings?.data?.data?.map((item) => {
//         return {
//           ...item,
//           group: item?.accommodation,
//           start_time: moment(`${item?.start_date} ${item?.start_time}`),
//           end_time: moment(`${item?.end_date} ${item?.end_time}`),
//         };
//       });
//       setRoomBookingDetails(modifiedBookings);
//     } catch (error) {
//       console.log("BOOKINGERRORROOMS", error);
//     }
//   };
