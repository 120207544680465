import React, { useEffect, useState, useRef, useContext } from "react";
import styles from "./CourseReviews.module.css";
import Container from "../Container/Container";
import FilterContainer from "../FitlerContainer/FilterContainer";
import CustomSearchFilter from "../CustomSearch/CustomSearchFilter";
import CustomSelectChip from "../CustomSelectChip/CustomSelectChip";
import CustomButton from "../CustomButton/CustomButton";
import CustomTable from "../CustomTable/CustomTable";
import CustomPagination from "../CustomPagination/CustomPagination";
import reviewerIcon from "../../Assets/reviewrIcon.svg";
import { Button, Checkbox } from "@mui/material";
import Rating from "@mui/material/Rating";
import { token_api, base_url } from "../../Utils/network";
import Switch from "@mui/material/Switch";
import dummyAvatar from "../../Assets/reviewrIcon.svg";
import { formatDate } from "../../Utils/Utils";
import DrawerComp from "../DrawerComp/DrawerComp";
import DrawerContainer from "../DrawerContainer/DrawerContainer";
import DrawerTitle from "../DrawerTitle/DrawerTitle";
import TextEditor from "../TextEditor/TextEditor";
import DDuserComboBox from "../DDuserComboBox/DDuserComboBox";
import CourseComboBoxCom from "../CourseComboBoxCom/CourseComboBoxCom";
import editIcon from "../../Assets/edit_icon.svg";
import deleteIcon from "../../Assets/delete_icon.svg";
import { ValidateReview } from "./Utility";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import CustomModal from "../CustomModal/CustomModal";
import axios from "axios";

const ServiceReviews = ({ userList, courseList }: props) => {
  const { user_permissions } = useContext(UserCredsContext);

  console.log(user_permissions, "courseUserPermissions");
  const [filters, setfilters] = useState({});
  const [ratingList, setRatingList] = useState([]);
  const [courseReviewList, setCourseReviewList] = useState([]);
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openDailog, setOpenDailog] = useState(false);
  const [deleteReview, setDeleteReview] = useState(null);
  const [formData, setFormData] = useState({
    id: null,
    user: [],
    comment: "",
    course: [],
    rating: [],
    status: [],
  });

  const [formErors, setFormErrors] = useState({});
  const descriptionRef = useRef(null);

  console.log(userList, courseList, "hariCourseList");
  const handleEditReview = (item) => {
    console.log(item, userList, courseList, "editItem");

    const { id, course, rating, status, user, comment } = item;

    setFormData({
      ...formData,
      id: id,
      comment: comment,
      user: userList.filter((item) => item?.id === user?.id),
      course: courseList.filter((item) => item?.id === course),
      rating: reviewsRating.filter((item) => item?.id === rating),
      status: statusList.filter((item) => item?.id === status),
    });

    setOpenDrawer(true);
    setFormErrors({});
  };

  const handleDeleteReview = () => {
    token_api.delete(`course/reviews/${deleteReview}/`).then((response) => {
      if (
        response?.status === 200 ||
        response?.status === 201 ||
        response?.status === 204
      ) {
        alert("Review Deleted Successfully!");
        setOpenDailog(false);
        setDeleteReview(null);
        getCourseReviewsList();
      }
    });
  };

  const handleDeleteReviewPopup = (reviewId) => {
    setDeleteReview(reviewId);
    setOpenDailog(true);
  };

  const handleCloseModal = () => {
    setOpenDailog(false);
    setDeleteReview(null);
  };

  const getCourseReviewsList = async (filters) => {
    try {
      const fetchReviews = await token_api.get(`/course/reviews`, {
        params: { ...filters },
      });
      if (fetchReviews.status !== 200) {
        throw new Error("Error while fetching reviews");
      }
      setPaginationData(fetchReviews?.data);
      let modifiedArray = fetchReviews?.data?.data.map((item) => {
        return {
          id: <p style={{ fontSize: "12px" }}>{item?.id}</p>,
          status: (
            <Switch
              checked={item?.status}
              inputProps={{ "aria-label": "controlled" }}
            />
          ),

          fullName: (
            <div className={styles.userProfileCon} style={{ fontSize: "12px" }}>
              <img
                src={item?.user_avatar ? item?.user_avatar : dummyAvatar}
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "100%",
                }}
              />
              <p style={{ textAlign: "start" }}>
                {" "}
                {`${item?.user?.first_name} ${item?.user?.last_name}` || "N.A"}
              </p>{" "}
            </div>
          ),
          course: <p style={{ fontSize: "12px" }}>{item?.course_name}</p>,
          rating: (
            <div className={styles.ratingsCon} style={{ fontSize: "12px" }}>
              <Rating name="read-only" value={item?.rating} readOnly />(
              {item?.rating})
            </div>
          ),
          review: (
            <div style={{ cursor: "pointer" }} title={item?.comment}>
              <div title={item?.review} style={{ fontSize: "12px" }}>
                {item?.comment.length > 40
                  ? item?.comment.slice(0, 40) + "..."
                  : item?.comment}
              </div>
            </div>
          ),

          actions: (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                columnGap: "10px",
                justifyContent: "center",
              }}
            >
              {user_permissions?.update && (
                <img
                  style={{ cursor: "pointer" }}
                  onClick={() => handleEditReview(item)}
                  src={editIcon}
                />
              )}
              {user_permissions?.delete && (
                <img
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDeleteReviewPopup(item?.id)}
                  src={deleteIcon}
                />
              )}
            </div>
          ),
          date: <div>{formatDate(item?.created)}</div>,
        };
      });
      setCourseReviewList([...modifiedArray]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteChip = (id, selectName) => {
    setfilters({
      ...filters,
      [selectName]: filters?.[selectName]?.filter((chip) => chip?.id !== id),
    });
  };

  const handleFilter = (e, type, selectName, multiple) => {
    console.log("HANDLERSS", e, type);
    if (type === "select" && selectName && multiple) {
      setfilters({
        ...filters,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setfilters({
        ...filters,
        [selectName]: [e],
      });
    }
  };

  const handleChange = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setFormData({
        ...formData,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setFormData({
        ...formData,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  const handleDeleteAcc = (id, selectName) => {
    setFormData({
      ...formData,
      [selectName]: formData?.[selectName]?.filter((chip) => chip?.id !== id),
    });
  };

  const handleAutocompleteChangeforUser = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("User already included");
    } else {
      setFormData({
        ...formData,
        ["user"]: selectedValues,
      });
    }
  };

  const handleAutocompleteChangeforCourse = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Course already included");
    } else {
      setFormData({
        ...formData,
        ["course"]: selectedValues,
      });
    }
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      let filterObj = {};
      if (pageState) {
        filterObj.page = pageState;
      }
      if (filters?.q) {
        filterObj.q = filters?.q;
      }
      if (filters?.rating) {
        const ids = filters?.rating?.map((item) => item?.id);
        filterObj.rating = ids[0];
      }
      if (filters?.user) {
        const ids = filters?.user?.map((item) => item?.id);
        filterObj.user = ids[0];
      }
      if (filters?.course) {
        const ids = filters?.course?.map((item) => item?.id);
        filterObj.course = ids[0];
      }
      if (filters?.status) {
        const ids = filters?.status?.map((item) => item?.id);
        filterObj.status = ids[0];
      }
      getCourseReviewsList(filterObj);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [
    pageState,
    filters?.q,
    filters?.rating,
    filters?.user,
    filters?.course,
    filters?.status,
  ]);

  const handleAddReview = () => {
    setOpenDrawer(true);
    setFormData({
      id: null,
      user: [],
      course: [],
      rating: [],
      comment: "",
      status: [],
    });
    setFormErrors({});
  };

  const handleCreateReview = () => {
    let data = {
      user: formData?.user[0]?.id,
      course: formData?.course[0]?.id,
      comment: formData?.comment,
      status: formData?.status[0]?.id,
      rating: formData?.rating[0]?.id,
    };

    ValidateReview(formData)
      .then(async (response) => {
        if (response == "success") {
          if (formData?.id) {
            const reviewData = await token_api.patch(
              `course/reviews/${formData?.id}/`,
              data
            );

            if (reviewData?.status == 400) {
              alert(reviewData?.data?.error?.fields[0]?.message[0]);
            }

            if (reviewData.status !== 200) {
              throw new Error("Error in fetching");
            }

            alert("Review Details Updated Successfully!");
            setFormData({
              id: null,
              course: [],
              user: [],
              comment: "",
              status: [],
              rating: [],
            });
            setFormErrors({});
            setOpenDrawer(false);
            getCourseReviewsList();
          } else {
            const reviewData = await token_api.post(`course/reviews/`, data);

            if (reviewData?.status == 403) {
              alert(reviewData?.data?.error?.message);
              setOpenDrawer(false);
            }
            if (reviewData.status !== 201) {
              throw new Error("Error in fetching");
            }

            alert("Review Created Successfully!");
            setFormData({
              id: null,
              user: [],
              course: [],
              status: [],
              rating: [],
              comment: "",
            });
            setFormErrors({});
            setOpenDrawer(false);
            getCourseReviewsList();
          }
        }
      })
      .catch((error) => {
        setFormErrors(error);
      });
  };

  //Course Reviews Export Functionality....

  const handleExportData = async () => {
    try {
      let filterObj = {};

      if (filters?.rating) {
        const ids = filters?.rating?.map((item) => item?.id);
        filterObj.rating = ids[0];
      }
      if (filters?.user) {
        const ids = filters?.user?.map((item) => item?.id);
        filterObj.user = ids[0];
      }
      if (filters?.course) {
        const ids = filters?.course?.map((item) => item?.id);
        filterObj.course = ids[0];
      }
      if (filters?.status) {
        const ids = filters?.status?.map((item) => item?.id);
        filterObj.status = ids[0];
      }
      const response = await axios.get(`${base_url}course/reviews/export`, {
        params: { ...filterObj },
        paramsSerializer: { indexes: null },
      });
      const excelUrl = response.data.data.data.excel_url;
      const link = document.createElement("a");
      link.href = excelUrl;
      link.setAttribute("download", `${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();

      if (
        response?.status === 200 ||
        response?.status === 201 ||
        response?.status === 204
      ) {
        alert("Course reviews export file downloaded successfully!");

        setfilters({});
      }
    } catch (error) {
      console.error("Error exporting the file", error);
    }
  };
  return (
    <Container>
      <FilterContainer style={{ maxHeight: "40px", marginTop: "20px" }}>
        <CustomSearchFilter
          placeholder="Search by name, course title..."
          onChange={(e) => {
            setPageState(1);
            setfilters({ ...filters, q: e });
          }}
        />

        <CustomSelectChip
          multiple={false}
          label="Filter By Rating"
          name="rating"
          listArray={reviewsRating}
          value={filters?.rating}
          onChange={handleFilter}
          onDelete={handleDeleteChip}
        />

        <CustomSelectChip
          multiple={false}
          label="Filter By Status"
          name="status"
          onChange={handleFilter}
          onDelete={handleDeleteChip}
          value={filters?.status}
          listArray={statusList}
        />
        <CustomButton
          text={"Export"}
          maxWidth="110px"
          onClick={handleExportData}
        />

        {user_permissions?.write && (
          <div>
            <Button
              sx={{ height: "53px" }}
              variant="contained"
              onClick={handleAddReview}
              // className="btnLinearGradient"
              style={{ width: "130px", fontSize: "12px" }}
            >
              Add Review
            </Button>
          </div>
        )}
      </FilterContainer>
      <div className="totalCountText">
        Total Count : <span>{total_count}</span>
      </div>

      <div className={styles.tableContainer}>
        <CustomTable
          columns={tableColumns}
          data={courseReviewList}
          tableFor="ServiceReviews"
        />
      </div>
      <div className={styles.paginationCon}>
        <CustomPagination max_pages={max_pages} setPageState={setPageState} />
      </div>

      <DrawerComp
        open={openDrawer}
        onClose={() => setOpenDrawer((prev) => !prev)}
        anchor="right"
      >
        <DrawerContainer>
          <DrawerTitle
            text={formData?.id ? `Update Course Review` : `Add Course Review`}
            handleCancelDrw={() => setOpenDrawer((prev) => !prev)}
          />

          <div style={{ marginBottom: "20px" }}>
            <DDuserComboBox
              value={formData?.user}
              onChange={handleAutocompleteChangeforUser}
            />
            {formErors?.user && (
              <p style={{ color: "#d32f2f" }}>{formErors?.user}</p>
            )}
          </div>

          <div style={{ marginBottom: "20px" }}>
            <CourseComboBoxCom
              value={formData?.course}
              onChange={handleAutocompleteChangeforCourse}
            />
            {formErors?.course && (
              <p style={{ color: "#d32f2f" }}>{formErors?.course}</p>
            )}
          </div>

          <div style={{ marginBottom: "20px" }}>
            <CustomSelectChip
              multiple={false}
              label="Rating"
              name="rating"
              onChange={handleChange}
              onDelete={handleDeleteAcc}
              value={formData?.rating}
              listArray={reviewsRating}
              error={formErors?.rating}
              helperText={formErors?.rating}
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            <CustomSelectChip
              multiple={false}
              label="Status"
              name="status"
              onChange={handleChange}
              onDelete={handleDeleteAcc}
              value={formData?.status}
              listArray={statusList}
              error={formErors?.status}
              helperText={formErors?.status}
            />
          </div>

          <div style={{ marginTop: "10px" }}>
            <textarea
              style={{
                width: "100%",
                padding: "20px 10px",
                boxSizing: "border-box",
              }}
              placeholder="Comment...."
              rows={10}
              label="Comment"
              name="comment"
              value={formData?.comment || ""}
              onChange={handleChange}
              error={formErors?.comment}
              helperText={formErors?.comment}
            />
            {formErors?.comment && (
              <p style={{ color: "#d32f2f" }}>Please enter comment....</p>
            )}
          </div>
          <div className={`${styles.formContainer} mt-20`}>
            <Button
              className={`${styles.gradientColor}`}
              variant="contained"
              onClick={handleCreateReview}
            >
              {formData?.id ? "Update" : "Create"}
            </Button>
          </div>
        </DrawerContainer>
      </DrawerComp>

      <CustomModal open={openDailog} onClose={handleCloseModal} height="300px">
        <div className="alertBox">
          <h3>Are you sure you want to delete the review?</h3>
          <div className="alertBtnCon">
            <Button onClick={handleDeleteReview} variant="contained">
              Yes
            </Button>
            <Button onClick={handleCloseModal} variant="contained">
              No
            </Button>
          </div>
        </div>
      </CustomModal>
    </Container>
  );
};

export default ServiceReviews;

const tableColumns = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "status",
    label: "Active",
  },
  {
    id: "fullName",
    label: "Full Name",
  },
  {
    id: "course",
    label: "Course",
  },
  {
    id: "rating",
    label: "Ratings",
  },
  {
    id: "review",
    label: "Review",
  },
  {
    id: "date",
    label: "Date & Time",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

const tableBodyData = [
  {
    id: 1,
    status: true,
    profileIcon: reviewerIcon,
    fullName: "Meenal saxena",
    rating: 4,
    review:
      "Lorem ipsum is a dumy text for reference.Lorem ipsum is a dumy text for reference.Lorem ipsum is a dumy text for reference.",
    date: "14th jan 2020,01:45 PM",
  },
  {
    id: 2,
    status: true,
    profileIcon: reviewerIcon,
    fullName: "Hari Gadi",
    rating: 4,
    review:
      "Lorem ipsum is a dumy text for reference.Lorem ipsum is a dumy text for reference.Lorem ipsum is a dumy text for reference.",
    date: "14th jan 2020,01:45 PM",
  },
  {
    id: 3,
    status: true,
    profileIcon: reviewerIcon,
    fullName: "Rocky Rawlo",
    rating: 4,
    review:
      "Lorem ipsum is a dumy text for reference.Lorem ipsum is a dumy text for reference.Lorem ipsum is a dumy text for reference.",
    date: "14th jan 2020,01:45 PM",
  },
];

const reviewsRating = [
  { id: 1, title: "Rating 1" },
  { id: 2, title: "Rating 2" },
  { id: 3, title: "Rating 3" },
  { id: 4, title: "Rating 4" },
  { id: 5, title: "Rating 5" },
];

const statusList = [
  {
    id: true,
    title: "Active",
  },
  {
    id: false,
    title: "Inactive",
  },
];
