export const ValidateCustomer = (value) => {
  return new Promise((resolve, reject) => {
    const {
      full_name,
      contact_no,
      area,
      city,
      pincode,
      district,
      state,
      country,
      landmark,
    } = value;

    const errors = { error: false };
    const nameRegex = /^[A-Za-z]+$/;

    if (!full_name) {
      errors.full_name = "Please enter full name";
      errors.error = true;
    }

    if (!contact_no) {
      errors.contact_no = "Please fill contact no";
      errors.error = true;
    } else if (!/^\d{10}$/.test(contact_no)) {
      errors.contact_no = "Contact number must be 10 digits";
      errors.error = true;
    }
    // if (!area) {
    //   errors.area = "Please enter area";
    //   errors.error = true;
    // }
    if (!city) {
      errors.city = "Please enter city";
      errors.error = true;
    }
    if (!pincode) {
      errors.pincode = "Please enter pincode";
      errors.error = true;
    }
    if (!state) {
      errors.state = "Please enter state";
      errors.error = true;
    }
    if (!country) {
      errors.country = "Please enter country";
      errors.error = true;
    }
    if (!district) {
      errors.district = "Please enter district";
      errors.error = true;
    }
    // if (!landmark) {
    //   errors.landmark = "Please enter street name/landmark";
    //   errors.error = true;
    // }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};
