import React, { useEffect, useState } from "react";
import { Calendar, globalizeLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import globalize from "globalize";
import Container from "../Container/Container";
import { calendarEvent } from "../../Utils/calendarDummy";
import CustomPagination from "../CustomPagination/CustomPagination";
import {
  convertCalendarDate,
  getCurrentDateTime,
  getTime,
} from "../../Utils/Utils";
import moment from "moment";
import { Button } from "@mui/material";
import DrawerComp from "../DrawerComp/DrawerComp";
import DrawerTitle from "../DrawerTitle/DrawerTitle";
import DrawerContainer from "../DrawerContainer/DrawerContainer";
import InputFields from "../InputFields/InputFields";
import CustomDate from "../CustomDate/CustomDate";
import DurationPicker from "../DurationPicker/DurationPicker";
import CustomSelectChip from "../CustomSelectChip/CustomSelectChip";
import { token_api } from "../../Utils/network";
import { ApiCallForEvents } from "./utility";
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";
import CustomDatePicker from "../MaterialDate/MaterialDate";
import { formatDate } from "../../Utils/helper";

const CustomCalendar = ({}) => {
  const [calendarData, setCalendarData] = useState();
  const [currenctDate, setCurrentDate] = useState(getCurrentDateTime());
  const [dateTime, setDateTime] = useState();
  const [eventForm, setEventForm] = useState({
    start_date: null,
    end_date: null,
  });
  const [courseList, setCourseList] = useState([]);
  const [pilotsList, setPilotsList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [accommodationList, setAccommodationList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [selectFilters, setSelectFilters] = useState({});
  const [eventCategories, setEventCategories] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [eventDrw, setEvntDrw] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // const [selectedEvent, setSelectedEvent] = useState(undefined)
  const [modalState, setModalState] = useState(false);
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;

  const [isMonthView, setIsMonthView] = useState(true);

  const handleSelectedEvent = (event) => {
    setSelectedEvent(event);
    setModalState(true);
  };

  const Modal = () => {
    return (
      <div
        className={`${
          modalState == true ? `${styles.modal_show}` : `${styles.modal_show}`
        }`}
      >
        <h1>Hello</h1>
      </div>
    );
  };
  const getEvent = (e) => {
    console.log(e);
  };

  const onEventClick = (e) => {
    if (e?.course) {
      navigate(`/bookings/course-bookings/?batchid=${e?.course?.name}`);
    } else if (e?.service_booking) {
      navigate(`/bookings/service-booking-details/${e?.service_booking?.id}`);
    }
    // setSelectedEvent(e);
    // setShowPopup(true);
    // token_api
    //   .get(`course/events/${e.id}`)
    //   .then((res) => {
    //     console.log(res, "This is response")
    //   })
    //   .catch((err) => console.log(err))
    // setEvntDrw((prev) => !prev);
    // setEventForm(e)
  };

  const onSlotChange = (e) => {
    console.log(e);
  };

  const getListing = async (month, year) => {
    try {
    } catch (error) {}
    const response = await fetch(
      `
    http://prod-v2-api.eduaratech.com/calendar/v1/events?event_type=2&month=12&year=2023&page_size=300`,
      {
        method: "GET",
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzY0MzMxOTEzLCJpYXQiOjE3MDEyNTk5MTMsImp0aSI6Ijc5YWRjYzZlNDdiYjQxMDU5ZmI1YTdlZDdmNzIzOTAzIiwidXNlcl9pZCI6Niwic3ViIjoiODg5ODc4Njc4OSJ9.9SytHANtufDJ-Pnpq8EDfK0t_UQLNfdRDEdCMm6O6D4", // Replace 'YOUR_TOKEN' with your actual Bearer token
          "Content-Type": "application/json", // Adjust content type as needed
        },
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
  };

  useEffect(() => {
    let filterObject = {}; //page_size=300 removed
    let date;
    let mnth;
    let yrs;
    let courseStr;
    if (pageState) {
      filterObject.page = pageState;
    }
    if (dateTime) {
      date = moment(dateTime, "MMM DD YYYY");
      mnth = date.format("M"); // Full month name (e.g., "June")
      yrs = date.format("YYYY"); // 4-digit year (e.g., "2023")
      filterObject.month = mnth;
      filterObject.year = yrs;
    } else {
      const { month, year } = currenctDate;
      filterObject.month = month + 1;
      filterObject.year = year;
    }

    if (selectFilters?.event_type) {
      filterObject.event_type = selectFilters?.event_type[0]?.id;
    }
    //
    if (
      Array.isArray(selectFilters?.course) &&
      selectFilters?.course?.length > 0
    ) {
      let courseIds = selectFilters?.course?.map((item) => item?.id);
      filterObject.course = courseIds;
    }

    //
    if (
      Array.isArray(selectFilters?.location) &&
      selectFilters?.location?.length > 0
    ) {
      let ids = selectFilters?.location?.map((item) => item?.id);
      filterObject.location = ids;
    }
    //
    if (
      Array.isArray(selectFilters?.instructor) &&
      selectFilters?.instructor?.length > 0
    ) {
      let ids = selectFilters?.instructor?.map((item) => item?.id);
      filterObject.instructor = ids;
    }
    getEvents(filterObject);
  }, [
    dateTime,
    selectFilters?.event_type,
    selectFilters?.course,
    selectFilters?.location,
    selectFilters?.instructor,
    pageState,
  ]);

  const getEvents = (filters) => {
    console.log("IAMEXE", filters);

    // ?page_size=300&month=${month}&year=${year}&event_type=${event_type}
    token_api
      .get(`course/events`, {
        params: { ...filters },
        paramsSerializer: {
          indexes: null,
        },
      })
      .then((response) => {
        const data = convertCalendarDate(response?.data?.data);
        console.log("REsponsDDDDDD", data);
        setPaginationData(response?.data);
        setCalendarData(data);
      })
      .catch((err) => {});
  };

  // useEffect(() => {
  //   getEvents();
  // }, []);

  const handleChangeFilters = (e, type, name, multiple) => {
    console.log("HANDLER", e, name);

    if (name && multiple) {
      setSelectFilters({
        ...selectFilters,
        [name]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (name && !multiple) {
      setSelectFilters({
        ...selectFilters,
        [name]: [e],
      });
    }
  };

  const handleChange = (e, type, name) => {
    console.log("SLECTE", type, name, e);

    if (type === "select" && name) {
      setEventForm({
        ...eventForm,
        [name]: typeof e === "string" ? e.split(",") : e,
      });

      return;
    }

    setEventForm({
      ...eventForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleDeleteFilters = (id, name) => {
    setSelectFilters({
      ...selectFilters,
      [name]: selectFilters[name].filter((item) => item?.id !== id),
    });
  };

  const handleDeleteChip = (id, name) => {
    setEventForm({
      ...eventForm,
      [name]: eventForm?.[name]?.filter((chip) => chip?.id !== id),
    });
  };

  console.log("FomrDateEvent", eventTypes, eventCategories);

  const localizer = globalizeLocalizer(globalize);

  const handleEventDrw = () => {
    setEvntDrw((prev) => !prev);
  };

  const handleAddEvent = () => {
    token_api
      .post(`http://65.0.241.66/course/events/`, {
        ...eventForm,
        event_type_id: eventForm?.event_type[0]?.id,
        event_category: eventForm?.event_category[0]?.id,
        course_id: eventForm?.course[0]?.id,
        chapter_id: eventForm?.chapter[0]?.id,
        start: formatDate(eventForm?.start_date),
        end: formatDate(eventForm?.end_date),
        instructor: [2],
      })
      .then((response) => {
        getEvents();
        window.location.reload();
        setEvntDrw(false);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    const allApigetData = new ApiCallForEvents(token_api);
    allApigetData
      .getALLApiData()
      .then((data) => {
        console.log("ALLAPAI", data);
        setCourseList(data?.courses);
        setPilotsList(data?.pilots);
        setLocationList(data?.locations);
        setAccommodationList([]);
        // setUserList(data?.users);
        setEventTypes(data?.eventTypes);
        setEventCategories(data?.eventCategories);
        setChapters(data?.chapters);
      })
      .catch((err) => {
        console.log("CathAPIerr", err);
      });
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };
  // useEffect(() => {}, []);

  // Custom Event Component
  const CustomEventComponent = ({ event }) => {
    const [isHovered, setIsHovered] = useState(false);
    const isEventOnRightSide =
      event && event.box && window.innerWidth - event.box.right < 300; // Adjust 300 according to your popup width

    console.log(event, isEventOnRightSide, "CustomEventComponent");
    return (
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        // style={{position:"relative", overflow:"visible"}}
      >
        <div
          style={{
            overflow: "visible",
            fontSize: "12px",
            fontWeight: "500",
            padding: " 2px",
            boxSizing: "border-box",
            whiteSpace: "nowrap",
          }}
        >
          {event?.title}
        </div>
        {isHovered && (
          <div
            style={{
              position: "absolute",
              top: "-20px",
              left: `${isMonthView ? "30%" : "20%"}`,
              zIndex: 999,
              overflow: "visible",
              background: `${
                event?.event_type?.id === 3
                  ? "linear-gradient(92.62deg, #B7E750 0.4%, #FBFF41 98.28%)"
                  : event?.event_type?.id === 2
                  ? " linear-gradient(145deg, #FF8A00 20%, #FFEA2A 100%)"
                  : event?.event_type?.id === 1
                  ? "linear-gradient(92.7deg, #A0E3FF 0.38%, #8976FC 100%)"
                  : ""
              }  `,
              // background:"#fff",
              padding: "5px",
              borderRadius: "8px",
              color: "#000",
              fontSize: "12px",
              boxShadow:
                "0px 2.2160000801086426px 3.324000120162964px 0px #00000040",
            }}
          >
            <div style={{ display: "flex", gap: "20px" }}>
              {" "}
              <p>{event?.title?.props?.children?.props?.children[0]}</p>
              <p>
                ID:
                {event?.event_type?.id === 3
                  ? event?.service_booking?.id
                  : event?.event_type?.id === 2
                  ? event?.course?.id
                  : event?.course?.id}
              </p>
              <p style={{ whiteSpace: "nowrap" }}>
                {event.commence &&
                  moment(event?.commence, "HH:mm:ss.SSSSS").format(
                    "hh:mm a"
                  )}{" "}
                -{" "}
                {event.conclude &&
                  moment(event?.conclude, "HH:mm:ss.SSSSS").format("hh:mm a")}
              </p>
            </div>
            <div
              style={{
                color: `${
                  event?.event_type?.id === 3
                    ? "#476800"
                    : event?.event_type?.id === 2
                    ? "#983500"
                    : event?.event_type?.id === 1
                    ? "#000000"
                    : ""
                }`,
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                margin: `${isMonthView ? "0" : "5px 0"}`,
              }}
            >
              {event?.title?.props?.children?.props?.children[2]}{" "}
            </div>
            {event?.event_type?.id === 1 ? (
              <span
                style={{
                  backgroundColor: "#A44CFC",
                  padding: "2px 5px",
                  margin: "5px 0",
                  borderRadius: "10px",
                  color: "#fff",
                }}
              >
                Online Class
              </span>
            ) : event?.event_type?.id === 2 ? (
              <span
                style={{
                  backgroundColor: "#FF5454",
                  padding: "2px 5px",
                  borderRadius: "10px",
                  color: "#fff",
                }}
              >
                Flying Slot
              </span>
            ) : (
              ""
            )}

            <div className={styles.eventPilotname}>
              {event?.event_type?.id === 3 ? (
                <>
                  <p> Pilot Name : </p>
                  <div className={styles.imgnameContainer}>
                    {event?.pilot && (
                      <img
                        width={25}
                        height={25}
                        src={
                          event?.pilot?.avatar
                            ? event?.pilot?.avatar
                            : event?.pilot?.default_avatar
                        }
                      />
                    )}

                    <h4>
                      {event?.pilot?.first_name} {event?.pilot?.last_name}
                    </h4>
                  </div>
                </>
              ) : (
                <>
                  <p> student : {event?.attendies || 0} </p>
                </>
              )}
            </div>
            {event?.service_booking ? (
              <p className={styles.eventbookingstatus}>
                Booking:{" "}
                {event?.service_booking?.status === 3 ? (
                  <span>Confirmed</span>
                ) : (
                  ""
                )}
              </p>
            ) : (
              ""
            )}

            <div className={styles.eventSIProvider}>
              {event?.event_type?.id === 3 ? (
                <img
                  width={25}
                  height={25}
                  src={
                    event?.service_booking?.avatar
                      ? event?.pilot?.avatar
                      : event?.pilot?.default_avatar
                  }
                />
              ) : (
                ""
              )}
              {event?.event_type?.id === 3 ? (
                <h4> {event?.service_booking?.full_name}</h4>
              ) : (
                ""
              )}
              {/* <img src={event?.service_booking?.avatar ? event?.pilot?.avatar : event?.pilot?.default_avatar} /> */}
            </div>
          </div>
        )}
      </div>
    );
  };

  const handleViewChange = (view) => {
    console.log(view, "handleViewChange");
    setIsMonthView(view === "month" || view === "agenda");
    if (view === "month" || view === "agenda") {
      setIsMonthView(true);
    } else {
      setIsMonthView(false);
    }
  };

  const formats = {
    eventTimeRangeFormat: () => {
      return "";
    },
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  console.log(windowWidth, "setWindowWidth");
  console.log(eventForm, "divyaEvenetFormmm");
  return (
    <Container>
      <div>
        <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: "1",
              rowGap: "15px",
            }}
          >
            <Button variant="contained" onClick={handleEventDrw}>
              Add Event
            </Button>

            <CustomSelectChip
              multiple={false}
              label="Event Type"
              name="event_type"
              onChange={handleChangeFilters}
              onDelete={handleDeleteFilters}
              value={selectFilters?.event_type}
              listArray={eventTypes}
            />
            <CustomSelectChip
              multiple={true}
              label="Courses"
              name="course"
              onChange={handleChangeFilters}
              onDelete={handleDeleteFilters}
              value={selectFilters?.course}
              listArray={courseList}
            />
            <CustomSelectChip
              multiple={true}
              label="Instructor"
              name="instructor"
              onChange={handleChangeFilters}
              onDelete={handleDeleteFilters}
              value={selectFilters?.instructor}
              listArray={pilotsList}
            />
            <CustomSelectChip
              multiple={true}
              label="Location"
              name="location"
              onChange={handleChangeFilters}
              onDelete={handleDeleteFilters}
              value={selectFilters?.location}
              listArray={locationList}
            />
            {/* <CustomSelectChip
              multiple={true}
              label="Accommodation"
              name="accommodation"
              onChange={handleChangeFilters}
              onDelete={handleDeleteFilters}
              value={selectFilters?.accommodation}
              listArray={customChipData}
            /> */}
            {/* <CustomSelectChip
              multiple={true}
              label="Users"
              name="users"
              onChange={handleChangeFilters}
              onDelete={handleDeleteFilters}
              value={selectFilters?.users}
              listArray={userList}
            /> */}
          </div>
          <div
            style={{
              overflow: "visible",
              height: "100%",
              width: "calc(100% - 300px)",
              flex: "4",
              position: "relative,",
            }}
          >
            <Calendar
              localizer={localizer}
              events={calendarData}
              // events={calendarData}
              components={{ event: CustomEventComponent }}
              tooltipAccessor={null}
              formats={formats}
              startAccessor="start"
              endAccessor="end"
              selectable
              style={{ height: "95vh" }}
              onClick={(e) => getEvent(e)}
              onSelectEvent={(event) => onEventClick(event)}
              onSelectSlot={(slotInfo) => onSlotChange(slotInfo)}
              onView={(view) => {
                console.log("#### onView");
                console.log("#### view=", view);
                //this.setState({currentView: view});
                handleViewChange(view);
              }}
              onNavigate={(date, view) => {
                console.log("#### onNavigate");
                console.log("#### date=", date);
                console.log("#### view=", view);
                setDateTime(date);
                // changeDate(view, date);
                //this.setState({currentDate: date}); }}
              }}
              // popup
              // showMultiDayTimes
              // startAccessor="start"
              // endAccessor="end"

              eventPropGetter={(event, start, end, isSelected) => {
                let newStyle = {
                  backgroundColor: `${event?.colour}`,
                  background: `${
                    event?.event_type?.id === 3
                      ? "linear-gradient(92.62deg, #B7E750 0.4%, #FBFF41 98.28%)"
                      : event?.event_type?.id === 2
                      ? " linear-gradient(145deg, #FF8A00 20%, #FFEA2A 100%)"
                      : event?.event_type?.id === 1
                      ? "linear-gradient(92.7deg, #A0E3FF 0.38%, #8976FC 100%)"
                      : ""
                  }  `,
                  // color: 'black',
                  // color: `${(event?.subject?.dark_colour) ? event?.subject?.dark_colour : 'black'}`,
                  color: "white",
                  borderRadius: "5px",
                  border: "none",
                  overflow: "visible",
                  // position: "relative",
                };

                // if (event.color) {
                //   newStyle.backgroundColor = "lightgreen"
                // }
                if (isMonthView) {
                  newStyle.position = "relative";
                }

                return {
                  className: "",
                  style: newStyle,
                };
              }}
            />
            {/* {showPopup && selectedEvent && (
              <PopupComponent event={selectedEvent} onClose={closePopup} />
            )} */}
            {/* <h1>Calender ROutse</h1> */}
          </div>
        </div>
      </div>
      {/* {showPopup && selectedEvent && (
        <PopupComponent event={selectedEvent} onClose={closePopup} />
      )} */}

      <div style={{ marginTop: "25px", marginLeft: "auto" }}>
        <CustomPagination max_pages={max_pages} setPageState={setPageState} />
      </div>
      <div>
        <DrawerComp
          open={eventDrw}
          onClose={() => setEvntDrw((prev) => !prev)}
          anchor="right"
        >
          <DrawerContainer>
            <DrawerTitle
              text="Add Event"
              handleCancelDrw={() => setEvntDrw((prev) => !prev)}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
              }}
            >
              <InputFields
                label="title"
                required
                name="title"
                value={eventForm?.title}
                onChange={handleChange}
              />
              <InputFields
                label="Description"
                required
                name="description"
                value={eventForm?.description}
                onChange={handleChange}
              />

              {/* <CustomDate
                name="start_date"
                label="Start Date"
                handleChange={handleChange}
              /> */}

              <CustomDatePicker
                label="Start Date"
                value={eventForm?.start_date}
                onChange={(newValue) =>
                  setEventForm({
                    ...eventForm,
                    start_date: newValue,
                  })
                }
                // error={formErrors?.start_date}
              />

              {/* <CustomDate
                name="end_date"
                label="End Date"
                handleChange={handleChange}
              /> */}

              <CustomDatePicker
                label="End Date"
                value={eventForm?.end_date}
                onChange={(newValue) =>
                  setEventForm({
                    ...eventForm,
                    end_date: newValue,
                  })
                }
                // error={formErrors?.end_date}
              />
              <DurationPicker
                style={{ width: "100%" }}
                label="Commence"
                size="small"
                name="commence"
                onChange={handleChange}
                // required
                // error={startTimeCheck}
                // helperText={startTimeCheck ? "Enter start time" : ""}
              />
              <DurationPicker
                style={{ width: "100%" }}
                label="Conclude"
                size="small"
                name="conclude"
                onChange={handleChange}
                // required
                // error={startTimeCheck}
                // helperText={startTimeCheck ? "Enter start time" : ""}
              />

              <CustomSelectChip
                multiple="false"
                label="Event Type"
                name="event_type"
                onChange={handleChange}
                onDelete={handleDeleteChip}
                value={eventForm?.event_type}
                listArray={eventTypes}
              />
              <CustomSelectChip
                multiple="false"
                label="Event Category"
                name="event_category"
                onChange={handleChange}
                onDelete={handleDeleteChip}
                value={eventForm?.event_category}
                listArray={eventCategories}
              />
              <CustomSelectChip
                multiple="false"
                label="Course"
                name="course"
                onChange={handleChange}
                onDelete={handleDeleteChip}
                value={eventForm?.course}
                listArray={courseList}
              />
              <CustomSelectChip
                multiple="false"
                label="Chapter"
                name="chapter"
                onChange={handleChange}
                onDelete={handleDeleteChip}
                value={eventForm?.chapter}
                listArray={chapters}
              />
              <div>
                <Button variant="contained" onClick={handleAddEvent}>
                  Add
                </Button>
              </div>
            </div>
          </DrawerContainer>
        </DrawerComp>
      </div>
    </Container>
  );
};

const PopupComponent = ({ event, onClose }) => {
  const { hr: s_hr, min: s_min, sec: s_sec } = getTime(event.commence);
  const { hr: e_hr, min: e_min, sec: e_sec } = getTime(event.conclude);
  return (
    <div
      style={{
        position: "absolute",
        zIndex: 99,
        top: "50%",
        left: "50%",
        transform: "translate(50%, -50%)",
        padding: "20px",
        backgroundColor: "white",
        borderRadius: "10px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      {/* <h2>{event.title}</h2> */}
      {/* <p>Start: {event.start.toLocaleString()}</p>
      <p>End: {event.end.toLocaleString()}</p> */}
      {/* Add more event details as needed */}

      <div className={styles.eventCardpopup}>
        <div className={styles.eventIDDate}>
          <h5 className={styles.eventID}>
            ID:{" "}
            {event?.event_type?.id === 3
              ? event?.service_booking?.id
              : event?.event_type?.id === 2
              ? event?.course?.id
              : event?.course?.id}
          </h5>
          <p>
            {" "}
            {s_hr}:{s_min}:{s_sec} - {e_hr}:{e_min}:{e_sec}
          </p>
        </div>

        <h4 className={styles.eventTitle}>
          {event?.event_type?.id === 3
            ? event.service?.title
            : event?.event_type?.id === 2
            ? event?.course?.name
            : event?.course?.name}
        </h4>
        {event?.event_type?.id === 1 ? (
          <span
            style={{
              backgroundColor: "#A44CFC",
              padding: "5px 10px",
              borderRadius: "10px",
              color: "#fff",
            }}
          >
            Online Class
          </span>
        ) : event?.event_type?.id === 2 ? (
          <span
            style={{
              backgroundColor: "#FF5454",
              padding: "5px 10px",
              borderRadius: "10px",
              color: "#fff",
            }}
          >
            Flying Slot
          </span>
        ) : (
          ""
        )}

        <div className={styles.eventPilotname}>
          {event?.event_type?.id === 3 ? (
            <>
              <p> Pilot Name : </p>
              <div className={styles.imgnameContainer}>
                <img
                  width={25}
                  height={25}
                  src={
                    event?.pilot?.avatar
                      ? event?.pilot?.avatar
                      : event?.pilot?.default_avatar
                  }
                />
                <h4>
                  {event?.pilot?.first_name} {event?.pilot?.last_name}
                </h4>
              </div>
            </>
          ) : (
            <>
              <p> student : {event?.attendies || 0} </p>
            </>
          )}
        </div>
        <p className={styles.eventbookingstatus}>
          Booking: {event?.service_booking?.status}
        </p>
        {/* <p className={styles.eventbookingstatus}>
          Payment:: {event?.service_booking?.status}
          </p> */}
        <div className={styles.eventSIProvider}>
          {event?.event_type?.id === 3 ? (
            <img
              width={25}
              height={25}
              src={
                event?.service_booking?.avatar
                  ? event?.pilot?.avatar
                  : event?.pilot?.default_avatar
              }
            />
          ) : (
            ""
          )}
          {event?.event_type?.id === 3 ? (
            <h4> {event?.service_booking?.full_name}</h4>
          ) : (
            ""
          )}
          {/* <img src={event?.service_booking?.avatar ? event?.pilot?.avatar : event?.pilot?.default_avatar} /> */}
        </div>
      </div>
      <button onClick={onClose}>Close</button>
    </div>
  );
};

export default CustomCalendar;

const courseD = [
  {
    id: 1,
    title: "DGCA-authorised Small, Rotorcraft Drone Training",
  },
];
const event_categoryD = [
  {
    id: 1,
    title: "All",
  },
];
const chapterD = [
  {
    id: 1,
    title: "Theory: Day 1",
  },
];
const event_TypeD = [
  {
    id: 1,
    title: "Course Theory",
  },
];

const customChipData = [
  {
    id: 1,
    title: "A",
  },
  {
    id: 2,
    title: "B",
  },
  {
    id: 3,
    title: "C",
  },
  {
    id: 4,
    title: "D",
  },
];
