import React from "react";
import styles from "./CustomCard.module.css";
import eclipseIcon from "../../Assets/whiteEclipse.svg";

const CustomCard = ({ cardIcon, background, data = {} }) => {
  return (
    <div
      style={{
        position: "relative",
        background: background,
        // minWidth: "300px",
        width: "100%",
        height: "100%",
        padding: "10px 20px",
        borderRadius: "10px",
      }}
    >
      <div className={styles.gridContainer}>
        {Object.keys(data).map((key) => (
          <div className={styles.gridItem}>
            <p style={{ fontSize: "16px", fontWeight: "700" }}>
              {/* {data[key].value ? data[key].value : "-"} */}
              {data[key].value}
            </p>
            <p style={{ fontSize: "17px", fontWeight: "600" }} className={styles.countVAl}>
              {data[key].name}
            </p>
          </div>
        ))}
      </div>
      {/* <div className={styles.cardIcon}> */}

      {/* <div
        style={{
          position: "absolute",
          top: "10px",
          right: "5px",
          backgroundImage: `url(${eclipseIcon})`,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "80px",
          width: "80px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <img src={cardIcon} />
      </div> */}
     
    </div>
  );
};

export default CustomCard;
