import React, { useEffect, useState } from "react";
import { formatDroneBatteryList } from "../../Pages/DroneInventory/utility";
import CustomTable from "../CustomTable/CustomTable";
import { token_api } from "../../Utils/network";
import editIcon from "../../Assets/editIcon.png";
import deleteIcon from "../../Assets/delete.png";
import {
  formatDate,
  helperdayMonthYearFull,
  getStatusStyles,
} from "../../Utils/Utils";

const BatteryInventoryTab = () => {
  const [droneInventoryList, setDroneInventoryList] = useState([]);

  const handleEditDrone = () => {};
  const handleDeleteDrone = () => {};

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return "Working";
      case 2:
        return "Sold";
      case 3:
        return "Damaged";
      case 4:
        return "Under Repair";
      default:
        return "Unknown";
    }
  };
  const getDroneInventoryListing = async (filterobject) => {
    try {
      const fetchDroneInventory = await token_api.get(
        `/drone/battery/inventory`
      );
      if (fetchDroneInventory.status !== 200) {
        throw new Error("Error in fetching");
      }
      //   setPaginationData(fetchDroneInventory?.data);
      // const modifiedData = formatDroneBatteryList(
      //   fetchDroneInventory?.data?.data,
      //   handleEditDrone,
      //   handleDeleteDrone
      // );

      let modifiedData = fetchDroneInventory?.data?.data.map((item, index) => {
        const droneStatusStyles = getStatusStyles(item?.inv_status);

        return {
          id: <p style={{ fontSize: "12px" }}>{item.id}</p>,
          name: <p style={{ fontSize: "12px" }}>{item.battery?.name}</p>,
          serial_no: <p style={{ fontSize: "12px" }}>{item.serial_no}</p>,
          registered_date: (
            <p style={{ fontSize: "12px" }}>
              {(item.registration_date &&
                helperdayMonthYearFull(item.registration_date)) ||
                "N.A"}
            </p>
          ),
          location: <p style={{ fontSize: "12px" }}>{item.city || "N.A"}</p>,
          updated_on: (
            <p style={{ fontSize: "12px" }}>
              {(item.modified && helperdayMonthYearFull(item.modified)) ||
                "N.A"}
            </p>
          ),
          updated_by: (
            <div style={{ fontSize: "12px" }}>
              {item?.modified_by?.first_name && item?.modified_by?.last_name
                ? `${item?.modified_by?.first_name} ${item?.modified_by?.last_name}`
                : "NA"}{" "}
            </div>
          ),
          power: (
            <p style={{ fontSize: "12px" }}>{item?.battery?.power || "NA"}</p>
          ),
          quantity: <p style={{ fontSize: "12px" }}>{item.quantity || "Na"}</p>,
          drone: (
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <div>
                <img
                  src={item?.drone_inventory?.drone?.drone_image}
                  style={{ width: "56px", height: "56px" }}
                />
              </div>
              <p style={{ fontSize: "12px" }}>
                {" "}
                {item?.drone_inventory?.drone?.title}
              </p>
            </div>
          ),
          status: (
            <span
              style={{
                padding: "5px",
                borderRadius: "50px",
                ...droneStatusStyles,
                minWidth: "60%",
                font: "bold",
                display: "inline-block",
                width: "100px",
                fontSize: "12px",
              }}
            >
              {getStatusText(item?.inv_status)}
            </span>
          ),
          actions: (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                columnGap: "10px",
                justifyContent: "center",
              }}
            >
              <span
                style={{ cursor: "pointer" }}
                onClick={() => handleEditDrone(item)}
              >
                <img src={editIcon} alt="Edit" />
              </span>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => handleDeleteDrone(item?.id)}
              >
                <img src={deleteIcon} alt="Delete" />
              </span>
            </div>
          ),
        };
      });

      setDroneInventoryList(modifiedData);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(droneInventoryList, "hariDroneInventoryList");

  useEffect(() => {
    getDroneInventoryListing();
  }, []);
  return (
    <div style={{ height: "300px", overflow: "auto" }}>
      <CustomTable
        columns={tableColumns}
        data={droneInventoryList}
        // handleClick={handleRedirect}
        // tableFor=""
      />
    </div>
  );
};

export default BatteryInventoryTab;

const tableColumns = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "name",
    label: "Battery Name",
  },
  {
    id: "serial_no",
    label: "Serial No",
  },
  {
    id: "drone",
    label: "Drone",
  },
  {
    id: "power",
    label: "Power",
  },

  {
    id: "quantity",
    label: "Quantity",
  },
  {
    id: "location",
    label: "Location",
  },
  {
    id: "registered_date",
    label: "Registration Date",
  },
  {
    id: "updated_on",
    label: "Updated On",
  },
  {
    id: "updated_by",
    label: "Updated By",
  },
  {
    id: "status",
    label: "Status",
  },
  // {
  //   id: "actions",
  //   label: "Actions",
  // },
];

const statusListing = [
  {
    id: true,
    title: "Active",
  },
  {
    id: false,
    title: "Inactive",
  },
];
