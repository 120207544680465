import React, { useContext, useEffect, useRef, useState } from "react";
import Container from "../../Component/Container/Container";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import CustomTable from "../../Component/CustomTable/CustomTable";
import FilterContainer from "../../Component/FitlerContainer/FilterContainer";
import CustomSearchFilter from "../../Component/CustomSearch/CustomSearchFilter";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import {
  Button,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import DrawerContainer from "../../Component/DrawerContainer/DrawerContainer";
import DrawerTitle from "../../Component/DrawerTitle/DrawerTitle";
import DrawerComp from "../../Component/DrawerComp/DrawerComp";
import CustomDate from "../../Component/CustomDate/CustomDate";
import useFetchData from "../../Component/CustomHook/useFetchData";
import { token_api } from "../../Utils/network";
import { formatEditState, modifySlotData } from "./slotUtility";
import CustomPagination from "../../Component/CustomPagination/CustomPagination";
import styles from "./slot.module.css";
import TextEditor from "../../Component/TextEditor/TextEditor";
import { validateSlot } from "./Slotvalidate";
import { Link, useNavigate } from "react-router-dom";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import SearchAndDropdown from "../../Component/SearchAndDropdown/SearchAndDropdown";
import CustomDatePicker from "../../Component/MaterialDate/MaterialDate";
import { formatDate } from "../../Utils/helper";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import CustomModal from "../../Component/CustomModal/CustomModal";

const SlotMaster = () => {
  let navigate = useNavigate();
  const { user_permissions } = useContext(UserCredsContext);
  const { data: courseFetch } = useFetchData(`course?page_size=100`);

  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <div className="orange-1 bread">Slot Master</div>,
  ];
  const slotEditorRef = useRef(null);
  const [slotForm, setSlotForm] = useState({
    course_id: "",
    location_id: [],
    descriptions: "",
    start_date: null,
    end_date: null,
    type: "",
  });
  const [locationTypedValue, setLocationTypedValue] = useState("");
  const [typedValue, setTypedValue] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [slotList, setSlotList] = useState([]);
  const [courseListing, setCourseListing] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [locationsForItem, setLocationsForItem] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [editState, setEditState] = useState(false);
  const [filters, setfilters] = useState({});
  const [accForm, setaccForm] = useState({});
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const [editid, setEditid] = useState(null);
  const [openDailog, setOpenDailog] = useState(false);
  const [deletedSlot, setDeletedSlot] = useState(null);

  const handleEditSlot = (editObject) => {
    // setEd'
    setFormErrors({});
    setEditState(true);
    setOpenDrawer(true);
    setSlotForm(formatEditState(editObject));
    setEditid(editObject?.id);
  };

  const handleDeleteSlot = () => {
    token_api
      .delete(`/course/slots/${deletedSlot}/`)
      .then((response) => {
        if (response?.status == 204) {
          alert("Slot deleted successfully!");
          setOpenDailog(false);
          setDeletedSlot(null);
          getAllSlots();
        }
      })
      .catch((error) => console.log(error));
  };

  const handleDeleteSlotPopup = (id) => {
    setDeletedSlot(id);
    setOpenDailog(true);
  };

  const handleCloseModal = () => {
    setOpenDailog(false);
    setDeletedSlot(null);
  };

  const handleCancelDrw = () => {
    setEditState(false);
    getAllSlots();
    setSlotForm({});
    setOpenDrawer(false);
  };

  const getAllSlots = async (filtersObject) => {
    try {
      const fetchSlot = await token_api.get(`course/slots`, {
        params: { ...filtersObject },
        // paramsSerializer: {
        //   indexes: null,
        // },
      });

      if (fetchSlot.status !== 200) {
        throw new Error("Network error");
      }
      setPaginationData(fetchSlot?.data);
      const modifiedData = modifySlotData(
        fetchSlot?.data?.data,
        handleEditSlot,
        handleDeleteSlotPopup,
        user_permissions
      );
      setSlotList(modifiedData);
    } catch (error) {}
  };

  const handleCreateSlot = async () => {
    let isDescriptionsEmpty;

    try {
      if (slotEditorRef.current) {
        const content = slotEditorRef.current.getContent();
        slotForm.descriptions = content;
        isDescriptionsEmpty = !content.trim(isDescriptionsEmpty);
      }

      const validationResponse = await validateSlot(
        slotForm,
        isDescriptionsEmpty
      );

      if (validationResponse === "success") {
        const apiEndpoint = editState
          ? `course/slots/${editid}/`
          : `course/slots/`;

        const apiMethod = editState ? token_api.patch : token_api.post;

        const createSlot = await apiMethod(apiEndpoint, {
          ...slotForm,
          start_date: formatDate(slotForm?.start_date),
          end_date: formatDate(slotForm?.end_date),
          course_id: slotForm?.course_id[0]?.id,
          location_id: slotForm?.location_id?.id,
          type: slotForm?.type[0]?.id,
        });

        if (createSlot?.status !== (editState ? 200 : 201)) {
          throw new Error("Slot Create Error");
        }

        if (createSlot?.status === 200 || createSlot?.status === 201) {
          alert(`Slot ${editState ? "updated" : "created"} successfully!`);
          handleCancelDrw();
        }
      }
    } catch (error) {
      console.error(error);
      setFormErrors(error);
    }
  };

  const handleChangeSlotForm = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setSlotForm({
        ...slotForm,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setSlotForm({
        ...slotForm,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setSlotForm({
        ...slotForm,
        [name]: value,
      });
    }
  };

  const handleDeleteChip = (id, selectName) => {
    setSlotForm({
      ...slotForm,
      [selectName]: slotForm?.[selectName]?.filter((chip) => chip?.id !== id),
    });
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      let filterObj = {};

      filterObj.page = pageState;
      if (filters?.q) {
        filterObj.q = filters?.q;
      }

      if (filters?.course) {
        filterObj.course = filters?.course[0]?.slug;
      }
      if (filters?.type) {
        filterObj.type = filters?.type[0]?.id;
      }
      if (accForm?.location) {
        filterObj.location = accForm?.location?.id;
      }
      getAllSlots(filterObj);
    }, 500);

    return () => clearTimeout(timeOut);
  }, [
    filters?.q,
    filters?.course,
    filters?.type,
    accForm?.location,
    pageState,
  ]);

  useEffect(() => {
    if (courseFetch && courseFetch.length > 0) {
      const modifiedData = courseFetch.map((item) => {
        return {
          id: item?.id,
          title: item?.name,
          slug: item?.slug,
        };
      });
      setCourseListing(modifiedData);
    }
  }, [courseFetch]);

  const handleChangeforSearch = (event, newValue) => {
    setTypedValue(newValue);
    setaccForm((prev) => ({
      ...prev,
      location: newValue,
    }));
  };

  const handleAddLocation = (event, newValue) => {
    setLocationTypedValue(newValue);
    setSlotForm((prev) => ({
      ...prev,
      location_id: newValue,
    }));
  };

  const getLocationList = (value) => {
    let apiEndpoint = "/course/location?page_size=100";

    if (value) {
      apiEndpoint += `&q=${value}`;
    }
    token_api
      .get(apiEndpoint)
      .then((res) => {
        if (res.data.data) {
          let userloc = res.data.data?.map((info) => ({
            id: info?.id,
            title: `${info?.line_1}, ${info?.line_2}, ${info?.area}, ${info?.city_new}, ${info?.state}, ${info?.country}`,
          }));
          setLocationList(userloc);
        }
      })
      .catch((err) => console.log(err));
  };

  const getLocationListForItem = (value) => {
    let apiEndpoint = "/course/location?page_size=100";

    if (value) {
      apiEndpoint += `&q=${value}`;
    }
    token_api
      .get(apiEndpoint)
      .then((res) => {
        if (res.data.data) {
          let userloc = res.data.data?.map((info) => ({
            id: info?.id,
            title: `${info?.line_1}, ${info?.line_2}, ${info?.area}, ${info?.city_new}, ${info?.state}, ${info?.country}`,
          }));
          setLocationsForItem(userloc);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    let timer;

    if (typedValue) {
      timer = setTimeout(() => {
        getLocationList(typedValue);
      }, 500);
    } else {
      getLocationList();
    }
    return () => {
      clearTimeout(timer);
    };
  }, [typedValue]);

  useEffect(() => {
    let timer;

    if (locationTypedValue) {
      timer = setTimeout(() => {
        getLocationListForItem(locationTypedValue);
      }, 500);
    } else {
      getLocationListForItem();
    }
    return () => {
      clearTimeout(timer);
    };
  }, [locationTypedValue]);

  const handleNavigateBack = () => {
    navigate(`/dashboard`);
  };
  return (
    <Container>
      <div className="flexBetween">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
            <img src={breadCrumbBack} />
          </div>
          <CustomBreadCrumbs breadcrumbs={breadcrumbs} />
        </div>

        {user_permissions?.write && (
          <Button
            variant="contained"
            className="btnLinearGradient"
            onClick={() => {
              setOpenDrawer(true);
              setEditState(false);
              setFormErrors({});
              setSlotForm({});
            }}
          >
            + Add New Slot
          </Button>
        )}
      </div>

      <FilterContainer style={{ maxHeight: "40px", marginTop: "20px" }}>
        <CustomSelectChip
          multiple={false}
          label={"Filter By Course"}
          listArray={courseListing}
          value={filters?.course || []}
          onChange={(e) =>
            setfilters({
              ...filters,
              course: [e],
            })
          }
          onDelete={(id) =>
            setfilters({
              ...filters,
              course: filters?.course?.filter((chip) => chip?.id !== id),
            })
          }
        />
        <CustomSelectChip
          multiple={false}
          label={"Filter By Slot Type"}
          value={filters?.type || []}
          onChange={(e) =>
            setfilters({
              ...filters,
              type: [e],
            })
          }
          onDelete={(id) =>
            setfilters({
              ...filters,
              type: filters?.type?.filter((chip) => chip?.id !== id),
            })
          }
          listArray={slotTypesObj}
        />

        <SearchAndDropdown
          label="Filter By Location"
          value={accForm?.location}
          onChange={handleChangeforSearch}
          listing={locationList}
          typedValue={typedValue}
          setTypedValue={setTypedValue}
        />
      </FilterContainer>
      <div className="totalCountText">
        Total Count : <span>{total_count}</span>
      </div>

      <div className={styles.tableContainer}>
        <CustomTable
          columns={tableColumns}
          data={slotList}
          // handleClick={handleRedirect}
        />
      </div>

      <div className={styles.paginationCon}>
        <CustomPagination max_pages={max_pages} setPageState={setPageState} />
      </div>

      <DrawerComp
        width="40%"
        open={openDrawer}
        onClose={handleCancelDrw}
        anchor={"right"}
      >
        <DrawerContainer style={{ marginTop: "0px", marginBottom: "10px" }}>
          <DrawerTitle
            text={editState ? "Edit Slot" : "Add Slot"}
            handleCancelDrw={handleCancelDrw}
          />

          <div style={{ rowGap: "15px" }} className="flexCol mt-10">
            <CustomSelectChip
              multiple={false}
              label="Course *"
              name="course_id"
              onChange={handleChangeSlotForm}
              onDelete={handleDeleteChip}
              value={slotForm?.course_id}
              listArray={courseListing}
              error={formErrors?.course_id}
            />
            <CustomSelectChip
              multiple={false}
              label="Type *"
              name="type"
              onChange={handleChangeSlotForm}
              onDelete={handleDeleteChip}
              value={slotForm?.type}
              listArray={slotTypesObj}
              error={formErrors?.type}
            />
            <div style={{ marginTop: "10px" }}>
              <TextEditor
                editorRef={slotEditorRef}
                initialValue={slotForm?.descriptions}
                height={300}
              />
              <small style={{ marginTop: "5px", color: "#d32f2f" }}>
                {formErrors?.descriptions &&
                  "Please fill the descriptions for the slot"}
              </small>
            </div>
            <SearchAndDropdown
              label="Location *"
              name="location_id"
              value={slotForm?.location_id}
              onChange={handleAddLocation}
              listing={locationsForItem}
              typedValue={locationTypedValue}
              setTypedValue={setLocationTypedValue}
              error={formErrors?.location_id}
            />

            <CustomDatePicker
              label="Start Date *"
              value={slotForm?.start_date}
              onChange={(newValue) =>
                setSlotForm({
                  ...slotForm,
                  start_date: newValue,
                })
              }
              error={formErrors?.start_date}
            />

            <CustomDatePicker
              label="End Date *"
              value={slotForm?.end_date}
              onChange={(newValue) =>
                setSlotForm({
                  ...slotForm,
                  end_date: newValue,
                })
              }
              error={formErrors?.end_date}
            />
          </div>

          <div style={{ columnGap: "15px" }} className="flex mt-10">
            <Button variant="contained" onClick={handleCreateSlot}>
              {editState ? "Edit slot" : "Create slot"}
            </Button>
            <Button variant="contained" onClick={handleCancelDrw}>
              cancel
            </Button>
          </div>
        </DrawerContainer>
      </DrawerComp>

      <CustomModal open={openDailog} onClose={handleCloseModal} height="300px">
        <div className="alertBox">
          <h3>Are you sure you want to delete the slot?</h3>
          <div className="alertBtnCon">
            <Button onClick={handleDeleteSlot} variant="contained">
              Yes
            </Button>
            <Button onClick={handleCloseModal} variant="contained">
              No
            </Button>
          </div>
        </div>
      </CustomModal>
    </Container>
  );
};

export default SlotMaster;

// table
// add slot master
// edit slot
const tableColumns = [
  {
    id: "slot_id",
    label: "Slot Id",
  },
  {
    id: "course_name",
    label: "Course Name",
  },
  {
    id: "slot_type",
    label: "Slot Type",
  },
  {
    id: "start_date",
    label: "Start Date",
  },
  {
    id: "end_date",
    label: "End Date",
  },

  {
    id: "slot_location",
    label: "Slot Location",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

const tableData = [
  {
    slot_id: "1",
    course_name: "Test",
    slot_type: "Flying Slot",
    slot_location: "Mumbai",
  },
];
export const slotTypes = [
  // "Online Class",
  "Offline Class",
  "Flying Class",
];

const slotTypesObj = [
  // { id: 1, title: "Online Class" },
  { id: 2, title: "Offline Class" },
  { id: 3, title: "Flying Class" },
];
