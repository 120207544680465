import editIcon from "../../Assets/edit_icon.svg";

export const modifyCourseBookData = (data, handleRedirect, userPermission) => {
  const modifiedArray = data?.map((item) => {
    return {
      bookin_status: (
        <div
          onClick={() => handleRedirect(item?.id)}
          style={{ fontSize: "12px" }}
        >
          {item?.purchase_status_str || "N.A"}
        </div>
      ),
      book_id: (
        <div
          onClick={() => handleRedirect(item?.id)}
          style={{ fontSize: "12px" }}
        >
          {item?.id}
        </div>
      ),
      book_date: (
        <div
          onClick={() => handleRedirect(item?.id)}
          style={{ fontSize: "12px" }}
        >{`${helperdayMonthYearFull(item?.created)} `}</div>
      ),
      user_name: (
        <div onClick={() => handleRedirect(item?.id)}>
          <div style={{ fontSize: "12px" }}> {item?.user_name}</div>
          <div style={{ fontSize: "12px" }}> {item?.mobile}</div>
        </div>
      ),
      course_name: (
        <div
          style={{ minWidth: "120px", fontSize: "12px" }}
          onClick={() => handleRedirect(item?.id)}
        >
          {item?.course_name}
        </div>
      ),
      batch_name: (
        <div
          style={{ minWidth: "100px", fontSize: "12px" }}
          onClick={() => handleRedirect(item?.id)}
        >
          {item?.batch_name}
        </div>
      ),
      mode: (
        <div
          onClick={() => handleRedirect(item?.id)}
          style={{ fontSize: "12px" }}
        >
          {item?.mode}
        </div>
      ),
      start_date: item?.batch_start_date ? (
        <div
          onClick={() => handleRedirect(item?.id)}
          style={{ fontSize: "12px" }}
        >
          {helperdayMonthYearFull(item?.batch_start_date)}
        </div>
      ) : (
        "NA"
      ),

      slot_fly: item?.flying_slot?.start_date ? (
        <div
          style={{ minWidth: "160px", fontSize: "12px" }}
          onClick={() => handleRedirect(item?.id)}
        >{`${helperdayMonthYearFull(
          item?.flying_slot?.start_date
        )} to ${helperdayMonthYearFull(item?.flying_slot?.end_date)} `}</div>
      ) : (
        "N.A"
      ),

      // slot_flying_center: (
      //   <div onClick={() => handleRedirect(item?.id)}>
      //     {item?.flying_slot?.location?.line_1},
      //     {item?.flying_slot?.location?.line_2},
      //     {item?.flying_slot?.location?.area},
      //     {item?.flying_slot?.location?.city},
      //     {item?.flying_slot?.location?.state},
      //     {item?.flying_slot?.location?.country},
      //     {item?.flying_slot?.location?.pincode},
      //   </div>
      // ),
      location: (
        <div
          onClick={() => handleRedirect(item?.id)}
          style={{ fontSize: "12px" }}
        >
          {item?.batch?.location?.line_1},{item?.batch?.location?.line_2},
          {item?.batch?.location?.area},{item?.batch?.location?.city},
          {item?.batch?.location?.state},{item?.batch?.location?.country},
          {item?.batch?.location?.pincode},{" "}
        </div>
      ),
      payment_status: (
        <div
          onClick={() => handleRedirect(item?.id)}
          style={{ fontSize: "12px" }}
        >
          {item?.payment_status_str || "N.A"}
        </div>
      ),
      amount: (
        <div
          onClick={() => handleRedirect(item?.id)}
          style={{ fontSize: "12px" }}
        >
          <span>&#x20b9;</span> {item?.total_payable || "N.A"}
        </div>
      ),
      accommodation: (
        <div
          onClick={() => handleRedirect(item?.id)}
          style={{ fontSize: "12px" }}
        >
          {item?.hotel_name || "N.A"}
        </div>
      ),
      accomodation_date: item?.accomodation_date ? (
        <div
          onClick={() => handleRedirect(item?.id)}
          style={{ fontSize: "12px" }}
        >
          {helperdayMonthYearFull(item?.accomodation_date)}
        </div>
      ) : (
        "N.A"
      ),

      invoice: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: "10px",
            justifyContent: "center",
          }}
        >
          <span
            style={{ cursor: "pointer", fontSize: "12px" }}
            onClick={() => handleRedirect(item, "generate")}
          >
            Generate Invoice
          </span>
          <span
            style={{ cursor: "pointer", fontSize: "12px" }}
            onClick={() => handleRedirect(item?.id, "download")}
          >
            Download Invoice
          </span>
        </div>
      ),

      actions: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: "10px",
            justifyContent: "center",
          }}
        >
          {userPermission?.update && (
            <img
              src={editIcon}
              style={{ cursor: "pointer" }}
              onClick={() => handleRedirect(item, "edit")}
            />
          )}

          {/* <span
            style={{ cursor: "pointer" }}
            onClick={() => handleRedirect(item?.id)}
          >
            Delete
          </span> */}
        </div>
      ),
    };
  });
  return modifiedArray;
};

export function helperdayMonthYearFull(date_) {
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const d = new Date(date_);
  let day = d.getDate();
  let name = month[d.getMonth()];
  let year = d.getFullYear();

  return day + " " + name + " " + year;
}
