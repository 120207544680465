import React, { useEffect, useState } from "react";
import Container from "../../Component/Container/Container";
import { token_api } from "../../Utils/network";
import { formatDroneInventoryList } from "./utility";
import FilterContainer from "../../Component/FitlerContainer/FilterContainer";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import CustomSearchFilter from "../../Component/CustomSearch/CustomSearchFilter";
import CustomTable from "../../Component/CustomTable/CustomTable";
import CustomPagination from "../../Component/CustomPagination/CustomPagination";
import styles from "../DroneMaster/drone.module.css";
import CustomTabs3 from "../../Component/CustomTabsNew/CustomTabs3";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import Droneinventorylist from "./Droneinventorylist";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DroneBatteries from "./DroneBatteries";
import { useNavigate, Link } from "react-router-dom";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import VehicleInventory from "../../Component/VehicleInventory/VehicleInventory";
import ChargerInventory from "../../Component/ChargerInventory/ChargerInventory";
import pilotUser from "../../Assets/pilotUser.svg";
import dronePilot from "../../Assets/dronePilot.svg";
import CustomCard from "../../Component/CustomCard/CustomCard";
import droneInventoryBook from "../../Assets/droneInventoryBook.svg";
import droneInventorySetting from "../../Assets/droneInventorySetting.svg";
import ChargingHubInventory from "../../Component/ChargingHubInventory/ChargingHubInventory";
import LaptopInventory from "../../Component/LaptopInventory/LaptopInventory";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DroneInventory = () => {
  let navigate = useNavigate();
  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <div className="orange-1 bread">Drone & Battery Inventory</div>,
  ];
  const [tabValue, setTabValue] = useState(1);
  const [value, setValue] = React.useState(0);

  const [cardData, setCardData] = useState({
    assign: { name: "Assigned", value: null },
    not_assign: { name: "Not Assigned", value: null },
    working: { name: "Working", value: null },
    damaged: { name: "Damaged", value: null },
    sold: { name: "Sold", value: null },
    repair: { name: "Under Repair", value: null },
    at_base: { name: "At Base", value: null },
    on_field: { name: "On Field", value: null },
    training: { name: "Training", value: null },
    survey_service: { name: "Survey Service", value: null },
    agri_service: { name: "Agri Service", value: null },
    no_category_assign: { name: "No Application Assigned", value: null },
  });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    getDashboardCount();
  }, []);
  const handleNavigateBack = () => {
    navigate(`/dashboard`);
  };
  const getDashboardCount = () => {
    token_api
      .get(`drone/inventory/dashboard_count`)
      .then((res) => {
        if (res.data.data) {
          const {
            assign,
            not_assign,
            damaged,
            sold,
            working,
            under_repair,
            agri_service,
            no_category_assign,
            survey_service,
            training,
            at_base,
            on_field,
          } = res.data.data;
          const _data = {
            assign: { name: "Assigned", value: assign },
            not_assign: { name: "Not Assigned", value: not_assign },
            damaged: { name: "Damaged", value: damaged },
            working: { name: "Working", value: working },
            sold: { name: "Sold", value: sold },
            repair: { name: "Under Repair", value: under_repair },
            at_base: { name: "At Base", value: at_base },
            on_field: { name: "On Field", value: on_field },
            training: { name: "Training", value: training },
            survey_service: { name: "Survey Service", value: survey_service },
            agri_service: { name: "Agri Service", value: agri_service },
            no_category_assign: {
              name: "No Application Assigned",
              value: no_category_assign,
            },
          };
          setCardData(_data);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
          <img src={breadCrumbBack} />
        </div>
        <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
      </div>
      <div className={styles.tabContainer}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="All Drone Inventory" {...a11yProps(0)} />
              <Tab label="All Battery Inventory" {...a11yProps(1)} />
              <Tab label="All Vehicle Inventory" {...a11yProps(2)} />
              <Tab label="All Charger Inventory" {...a11yProps(3)} />
              <Tab label="All Charger Hub Inventory" {...a11yProps(4)} />
              <Tab label="All Laptop Inventory" {...a11yProps(5)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <div
              className={styles.pilotDash}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                // flexWrap: "wrap",
              }}
            >
              <div className={styles.dashboardCard}>
                <CustomCard
                  cardIcon={pilotUser}
                  data={{
                    assign: cardData.assign,
                    not_assign: cardData.not_assign,
                  }}
                  background={`linear-gradient(to left, rgb(81, 79, 255), rgb(87, 194, 255))`}
                />
              </div>
              <div className={styles.dashboardCard}>
                <CustomCard
                  cardIcon={dronePilot}
                  data={{
                    working: cardData.working,
                    damaged: cardData.damaged,
                    sold: cardData.sold,
                    repair: cardData.repair,
                  }}
                  background={` linear-gradient(103.23deg, #57B67A -2.03%, #0B7754 96.35%)`}
                />
              </div>
              <div className={styles.dashboardCard}>
                <CustomCard
                  cardIcon={droneInventoryBook}
                  data={{
                    at_base: cardData.at_base,
                    on_field: cardData.on_field,
                  }}
                  background={`linear-gradient(to left, rgba(132, 98, 251, 1), rgba(235, 137, 235, 1))`}
                />
              </div>

              <div className={styles.dashboardCard}>
                <CustomCard
                  cardIcon={droneInventorySetting}
                  data={{
                    training: cardData?.training,
                    survey_service: cardData?.survey_service,
                    agri_service: cardData?.agri_service,
                    no_category_assign: cardData?.no_category_assign,
                  }}
                  background={`linear-gradient(to left, rgba(246, 133, 100, 1), rgba(245, 199, 99, 1))`}
                />
              </div>
            </div>

            <Droneinventorylist />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <DroneBatteries />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <VehicleInventory />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <ChargerInventory />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            <ChargingHubInventory />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={5}>
            <LaptopInventory />
          </CustomTabPanel>
        </Box>
      </div>
    </Container>
  );
};

export default DroneInventory;

const tabData = [
  {
    id: 1,
    title: "All Drone Inventory",
  },
  {
    id: 2,
    title: "All Battery Inventory",
  },
];

const tableColumns = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "drone_name",
    label: "Drone",
  },
  {
    id: "drone_category",
    label: "Category",
  },
  {
    id: "serial_no",
    label: "Serial No.",
  },
  {
    id: "location",
    label: "Location",
  },
  {
    id: "registered_on",
    label: "Registered On",
  },
  {
    id: "updated_on",
    label: "Updated On",
  },
  {
    id: "updated_by",
    label: "Updated By",
  },
  {
    id: "drone_status",
    label: "Status",
  },
  {
    id: "actions",
    label: "",
  },
];
