import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useRef } from "react";

const TextEditor = ({ editorRef, initialValue, height = 300 }) => {
  // const editorRef = useRef(null);
  // const log = () => {
  //   if (editorRef.current) {
  //     console.log("Logingeditorcontent", editorRef.current.getContent());
  //   }

  // };
  // ka3m4vtkuvdfyccl7nlbiycjzx084zvh0r08xvoxk8bi8i16

  return (
    <div>
      <Editor
        apiKey="e71xpjbtacm0kyew562751t4ad064ut2ve8fbgam7tgtwa6m"
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={initialValue}
        init={{
          height: height,
          menubar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | blocks | " +
            "bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
      {/* <button onClick={log}>Log editor content</button> */}
    </div>
  );
};

export default TextEditor;
