export const ValidateReview = (value) => {
  return new Promise((resolve, reject) => {
    const { comment, rating, status, course, user } = value;

    const errors = { error: false };
    if (!comment) {
      errors.comment = "Please enter comment";
      errors.error = true;
    }

    if (!user || Object.keys(user).length === 0) {
      errors.user = "User is required";
      errors.error = true;
    }

    if (!course || Object.keys(course).length === 0) {
      errors.course = "Course is required";
      errors.error = true;
    }
    if (!rating || Object.keys(rating).length === 0) {
      errors.rating = "Rating is required";
      errors.error = true;
    }
    if (!status || Object.keys(status).length === 0) {
      errors.status = "Status is required";
      errors.error = true;
    }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};
